import styles from "./SelectAspectRatio.module.scss";

interface IProps {
    selectedAspectRatio: number | undefined;
    handleAspectRatioChange: (aspectRatio: number | undefined) => void;
}

export function SelectAspectRatio(props: IProps) {
    const possibleAspectRatios = [
        { title: "Inget format", value: undefined },
        { title: "16:9", value: 16 / 9 },
        { title: "3:2", value: 3 / 2 },
        { title: "4:3", value: 4 / 3 },
        { title: "3:4", value: 3 / 4 },
        { title: "1:1", value: 1 },
    ];
    return (
        <div className={styles.wrapper}>
            {possibleAspectRatios.map((aspectRatio) => (
                <div
                    key={aspectRatio.value ? aspectRatio.value : "nothing"}
                    className={props.selectedAspectRatio === aspectRatio.value ? styles.aspectSelected : styles.aspect}
                    onClick={() => props.handleAspectRatioChange(aspectRatio.value)}
                >
                    <div className={styles.text}>{aspectRatio.title}</div>
                    {aspectRatio.value ? (
                        <div className={styles.visual} style={{ aspectRatio: aspectRatio.value + "" }}></div>
                    ) : null}
                </div>
            ))}
        </div>
    );
}
