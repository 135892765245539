import React, { useState } from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import { IconMap } from "../../icons/map";
import EditMap from "./components/EditMap/EditMap";
import styles from "./ContentTypeMap.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataMap } from "../../interfaces/IContentData";
import { Map } from "./components/Map/Map";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataMap;
    updateContentData: (value: IContentDataMap) => void;
}

export const ContentTypeMapEdit = (props: IProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {showModal === true && (
                <ActionCurtain close={() => setShowModal(false)}>
                    <EditMap
                        site={props.site}
                        contentData={props.contentData}
                        updateContentData={props.updateContentData}
                        onSuccess={() => setShowModal(false)}
                    />
                </ActionCurtain>
            )}
            <div className={styles.editableWrapper} onClick={() => setShowModal(true)}>
                {props.contentData.map ? (
                    <Map site={props.site} map={props.contentData} linkToExternalMap={false}/>
                ) : (
                    <div className={styles.placeholder}>
                        <IconMap />
                        <div className={styles.helpText}>Ingen kartplats angiven</div>
                    </div>
                )}
            </div>
        </>
    );
};
