import { ISite, ISiteV2 } from "../../../../../../interfaces/Site";
import { PaymentReceipt } from "../../../../../../modules/PaymentReceipt/PaymentReceipt";
import styles from "./CheckoutConfirmation.module.scss";

interface IProps {
    site: ISite | ISiteV2;
    product: "subdomain" | "domain-name" | "domain-other-registrar";
    paymentReference?: string;
}

export const CheckoutConfirmation = (props: IProps) => {
    return (
        <div className={styles.wrapper} data-test-id="checkout-confirmation-module">
            <div className={styles.grid}>
                <div className={styles.sectionFullWidth}>
                    <h1>{props.paymentReference ? "Tack för ditt köp!" : "Bekräftelse"}</h1>
                    {props.product === "subdomain" ? (
                        <div className={styles.intro}>
                            Nu finns din sida publicerad på{" "}
                            <a href={"https://" + props.site.host} target="_blank" rel="noreferrer">
                                {props.site.host}
                            </a>
                            .
                        </div>
                    ) : null}
                    {props.product === "domain-name" ? (
                        <div className={styles.intro}>
                            <a href={"https://" + props.site.host} target="_blank" rel="noreferrer">
                                {props.site.host}
                            </a>{" "}
                            är nu kopplad till din sida. Det kan dröja upp till 48 timmar innan domänen fungerar från
                            alla internetanslutningar världen över. Oftast fungerar det inom några timmar hos de flesta.
                        </div>
                    ) : null}
                    {props.product === "domain-other-registrar" ? (
                        <>
                            <div className={styles.infoBox}>
                                <p>
                                    Nu har vi aktiverat stöd för externa domäner på ditt konto och kopplat din sida till{" "}
                                    {props.site.host}. Du behöver ändra DNS-inställningarna på din domän hos ditt
                                    webbhotell. Om du är osäker på hur du ska göra, kontakta ditt webbhotell så hjälper
                                    de dig med hur man ändrar hos dem.{" "}
                                    <strong>
                                        Inställningarna nedan kan komma att ändras. Du informeras i sådana fall via mejl
                                        minst en månad i förväg vilka ändringar du måste göra för att sidan ska
                                        fortsätta fungera.{" "}
                                    </strong>{" "}
                                    Följande inställningar behöver göras nu:
                                </p>
                                <div className={styles.dnsInfo}>
                                    <p>
                                        Subdomän: @<br />
                                        Typ: A<br />
                                        TTL: 1800 (eller standard för webbhotellet)
                                        <br />
                                        Data: 76.76.21.21
                                    </p>
                                    <p>
                                        Subdomän: www
                                        <br />
                                        Typ: CNAME
                                        <br />
                                        TTL: 1800 (eller standard för webbhotellet)
                                        <br />
                                        Data: cname.vercel-dns.com.
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
                {props.paymentReference ? (
                    <div className={styles.receipt}>
                        <h3 className={styles.sectionTitle}>Kvitto</h3>
                        <div>
                            <PaymentReceipt paymentReference={props.paymentReference} siteId={props.site._id} />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
