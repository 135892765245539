import React from "react";
import { giftRegistryReservedQuantity } from "../../utils/GiftRegistryReservedQuantity";
import { IContentDataGiftRegistryItem, ISiteModuleGiftRegistry } from "../../../../../../interfaces/ISiteModule";
import { IGiftRegistryReservations, ISiteV2 } from "../../../../../../interfaces/Site";
import { ContentTypeText } from "../../../../../../contentTypes/ContentTypeText/ContentTypeText";

interface IProps {
    site: ISiteV2;
    className: string;
    siteModule: ISiteModuleGiftRegistry;
    giftRegistryItem: IContentDataGiftRegistryItem;
    giftRegistryReservations: IGiftRegistryReservations[] | null;
}

export function QuantityAndReservedQuantity(props: IProps) {
    if (props.giftRegistryItem.reservable !== true) {
        return null;
    }
    const reservedQuantity = giftRegistryReservedQuantity(props.giftRegistryItem, props.giftRegistryReservations);

    return (
        <div className={props.className}>
            {props.giftRegistryItem.quantity === 1 ? (
                <>{reservedQuantity > 0 ? <ContentTypeText site={props.site} contentData={props.siteModule.statusTextReservedSingular} tag={"span"} /> : ""}</>
            ) : (
                <>
                    <ContentTypeText site={props.site} contentData={props.siteModule.statusTextQuantity} tag={"span"} />: {props.giftRegistryItem.quantity}
                    {!!reservedQuantity && <span>, <ContentTypeText site={props.site} contentData={props.siteModule.statusTextReservedPlural} tag={"span"} />: {reservedQuantity}</span>}
                </>
            )}
        </div>
    );
}
