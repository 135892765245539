import { IRsvpV2 } from "../../ViewRsvp";
import dayjs from "dayjs";
import styles from "./RsvpAnswers.module.scss";
import { getInRsvpTranslation, getInRsvpTranslationOneLanguage } from "../../utils/getInRsvpTranslation";
import { IFormElementSummary, uniqueFormElementsFromRsvps } from "../../utils/uniqueFormElementsFromRsvps";
import { answerFromFormElementId } from "../../utils/textAnswerFromFormElementId";
import { RsvpChoiceGraphs } from "./components/RsvpChoiceGraphs/RsvpChoiceGraphs";
import { IconDelete } from "../../../../../../icons/delete";
import { useState } from "react";
import { Confirmation } from "../../../../../../modules/confirmation/Confirmation";
import { Dialog } from "../../../../../../editComponents/Dialog/Dialog";
import { DialogTop } from "../../../../../../editComponents/DialogTop/DialogTop";
import { IconChevronDown } from "../../../../../../icons/chevronDown";

interface IProps {
    rsvps: IRsvpV2[];
    demo: boolean;
    deleteRsvpItem: (id: string) => void;
}

export const RsvpAnswers = (props: IProps) => {
    const [deleteItemId, setDeleteItemId] = useState<null | string>(null);
    const [sort, setSort] = useState<{ column: string | null; orderReversed: boolean }>({
        column: null,
        orderReversed: false,
    });

    const getAnswerStyling = (rsvp: IRsvpV2, column: IFormElementSummary) => {
        if (column.formElementType === "ShortText") return "";
        const formElement = rsvp.formElements.find((formElement) => formElement.formElement.id === column.id);

        let answer = getInRsvpTranslation(formElement?.selectedOption?.text, rsvp);

        if (!answer) return "";

        answer = answer.toUpperCase();
        if (["JA", "JA!", "JA, SJÄLVKLART!", "YES", "YES!"].indexOf(answer) > -1) {
            return styles.statusYes;
        } else if (["NEJ", "NEJ TYVÄRR", "NEJ, TYVÄRR", "NEJ, JAG KAN TYVÄRR INTE KOMMA", "NO"].indexOf(answer) > -1) {
            return styles.statusNo;
        }

        return "";
    };

    const questions = uniqueFormElementsFromRsvps(props.rsvps);

    let sortedRsvps = props.rsvps;

    const sortColumn = sort.column;
    if (sortColumn) {
        sortedRsvps = sortedRsvps.sort((a, b) => {
            const aValue = sortColumn === "DATE" ? a.createdAt : answerFromFormElementId(a, sortColumn) || "";
            const bValue = sortColumn === "DATE" ? b.createdAt : answerFromFormElementId(b, sortColumn) || "";
            const compare = aValue.localeCompare(bValue, undefined, { numeric: true, sensitivity: "base" });
            return sort.orderReversed ? -compare : compare;
        });
    }

    const sortOnColumn = (columnId: string) => {
        setSort({
            column: columnId,
            orderReversed: sort.column === columnId ? !sort.orderReversed : false,
        });
    };

    return (
        <div className={styles.wrapper}>
            {deleteItemId ? (
                !props.demo ? (
                    <Confirmation
                        confirmationTitle="Ta bort"
                        confirmButtonText="Ta bort"
                        abortButtonText="Avbryt"
                        confirmationText="Är du säker på att du vill ta bort det här OSA-svaret? Åtgärden går inte att ångra"
                        confirmCallback={() => props.deleteRsvpItem(deleteItemId)}
                        closeCallback={() => setDeleteItemId(null)}
                    />
                ) : (
                    <Dialog
                        small={true}
                        closableByOutsideClickAndEsc={true}
                        handleClose={() => setDeleteItemId(null)}
                        open={true}
                    >
                        <DialogTop title="Radera anmälan" close={() => setDeleteItemId(null)} />
                        Du kan inte ta bort den här. Exempelanmälningarna försvinner automatiskt när du fått in din
                        första riktiga OSA.
                    </Dialog>
                )
            ) : null}
            <h1>
                OSA
                <div className={styles.totalCount}>{props.rsvps.length} svar</div>
            </h1>
            {props.demo === true ? (
                <div className={styles.intro}>
                    Du har inte fått någon OSA än. Nedan visar vi några exempelanmälningar. Du väljer själv vilka frågor
                    dina gäster ska svara på när du skapar OSA-formuläret.
                </div>
            ) : null}
            <RsvpChoiceGraphs questions={questions} rsvps={props.rsvps} />
            <div className={styles.answers}>
                <table>
                    <thead>
                        <tr>
                            {questions.map((column) => (
                                <th key={column.id} onClick={() => sortOnColumn(column.id)}>
                                    <div className={styles.thInnerWrapper}>
                                        <div className={styles.truncate}>
                                            {getInRsvpTranslationOneLanguage(column.label)}
                                        </div>
                                        {sort.column === column.id ? (
                                            <div
                                                className={
                                                    sort.orderReversed
                                                        ? styles.sortIndicatorReversed
                                                        : styles.sortIndicator
                                                }
                                            >
                                                <IconChevronDown />
                                            </div>
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                            <th onClick={() => sortOnColumn("DATE")}>
                                <div className={styles.thInnerWrapper}>
                                    Datum
                                    {sort.column === "DATE" ? (
                                        <div
                                            className={
                                                sort.orderReversed ? styles.sortIndicatorReversed : styles.sortIndicator
                                            }
                                        >
                                            <IconChevronDown />
                                        </div>
                                    ) : null}
                                </div>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedRsvps.map((rsvp) => (
                            <tr key={rsvp._id}>
                                {questions.map((column) => (
                                    <td key={column.id}>
                                        <span className={getAnswerStyling(rsvp, column)}>
                                            {answerFromFormElementId(rsvp, column.id)}
                                        </span>
                                    </td>
                                ))}
                                <td className={styles.dateColumn}>{dayjs(rsvp.createdAt).format("YYYY-MM-DD")}</td>
                                <td>
                                    <button onClick={() => setDeleteItemId(rsvp._id)} className={styles.delete}>
                                        {IconDelete()}
                                        <span>Ta bort</span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
