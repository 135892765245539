import { useEffect, useState } from "react";
import { ISite, ISiteV2, ISiteVersion } from "../../interfaces/Site";
import { ActionBar } from "../../modules/actionBar/ActionBar";
import { SiteGraphic } from "../../components/SiteGraphic/SiteGraphic";
import { Menu } from "../../modules/Menu/Menu";
import { SelectLanguageEdit } from "../../components/SelectLanguage/SelectLanguageEdit";
import { SiteModulesList } from "../../siteModules/SiteModulesList";
import { SiteModulesListWrapper } from "./components/SiteModulesListWrapper/SiteModulesListWrapper";
import { SiteFontSetter } from "./components/SiteFontSetter/SiteFontSetter";
import { ThemeStylesSetter } from "./components/ThemeStylesSetter/ThemeStylesSetter";
import { IError } from "../../../App";
import Loading from "../../components/Loading/Loading";
import ShowError from "../../components/ShowError/ShowError";
import { isSiteV2 } from "../../utils/isSiteV2";
import { getEditSite, putEditSiteV2 } from "../../services/SiteService";
import { LanguageProvider } from "../../../contexts/LanguageContext";
import { loadSiteFonts } from "../../utils/LoadSiteFonts";
import {
    setGeneratorColorStylesVariables,
    setGeneratorFontsStylesVariables,
} from "../../utils/SetGeneratorStylesVariables";
import { ActionBarItem } from "../../modules/actionBar/components/ActionBarItem/ActionBarItem";
import { IconSave } from "../../icons/save";
import { IconClose } from "../../icons/close";
import { IconVersions } from "../../icons/versions";
import Confirmation from "../../modules/confirmation/Confirmation";
import { SelectSiteVersion } from "./components/SelectSiteVersion/SelectSiteVersion";
import { Redirect } from "react-router-dom";
import { TLanguageCode } from "../../interfaces/IContentData";

interface IProps {
    siteId: string;
    drawerSlotPortalNode?: JSX.Element;
}

const appLanguage = "sv";

export function SiteVersionsPreview(props: IProps) {
    const [version, setVersion] = useState<ISiteVersion | null>(null);
    const [showSelectVersion, setShowSelectVersion] = useState<boolean>(true);
    const [showUseVersionConfirmation, setShowUseVersionConfirmation] = useState<boolean>(false);

    const [loading, setLoading] = useState(true);
    const [redirectToEditPage, setRedirectToEditPage] = useState(false);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [currentSite, setCurrentSite] = useState<ISite | ISiteV2 | null>(null);
    const [error, setError] = useState<IError | null>(null);

    useEffect(() => {
        const getSite = async () => {
            setLoading(true);
            try {
                const data = await getEditSite(props.siteId);
                setCurrentSite(data);
            } catch (error) {
                setError({ text: "Misslyckades att hämta sidan", response: (error as any).response });
            }
            setLoading(false);
        };
        getSite();
    }, [props.siteId]);

    if (redirectToEditPage) {
        return <Redirect to={"/redigera/" + props.siteId + "/"} />;
    }

    if (loading) {
        return <Loading fixed={true} inverted={true} />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    if (!currentSite) {
        throw Error("Site not loaded but not error");
    }

    if (!isSiteV2(currentSite)) {
        throw Error("Site not version 2");
    }

    const site = {
        ...currentSite,
        ...version,
    };

    loadSiteFonts(site.fonts);
    setGeneratorFontsStylesVariables(site.fonts);
    setGeneratorColorStylesVariables(site.colorScheme);

    const handleSaveAsNewVersion = async () => {
        setLoadingOverlay(true);
        try {
            await putEditSiteV2(site);
        } catch (error) {
            setError({ text: "Misslyckades att spara över med ny version", response: (error as any).response });
        }
        setLoadingOverlay(false);
        setRedirectToEditPage(true);
    };

    const getFirstLanguage = (): TLanguageCode => {
        if (isSiteV2(site) && site.availableLanguages?.length) {
            if (site.availableLanguages.find((lang) => lang.languageCode === appLanguage)) {
                return appLanguage;
            }
            return site.availableLanguages[0].languageCode;
        }
        return appLanguage;
    };

    return (
        <>
            {showUseVersionConfirmation ? (
                <Confirmation
                    confirmationTitle="Använd version"
                    confirmButtonText="Ja, använd version"
                    abortButtonText="Avbryt"
                    confirmationText={
                        "Vill du använda denna versionen? Om du väljer ja kommer denna versionen att sparas som en ny version och ersätta din befintliga. Du kan alltid återgå till tidigare versioner, även den du skriver över nu."
                    }
                    confirmCallback={handleSaveAsNewVersion}
                    closeCallback={() => setShowUseVersionConfirmation(false)}
                />
            ) : null}
            {loadingOverlay ? <Loading fixed={true} /> : null}
            <LanguageProvider defaultLanguage={getFirstLanguage()}>
                <ThemeStylesSetter site={site}>
                    <div id="DialogPublicPlacement" />
                    <SelectSiteVersion
                        site={site}
                        setVersion={setVersion}
                        close={() => setShowSelectVersion(false)}
                        open={showSelectVersion}
                        portalNode={props.drawerSlotPortalNode}
                        latestVersion={currentSite.siteVersion}
                    />
                    <ActionBar primary={true}>
                        <>
                            <ActionBarItem
                                text="Alla versioner"
                                icon={IconVersions()}
                                onClick={() => setShowSelectVersion(!showSelectVersion)}
                                active={showSelectVersion}
                            />
                            <ActionBarItem
                                text="Använd denna version"
                                onClick={() => setShowUseVersionConfirmation(true)}
                                icon={IconSave()}
                                hide={version?.siteVersion === currentSite.siteVersion || !version}
                            />
                            <ActionBarItem text="Avbryt" to={"/redigera/" + site._id + "/"} icon={IconClose()} />
                        </>
                    </ActionBar>
                    <SiteFontSetter>
                        {site.siteGraphic ? (
                            <SiteGraphic type="SITE" siteGraphic={site.siteGraphic} editAvailable={true} />
                        ) : null}
                        <Menu site={site} editMode={false} editAvailable={true} />

                        <SelectLanguageEdit site={site} />

                        <SiteModulesListWrapper site={site}>
                            <SiteModulesList editAvailable={true} site={site} />
                        </SiteModulesListWrapper>
                    </SiteFontSetter>
                </ThemeStylesSetter>
            </LanguageProvider>
        </>
    );
}
