import { TFormElementType } from "../../../../../interfaces/FormElement";
import { TTranslation } from "../../../../../interfaces/IContentData";
import { IRsvpV2 } from "../ViewRsvp";

export interface IFormElementSummary {
    id: string;
    label: TTranslation;
    formElementType: TFormElementType;
}

export const uniqueFormElementsFromRsvps = (rsvps: IRsvpV2[]): IFormElementSummary[] => {
    const allFormElements = rsvps.flatMap((rsvp) => {
        return rsvp.formElements.map((formElement) => ({
            id: formElement.formElement.id,
            label: formElement.formElement.label,
            formElementType: formElement.formElement.formElementType,
        }));
    });
    const columns = allFormElements.filter((a, i) => allFormElements.findIndex((s) => a.id === s.id) === i);
    return columns;
};
