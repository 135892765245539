import { TLanguageCode } from "../interfaces/IContentData";
import { TSiteModule } from "../interfaces/ISiteModule";

export const getSiteModuleMenuText = (siteModule: TSiteModule, language: TLanguageCode) => {
    let menuText = siteModule.menuTitle[language];
    
    if ("title" in siteModule && !menuText) {
        menuText = siteModule.title.text[language];
    }

    if (!menuText) {
        return null;
    }
    return menuText;
};
