import { Dialog } from "../Dialog/Dialog";
import { FeedbackForm } from "./components/FeedbackForm/FeedbackForm";

interface IProps {
    handleClose: () => void;
}

export const FeedbackDialog = (props: IProps) => {
    return (
        <Dialog open={true} small={true} handleClose={props.handleClose} closableByOutsideClickAndEsc={true}>
            <FeedbackForm handleClose={props.handleClose} />
        </Dialog>
    );
};
