import styles from "./RsvpFilter.module.scss";
import { IRsvpV2 } from "../../../../ViewRsvp";
import { IFormElementSummary } from "../../../../utils/uniqueFormElementsFromRsvps";
import { getInRsvpTranslationOneLanguage } from "../../../../utils/getInRsvpTranslation";
import Select from "../../../../../../../../components/form/Select/Select";
import { uniqueOptionAnswersFromRsvps } from "../../../../utils/uniqueOptionAnswersFromRsvps";
import { IFormElementOption } from "../../../../../../../../interfaces/FormElement";

export interface IRsvpFilter {
    question?: IFormElementSummary;
    answer?: IFormElementOption;
}

interface IProps {
    questions: IFormElementSummary[];
    rsvps: IRsvpV2[];
    filter: IRsvpFilter;
    setFilter: (filter: IRsvpFilter) => void;
}

export function RsvpFilter(props: IProps) {
    const options = props.questions
        .filter((question) => question.formElementType === "SingleSelect")
        .map((item) => ({
            title: getInRsvpTranslationOneLanguage(item.label) || "",
            value: item.id,
        }));

    const conditionQuestion = props.questions.find((item) => item.id === props.filter.question?.id);

    let answerOptions: { title: string; value: string }[] = [];
    let uniqueAnwersInRsvps: IFormElementOption[] = [];
    if (conditionQuestion) {
        uniqueAnwersInRsvps = uniqueOptionAnswersFromRsvps(conditionQuestion?.id, props.rsvps);
        answerOptions = uniqueAnwersInRsvps.map((option) => ({
            title: getInRsvpTranslationOneLanguage(option.text) || "",
            value: option.id,
        }));
        const notSelectedAnswer = { title: "Inget svar valt", value: "" };
        answerOptions?.unshift(notSelectedAnswer);
    }

    const notSelected = { title: "Ingen fråga vald", value: "" };

    options.unshift(notSelected);

    return (
        <div className={styles.wrapper}>
            <div>
                <h3>Filtrera</h3>
                <p>
                    Som standard visas alla frågor och svar nedan. Men här kan du filtrera på frågor med svarsalternativ
                    på ett specifikt svar. Det kan vara användbart för att tex. visa e-postadresser bara till de som har
                    tackat ja.
                </p>
            </div>
            <div className={styles.selects}>
                <div>
                    <Select
                        id="selectConditionQuestion"
                        label="Välj fråga"
                        options={options}
                        defaultValue={props.filter.question?.id}
                        onChangeCallback={(value: string) => {
                            const selectedQuestion = props.questions.find((question) => question.id === value);
                            props.setFilter({ question: selectedQuestion, answer: undefined });
                        }}
                    />
                </div>
                <div>
                    {props.filter.question && answerOptions.length ? (
                        <Select
                            id="selectConditionAnswer"
                            label="Välj svar"
                            options={answerOptions}
                            defaultValue={props.filter.answer?.id}
                            onChangeCallback={(value: string) => {
                                const selectedAnswer = uniqueAnwersInRsvps.find((answer) => answer.id === value);
                                props.setFilter({ question: props.filter.question, answer: selectedAnswer });
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}
