import React from "react";
import Button from "../../components/button/Button";
import { IImage } from "../../interfaces/Site";
import { UploadImage } from "../components/UploadImage/UploadImage";
import Edit, { IEditProps, IEditState } from "../Edit";
import { imageSizeV2, SelectImageSize } from "./components/SelectImageSize/SelectImageSize";
import { Image } from "../components/Image/Image";
import styles from "./Edit.module.scss";
import { CropImage } from "../../components/CropImage/CropImage";
import { getImageFromSite } from "../../utils/getSiteImage";

interface IProps extends IEditProps {
    addOrUpdateImageToSite: (image: IImage) => void;
    userCanChooseWidth?: boolean;
}

enum View {
    UPLOAD_AND_OVERVIEW,
    CROP,
}

interface IState extends IEditState {
    view: View;
}

export class EditImage extends Edit<IProps, IState> {
    state: IState = {
        view: View.UPLOAD_AND_OVERVIEW,
    };

    updateContentImage = (imageContent: any) => {
        imageContent.size = this.propValue() && this.propValue().size ? this.propValue().size : null;
        if (this.props.userCanChooseWidth && !imageContent.size) {
            imageContent.size = imageSizeV2.LARGE;
        }
        this.updateContent(imageContent);
    };

    delete = (): void => {
        this.updateContent(null);
    };

    revertToImageBeforeEdit = (): void => {
        const currentImageObject = this.propValue() ? getImageFromSite(this.propValue(), this.props.site) : null;
        if (!currentImageObject?.imageBeforeEdit) {
            throw new Error("Image is not edited");
        }
        const newImageObject = { ...currentImageObject, ...currentImageObject.imageBeforeEdit };
        delete newImageObject.imageBeforeEdit;
        this.props.addOrUpdateImageToSite(newImageObject);
    };

    render() {
        const currentImageObject = this.propValue() ? getImageFromSite(this.propValue(), this.props.site) : null;
        if (this.state.view === View.CROP) {
            return (
                <CropImage
                    updateContentImage={this.updateContentImage.bind(this)}
                    addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                    site={this.props.site}
                    image={this.propValue()}
                    goToImageView={() => this.setState({ view: View.UPLOAD_AND_OVERVIEW })}
                />
            );
        } else if (this.state.view === View.UPLOAD_AND_OVERVIEW) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.uploadImageAndSelectImageSize}>
                        <UploadImage
                            updateContentImage={this.updateContentImage.bind(this)}
                            addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                            site={this.props.site}
                        />
                        {this.props.userCanChooseWidth ? (
                            <SelectImageSize
                                property={this.props.property}
                                image={this.propValue()}
                                site={this.props.site}
                                updateProperty={this.props.updateProperty.bind(this)}
                                module={this.props.module}
                            />
                        ) : null}
                    </div>
                    {this.propValue() ? (
                        <div className={styles.currentImageAndEditImage}>
                            <div className={styles.inner}>
                                <div className={styles.imageWrapper}>
                                    <Image image={this.propValue()} site={this.props.site} sizes={null} />
                                </div>
                                <div className={styles.buttonWrapper}>
                                    <Button buttonText="Ta bort bild" type="warning" callback={this.delete} />
                                    {currentImageObject?.cdn === true ? (
                                        <Button
                                            buttonText="Beskär bild"
                                            type="secondary"
                                            callback={() => {
                                                this.setState({ view: View.CROP });
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            {currentImageObject?.imageBeforeEdit?.path ? (
                                <div className={styles.revertCropButton}>
                                    <Button
                                        buttonText="Ångra beskärning"
                                        type="secondary"
                                        callback={this.revertToImageBeforeEdit}
                                    />
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            );
        }
    }
}

export default EditImage;
