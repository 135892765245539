import { ISiteV2 } from "../../interfaces/Site";
import styles from "./SiteModuleCountdown.module.scss";
import { ISiteModuleCountdown } from "../../interfaces/ISiteModule";
import { Countdown } from "./components/Countdown/Countdown";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleCountdown;
}

export const SiteModuleCountdown = (props: IProps) => {
    return (
        <section className={styles[props.site.theme]}>
            <Countdown siteModule={props.siteModule} site={props.site} />
        </section>
    );
};
