import React from "react";
import styles from "./ContentTypeVideo.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataVideo } from "../../interfaces/IContentData";
import { getYoutubeIdFromUrl } from "../../utils/getYouTubeIdFromUrl";
import { Video } from "./components/Video/Video";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataVideo;
}

export const ContentTypeVideo = (props: IProps) => {
    const videoIdentifier = getYoutubeIdFromUrl(props.contentData.videoUrl);

    if (videoIdentifier) {
        return (
            <div className={styles.wrapper}>
                <Video autoStart={props.contentData.autoStart} videoIdentifier={videoIdentifier} />
            </div>
        );
    } else {
        return null;
    }
};
