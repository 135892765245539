import React, { useState } from "react";
import { IconColor } from "../../../landing/icons/colors";
import { Drawer } from "../../components/Drawer/Drawer";
import { IconFont } from "../../icons/font";
import { IconLayout } from "../../icons/layout";
import { enumTheme, IColorScheme, ISite, ISiteV2 } from "../../interfaces/Site";
import { ActionBar } from "../actionBar/ActionBar";
import { ActionBarItem } from "../actionBar/components/ActionBarItem/ActionBarItem";
import { EditColorV2 } from "../EditColorV2/EditColorV2";
import { EditFont } from "../EditFont/EditFont";
import { EditTheme } from "../EditTheme/EditTheme";
import { EditSiteGraphic } from "../EditSiteGraphic/EditSiteGraphic";
import { TSiteGraphic } from "../../components/SiteGraphic/SiteGraphic";
import { IconGraphic } from "../../../landing/icons/graphic";
import { IconDesignTemplate } from "../../icons/designTemplate";
import { EditDesignTemplate } from "../EditDesignTemplate/EditDesignTemplate";
import { IDesignTemplate } from "../EditDesignTemplate/utils/designTemplates";
import { TFontHeading, TFontText } from "../../enums/font";

interface IProps {
    site: ISite | ISiteV2;
    setTheme: (theme: enumTheme) => void;
    setFont: (font: TFontHeading | TFontText, fontType: "heading" | "text") => void;
    setColors: (colors: IColorScheme) => void;
    setSiteGraphic: (siteGraphic: TSiteGraphic | null) => void;
    setDesignTemplate: (designTemplate: IDesignTemplate) => void;
    close: () => void;
    open: boolean;
    portalNode: any;
}

enum View {
    DESIGN_TEMPLATES = "DESIGN_TEMPLATES",
    FONTS = "FONTS",
    COLORS = "COLORS",
    THEMES = "THEMES",
    SITE_GRAPHICS = "SITE_GRAPHICS",
}

export function EditDesign(props: IProps) {
    const [currentView, setCurrentView] = useState(View.DESIGN_TEMPLATES);

    return (
        <Drawer
            title="Design"
            close={props.close}
            open={props.open}
            portalNode={props.portalNode}
            resetScrollOnNewKey={currentView}
            drawerId="editDesign"
            actionBar={
                <ActionBar multipleRows={true}>
                    <ActionBarItem
                        text="Mallar"
                        icon={IconDesignTemplate()}
                        active={currentView === View.DESIGN_TEMPLATES}
                        onClick={() => setCurrentView(View.DESIGN_TEMPLATES)}
                    />
                    <ActionBarItem
                        text="Layout"
                        icon={IconLayout()}
                        active={currentView === View.THEMES}
                        onClick={() => setCurrentView(View.THEMES)}
                        testId="editDesignActionBarItemLayout"
                    />
                    <ActionBarItem
                        text="Typsnitt"
                        icon={IconFont()}
                        active={currentView === View.FONTS}
                        onClick={() => setCurrentView(View.FONTS)}
                        testId="editDesignActionBarItemFonts"
                    />
                    <ActionBarItem
                        text="Färger"
                        icon={IconColor()}
                        active={currentView === View.COLORS}
                        onClick={() => setCurrentView(View.COLORS)}
                        testId="editDesignActionBarItemColors"
                    />
                    <ActionBarItem
                        text="Grafik"
                        icon={IconGraphic()}
                        active={currentView === View.SITE_GRAPHICS}
                        onClick={() => setCurrentView(View.SITE_GRAPHICS)}
                        testId="editDesignActionBarItemSiteGraphic"
                    />
                </ActionBar>
            }
        >
            <div>
                {currentView === View.DESIGN_TEMPLATES && (
                    <EditDesignTemplate setDesignTemplate={props.setDesignTemplate} site={props.site} />
                )}
                {currentView === View.SITE_GRAPHICS && (
                    <EditSiteGraphic setSiteGraphic={props.setSiteGraphic} siteGraphic={props.site.siteGraphic} />
                )}
                {currentView === View.THEMES && <EditTheme setTheme={props.setTheme} site={props.site} />}
                {currentView === View.FONTS && <EditFont setFont={props.setFont} fonts={props.site.fonts} />}
                {currentView === View.COLORS && (
                    <EditColorV2 setColors={props.setColors} currentColors={props.site.colorScheme as IColorScheme} />
                )}
            </div>
        </Drawer>
    );
}
