export const fontHeadings = [
    "Parisienne",
    "Playfair Display",
    "Pompiere",
    "Fjalla One",
    "Amatic SC",
    "Zilla Slab",
    "Kaisei Tokumin",
    "Vidaloka",
    "Inspiration",
    "Lobster",
    "Alegreya Sans SC",
    "Josefin Slab",
    "Nixie One",
    "Cinzel Decorative",
    "Sacramento",
    "Gudea",
    "Mulish",
    "Dancing Script",
    "Coming Soon",
    "Great Vibes",
    "Life Savers",
    "Alex Brush",
] as const;
export type TFontHeading = (typeof fontHeadings)[number];

export const fontTexts = [
    "Open Sans",
    "Arial",
    "Georgia",
    "Raleway",
    "Cutive Mono",
    "Lato",
    "Crimson Pro",
    "Lora",
    "Anonymous Pro",
    "Lancelot",
    "Alegreya",
] as const;
export type TFontText = (typeof fontTexts)[number];