import styles from "./SiteModuleVideo.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { ISiteModuleVideo } from "../../interfaces/ISiteModule";
import { IContentDataTitle, IContentDataVideo } from "../../interfaces/IContentData";
import { ContentTypeModuleHeadingEdit } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeadingEdit";
import { ContentTypeVideoEdit } from "../../contentTypes/ContentTypeVideo/ContentTypeVideoEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleVideo;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleVideo, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
}

export const SiteModuleVideoEdit = (props: IProps) => {
    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeadingEdit
                contentData={props.siteModule.title}
                site={props.site}
                updateContentData={(value: IContentDataTitle) => props.updateSiteModuleProperty("title", value)}
            />
            <div className={styles.videoWrapper}>
                <ContentTypeVideoEdit
                    contentData={props.siteModule.video}
                    site={props.site}
                    updateContentData={(value: IContentDataVideo) => props.updateSiteModuleProperty("video", value)}
                />
            </div>
        </section>
    );
};
