import React from "react";
import { enumTheme, ISite, ISiteV2 } from "../../interfaces/Site";
import styles from "./EditTheme.module.scss";
import { ThemeItem } from "./ThemeItem/ThemeItem";

interface IProps {
    site: ISite | ISiteV2;
    setTheme: (theme: enumTheme) => void;
}

export class EditTheme extends React.Component<IProps> {
    themesCollection = ["theme4", "theme5", "theme7", "theme8", "theme9", "theme10", "theme11"];

    render() {
        return (
            <div className={styles.wrapper} data-test-id="edit-theme-list">
                {this.themesCollection.map((theme) => (
                    <ThemeItem
                        currentTheme={this.props.site.theme}
                        key={theme}
                        setTheme={this.props.setTheme}
                        theme={theme}
                    />
                ))}
            </div>
        );
    }
}
