import { TranslationWithFallback } from "../../../../components/TranslationWithFallback/TranslationWithFallback";
import { ContentBox } from "../../../../editComponents/ContentBox/ContentBox";
import { IconClose } from "../../../../icons/close";
import { IconDelete } from "../../../../icons/delete";
import { IconMoveDownContentModule } from "../../../../icons/moveDownContentModule";
import { IconMoveUpContentModule } from "../../../../icons/moveUpContentModule";
import { TFormElement } from "../../../../interfaces/FormElement";
import { IContentDataForm } from "../../../../interfaces/IContentData";
import { FormConditionIndicationText } from "./components/FormConditionIndicationText/FormConditionIndicationText";
import styles from "./FormElementListItem.module.scss";
import { useState } from "react";
import { DraftFunction } from "use-immer";

interface IProps {
    formElement: TFormElement;
    formElements: TFormElement[];
    updateForm: (updater: DraftFunction<IContentDataForm>) => void;
    setEdit: () => void;
}

export function FormElementListItem(props: IProps) {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const itemIsLast = () => {
        const index = props.formElements.findIndex((formElement) => formElement.id === props.formElement.id);
        return index === props.formElements.length - 1;
    };

    const itemIsFirst = () => {
        const index = props.formElements.findIndex((formElement) => formElement.id === props.formElement.id);
        return index === 0;
    };

    const moveItem = (indexAdjustment: number) => {
        props.updateForm((form) => {
            const oldIndex = form.formElements.findIndex((formElement) => formElement.id === props.formElement.id);
            let newIndex = oldIndex + indexAdjustment;

            if (newIndex < 0) {
                newIndex = 0;
            }

            if (newIndex >= form.formElements.length) {
                newIndex = form.formElements.length;
            }

            form.formElements.splice(newIndex, 0, { ...form.formElements.splice(oldIndex, 1)[0] });
        });
    };

    const deleteItem = () => {
        props.updateForm((form) => {
            const index = form.formElements.findIndex((formElement) => formElement.id === props.formElement.id);
            form.formElements.splice(index, 1);
        });
    };

    return (
        <ContentBox className={styles.wrapper} selectable={false} padding={false}>
            <div className={styles.left} onClick={props.setEdit}>
                <div className={styles.titleAndDescription}>
                    <div className={styles.title}><TranslationWithFallback translation={props.formElement.label} /></div>
                    <div className={styles.description}>
                        <span>{props.formElement.required ? "Obligatoriskt" : "Frivilligt"}</span>
                        <FormConditionIndicationText
                            formElements={props.formElements}
                            formElement={props.formElement}
                        />
                        {props.formElement.formElementType === "SingleSelect" ? (
                            <span>{props.formElement.options.length} svarsalternativ</span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            {showDeleteConfirmation ? (
                <div className={styles.deleteConfirmation}>
                    <div className={styles.questionText}>Ta bort?</div>
                    <div className={styles.confirmationsButtonsWrapper}>
                        <button onClick={() => deleteItem()}>
                            {IconDelete()}
                            <span>Ja, ta bort</span>
                        </button>
                        <button onClick={() => setShowDeleteConfirmation(false)}>
                            {IconClose()}
                            <span>Nej, avbryt</span>
                        </button>
                    </div>
                </div>
            ) : (
                <div className={styles.buttons}>
                    <button onClick={() => setShowDeleteConfirmation(true)} className={styles.delete}>
                        {IconDelete()}
                        <span className={styles.text}>Ta bort</span>
                    </button>
                    <div className={styles.move}>
                        {!itemIsFirst() ? (
                            <button onClick={() => moveItem(-1)}>{IconMoveUpContentModule()}</button>
                        ) : null}
                        {!itemIsLast() ? (
                            <button onClick={() => moveItem(1)}>{IconMoveDownContentModule()}</button>
                        ) : null}
                    </div>
                </div>
            )}
        </ContentBox>
    );
}
