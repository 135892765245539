import { IContentDataTitle } from "../../interfaces/IContentData";
import { ISiteV2 } from "../../interfaces/Site";
import { ContentTypeTitle } from "../ContentTypeTitle/ContentTypeTitle";
import styles from "./ContentTypeModuleHeading.module.scss";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataTitle;
}

export const ContentTypeModuleHeading = (props: IProps) => {
    return (
        <div className={styles[props.site.theme]}>
            <hr className={styles.hr} />
            <ContentTypeTitle
                tag="h2"
                contentData={props.contentData}
                site={props.site}
            />
        </div>
    );
};
