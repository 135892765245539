import { Updater } from "use-immer";
import { IconClose } from "../../../icons/close";
import { IconMoveUpContentModule } from "../../../icons/moveUpContentModule";
import { IconToggleContentModuleActionBar } from "../../../icons/toggleContentModuleActionBar";
import { TSiteModule, TSiteModuleType } from "../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../interfaces/Site";
import useOnClickOutside from "../../../utils/useOnClickOutside";
import styles from "./SiteModuleActionBar.module.scss";
import { useState } from "react";
import { AddSiteModule } from "../AddSiteModule/AddSiteModule";
import { IconDelete } from "../../../icons/delete";
import { IconNewContentModule } from "../../../icons/newContentModule";
import { IconShow } from "../../../icons/show";
import { IconHide } from "../../../icons/hide";
import { IconMoveDownContentModule } from "../../../icons/moveDownContentModule";
import Confirmation from "../../../modules/confirmation/Confirmation";
import { addSiteModule, deleteSiteModule, scrollToSiteModuleAfterUpdate, siteModuleMove, siteModuleIsFirst, siteModuleIsLast } from "../../utils/siteModulesUtils";
import { getSiteModuleSlug } from "../../../utils/getSiteModuleSlug";
import { useTranslation } from "../../../../contexts/LanguageContext";

interface IProps {
    siteModule: TSiteModule;
    site: ISiteV2;
    setShowActionBar: (show: boolean) => void;
    showActionBar: boolean;
    updateSite: Updater<ISiteV2>;
}

export function SiteModuleActionBar(props: IProps) {
    const pickerWrapper = useOnClickOutside(() => props.setShowActionBar(false), props.showActionBar);
    const [showAddSiteModule, setShowAddSiteModule] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const { language } = useTranslation();

    const deleteModule = () => {
        props.updateSite((draft) => {
            draft.siteModules = deleteSiteModule(draft.siteModules, props.siteModule);
        });
    };

    const moveModule = (indexAdjustment: number) => {
        props.updateSite((draft) => {
            draft.siteModules = siteModuleMove(draft.siteModules, props.siteModule.id, indexAdjustment);

            const slug = getSiteModuleSlug(props.siteModule, props.site.siteModules, language);
            scrollToSiteModuleAfterUpdate(slug);
        });
    };

    const toggleShowOrHide = () => {
        props.updateSite((draft) => {
            const module = draft.siteModules.find((module) => props.siteModule.id === module.id);
            if (!module) {
                throw new Error("No siteModule with id " + props.siteModule.id);
            }
            module.hide = !module.hide;
        });
    };

    const addNewModule = (type: TSiteModuleType) => {
        props.updateSite((site) => {
            const { modules, newModule } = addSiteModule(site.siteModules, type, props.siteModule);
            site.siteModules = modules;

            const slug = getSiteModuleSlug(newModule, props.site.siteModules, language);
            scrollToSiteModuleAfterUpdate(slug);
        });
        setShowAddSiteModule(false);
    };

    return (
        <>
            {showAddSiteModule ? (
                <AddSiteModule addNewModule={addNewModule} close={() => setShowAddSiteModule(false)} />
            ) : null}
            {showDeleteConfirmation ? (
                <Confirmation
                    confirmationTitle="Ta bort modul"
                    confirmButtonText="Ta bort"
                    abortButtonText="Avbryt"
                    confirmationText="Är du säker på att du vill ta bort den här modulen? Du kan inte ångra dig och innehållet raderas permanent."
                    confirmCallback={deleteSiteModule}
                    closeCallback={() => setShowDeleteConfirmation(false)}
                />
            ) : null}
            <div className={`${styles.wrapper} ${props.showActionBar ? styles.open : ""}`}>
                <button className={styles.toggle} onClick={() => props.setShowActionBar(true)}>
                    <span className={styles.icon}>{IconToggleContentModuleActionBar()}</span>
                    <span className={styles.text}>Modul</span>
                </button>
                <div className={`${styles.actions} ${props.showActionBar ? styles.open : ""}`} ref={pickerWrapper}>
                    <button onClick={() => props.setShowActionBar(false)}>
                        <span className={styles.icon}>{IconClose()}</span>
                        <span className={styles.text}>Stäng</span>
                    </button>
                    {!siteModuleIsFirst(props.site.siteModules || [], props.siteModule) ? (
                        <button
                            onClick={() => {
                                moveModule(-1);
                            }}
                        >
                            <span className={styles.icon}>{IconMoveUpContentModule()}</span>
                            <span className={styles.text}>Upp</span>
                        </button>
                    ) : null}
                    {!siteModuleIsLast(props.site.siteModules || [], props.siteModule) ? (
                        <button
                            onClick={() => {
                                moveModule(1);
                            }}
                        >
                            <span className={styles.icon}>{IconMoveDownContentModule()}</span>
                            <span className={styles.text}>Ner</span>
                        </button>
                    ) : null}
                    <button onClick={toggleShowOrHide}>
                        <span className={styles.icon}>{props.siteModule.hide ? IconShow() : IconHide()}</span>
                        <span className={styles.text}>{props.siteModule.hide ? "Visa" : "Dölj"}</span>
                    </button>
                    <button onClick={() => deleteModule()}>
                        <span className={styles.icon}>{IconDelete()}</span>
                        <span className={styles.text}>Ta bort</span>
                    </button>
                    <button onClick={() => setShowAddSiteModule(true)}>
                        <span className={styles.icon}>{IconNewContentModule()}</span>
                        <span className={styles.text}>Ny modul</span>
                    </button>
                </div>
            </div>
        </>
    );
}
