import { ISiteModuleLocation } from "../../interfaces/ISiteModule";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModuleLocation = (): ISiteModuleLocation => {
    return {
        siteModuleType: "Location",
        ...getNewSiteModuleBasic(false),
        title: {
            contentDataType: "Title",
            text: {},
        },
        map: {
            contentDataType: "Map",
            map: null,
            mapTheme: null,
            zoom: null,
            markerColor: null,
        },
        secondTitle: {
            contentDataType: "Title",
            text: {},
        },
        text: {
            contentDataType: "RichText",
            richText: {},
        },
        image: {
            contentDataType: "Image",
            image: null,
        },
    };
};
