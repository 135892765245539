import { ISiteModuleText } from "../../interfaces/ISiteModule";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModuleText = (): ISiteModuleText => {
    return {
        siteModuleType: "Text",
        ...getNewSiteModuleBasic(false),
        title: {
            contentDataType: "Title",
            text: {},
        },
        text: {
            contentDataType: "RichText",
            richText: {},
        },
    };
};
