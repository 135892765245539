import styles from "./ViewPrintCheckoutConfirmation.module.scss";
import { useEffect, useState } from "react";
import { IOrder } from "../../../../interfaces/Order";
import { IError } from "../../../../../App";
import { getOrder } from "../../../../services/OrderService";
import Loading from "../../../../components/Loading/Loading";
import ShowError from "../../../../components/ShowError/ShowError";

interface IProps {
    orderId: string;
}

export const ViewPrintCheckoutConfirmation = (props: IProps) => {
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState<IOrder | null>(null);
    const [error, setError] = useState<IError | null>(null);

    useEffect(() => {
        const getOrderAsync = async () => {
            setLoading(true);
            try {
                setOrder(await getOrder(props.orderId));
            } catch (error) {
                setError({ text: "Misslyckades att hämta order", response: (error as any).response });
            }
            setLoading(false);
        };
        getOrderAsync();
    }, [props.orderId]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    if (!order) {
        throw Error("Order not loaded but not error");
    }

    const currencyFormatter = (number: number) => {
        return new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK",
            maximumSignificantDigits: 10,
        }).format(number);
    };

    return (
        <div className={styles.wrapper} data-test-id="print-checkout-confirmation-module">
            <div className={styles.grid}>
                <div className={styles.sectionFullWidth}>
                    <h1>Tack för din beställning!</h1>
                    <div className={styles.intro}>Nu ska produkterna tillverkas. När det är klart och de har skickats får du ett mejl med ett spårningsnummer så du kan följa din leverans.</div>
                </div>
                <div className={styles.receipt}>
                    <h3 className={styles.sectionTitle}>Bekräftelse</h3>
                    <div>
                        <div>
                            {order.items.map((item) => (
                                <span key={item.print}>
                                    {item.title} - {currencyFormatter(item.priceInclVat / 100)}
                                </span>
                            ))}
                            <br />
                            <br />
                            Frakt - {currencyFormatter(order.shipment?.priceInclVat! / 100)}
                            <br />
                            <br />
                            Totalt belopp: {currencyFormatter(order.totalInclVat / 100)}
                            <br />
                            Varav moms: {currencyFormatter(order.totalVat / 100)}
                            <br />
                            <br />
                            Orderreferens: {order._id}
                            <br /><br/>
                            Vi har skickat ett fullständigt kvitto till din e-postadress.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
