import { Origin } from "../../../../types/origin";
import { TSiteGraphic } from "../../../components/SiteGraphic/SiteGraphic";
import { IColorScheme, IFonts, enumTheme } from "../../../interfaces/Site";
import { colorSchemeCollections } from "../../EditColorV2/utils/ColorSchemeCollections";

export interface IDesignTemplates {
    [key: string]: IDesignTemplate;
}

export interface IDesignTemplate {
    name: string;
    colorScheme: IColorScheme;
    fonts: IFonts;
    theme: enumTheme;
    siteGraphic: TSiteGraphic | null;
    brandsExclusive?: Origin[];
}

export const DesignTemplates: IDesignTemplates = {
    STANDARD: {
        name: "STANDARD",
        colorScheme: colorSchemeCollections["BLACK_AND_WHITE"].colorScheme,
        fonts: { heading: "Pompiere", text: "Open Sans" },
        theme: enumTheme.theme4,
        siteGraphic: null,
    },
    EUCALYPTUS: {
        name: "EUCALYPTUS",
        colorScheme: colorSchemeCollections["LIGHT_GREEN"].colorScheme,
        fonts: {
            heading: "Amatic SC",
            text: "Open Sans",
        },
        theme: enumTheme.theme4,
        siteGraphic: "EUCALYPTUS",
        brandsExclusive: ["wedding"],
    },
    TYPEWRITER_VINTAGE_GREEN: {
        name: "TYPEWRITER_VINTAGE_GREEN",
        colorScheme: colorSchemeCollections["VINTAGE_GREEN"].colorScheme,
        fonts: {
            heading: "Alegreya Sans SC",
            text: "Cutive Mono",
        },
        theme: enumTheme.theme10,
        siteGraphic: "BACKGROUND_IMAGE_NOISE",
    },
    GOLD: {
        name: "GOLD",
        colorScheme: colorSchemeCollections["GOLD"].colorScheme,
        fonts: {
            heading: "Alegreya Sans SC",
            text: "Arial",
        },
        theme: enumTheme.theme8,
        siteGraphic: null,
    },
    MILITARY_GREEN_PHOTO: {
        name: "MILITARY_GREEN_PHOTO",
        colorScheme: colorSchemeCollections["MILITARY_GREEN"].colorScheme,
        fonts: {
            heading: "Sacramento",
            text: "Open Sans",
        },
        theme: enumTheme.theme9,
        siteGraphic: null,
    },
    MILITARY_LEAFS: {
        name: "MILITARY_LEAFS",
        colorScheme: colorSchemeCollections["MILITARY_GREEN"].colorScheme,
        fonts: {
            heading: "Parisienne",
            text: "Raleway",
        },
        theme: enumTheme.theme4,
        siteGraphic: "LEAFS_MIX",
    },
    GOLD_CARD: {
        name: "GOLD_CARD",
        colorScheme: colorSchemeCollections["GOLD"].colorScheme,
        fonts: {
            heading: "Josefin Slab",
            text: "Cutive Mono",
        },
        theme: enumTheme.theme11,
        siteGraphic: "BACKGROUND_IMAGE_GREEN_AND_GOLD_FLOWERS",
        brandsExclusive: ["wedding"],
    },
    MODERN_GREEN: {
        name: "MODERN_GREEN",
        colorScheme: colorSchemeCollections["LIGHT_GREEN"].colorScheme,
        fonts: {
            heading: "Lobster",
            text: "Arial",
        },
        theme: enumTheme.theme8,
        siteGraphic: null,
    },
    BLUE_FLOWERS: {
        name: "BLUE_FLOWERS",
        colorScheme: colorSchemeCollections["BLUE_GREY"].colorScheme,
        fonts: {
            heading: "Josefin Slab",
            text: "Open Sans",
        },
        theme: enumTheme.theme4,
        siteGraphic: "CORNER_GRAPHICS_BLUE",
        brandsExclusive: ["wedding"],
    },
    STANDARD_2: {
        name: "STANDARD_2",
        colorScheme: colorSchemeCollections["BLACK_AND_WHITE"].colorScheme,
        fonts: { heading: "Alegreya Sans SC", text: "Raleway" },
        theme: enumTheme.theme4,
        siteGraphic: null,
    },
    WOOD: {
        name: "WOOD",
        colorScheme: colorSchemeCollections["BLACK"].colorScheme,
        fonts: {
            heading: "Mulish",
            text: "Arial",
        },
        theme: enumTheme.theme4,
        siteGraphic: "BACKGROUND_IMAGE_WOOD_AND_FLOWERS",
        brandsExclusive: ["wedding"],
    },
    GREEN_CARD: {
        name: "GREEN_CARD",
        colorScheme: colorSchemeCollections["MILITARY_GREEN_2"].colorScheme,
        fonts: {
            heading: "Parisienne",
            text: "Georgia",
        },
        theme: enumTheme.theme11,
        siteGraphic: null,
    },
    VALENTINE: {
        name: "VALENTINE",
        colorScheme: colorSchemeCollections["SKIN"].colorScheme,
        fonts: {
            heading: "Lobster",
            text: "Arial",
        },
        theme: enumTheme.theme5,
        siteGraphic: "BACKGROUND_IMAGE_BOKEH_LIGHT",
    },
    CLEAN_FLOWERS: {
        name: "CLEAN_FLOWERS",
        colorScheme: colorSchemeCollections["BLACK_AND_WHITE"].colorScheme,
        fonts: {
            heading: "Nixie One",
            text: "Open Sans",
        },
        theme: enumTheme.theme5,
        siteGraphic: "BACKGROUND_IMAGE_FLOWERS_CLEAN",
        brandsExclusive: ["wedding"],
    },
    BLUE_SKY: {
        name: "BLUE_SKY",
        colorScheme: colorSchemeCollections["RICH_BLUE"].colorScheme,
        fonts: {
            heading: "Alegreya Sans SC",
            text: "Open Sans",
        },
        theme: enumTheme.theme4,
        siteGraphic: "MOVING_DOTS_BACKGROUND",
    },
    BLUE_FIREWORKS: {
        name: "BLUE_FIREWORKS",
        colorScheme: colorSchemeCollections["RICH_BLUE"].colorScheme,
        fonts: {
            heading: "Josefin Slab",
            text: "Open Sans",
        },
        theme: enumTheme.theme4,
        siteGraphic: "BACKGROUND_IMAGE_BOKEH_FIREWORKS",
    },
    PURPLE: {
        name: "PURPLE",
        colorScheme: colorSchemeCollections["PURPLE"].colorScheme,
        fonts: {
            heading: "Nixie One",
            text: "Arial",
        },
        theme: enumTheme.theme4,
        siteGraphic: null,
    },
    BLUE_GREY: {
        name: "GREY_BLUE",
        colorScheme: colorSchemeCollections["BLUE_GREY"].colorScheme,
        fonts: {
            heading: "Fjalla One",
            text: "Arial",
        },
        theme: enumTheme.theme7,
        siteGraphic: null,
    },
    BEIGE_AND_BROWN: {
        name: "BEIGE_AND_BROWN",
        colorScheme: colorSchemeCollections["LIGHT_BROWN"].colorScheme,
        fonts: {
            heading: "Playfair Display",
            text: "Arial",
        },
        theme: enumTheme.theme4,
        siteGraphic: null,
    },
    PARTY_DANCE: {
        name: "PARTY_DANCE",
        colorScheme: colorSchemeCollections["BLACK"].colorScheme,
        fonts: {
            heading: "Kaisei Tokumin",
            text: "Open Sans",
        },
        theme: enumTheme.theme7,
        siteGraphic: "BACKGROUND_IMAGE_DANCING_PARTY",
        brandsExclusive: ["party"],
    },
    KIDS_BALLOONS: {
        name: "KIDS_BALLOONS",
        colorScheme: colorSchemeCollections["BLUE_GREY"].colorScheme,
        fonts: {
            heading: "Sacramento",
            text: "Open Sans",
        },
        theme: enumTheme.theme4,
        siteGraphic: "CORNER_GRAPHICS_BALLOONS",
        brandsExclusive: ["party"],
    },
    EARTH: {
        name: "EARTH",
        colorScheme: colorSchemeCollections["BEIGE_AND_PURPLE"].colorScheme,
        fonts: {
            heading: "Nixie One",
            text: "Open Sans",
        },
        theme: enumTheme.theme4,
        siteGraphic: null,
    },
    MODERN_FLOWERS: {
        name: "MODERN_FLOWERS",
        colorScheme: colorSchemeCollections["YELLOW_GREEN"].colorScheme,
        fonts: {
            heading: "Playfair Display",
            text: "Open Sans",
        },
        theme: enumTheme.theme4,
        siteGraphic: "CORNER_GRAPHICS_MULTI_COLOR",
        brandsExclusive: ["wedding"],
    },
    BROWN_PHOTO: {
        name: "BROWN_PHOTO",
        colorScheme: colorSchemeCollections["LIGHT_BROWN"].colorScheme,
        fonts: {
            heading: "Playfair Display",
            text: "Open Sans",
        },
        theme: enumTheme.theme9,
        siteGraphic: null,
    },
    STANDARD_GREEN: {
        name: "STANDARD_GREEN",
        colorScheme: colorSchemeCollections["GREEN_NEON"].colorScheme,
        fonts: { heading: "Playfair Display", text: "Georgia" },
        theme: enumTheme.theme4,
        siteGraphic: null,
    },
    GREY_MACHINE: {
        name: "GREY_MACHINE",
        colorScheme: colorSchemeCollections["LIGHT_GREY"].colorScheme,
        fonts: {
            heading: "Zilla Slab",
            text: "Cutive Mono",
        },
        theme: enumTheme.theme10,
        siteGraphic: "BACKGROUND_IMAGE_NOISE",
    },
    GREEN_PURPLE_FLOWERS: {
        name: "GREEN_PURPLE_FLOWERS",
        colorScheme: colorSchemeCollections["MILITARY_GREEN"].colorScheme,
        fonts: {
            heading: "Dancing Script",
            text: "Raleway",
        },
        theme: enumTheme.theme4,
        siteGraphic: "CORNER_GRAPHICS_PURPLE_GREEN_FLOWERS",
    },
    HANG_LOOSE: {
        name: "HANG_LOOSE",
        colorScheme: colorSchemeCollections["BEIGE_AND_PURPLE"].colorScheme,
        fonts: {
            heading: "Coming Soon",
            text: "Open Sans",
        },
        theme: enumTheme.theme4,
        siteGraphic: null,
        brandsExclusive: ["wedding"],
    },
    WATERCOLOR_BLUE_AND_GREEN: {
        name: "WATERCOLOR_BLUE_AND_GREEN",
        colorScheme: colorSchemeCollections["LIGHT_GREY"].colorScheme,
        fonts: {
            heading: "Nixie One",
            text: "Raleway",
        },
        theme: enumTheme.theme7,
        siteGraphic: "CORNER_GRAPHICS_WATER_COLOR_VINTAGE_FLOWERS",
        brandsExclusive: ["wedding"],
    },
    ARTIFICIAL_LEAFS: {
        name: "ARTIFICIAL_LEAFS",
        colorScheme: colorSchemeCollections["MILITARY_GREEN"].colorScheme,
        fonts: {
            heading: "Alegreya Sans SC",
            text: "Raleway",
        },
        theme: enumTheme.theme4,
        siteGraphic: "CORNER_GRAPHICS_GREEN_LEAFS_ARTIFICIAL",
        brandsExclusive: ["wedding"],
    },
    ARTIFICIAL_FLOWERS: {
        name: "ARTIFICIAL_FLOWERS",
        colorScheme: colorSchemeCollections["LIGHT_GREY"].colorScheme,
        fonts: {
            heading: "Lobster",
            text: "Raleway",
        },
        theme: enumTheme.theme4,
        siteGraphic: "CORNER_GRAPHICS_ARTIFICIAL_FLOWERS_RED_AND_PINK",
    },
};

export const selectableDesignTemplates = Object.keys(DesignTemplates)
    .map((key) => DesignTemplates[key])
    .filter(
        (designTemplate) =>
            !designTemplate.brandsExclusive ||
            designTemplate.brandsExclusive.includes(process.env.REACT_APP_BRAND as Origin)
    );
