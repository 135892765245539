import { useState } from "react";
import { Select } from "../../../../../../../../../../components/form/Select/Select";
import { IRvspPerson } from "../../../../../../../../../../interfaces/Rvsp";
import { ICustomValue } from "../../../../ViewRvsp";
import styles from "./RsvpSelectYesOrNoQuestionFilter.module.scss";

interface IProps {
    rsvps: IRvspPerson[];
    customValues: ICustomValue[];
    getCustomValueWithLabel: (rsvp: IRvspPerson, label: string) => string;
    setQuestionFilter: (questionAndAnswer: { question: string; answer: string } | null) => void;
}

export function RsvpSelectYesOrNoQuestionFilter(props: IProps) {
    const [selectedQuestion, setSelectedQuestion] = useState("");

    const setQuestionFilter = (selectedAnswer: string, selectedQuestion: string) => {
        if (selectedQuestion && selectedAnswer) {
            props.setQuestionFilter({ question: selectedQuestion, answer: selectedAnswer });
        } else {
            props.setQuestionFilter(null);
        }
    };

    const hasAnyDeprecatedWeddingYesOrNo = !!props.rsvps.filter(
        (rsvp) => rsvp.weddingYesOrNo === false || rsvp.weddingYesOrNo === true
    ).length;

    const customValuesInfo = props.customValues
        .filter((customValue: any) => customValue.type === "YesOrNo" || customValue.type === "RadioGroup")
        .map((customValue: any) => {
            const answers: { answer: string; count: number }[] = [];

            props.rsvps.forEach((rsvp: IRvspPerson) => {
                const answerCustomValue = props.getCustomValueWithLabel(rsvp, customValue.label) || "Inget svar";
                const alreadyAddedAnswerIndex = answers.findIndex((answer) => answer.answer === answerCustomValue);
                if (alreadyAddedAnswerIndex > -1) {
                    answers[alreadyAddedAnswerIndex].count = answers[alreadyAddedAnswerIndex].count + 1;
                } else {
                    answers.push({ answer: answerCustomValue, count: 1 });
                }
            });

            return {
                question: customValue.label,
                answers: answers.sort((a, b) => b.count - a.count),
            };
        });

    const options = customValuesInfo.map((customValueInfo) => ({
        title: customValueInfo.question,
        value: customValueInfo.question,
    }));

    if (hasAnyDeprecatedWeddingYesOrNo) {
        options.unshift({
            title: "Kommer",
            value: "deprecatedWeddingYesOrNo",
        });
    }

    const notSelected = { title: "Inget filter", value: "" };

    options.unshift(notSelected);

    let optionsAnswers = customValuesInfo
        .find((customValue) => customValue.question === selectedQuestion)
        ?.answers.map((answer) => ({
            title: answer.answer,
            value: answer.answer,
        }));

    if (selectedQuestion === "deprecatedWeddingYesOrNo") {
        optionsAnswers = [
            {
                title: "Ja",
                value: "Ja",
            },
            {
                title: "Nej",
                value: "Nej",
            },
        ];
    }

    const notSelectedAnswer = { title: "Välj svar", value: "" };

    optionsAnswers?.unshift(notSelectedAnswer);

    return (
        <div className={styles.wrapper}>
            <div>
                <h3>Filtrera</h3>
                <p>
                    Som standard visas alla frågor och svar nedan. Men här kan du filtrera på en ja och nej-fråga med
                    ett specifikt svar. Det kan vara användbart för att tex. bara visa e-postadresser till de som har
                    tackat ja.
                </p>
            </div>
            <div className={styles.selects}>
                <div>
                    <Select
                        id="selectFilterQuestion"
                        label="Filtrera på fråga"
                        options={options}
                        defaultValue={""}
                        onChangeCallback={(val: any) => {
                            setQuestionFilter("", val);
                            setSelectedQuestion(val);
                        }}
                    />
                </div>
                <div>
                    {optionsAnswers?.length ? (
                        <Select
                            id="selectFilterQuestionAnswer"
                            label="Välj svar"
                            options={optionsAnswers}
                            defaultValue={""}
                            onChangeCallback={(val: any) => {
                                setQuestionFilter(val, selectedQuestion);
                            }}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}
