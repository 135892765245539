import { ISite, ISiteV2 } from "../../../../../../../interfaces/Site";
import { randomId } from "../../../../../../../utils/Random";
import {
    IPrintableCard,
    TPrintableCardLayout,
} from "../components/PrintEditor/interfaces/IPrintableCard";

export const createDemoCard = (
    layout: TPrintableCardLayout,
    site: ISite | ISiteV2,
    currentPrint?: IPrintableCard
): IPrintableCard => {
    const host = site.host ? site.host : "demo.brollopssida.se";
    const password = site.password ? "<br>Lösenord: " + site.password : "";
    let demoCard: IPrintableCard;
    if (layout === "GENESIS") {
        demoCard = {
            layout: "GENESIS",
            content: [
                {
                    contentType: "text",
                    properties: {
                        text: "VARMT VÄLKOMMEN<br>PÅ BRÖLLOP MELLAN",
                    },
                    position: "welcomeText",
                    id: randomId(),
                    page: 1,
                },
                {
                    contentType: "text",
                    properties: {
                        text: "Anton &amp; Emma",
                    },
                    position: "names",
                    id: randomId(),
                    page: 1,
                },
                {
                    contentType: "text",
                    properties: {
                        text: "Vigsel i Stenhagens kyrka 12/2 2025<br> med efterföljande festligheter.",
                    },
                    position: "dateAndLocationLong",
                    id: randomId(),
                    page: 1,
                },
                {
                    contentType: "text",
                    properties: {
                        text: "Mer information och OSA:<br>" + host + password,
                    },
                    position: "websiteInfo",
                    id: randomId(),
                    page: 1,
                },
                {
                    contentType: "text",
                    properties: {
                        text: "Mer information och OSA:<br>" + host + password,
                    },
                    position: "textAtQR",
                    id: randomId(),
                    page: 2,
                },
                {
                    contentType: "qr",
                    properties: {
                        url: host,
                    },
                    position: "qr",
                    id: randomId(),
                    page: 2,
                },
            ],
            siteGraphic: site.siteGraphic!,
            colorScheme: site.colorScheme!,
            fonts: site.fonts,
            trim: currentPrint?.trim ? currentPrint.trim : "none",
        };
    } else if (layout === "PHOTO_CLEAN") {
        demoCard = {
            layout: "PHOTO_CLEAN",
            content: [
                {
                    contentType: "image",
                    properties: {
                        isDemoImage: true,
                        path: "demo-images/",
                        originalName: "demo_print.jpg",
                        fileName: "demo_print.jpg",
                        temporary: false,
                        sizes: [],
                    },
                    position: "primaryImage",
                    id: randomId(),
                    page: 1,
                },
                {
                    contentType: "text",
                    properties: {
                        text: "Vi gifter oss!",
                    },
                    position: "title",
                    id: randomId(),
                    page: 1,
                },
                {
                    contentType: "text",
                    properties: {
                        text: "Anton &amp; Emma",
                    },
                    position: "names",
                    id: randomId(),
                    page: 1,
                },
                {
                    contentType: "text",
                    properties: {
                        text: "15/6 Norrköping",
                    },
                    position: "dateAndLocation",
                    id: randomId(),
                    page: 1,
                },
                {
                    contentType: "text",
                    properties: {
                        text: "Mer information och OSA:<br>" + host + password,
                    },
                    position: "textAtQR",
                    id: randomId(),
                    page: 2,
                },
                {
                    contentType: "qr",
                    properties: {
                        url: host,
                    },
                    position: "qr",
                    id: randomId(),
                    page: 2,
                },
            ],
            siteGraphic: site.siteGraphic!,
            colorScheme: site.colorScheme!,
            fonts: site.fonts,
            trim: currentPrint?.trim ? currentPrint.trim : "none",
        };
        } else if (layout === "TEXT_ON_PHOTO") {
            demoCard = {
                layout: "TEXT_ON_PHOTO",
                content: [
                    {
                        contentType: "image",
                        properties: {
                            isDemoImage: true,
                            path: "demo-images/",
                            originalName: "demo_print_full_card.jpg",
                            fileName: "demo_print_full_card.jpg",
                            temporary: false,
                            sizes: [],
                        },
                        position: "primaryImage",
                        id: randomId(),
                        page: 1,
                    },
                    {
                        contentType: "text",
                        properties: {
                            text: "Vi gifter oss!",
                        },
                        position: "title",
                        id: randomId(),
                        page: 1,
                    },
                    {
                        contentType: "text",
                        properties: {
                            text: "15/6 Norrköping",
                        },
                        position: "dateAndLocation",
                        id: randomId(),
                        page: 1,
                    },
                    {
                        contentType: "text",
                        properties: {
                            text: "Mer information och OSA:<br>" + host + password,
                        },
                        position: "textAtQR",
                        id: randomId(),
                        page: 2,
                    },
                    {
                        contentType: "qr",
                        properties: {
                            url: host,
                        },
                        position: "qr",
                        id: randomId(),
                        page: 2,
                    },
                ],
                siteGraphic: site.siteGraphic!,
                colorScheme: site.colorScheme!,
                fonts: site.fonts,
                trim: currentPrint?.trim ? currentPrint.trim : "none",
            };
    } else {
        throw new Error("No layout " + layout);
    }

    if (!currentPrint) {
        return demoCard;
    } else {
        currentPrint.content.forEach((currentItem) => {
            const matchingDemoCardItem = demoCard.content.find(
                (demoItem) => currentItem.position === demoItem.position
            );
            if (matchingDemoCardItem) {
                matchingDemoCardItem.properties = { ...currentItem.properties };
            } else {
                demoCard.content.push({ ...currentItem });
            }
        });
        return demoCard;
    }
};
