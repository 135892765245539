import styles from "./FormRecipientsListEdit.module.scss";
import { IFormRecipients } from "../../../../../interfaces/Site";
import { Label } from "../../../../../editComponents/form/Label/Label";
import { Input } from "../../../../../editComponents/form/Input/Input";
import { IconDelete } from "../../../../../icons/delete";
import { Button } from "../../../../../components/button/Button";
import { useState } from "react";

interface IProps {
    formRecipients: IFormRecipients;
    updateformRecipients: (updater: (draft: IFormRecipients) => void) => void;
}

export function FormRecipientsListEdit(props: IProps) {
    const [mails, setMails] = useState(props.formRecipients.recipients);

    const propagateChanges = (latestMails: string[]) => {
        let filteredMails = latestMails.reduce(function (filtered: string[], mail) {
            if (mail) {
                mail = mail.trim();
                if (mail) {
                    filtered.push(mail);
                }
            }
            return filtered;
        }, []);

        props.updateformRecipients((draft) => {
            draft.recipients = filteredMails;
        });
    };

    const updateMail = (mail: string, index: number) => {
        const newMails = [...mails];
        newMails[index] = mail;
        setMails(newMails);
        propagateChanges(newMails);
    };

    const addMail = () => {
        const newMails = [...mails];
        newMails.push("");
        setMails(newMails);
        propagateChanges(newMails);
    };

    const deleteMail = (index: number) => {
        const newMails = [...mails];
        newMails.splice(index, 1);
        setMails(newMails);
        propagateChanges(newMails);
    };

    return (
        <div className={styles.wrapper}>
            {mails.length ? (
                <>
                    <Label htmlFor={null} className={styles.label}>
                        E-postadresser
                    </Label>
                    <div className={styles.list}>
                        {mails.map((mail, index) => (
                            <div key={index} className={styles.listItem}>
                                <div className={styles.inputWrapper}>
                                    <Input
                                        name={"receiverMail" + index}
                                        required={true}
                                        type="email"
                                        id={"receiverMail" + index}
                                        handleChange={(value: string) => updateMail(value, index)}
                                        value={mail}
                                    />
                                </div>
                                <div className={styles.delete}>
                                    <button onClick={() => deleteMail(index)} className={styles.delete}>
                                        {IconDelete()}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div>Inga e-postadresser</div>
            )}
            <div className={styles.addButton}>
                <Button type="secondary" buttonText="Lägg till e-postadress" callback={addMail} submit={false} />
            </div>
        </div>
    );
}
