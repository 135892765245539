import { useTranslation } from "../../../../../contexts/LanguageContext";
import { TFormElement } from "../../../../interfaces/FormElement";
import { EditFormCondition } from "./components/EditFormCondition/EditFormCondition";
import styles from "./EditFormElementListItem.module.scss";
import { DraftFunction } from "use-immer";
import { EditFormElementOptions } from "./components/EditFormElementOptions/EditFormElementOptions";
import { Input } from "../../../../editComponents/form/Input/Input";
import { Switch } from "../../../../editComponents/form/Switch/Switch";
import { IContentDataForm, TLanguageCode } from "../../../../interfaces/IContentData";
import { ISiteV2 } from "../../../../interfaces/Site";
import { languageFromLanguageCode } from "../../../../../utils/LanguageHelpers";

interface IProps {
    formElement: TFormElement;
    formElements: TFormElement[];
    updateForm: (updater: DraftFunction<IContentDataForm>) => void;
    closeEdit: () => void;
    site: ISiteV2;
    editingFormFieldsWarning?: JSX.Element;
}

export function EditFormElementListItem(props: IProps) {
    const { getInTranslation, setInTranslation } = useTranslation();

    const updateFormElement = <FormElement extends TFormElement>(updater: (draft: TFormElement) => void) => {
        return props.updateForm((draft) => {
            const thisFormElement = draft.formElements.find(
                (formElement) => formElement.id === props.formElement.id
            ) as FormElement;
            updater(thisFormElement);
        });
    };

    const questionLabel = (languageCode: TLanguageCode) => {
        if (props.site.availableLanguages.length === 1) return "Fråga";

        return "Fråga på " + languageFromLanguageCode(languageCode);
    };

    return (
        <>
            {props.editingFormFieldsWarning}
            <div className={styles.wrapper}>
                <div className={styles.column}>
                    <div className={styles.questionSection}>
                        {props.site.availableLanguages.map((language) => (
                            <Input
                                key={language.languageCode}
                                className={styles.question}
                                label={questionLabel(language.languageCode)}
                                name={"editFormElementLabel" + props.formElement.id + language.languageCode}
                                required={true}
                                id={"editFormElementLabel" + props.formElement.id + language.languageCode}
                                handleChange={(value: string) =>
                                    updateFormElement((item) => {
                                        item.label = setInTranslation(item.label, value, language.languageCode);
                                    })
                                }
                                value={getInTranslation(props.formElement.label, language.languageCode)}
                            />
                        ))}
                    </div>
                    {props.formElement.formElementType === "ShortText" ? (
                        <div className={styles.section}>
                            <Switch
                                label="Obligatorisk fråga - måste fyllas i"
                                name={"editFormElementRequired" + props.formElement.id}
                                handleChange={(value: boolean) =>
                                    updateFormElement((item) => {
                                        item.required = value;
                                    })
                                }
                                value={props.formElement.required}
                                id={"editFormElementRequired" + props.formElement.id}
                            />
                        </div>
                    ) : null}
                    {props.formElement.formElementType === "SingleSelect" ? (
                        <div className={`${styles.section} ${styles.noPadding}`}>
                            <EditFormElementOptions
                                site={props.site}
                                formElement={props.formElement}
                                updateFormElement={updateFormElement}
                            />
                        </div>
                    ) : null}
                </div>
                <div className={styles.column}>
                    <div className={styles.section}>
                        <EditFormCondition
                            formElement={props.formElement}
                            formElements={props.formElements}
                            updateFormElement={updateFormElement}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
