import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./SiteModulesListWrapper.module.scss";

interface IProps {
    site: ISiteV2;
    children: JSX.Element;
}

export function SiteModulesListWrapper(props: IProps) {
    return <div className={`${styles[props.site.theme]}`}>{props.children}</div>;
}
