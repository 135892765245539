import { ISiteV2 } from "../../interfaces/Site";
import styles from "./SiteModuleRsvp.module.scss";
import { ISiteModuleRsvp } from "../../interfaces/ISiteModule";
import { ContentTypeModuleHeadingEdit } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeadingEdit";
import { IContentDataRichText, IContentDataTitle } from "../../interfaces/IContentData";
import { ContentTypeRichTextEdit } from "../../contentTypes/ContentTypeRichText/ContentTypeRichTextEdit";
import EditButton from "../../components/EditButton/EditButton";
import { IconEdit } from "../../icons/edit";
import { useState } from "react";
import { RsvpFormEdit } from "./components/RsvpFormEdit/RsvpFormEdit";
import { Dialog } from "../../editComponents/Dialog/Dialog";
import { Updater } from "use-immer";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
    updateSite: Updater<ISiteV2>;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleRsvp, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
    updateSiteModule: <SiteModule extends ISiteModuleRsvp>(updater: (siteModule: SiteModule) => void) => void;
}

export const SiteModuleRsvpEdit = (props: IProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <Dialog open={showModal} handleClose={() => setShowModal(false)} closableByOutsideClickAndEsc={true}>
                <RsvpFormEdit
                    site={props.site}
                    siteModule={props.siteModule}
                    updateSiteModuleProperty={props.updateSiteModuleProperty}
                    updateSiteModule={props.updateSiteModule}
                    close={() => setShowModal(false)}
                    updateSite={props.updateSite}
                />
            </Dialog>
            <section className={styles[props.site.theme]}>
                <div className={styles.innerWrapper}>
                    <ContentTypeModuleHeadingEdit
                        contentData={props.siteModule.title}
                        site={props.site}
                        updateContentData={(value: IContentDataTitle) => props.updateSiteModuleProperty("title", value)}
                    />
                    <div className={styles.textAndFormWrapper}>
                        <div className={styles.rteWrapper}>
                            <ContentTypeRichTextEdit
                                contentData={props.siteModule.text}
                                site={props.site}
                                updateContentData={(value: IContentDataRichText) =>
                                    props.updateSiteModuleProperty("text", value)
                                }
                            />
                        </div>
                        <div className={styles.editOrSelectNumberOfPersonsWrapper}>
                            <div>
                                <EditButton callback={() => setShowModal(true)}>
                                    <>
                                        <IconEdit></IconEdit>
                                        Ändra OSA-formulär
                                    </>
                                </EditButton>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
