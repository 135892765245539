import React from "react";
import styles from "./Radio.module.scss";
import { ISiteV2 } from "../../../../../../../../interfaces/Site";
import { IFormElementOption } from "../../../../../../../../interfaces/FormElement";

interface IProps {
    handleChange: (option: IFormElementOption) => void;
    value: IFormElementOption;
    name: string;
    id: string;
    label: string;
    site: ISiteV2;
    checked: boolean;
}

export const Radio = (props: IProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            props.handleChange(props.value);
        }
    };

    return (
        <span className={`${styles[props.site.theme]}`}>
            <div className={styles.radioAndTextWrapper}>
                <div className={styles.radioWrapper}>
                    <input
                        type="radio"
                        className={styles.radio}
                        required={true}
                        id={props.id}
                        name={props.name}
                        value={props.value.id}
                        onChange={handleChange}
                        data-invalid-feedback-id={props.name + "_invalid_feedback"}
                        checked={props.checked}
                    />
                </div>
                <div className={styles.textWrapper}>
                    <label className={styles.label} htmlFor={props.id}>
                        {props.label}
                    </label>
                </div>
            </div>
        </span>
    );
};
