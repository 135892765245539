import { TSiteModule } from "../interfaces/ISiteModule";
import { ISiteV2 } from "../interfaces/Site";
import { SiteModuleImageAndTextEdit } from "./SiteModuleImageAndText/SiteModuleImageAndTextEdit";
import styles from "./SiteModule.module.scss";
import { useState } from "react";
import { Updater } from "use-immer";
import { SiteModuleActionBar } from "./components/SiteModuleActionBar/SiteModuleActionBar";
import { SiteModuleTextAndImageListEdit } from "./SiteModuleTextAndImageList/SiteModuleTextAndImageListEdit";
import { SiteModuleGiftRegistryEdit } from "./SiteModuleGiftRegistry/SiteModuleGiftRegistryEdit";
import { SiteModuleHeaderEdit } from "./SiteModuleHeader/SiteModuleHeaderEdit";
import { SiteModuleLocationEdit } from "./SiteModuleLocation/SiteModuleLocationEdit";
import { SiteModuleTextEdit } from "./SiteModuleText/SiteModuleTextEdit";
import { SiteModuleVideoEdit } from "./SiteModuleVideo/SiteModuleVideoEdit";
import { SiteModulePeopleListEdit } from "./SiteModulePeopleList/SiteModulePeopleListEdit";
import { SiteModuleRsvpEdit } from "./SiteModuleRsvp/SiteModuleRsvpEdit";
import { SiteModuleCountdownEdit } from "./SiteModuleCountdown/SiteModuleCountdownEdit";
import { SiteModuleFormEdit } from "./SiteModuleForm/SiteModuleFormEdit";
import { getSiteModuleSlug } from "../utils/getSiteModuleSlug";
import { useTranslation } from "../../contexts/LanguageContext";

interface IProps {
    site: ISiteV2;
    siteModule: TSiteModule;
    updateSite: Updater<ISiteV2>;
}

export const SiteModuleEdit = (props: IProps) => {
    const [showActionBar, setShowActionBar] = useState(false);
    const { language } = useTranslation();

    let siteModuleComponent = <></>;

    const updateSiteModuleProperty = <SiteModule extends TSiteModule, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => {
        return props.updateSite((draft) => {
            const thisModule = draft.siteModules.find((module) => module.id === props.siteModule.id) as SiteModule;
            thisModule[property] = value;
        });
    };

    const updateSiteModule = <SiteModule extends TSiteModule>(updater: (modulen: SiteModule) => void) => {
        return props.updateSite((draft) => {
            const thisModule = draft.siteModules.find((module) => module.id === props.siteModule.id) as SiteModule;
            updater(thisModule);
        });
    };

    if (props.siteModule.siteModuleType === "Header") {
        siteModuleComponent = (
            <SiteModuleHeaderEdit
                site={props.site}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
            />
        );
    }

    if (props.siteModule.siteModuleType === "ImageAndText") {
        siteModuleComponent = (
            <SiteModuleImageAndTextEdit
                site={props.site}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
            />
        );
    }

    if (props.siteModule.siteModuleType === "Text") {
        siteModuleComponent = (
            <SiteModuleTextEdit
                site={props.site}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
            />
        );
    }

    if (props.siteModule.siteModuleType === "Video") {
        siteModuleComponent = (
            <SiteModuleVideoEdit
                site={props.site}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
            />
        );
    }

    if (props.siteModule.siteModuleType === "TextAndImageList") {
        siteModuleComponent = (
            <SiteModuleTextAndImageListEdit
                site={props.site}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
                updateSiteModule={updateSiteModule}
            />
        );
    }

    if (props.siteModule.siteModuleType === "PeopleList") {
        siteModuleComponent = (
            <SiteModulePeopleListEdit
                site={props.site}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
                updateSiteModule={updateSiteModule}
            />
        );
    }

    if (props.siteModule.siteModuleType === "GiftRegistry") {
        siteModuleComponent = (
            <SiteModuleGiftRegistryEdit
                site={props.site}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
                updateSiteModule={updateSiteModule}
            />
        );
    }

    if (props.siteModule.siteModuleType === "Location") {
        siteModuleComponent = (
            <SiteModuleLocationEdit
                site={props.site}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
            />
        );
    }

    if (props.siteModule.siteModuleType === "Rsvp") {
        siteModuleComponent = (
            <SiteModuleRsvpEdit
                site={props.site}
                updateSite={props.updateSite}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
                updateSiteModule={updateSiteModule}
            />
        );
    }

    if (props.siteModule.siteModuleType === "Countdown") {
        siteModuleComponent = (
            <SiteModuleCountdownEdit
                site={props.site}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
            />
        );
    }

    if (props.siteModule.siteModuleType === "Form") {
        siteModuleComponent = (
            <SiteModuleFormEdit
                site={props.site}
                updateSite={props.updateSite}
                siteModule={props.siteModule}
                updateSiteModuleProperty={updateSiteModuleProperty}
                updateSiteModule={updateSiteModule}
            />
        );
    }

    const slug = getSiteModuleSlug(props.siteModule, props.site.siteModules, language);

    return (
        <div className={`${showActionBar ? styles.highlight : ""} ${styles.wrapper}`}>
            <div
                className={styles.scrollToIdWithActionBar}
                id={slug}
            ></div>
            {props.siteModule.hide === true ? (
                <div className={styles.hiddenInfoText}>
                    <div>Den här modulen är dold och visas inte för gästerna.</div>
                </div>
            ) : null}
            {siteModuleComponent}
            <SiteModuleActionBar
                showActionBar={showActionBar}
                setShowActionBar={setShowActionBar}
                updateSite={props.updateSite}
                site={props.site}
                siteModule={props.siteModule}
            />
        </div>
    );
};
