import { ContentTypeButtonEdit } from "../../../../../../contentTypes/ContentTypeButton/ContentTypeButtonEdit";
import { ContentBox } from "../../../../../../editComponents/ContentBox/ContentBox";
import { IContentDataText } from "../../../../../../interfaces/IContentData";
import { ISiteModuleForm } from "../../../../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { EmulateContentSurface } from "../../../../../components/EmulateContentSurface/EmulateContentSurface";
import { LanguageTitle } from "../../../../../components/LanguageTitle/LanguageTitle";
import { VerticalGapOnChildren } from "../../../../../components/VerticalGapOnChildren/VerticalGapOnChildren";
import styles from "./ShowFormButton.module.scss";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleForm;
    updateButton: (value: IContentDataText) => void;
}

export const ShowFormButton = (props: IProps) => {
    return (
        <>
            <ContentBox padding={true} className={styles.wrapper} title="Visa formulär-knapp">
                <p>Knappen som gästen trycker på för att visa formuläret. Tryck på texten för att ändra den.</p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <EmulateContentSurface site={props.site} className={styles.buttonWrapper}>
                                <ContentTypeButtonEdit
                                    site={props.site}
                                    contentData={props.siteModule.showFormButton}
                                    updateContentData={props.updateButton}
                                    placeholder={"Ingen text"}
                                    language={language.languageCode}
                                    small={false}
                                />
                            </EmulateContentSurface>
                        </div>
                    ))}
                </VerticalGapOnChildren>
            </ContentBox>
        </>
    );
};
