import React, { useState } from "react";
import ActionCurtain from "../../../../../../../../components/actionCurtain/ActionCurtain";
import { ISite, ISiteV2 } from "../../../../../../../../interfaces/Site";
import { Payment } from "../../../../../../../../modules/Payment/Payment";
import { getProductFromDomainOption } from "../../../../utils/getProductFromDomainOption";
import { domainOption } from "../../../SelectProduct/SelectProduct";

interface IProps {
    site: ISite | ISiteV2;
    handlePaymentDone: (paymentReference: string) => void;
    close: () => void;
    host: string;
}

export function SubdomainPayment(props: IProps) {
    const [hideEverythingButPaymentMethod, setHideEverythingButPaymentMethod] = useState(false);
    const subdomainProduct = getProductFromDomainOption(domainOption.SUBDOMAIN, props.site);

    if (subdomainProduct.product === null) {
        throw new Error("Missing product for subdomain");
    }
    return (
        <ActionCurtain title="Köp adress" close={props.close} preventClosingOnClickOutside={true}>
            <>
                {!hideEverythingButPaymentMethod && (
                    <>
                        <p>
                            Nu behöver du betala <strong>{subdomainProduct.price}:-</strong> för att dina gäster ska
                            kunna besöka dig på <strong>{props.host}</strong>. Efter att du har betalat är adressen din,
                            men du kan när som helst utan extra kostnad ändra till en annan ledig adress.
                        </p>
                        <p>
                            Sidan finns kvar i {subdomainProduct.months} månader efter genomförd betalning och du kan självklart fortsätta att
                            redigera den obegränsat under hela tiden.
                        </p>
                        <p>
                            <b>Ångerrätt.</b> Om du skulle ångra dig erbjuder vi ångerrätt i 30 dagar. Kontakta oss så
                            får du tillbaka hela summan.
                        </p>
                    </>
                )}
                <Payment
                    paymentProduct={subdomainProduct.product}
                    setHideEverythingButPaymentMethod={setHideEverythingButPaymentMethod}
                    hideEverythingButPaymentMethod={hideEverythingButPaymentMethod}
                    site={props.site}
                    handlePaymentDone={props.handlePaymentDone}
                />
            </>
        </ActionCurtain>
    );
}
