import React from "react";
import ContentH3 from "../../content/h3/H3";
import ContentImage from "../../content/image/Image";
import ContentRTE from "../../content/RTE/RTE";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import GeneratorModule from "../GeneratorModule";
import styles from "./ImageAndText.module.scss";

export class ImageAndText extends GeneratorModule {
    render() {
        return (
            <section className={styles[this.props.site.theme]}>
                <ModuleHeading
                    site={this.props.site}
                    propertyParent={this.props.moduleData}
                    updateProperty={this.props.updateProperty}
                    editMode={this.props.editMode}
                ></ModuleHeading>
                <div className={styles.imageAndTextWrapper}>
                    <div className={styles.imageWrapper}>
                        <ContentImage
                            site={this.props.site}
                            propertyParent={this.props.moduleData}
                            property="image"
                            updateProperty={this.props.updateProperty}
                            editMode={this.props.editMode}
                            addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                            sizes="(min-width: 968px) 968px, 100vw"
                        ></ContentImage>
                    </div>
                    <div className={styles.textContentWrapper}>
                        <div className={styles.innerTextContentWrapper}>
                            <ContentH3
                                site={this.props.site}
                                propertyParent={this.props.moduleData}
                                property="secondTitle"
                                updateProperty={this.props.updateProperty}
                                editMode={this.props.editMode}
                            ></ContentH3>
                            <div className={styles.textWrapper}>
                                <ContentRTE
                                    site={this.props.site}
                                    propertyParent={this.props.moduleData}
                                    property="text"
                                    updateProperty={this.props.updateProperty}
                                    editMode={this.props.editMode}
                                ></ContentRTE>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ImageAndText;
