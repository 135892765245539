import React from "react";
import styles from "./ThemeItem.module.scss";

interface IProps {
    theme: string;
    setTheme: any;
    currentTheme: string;
}

export class ThemeItem extends React.Component<IProps> {
    setTheme = () => {
        this.props.setTheme(this.props.theme);
    };

    isActive = () => {
        return this.props.currentTheme === this.props.theme;
    };

    render() {
        let url = "/images/theme-thumbnails/" + process.env.REACT_APP_BRAND + "/" + this.props.theme + ".webp";
        let urlPngFallback =
            "/images/theme-thumbnails/" + process.env.REACT_APP_BRAND + "/" + this.props.theme + ".png";

        return (
            <div
                className={this.isActive() ? styles.wrapperActive : styles.wrapper}
                onClick={this.setTheme}
                data-test-id={"theme-selector-" + this.props.theme}
                data-test-theme={this.props.theme}
            >
                <picture>
                    <source srcSet={url} type="image/webp" />
                    <source srcSet={urlPngFallback} type="image/png" />
                    <img srcSet={urlPngFallback} alt="Designmall" />
                </picture>
            </div>
        );
    }
}

export default ThemeItem;
