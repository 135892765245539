import React from "react";
import { ISiteV2 } from "../../interfaces/Site";
import styles from "./ContentTypeButton.module.scss";
import { IContentDataText, TLanguageCode } from "../../interfaces/IContentData";
import { useTranslation } from "../../../contexts/LanguageContext";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataText;
    updateContentData: (value: IContentDataText) => void;
    placeholder: string;
    small: boolean;
    language?: TLanguageCode;
}

export const ContentTypeButtonEdit = (props: IProps) => {
    const { getInTranslation, setInTranslation } = useTranslation();

    const updateContent = (content: IContentDataText) => {
        props.updateContentData(content);
    };

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        let targetElement = e.target;
        if (getInTranslation(props.contentData.text, props.language) !== targetElement.innerText) {
            updateContent({
                ...props.contentData,
                text: setInTranslation(props.contentData.text, targetElement.innerText, props.language),
            });
        }
    };

    return (
        <div
            className={`${styles[props.site.theme]} ${styles.edit} ${props.small ? styles.small : ""}`}
            placeholder={props.placeholder}
            contentEditable
            suppressContentEditableWarning={true}
            onBlur={handleBlur}
        >
            {getInTranslation(props.contentData.text, props.language)}
        </div>
    );
};
