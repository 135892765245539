import { TLanguageCode } from "../generator/interfaces/IContentData";

export const translatedLanguageFromLanguageCode = (languageCode: TLanguageCode) => {
    return LANGUAGES_IN_LANGUAGE[languageCode];
};

export const languageFromLanguageCode = (languageCode: TLanguageCode) => {
    return LANGUAGES_IN_SWEDISH[languageCode];
};

/*
Initial idea was to let the browser do this native. However some languages where missing in some browswers so I used safari to generate the list.
The code to genreate it is below.

export const translatedLanguageFromLanguageCode = (languageCode: TLanguageCode) => {
    return new Intl.DisplayNames([languageCode], { type: "language" }).of(languageCode);
};

export const languageFromLanguageCode = (languageCode: TLanguageCode) => {
    return new Intl.DisplayNames(["sv"], { type: "language" }).of(languageCode) as string;
};

const temp = {};

LANGUAGE_CODES.filter((langCode) => langCode !== languageFromLanguageCode(langCode)).forEach((langCode) => {
    temp[langCode] = languageFromLanguageCode(langCode);
});

console.log(temp);
*/

const LANGUAGES_IN_SWEDISH: Partial<{ [key in TLanguageCode]: string }> = {
    ab: "Abchaziska",
    aa: "Afar",
    af: "Afrikaans",
    ak: "Akan",
    sq: "Albanska",
    am: "Amhariska",
    ar: "Arabiska",
    an: "Aragonesiska",
    hy: "Armeniska",
    as: "Assamesiska",
    av: "Avariska",
    ae: "Avestiska",
    ay: "Aymara",
    az: "Azerbajdzjanska",
    bm: "Bambara",
    ba: "Basjkiriska",
    eu: "Baskiska",
    be: "Belarusiska",
    bn: "Bengali",
    bh: "Bhojpuri",
    bi: "Bislama",
    bs: "Bosniska",
    br: "Bretonska",
    bg: "Bulgariska",
    my: "Burmesiska",
    ca: "Katalanska",
    km: "Kambodjanska",
    ch: "Chamorro",
    ce: "Tjetjenska",
    ny: "Nyanja",
    zh: "Kinesiska",
    cu: "Kyrkslaviska",
    cv: "Tjuvasjiska",
    kw: "Korniska",
    co: "Korsikanska",
    cr: "Cree",
    hr: "Kroatiska",
    cs: "Tjeckiska",
    da: "Danska",
    dv: "Divehi",
    nl: "Nederländska",
    dz: "Dzongkha",
    en: "Engelska",
    eo: "Esperanto",
    et: "Estniska",
    ee: "Ewe",
    fo: "Färöiska",
    fj: "Fijianska",
    fi: "Finska",
    fr: "Franska",
    ff: "Fulani",
    gd: "Skotsk gäliska",
    gl: "Galiciska",
    lg: "Luganda",
    ka: "Georgiska",
    de: "Tyska",
    ki: "Kikuyu",
    el: "Grekiska",
    kl: "Grönländska",
    gn: "Guaraní",
    gu: "Gujarati",
    ht: "Haitiska",
    ha: "Hausa",
    he: "Hebreiska",
    hz: "Herero",
    hi: "Hindi",
    ho: "Hirimotu",
    hu: "Ungerska",
    is: "Isländska",
    io: "Ido",
    ig: "Igbo",
    id: "Indonesiska",
    ia: "Interlingua",
    ie: "Interlingue",
    iu: "Inuktitut",
    ik: "Inupiak",
    ga: "Iriska",
    it: "Italienska",
    ja: "Japanska",
    jv: "Javanesiska",
    kn: "Kannada",
    kr: "Kanuri",
    ks: "Kashmiriska",
    kk: "Kazakiska",
    rw: "Kinjarwanda",
    kv: "Kome",
    kg: "Kikongo",
    ko: "Koreanska",
    kj: "Kuanyama",
    ku: "Kurdiska",
    ky: "Kirgiziska",
    lo: "Laotiska",
    la: "Latin",
    lv: "Lettiska",
    lb: "Luxemburgiska",
    li: "Limburgiska",
    ln: "Lingala",
    lt: "Litauiska",
    lu: "Luba-katanga",
    mk: "Makedonska",
    mg: "Malagassiska",
    ms: "Malajiska",
    ml: "Malayalam",
    mt: "Maltesiska",
    gv: "Manx",
    mi: "Maori",
    mr: "Marathi",
    mh: "Marshalliska",
    ro: "Rumänska",
    mn: "Mongoliska",
    na: "Nauruanska",
    nv: "Navaho",
    nd: "Nordndebele",
    ng: "Ndonga",
    ne: "Nepalesiska",
    se: "Nordsamiska",
    no: "Norska",
    nb: "Norskt bokmål",
    nn: "Nynorska",
    ii: "Szezuan i",
    oc: "Occitanska",
    oj: "Odjibwa",
    or: "Oriya",
    om: "Oromo",
    os: "Ossetiska",
    pi: "Pali",
    pa: "Punjabi",
    ps: "Afghanska",
    fa: "Persiska",
    pl: "Polska",
    pt: "Portugisiska",
    qu: "Quechua",
    rm: "Rätoromanska",
    rn: "Rundi",
    ru: "Ryska",
    sm: "Samoanska",
    sg: "Sango",
    sa: "Sanskrit",
    sc: "Sardinska",
    sr: "Serbiska",
    sn: "Shona",
    sd: "Sindhi",
    si: "Singalesiska",
    sk: "Slovakiska",
    sl: "Slovenska",
    so: "Somaliska",
    st: "Sydsotho",
    nr: "Sydndebele",
    es: "Spanska",
    su: "Sundanesiska",
    sw: "Swahili",
    ss: "Swati",
    sv: "Svenska",
    tl: "Filippinska",
    ty: "Tahitiska",
    tg: "Tadzjikiska",
    ta: "Tamil",
    tt: "Tatariska",
    te: "Telugu",
    th: "Thailändska",
    bo: "Tibetanska",
    ti: "Tigrinja",
    to: "Tonganska",
    ts: "Tsonga",
    tn: "Tswana",
    tr: "Turkiska",
    tk: "Turkmeniska",
    tw: "Akan",
    ug: "Uiguriska",
    uk: "Ukrainska",
    ur: "Urdu",
    uz: "Uzbekiska",
    ve: "Venda",
    vi: "Vietnamesiska",
    vo: "Volapük",
    wa: "Vallonska",
    cy: "Walesiska",
    fy: "Västfrisiska",
    wo: "Wolof",
    xh: "Xhosa",
    yi: "Jiddisch",
    yo: "Yoruba",
    za: "Zhuang",
    zu: "Zulu",
};

const LANGUAGES_IN_LANGUAGE: Partial<{ [key in TLanguageCode]: string }> = {
    ab: "Abchaziska",
    aa: "Afar",
    af: "Afrikaans",
    ak: "Akan",
    sq: "shqip",
    am: "አማርኛ",
    ar: "العربية",
    an: "Aragonesiska",
    hy: "հայերեն",
    as: "অসমীয়া",
    av: "Avariska",
    ae: "Avestiska",
    ay: "Aymara",
    az: "azərbaycan",
    bm: "bamanakan",
    ba: "башҡорт теле",
    eu: "euskara",
    be: "беларуская",
    bn: "বাংলা",
    bh: "भोजपुरी",
    bi: "Bislama",
    bs: "bosanski",
    br: "brezhoneg",
    bg: "български",
    my: "မြန်မာ",
    ca: "català",
    km: "ខ្មែរ",
    ch: "Chamorro",
    ce: "нохчийн",
    ny: "Nyanja",
    zh: "中文",
    cu: "Kyrkslaviska",
    cv: "чӑваш",
    kw: "kernewek",
    co: "corsu",
    cr: "Cree",
    hr: "Hrvatski",
    cs: "Čeština",
    da: "dansk",
    dv: "ދިވެހިބަސް",
    nl: "Nederlands",
    dz: "རྫོང་ཁ་",
    en: "English",
    eo: "Esperanto",
    et: "eesti",
    ee: "Eʋegbe",
    fo: "føroyskt",
    fj: "Fijianska",
    fi: "suomi",
    fr: "français",
    ff: "Pulaar",
    gd: "Gàidhlig",
    gl: "galego",
    lg: "Luganda",
    ka: "ქართული",
    de: "Deutsch",
    ki: "Gikuyu",
    el: "Ελληνικά",
    kl: "kalaallisut",
    gn: "avañe’ẽ",
    gu: "ગુજરાતી",
    ht: "Haitiska",
    ha: "Hausa",
    he: "עברית",
    hz: "Herero",
    hi: "हिन्दी",
    ho: "Hirimotu",
    hu: "magyar",
    is: "íslenska",
    io: "Ido",
    ig: "Igbo",
    id: "Indonesia",
    ia: "interlingua",
    ie: "Interlingue",
    iu: "ᐃᓄᒃᑎᑐᑦ",
    ik: "Inupiak",
    ga: "Gaeilge",
    it: "Italiano",
    ja: "日本語",
    jv: "Jawa",
    kn: "ಕನ್ನಡ",
    kr: "Kanuri",
    ks: "کٲشُر",
    kk: "қазақ тілі",
    rw: "Kinyarwanda",
    kv: "Kome",
    kg: "Kikongo",
    ko: "한국어",
    kj: "Kuanyama",
    ku: "kurdî (kurmancî)",
    ky: "кыргызча",
    lo: "ລາວ",
    la: "Latin",
    lv: "latviešu",
    lb: "Lëtzebuergesch",
    li: "Limburgiska",
    ln: "lingála",
    lt: "lietuvių",
    lu: "Tshiluba",
    mk: "македонски",
    mg: "Malagasy",
    ms: "Bahasa Melayu",
    ml: "മലയാളം",
    mt: "Malti",
    gv: "Gaelg",
    mi: "Māori",
    mr: "मराठी",
    mh: "Marshalliska",
    ro: "română",
    mn: "монгол",
    na: "Nauruanska",
    nv: "Diné Bizaad",
    nd: "isiNdebele",
    ng: "Ndonga",
    ne: "नेपाली",
    se: "davvisámegiella",
    no: "norsk",
    nb: "norsk bokmål",
    nn: "norsk nynorsk",
    ii: "ꆈꌠꉙ",
    oc: "occitan",
    oj: "Odjibwa",
    or: "ଓଡ଼ିଆ",
    om: "Oromoo",
    os: "ирон",
    pi: "Pali",
    pa: "ਪੰਜਾਬੀ",
    ps: "پښتو",
    fa: "فارسی",
    pl: "polski",
    pt: "Português",
    qu: "Runasimi",
    rm: "rumantsch",
    rn: "Ikirundi",
    ru: "Русский",
    sm: "Gagana faʻa Sāmoa",
    sg: "Sängö",
    sa: "संस्कृत भाषा",
    sc: "sardu",
    sr: "српски",
    sn: "chiShona",
    sd: "سنڌي",
    si: "සිංහල",
    sk: "Slovenčina",
    sl: "slovenščina",
    so: "Soomaali",
    st: "Sesotho",
    es: "Español",
    su: "Basa Sunda",
    sw: "Kiswahili",
    ss: "siSwati",
    sv: "Svenska",
    tl: "Filipino",
    ty: "Tahitiska",
    tg: "тоҷикӣ",
    ta: "தமிழ்",
    tt: "татар",
    te: "తెలుగు",
    th: "ไทย",
    bo: "བོད་སྐད་",
    ti: "ትግርኛ",
    to: "lea fakatonga",
    ts: "Xitsonga",
    tn: "Setswana",
    tr: "Türkçe",
    tk: "Türkmen dili",
    tw: "Akan",
    ug: "ئۇيغۇرچە",
    uk: "Українська",
    ur: "اردو",
    uz: "o‘zbek",
    ve: "Tshivenḓa",
    vi: "Tiếng Việt",
    vo: "Volapük",
    wa: "walon",
    cy: "Cymraeg",
    fy: "Frysk",
    wo: "Wolof",
    xh: "IsiXhosa",
    yi: "ייִדיש",
    yo: "Èdè Yorùbá",
    za: "Vahcuengh",
    zu: "isiZulu",
};
