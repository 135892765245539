import { ISiteModuleHeader } from "../../interfaces/ISiteModule";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModuleHeader = (): ISiteModuleHeader => {
    return {
        siteModuleType: "Header",
        ...getNewSiteModuleBasic(true),
        title: {
            contentDataType: "Title",
            text: {},
        },
        secondLine: {
            contentDataType: "Text",
            text: {},
        },
        image: {
            contentDataType: "Image",
            image: null,
            userSelectedImageSize: "MEDIUM",
        },
    };
};
