import { TLanguageCode } from "../interfaces/IContentData";
import { TSiteModule } from "../interfaces/ISiteModule";
import { getSiteModuleMenuText } from "./getSiteModuleMenuText";

let latestCacheIndex: { siteModules: TSiteModule[] | null; language: TLanguageCode | null } = {
    siteModules: null,
    language: null,
};
let cacheSlugIndex: Record<string, string> = {};

const slugify = (string: string) => {
    const a = "àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
    const b = "aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-och-") // Replace & with 'och'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
};

const createIndex = (siteModules: TSiteModule[], language: TLanguageCode) => {
    cacheSlugIndex = {};
    siteModules.forEach((siteModule) => {
        let slug = slugify(getSiteModuleMenuText(siteModule, language) || "no-menu-title");
        slug = getUniqueSlug(slug);
        cacheSlugIndex[siteModule.id] = slug;
    });
    latestCacheIndex = { siteModules, language };
    return cacheSlugIndex;
};

const getSlugIndex = (siteModules: TSiteModule[], language: TLanguageCode) => {
    if (latestCacheIndex.siteModules === siteModules && latestCacheIndex.language === language) {
        return cacheSlugIndex;
    } else {
        return createIndex(siteModules, language);
    }
};

const getUniqueSlug = (slug: string) => {
    const isSlugUnique = (slug: string) => {
        for (const key in cacheSlugIndex) {
            // Get the indexed item by the key:
            if (cacheSlugIndex[key] === slug) {
                return false;
            }
        }
        return true;
    };

    if (!isSlugUnique(slug)) {
        let index = 1;

        while (true) {
            index += 1;
            const slugWithIndex = slug + "-" + index;
            if (isSlugUnique(slugWithIndex)) {
                slug = slugWithIndex;
                break;
            }
        }
    }
    return slug;
};

export const getSiteModuleSlug = (
    siteModule: TSiteModule,
    allSiteModules: TSiteModule[],
    language: TLanguageCode
): string => {
    const slugIndex = getSlugIndex(allSiteModules, language);
    return slugIndex[siteModule.id];
};
