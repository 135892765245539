import React from "react";
import styles from "./Input.module.scss";
import { ISiteV2 } from "../../../../../../interfaces/Site";

interface IProps {
    handleChange: (value: string) => void;
    value: string;
    name: string;
    id: string;
    label: string;
    site: ISiteV2;
    required: boolean;
}

export const Input = (props: IProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChange(event.target.value);
    }
    return (
        <div className={styles[props.site.theme]}>
            <label htmlFor={props.id}>{props.label}</label>
            <input
                required={props.required}
                id={props.id}
                type="text"
                name={props.name}
                value={props.value}
                onChange={handleChange}
            />
            <div className="invalidFeedback" data-invalid-feedback="true"></div>
        </div>
    );
};
