import { convertLineBreaksToHtmlBreaks } from "../../utils/convertLineBreaksToHtmlBreaks";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataText } from "../../interfaces/IContentData";
import { useTranslation } from "../../../contexts/LanguageContext";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataText;
    tag: "div" | "span";
    className?: string;
}

export const ContentTypeText = (props: IProps) => {
    const { getInTranslation } = useTranslation();
    return (
        <props.tag
            className={props.className}
            dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(getInTranslation(props.contentData.text))}
        />
    );
};
