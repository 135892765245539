import React from "react";
import ContentCountDown from "../../content/CountDown/CountDown";
import GeneratorModule from "../GeneratorModule";
import styles from "./CountDown.module.scss";

export class CountDown extends GeneratorModule {
    render() {
        return (
            <section className={styles[this.props.site.theme]}>
                <ContentCountDown
                    site={this.props.site}
                    propertyParent={this.props.moduleData}
                    property="countDown"
                    updateProperty={this.props.updateProperty}
                    editMode={this.props.editMode}
                ></ContentCountDown>
            </section>
        );
    }
}

export default CountDown;
