import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./GuestHeading.module.scss";
import { ISiteModuleRsvp } from "../../../../interfaces/ISiteModule";
import { IContentDataText, TLanguageCode } from "../../../../interfaces/IContentData";
import { ContentTypeTextEdit } from "../../../../contentTypes/ContentTypeText/ContentTypeTextEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
    guestNumber: number;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleRsvp, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
    language?: TLanguageCode;
}

export function GuestHeadingEdit(props: IProps) {
    return (
        <h3 className={styles[props.site.theme]}>
            <ContentTypeTextEdit
                updateContentData={(value: IContentDataText) =>
                    props.updateSiteModuleProperty("guestIndexTitle", value)
                }
                site={props.site}
                contentData={props.siteModule.guestIndexTitle}
                tag={"span"}
                placeholder="Ingen rubrik"
                language={props.language}
            />{" "}
            {props.guestNumber}
        </h3>
    );
}
