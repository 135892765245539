import { IColorScheme, IFonts } from "../interfaces/Site";
import { colorToCssValue } from "./colorToCssValue";

export function setGeneratorFontsStylesVariables(fonts: IFonts) {
    document.documentElement.style.setProperty("--font-heading", fonts.heading);
    document.documentElement.style.setProperty("--font-text", fonts.text);
}

export function setGeneratorColorStylesVariables(colors: IColorScheme) {
    document.documentElement.style.setProperty("--color-primary", colorToCssValue(colors.primary));
    document.documentElement.style.setProperty("--color-primary-h", colorToCssValue(colors.primary, "h"));
    document.documentElement.style.setProperty("--color-primary-s", colorToCssValue(colors.primary, "s"));
    document.documentElement.style.setProperty("--color-primary-l", colorToCssValue(colors.primary, "l"));
    document.documentElement.style.setProperty("--color-primary-a", colorToCssValue(colors.primary, "a"));
    document.documentElement.style.setProperty("--color-background", colorToCssValue(colors.background));
    document.documentElement.style.setProperty("--color-background-h", colorToCssValue(colors.background, "h"));
    document.documentElement.style.setProperty("--color-background-s", colorToCssValue(colors.background, "s"));
    document.documentElement.style.setProperty("--color-background-l", colorToCssValue(colors.background, "l"));
    document.documentElement.style.setProperty("--color-background-a", colorToCssValue(colors.background, "a"));
    document.documentElement.style.setProperty("--color-surface", colorToCssValue(colors.surface));
    document.documentElement.style.setProperty("--color-surface-h", colorToCssValue(colors.surface, "h"));
    document.documentElement.style.setProperty("--color-surface-s", colorToCssValue(colors.surface, "s"));
    document.documentElement.style.setProperty("--color-surface-l", colorToCssValue(colors.surface, "l"));
    document.documentElement.style.setProperty("--color-surface-a", colorToCssValue(colors.surface, "a"));
    document.documentElement.style.setProperty("--color-on-primary", colorToCssValue(colors.onPrimary));
    document.documentElement.style.setProperty("--color-on-primary-h", colorToCssValue(colors.onPrimary, "h"));
    document.documentElement.style.setProperty("--color-on-primary-s", colorToCssValue(colors.onPrimary, "s"));
    document.documentElement.style.setProperty("--color-on-primary-l", colorToCssValue(colors.onPrimary, "l"));
    document.documentElement.style.setProperty("--color-on-primary-a", colorToCssValue(colors.onPrimary, "a"));
    document.documentElement.style.setProperty("--color-on-background", colorToCssValue(colors.onBackground));
    document.documentElement.style.setProperty("--color-on-surface", colorToCssValue(colors.onSurface));
    document.documentElement.style.setProperty("--color-on-surface-h", colorToCssValue(colors.onSurface, "h"));
    document.documentElement.style.setProperty("--color-on-surface-s", colorToCssValue(colors.onSurface, "s"));
    document.documentElement.style.setProperty("--color-on-surface-l", colorToCssValue(colors.onSurface, "l"));
    document.documentElement.style.setProperty("--color-on-surface-a", colorToCssValue(colors.onSurface, "a"));
}
