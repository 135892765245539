import { ISiteModuleRsvp } from "../../interfaces/ISiteModule";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModuleRsvp = (): ISiteModuleRsvp => {
    return {
        siteModuleType: "Rsvp",
        ...getNewSiteModuleBasic(false),
        title: {
            contentDataType: "Title",
            text: {},
        },
        selectNumberOfGuestsText: {
            contentDataType: "Text",
            text: { sv: "Välj antalet personer att OSA för:", en: "Select number of guests:" },
        },
        sendButton: {
            contentDataType: "Text",
            text: { sv: "Skicka", en: "Send" },
        },
        oneGuestRsvpButton: {
            contentDataType: "Text",
            text: { sv: "OSA", en: "RSVP" },
        },
        text: {
            contentDataType: "RichText",
            richText: {},
        },
        maxNumberOfGuests: 5,
        form: {
            contentDataType: "Form",
            formElements: [],
        },
        confirmationTitle: {
            contentDataType: "Title",
            text: { sv: "Tack!", en: "Thank you!" },
        },
        confirmationText: {
            contentDataType: "RichText",
            richText: {
                sv: "<p>Tack för att du skickat in OSA, vi har tagit emot dina uppgifter.</p>",
                en: "<p>We have received you RSVPs.</p>",
            },
        },
        guestIndexTitle: {
            contentDataType: "Text",
            text: { sv: "Person", en: "Guest" },
        },
    };
};
