import EditButton from "../../components/EditButton/EditButton";
import { ContentTypeModuleHeadingEdit } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeadingEdit";
import { IconNewContentModule } from "../../icons/newContentModule";
import { IContentDataTitle } from "../../interfaces/IContentData";
import { ISiteModuleTextAndImageList } from "../../interfaces/ISiteModule";
import { ISiteV2 } from "../../interfaces/Site";
import { getNewSiteModuleTextAndImageListItem } from "../utils/getNewSiteModuleTextAndImageList";
import styles from "./SiteModuleTextAndImageList.module.scss";
import { SiteModuleTextAndImageListItemEdit } from "./SiteModuleTextAndImageListItem/SiteModuleTextAndImageListItemEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleTextAndImageList;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleTextAndImageList, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
    updateSiteModule: <SiteModule extends ISiteModuleTextAndImageList>(
        updater: (siteModule: SiteModule) => void
    ) => void;
}

export const SiteModuleTextAndImageListEdit = (props: IProps) => {

    const addItem = () => {
        props.updateSiteModule((siteModule) => {
            siteModule.list.push(getNewSiteModuleTextAndImageListItem());
        });
    };

    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeadingEdit
                contentData={props.siteModule.title}
                site={props.site}
                updateContentData={(value: IContentDataTitle) => props.updateSiteModuleProperty("title", value)}
            />
            {props.siteModule.list.length ? (
                <div className={styles.itemsWrapper}>
                    {props.siteModule.list.map((listItem) => (
                        <SiteModuleTextAndImageListItemEdit
                            site={props.site}
                            list={props.siteModule.list}
                            listItem={listItem}
                            key={listItem.id}
                            updateSiteModule={props.updateSiteModule}
                        />
                    ))}
                </div>
            ) : null}
            <div className={styles.editButtonWrapper}>
                <EditButton callback={addItem}>
                    <>
                        <IconNewContentModule></IconNewContentModule>
                        Lägg till ruta
                    </>
                </EditButton>
            </div>
        </section>
    );
};
