import { ISiteV2 } from "../../../interfaces/Site";
import styles from "./EmulateContentSurface.module.scss";
import themeStyles from "../../../views/GeneratorSite/ThemeStyles.module.scss";

interface IProps {
    site: ISiteV2;
    children: JSX.Element;
    className?: string;
}

export const EmulateContentSurface = (props: IProps) => {
    return (
        <div className={`${styles[props.site.theme]} ${themeStyles[props.site.theme]} ${props.className}`}>
            <div className={styles.contentBox}>{props.children}</div>
        </div>
    );
};
