import { TTranslation } from "../../interfaces/IContentData";
import { useTranslation } from "../../../contexts/LanguageContext";

interface IProps {
    translation: TTranslation;
}

export const TranslationWithFallback = (props: IProps) => {
    const { getInTranslationWithFallback } = useTranslation();

    let text = getInTranslationWithFallback(props.translation);

    return <>{text}</>;
};
