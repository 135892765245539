import { ISiteV2 } from "../../../interfaces/Site";
import styles from "./SiteModuleTextAndImageListItem.module.scss";
import { rteContentIsEmpty } from "../../../utils/rteContentIsEmpty";
import { ISiteModuleTextAndImageListItem } from "../../../interfaces/ISiteModule";
import { ContentTypeRichText } from "../../../contentTypes/ContentTypeRichText/ContentTypeRichText";
import { ContentTypeTitle } from "../../../contentTypes/ContentTypeTitle/ContentTypeTitle";
import { ContentTypeImage } from "../../../contentTypes/ContentTypeImage/ContentTypeImage";
import { useTranslation } from "../../../../contexts/LanguageContext";

interface IProps {
    site: ISiteV2;
    listItem: ISiteModuleTextAndImageListItem;
}

export function SiteModuleTextAndImageListItem(props: IProps) {
    const { getInTranslation } = useTranslation();

    const showTextBox =
        getInTranslation(props.listItem.title.text) ||
        !rteContentIsEmpty(getInTranslation(props.listItem.text.richText));

    return (
        <div className={`${styles[props.site.theme]}`}>
            <div className={styles.imageWrapper}>
                <ContentTypeImage
                    sizes="(min-width: 750px) 600px, 100vw"
                    contentData={props.listItem.image}
                    site={props.site}
                />
            </div>
            {showTextBox ? (
                <div className={styles.textAndHeadingContentWrapper}>
                    <ContentTypeTitle tag="h3" contentData={props.listItem.title} site={props.site} />
                    <div className={styles.textContentWrapper}>
                        <ContentTypeRichText contentData={props.listItem.text} site={props.site} />
                    </div>
                </div>
            ) : null}
        </div>
    );
}
