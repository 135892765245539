import styles from "./SiteModuleVideo.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { ISiteModuleVideo } from "../../interfaces/ISiteModule";
import { ContentTypeModuleHeading } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeading";
import { ContentTypeVideo } from "../../contentTypes/ContentTypeVideo/ContentTypeVideo";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleVideo;
}

export const SiteModuleVideo = (props: IProps) => {
    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeading contentData={props.siteModule.title} site={props.site} />
            <div className={styles.videoWrapper}>
                <ContentTypeVideo contentData={props.siteModule.video} site={props.site} />
            </div>
        </section>
    );
};
