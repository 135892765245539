import { useDebouncedCallback } from "use-debounce";
import Button from "../../../../components/button/Button";
import ExpandableArea from "../../../../components/ExpandableArea/ExpandableArea";
import { ColorPicker } from "../../../../components/form/ColorPicker/ColorPicker";
import FormWrapper from "../../../../components/form/FormWrapper/FormWrapper";
import { Slider } from "../../../../components/form/Slider/Slider";
import TextField from "../../../../components/form/TextField/TextField";
import { GeneratorSelectList } from "../../../../components/GeneratorSelectList/GeneratorSelectList";
import { MapTheme } from "../../../../components/map/Map";
import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./EditMap.module.scss";
import { IContentDataMap, TMapTheme } from "../../../../interfaces/IContentData";
import { useImmer } from "use-immer";
import Axios from "axios";
import { PreviewMap } from "../PreviewMap/PreviewMap";
import Loading from "../../../../components/Loading/Loading";
import { useContext, useState } from "react";
import { QuickStartContext } from "../../../../../contexts/QuickStartContext";
import { getMapImageUrl } from "../../utils/getMapImageUrl";

export interface IContentMapValue {
    location: string;
    mapTheme: MapTheme;
    markerColor: string;
    zoom: number;
}

interface IProps {
    site: ISiteV2;
    contentData: IContentDataMap;
    updateContentData: (value: IContentDataMap) => void;
    onSuccess: () => void;
}

interface ISaveMapResponse {
    map: {
        path: string;
        size: number;
        location: string;
        sizes: { size: number; path: string }[];
        temporary: boolean;
    };
}

export function EditMap(props: IProps) {
    const [newMapValues, setNewMapValues] = useImmer({
        ...props.contentData,
        location: props.contentData.map?.location,
    });
    const [isLoading, setIsLoading] = useState(false);
    const isQuickStart = useContext(QuickStartContext);

    const handleDone = async () => {
        let map = null;
        setIsLoading(true);
        if (newMapValues.location) {
            const url = getMapImageUrl(
                "SAVE",
                newMapValues.location,
                newMapValues.markerColor,
                isQuickStart,
                props.site._id,
                newMapValues.zoom,
                newMapValues.mapTheme
            );
            const response = await Axios.post<ISaveMapResponse>(url);
            map = response.data.map;
            map = { ...map, isDemoImage: !!props.site.quickStart && !isQuickStart }
        }
        props.updateContentData({
            contentDataType: "Map",
            mapTheme: newMapValues.mapTheme,
            markerColor: newMapValues.markerColor,
            zoom: newMapValues.zoom,
            map,
        });
        setIsLoading(false);
        props.onSuccess();
    };

    const mapThemeOptions = [
        {
            title: "Standard",
            value: "NORMAL",
        },
        {
            title: "Silver",
            value: "SILVER",
        },
        {
            title: "Retro",
            value: "RETRO",
        },
        {
            title: "Mörk",
            value: "DARK",
        },
        {
            title: "Natt",
            value: "NIGHT",
        },
    ];

    const debouncedLocationChange = useDebouncedCallback(
        (value) =>
            setNewMapValues((values) => {
                values.location = value;
            }),
        800
    );

    return (
        <>
            {isLoading === true && <Loading />}
            <FormWrapper submit={handleDone}>
                <div className={styles.wrapper}>
                    <div className={styles.formWrapper}>
                        <div className={styles.formFieldWrapper}>
                            <TextField
                                label="Plats/Adress/koordinater"
                                name="location"
                                id="editMapLocation"
                                onChangeCallback={debouncedLocationChange}
                                value={newMapValues.location || ""}
                                placeholder="Kyrkvägen 2, 111 22, Jönköping"
                            />
                        </div>
                        <div className={styles.formFieldWrapper}>
                            <GeneratorSelectList
                                defaultValue={MapTheme.NORMAL}
                                cols={3}
                                name="mapTheme"
                                options={mapThemeOptions}
                                value={newMapValues.mapTheme}
                                onChangeCallback={(value: TMapTheme) =>
                                    setNewMapValues((values) => {
                                        values.mapTheme = value;
                                    })
                                }
                                dark={true}
                            />
                        </div>
                        <ExpandableArea title="Avancerade inställningar">
                            <>
                                <div className={styles.formFieldWrapper}>
                                    <Slider
                                        min={6}
                                        max={18}
                                        value={newMapValues.zoom || 12}
                                        onChangeCallback={(value: number) =>
                                            setNewMapValues((values) => {
                                                values.zoom = value;
                                            })
                                        }
                                        label="Zoom"
                                        name="zoom"
                                        id="editMapZoom"
                                    />
                                </div>
                                <div className={styles.formFieldWrapper}>
                                    <ColorPicker
                                        value={newMapValues.markerColor || "#ea4335"}
                                        onChangeCallback={(value: string) =>
                                            setNewMapValues((values) => {
                                                values.markerColor = value;
                                            })
                                        }
                                        label="Markörfärg"
                                        name="markerColor"
                                        id="editMapMarkerColor"
                                        onlyHex={true}
                                    />
                                </div>
                            </>
                        </ExpandableArea>
                    </div>
                    {newMapValues.location ? (
                        <div className={styles.mapWrapper}>
                            <PreviewMap
                                site={props.site}
                                zoom={newMapValues.zoom}
                                markerColor={newMapValues.markerColor}
                                mapTheme={newMapValues.mapTheme}
                                location={newMapValues.location}
                            />
                        </div>
                    ) : null}
                </div>
                <div className={styles.bottomButtons}>
                    <Button buttonText="Klar" type="primary" submit={true} fullWidth={true} />
                </div>
            </FormWrapper>
        </>
    );
}

export default EditMap;
