import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./FormEdit.module.scss";
import { ISiteModuleForm } from "../../../../interfaces/ISiteModule";
import {
    IContentDataForm,
    IContentDataRichText,
    IContentDataText,
    IContentDataTitle,
} from "../../../../interfaces/IContentData";
import { Tab } from "../../../../editComponents/Tab/Tab";
import { useState } from "react";
import { ContentTypeFormEdit } from "../../../../contentTypes/ContentTypeForm/ContentTypeFormEdit";
import { FormConfirmationEdit } from "../../../components/FormConfirmationEdit/FormConfirmationEdit";
import { DialogTop } from "../../../../editComponents/DialogTop/DialogTop";
import { FormRecipientsEdit } from "../../../components/FormRecipientsEdit/FormRecipientsEdit";
import { DraftFunction, Updater } from "use-immer";
import { FormSendButtonEdit } from "../../../components/FormSendButtonEdit/FormSendButtonEdit";
import { ShowFormButton } from "./components/ShowFormButton/ShowFormButton";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleForm;
    updateSite: Updater<ISiteV2>;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleForm, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
    updateSiteModule: <SiteModule extends ISiteModuleForm>(updater: (siteModule: SiteModule) => void) => void;
    close: () => void;
}


export const FormEdit = (props: IProps) => {
    const [view, setView] = useState<"QUESTIONS" | "CONFIRMATION" | "BUTTONS">("QUESTIONS");

    const Tabs = (
        <>
            <Tab active={view === "QUESTIONS"} onClick={() => setView("QUESTIONS")} text="Frågor" />
            <Tab active={view === "CONFIRMATION"} onClick={() => setView("CONFIRMATION")} text="Bekräftelse" />
            <Tab active={view === "BUTTONS"} onClick={() => setView("BUTTONS")} text="Knappar" />
        </>
    );

    const updateForm = (updater: DraftFunction<IContentDataForm>) => {
        return props.updateSiteModule((draft) => {
            updater(draft.form);
        });
    };

    if (view === "QUESTIONS") {
        return (
            <>
                <div className={styles.wrapper}>
                    <ContentTypeFormEdit
                        tabs={Tabs}
                        contentData={props.siteModule.form}
                        site={props.site}
                        updateForm={updateForm}
                        close={props.close}
                    />
                </div>
            </>
        );
    }

    if (view === "CONFIRMATION") {
        return (
            <>
                <DialogTop title="Formulär" close={props.close} tabs={Tabs} />
                <div className={styles.confirmationWrapper}>
                    <FormRecipientsEdit formId={props.siteModule.id} updateSite={props.updateSite} site={props.site} />
                    <FormConfirmationEdit
                        site={props.site}
                        siteModule={props.siteModule}
                        updateConfirmationText={(value: IContentDataRichText) =>
                            props.updateSiteModuleProperty("confirmationText", value)
                        }
                        updateConfirmationTitle={(value: IContentDataTitle) =>
                            props.updateSiteModuleProperty("confirmationTitle", value)
                        }
                    />
                </div>
            </>
        );
    }

    if (view === "BUTTONS") {
        return (
            <>
                <DialogTop title="Formulär" close={props.close} tabs={Tabs} />
                <div className={styles.othersWrapper}>
                    <FormSendButtonEdit
                        site={props.site}
                        siteModule={props.siteModule}
                        updateSendButton={(value: IContentDataText) =>
                            props.updateSiteModuleProperty("sendButton", value)
                        }
                    />
                    <ShowFormButton
                        site={props.site}
                        siteModule={props.siteModule}
                        updateButton={(value: IContentDataText) =>
                            props.updateSiteModuleProperty("showFormButton", value)
                        }
                    />
                </div>
            </>
        );
    }

    throw new Error("No view in RsvpFormEdit");
};
