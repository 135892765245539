import { useTranslation } from "../../../../../contexts/LanguageContext";
import { IFormElementOption, IFormElementSingleSelect } from "../../../../interfaces/FormElement";
import { IFormElementData } from "../../../../interfaces/IFormData";
import { ISiteV2 } from "../../../../interfaces/Site";
import { RadioGroup } from "./components/RadioGroup/RadioGroup";

interface IProps {
    site: ISiteV2;
    formElement: IFormElementSingleSelect;
    handleValueChange: (formElement: IFormElementSingleSelect, value: IFormElementOption) => void;
    formPartIdentifier: string;
    formElementData?: IFormElementData;
}

export const FormElementSingleSelect = (props: IProps) => {
    const { getInTranslation } = useTranslation();
    const radioGroupIdentifier = props.formPartIdentifier + "-" + props.formElement.id;
    return (
        <div>
            <RadioGroup
                label={getInTranslation(props.formElement.label)}
                options={props.formElement.options}
                name={radioGroupIdentifier}
                id={radioGroupIdentifier}
                handleChange={(value: IFormElementOption) => {
                    props.handleValueChange(props.formElement, value);
                }}
                site={props.site}
                value={props.formElementData?.selectedOption}
            />
        </div>
    );
};
