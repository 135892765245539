import axios from "axios";
import React, { useState } from "react";
import { ISiteV2 } from "../../../interfaces/Site";
import { IError } from "../../../../App";
import { IContentDataImage } from "../../../interfaces/IContentData";
import ShowError from "../../../components/ShowError/ShowError";
import Loading from "../../../components/Loading/Loading";
import { FileUpload } from "../../../components/fileUpload/FileUpload";
import { QuickStartContext } from "../../../../contexts/QuickStartContext";
import { logError } from "../../../utils/LogError";

interface IProps {
    site: ISiteV2;
    updateContentData: (value: IContentDataImage) => void;
    contentData: IContentDataImage;
    onSuccess?: () => void;
}

export function UploadImage(props: IProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<IError | null>(null);

    const isQuickStart = React.useContext(QuickStartContext);

    const handleFileUpload = (file: any) => {
        setIsLoading(true);
        const data = new FormData();
        data.append("file", file);
        axios
            .post(
                process.env.REACT_APP_API_URL +
                    "/api/sites/" +
                    (isQuickStart ? "quick-start" : props.site._id) +
                    "/upload-image",
                data
            )
            .then((response) => {
                props.updateContentData({
                    ...props.contentData,
                    image: {
                        path: response.data.path,
                        originalName: response.data.originalName,
                        fileName: response.data.fileName,
                        temporary: response.data.temporary,
                        isDemoImage: !!props.site.quickStart && !response.data.temporary,
                        sizes: response.data.sizes,
                        width: response.data.width,
                        height: response.data.height,
                    },
                });
                setIsLoading(false);
                if (props.onSuccess) {
                    props.onSuccess();
                }
            })
            .catch((err) => {
                logError(
                    "generator.ContentTypeImage.uploadImage.handleFileUpload",
                    err,
                    1,
                    {
                        path:
                            process.env.REACT_APP_API_URL +
                            "/api/sites/" +
                            (isQuickStart ? "quick-start" : props.site._id) +
                            "/upload-image",
                    },
                    props.site
                );
                setError({ text: "Misslyckades att ladda upp bilden", response: err.response });
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading === true && <Loading />}
            <ShowError error={error} />
            <FileUpload
                onFileUpload={handleFileUpload}
                buttonText="Klicka här eller dra och släpp för att ladda upp bild"
            />
        </>
    );
}
