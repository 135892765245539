export const convertLineBreaksToHtmlBreaks = (value: string = ""): { __html: string } => {
    // innerText from contentEditable returns wrong number of line breaks so we allow only once for now, fix if any user complains
    // https://stackoverflow.com/questions/52817606/contenteditable-in-firefox-creates-2-newlines-instead-of-1
    const valueWithoutMultipleLinebreaks = value.replace(/\n\s*\n/g, "\n");
    let valueWithBreaksInsteadOfLinebreaks = valueWithoutMultipleLinebreaks.replace(/\n/g, "<br />");
    if (valueWithBreaksInsteadOfLinebreaks?.trim() === "<br />" || valueWithBreaksInsteadOfLinebreaks?.trim() === "<br />") {
        // So that empty really is empty and we can show placeholder
        valueWithBreaksInsteadOfLinebreaks = "";
    }
    return { __html: valueWithBreaksInsteadOfLinebreaks };
};
