import { useState } from "react";
import { ISiteV2 } from "../interfaces/Site";
import { SiteModuleEdit } from "./SiteModuleEdit";
import { AddSiteModule } from "./components/AddSiteModule/AddSiteModule";
import { SiteModulesEditEmpty } from "./components/SiteModulesEditEmpty/SiteModulesEditEmpty";
import { TSiteModuleType } from "../interfaces/ISiteModule";
import { Updater } from "use-immer";
import { addSiteModule, scrollToSiteModuleAfterUpdate } from "./utils/siteModulesUtils";
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";
import { getBrandedContactMail } from "../utils/getBrand";
import { getSiteModuleSlug } from "../utils/getSiteModuleSlug";
import { useTranslation } from "../../contexts/LanguageContext";
import { IconNewContentModule } from "../icons/newContentModule";
import EditButton from "../components/EditButton/EditButton";
import styles from "./SiteModulesList.module.scss";

interface IProps {
    site: ISiteV2;
    updateSite: Updater<ISiteV2>;
}

export const SiteModulesListEdit = (props: IProps) => {
    const [showAddSiteModule, setShowAddSiteModule] = useState<boolean>(false);
    const { language } = useTranslation();

    const addNewModule = (type: TSiteModuleType) => {
        props.updateSite((site) => {
            const { modules, newModule } = addSiteModule(site.siteModules || [], type);
            site.siteModules = modules;

            const newModuleSlug = getSiteModuleSlug(newModule, site.siteModules, language);
            scrollToSiteModuleAfterUpdate(newModuleSlug);
        });
        setShowAddSiteModule(false);
    };

    const siteModules = props.site.siteModules;
    return (
        <>
            {showAddSiteModule ? (
                <AddSiteModule addNewModule={addNewModule} close={() => setShowAddSiteModule(false)} />
            ) : null}
            {siteModules && siteModules.length ? (
                <>
                    {siteModules.map((siteModule) => (
                        <ErrorBoundary
                            key={siteModule.id}
                            logErrorSource={"SiteModuleEdit." + siteModule?.siteModuleType}
                            visitorsErrorMessage={
                                "Ett oväntat fel uppstod i den här modulen. Felet har loggats. Spara dina senaste ändringar och ladda om sidan. Om inte det hjälper, kontakta oss på " +
                                getBrandedContactMail() +
                                "."
                            }
                        >
                            <SiteModuleEdit updateSite={props.updateSite} site={props.site} siteModule={siteModule} />
                        </ErrorBoundary>
                    ))}
                    <div className={styles.addNewModuleButtonWrapper}>
                        <div className={styles.addNewModuleButton}>
                            <EditButton callback={() => setShowAddSiteModule(true)}>
                                <IconNewContentModule />
                                Ny modul
                            </EditButton>
                        </div>
                    </div>
                </>
            ) : (
                <SiteModulesEditEmpty addModule={() => setShowAddSiteModule(true)} />
            )}
        </>
    );
};
