import styles from "./Tabs.module.scss";

interface IProps {
    children?: JSX.Element[] | JSX.Element;
    className?: string;
}

export function Tabs(props: IProps) {
    return <div className={`${styles.wrapper} ${props.className}`}>{props.children}</div>;
}
