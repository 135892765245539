import Helmet from "react-helmet";
import { isDemoFromHost } from "../../../utils/DomainHelpers";
import { ISiteV2 } from "../../interfaces/Site";
import "../../style/variables.scss";
import { getBrand, getBrandedSingleCustomerPageName } from "../../utils/getBrand";
import { SiteGraphic } from "../../components/SiteGraphic/SiteGraphic";
import { SiteModulesList } from "../../siteModules/SiteModulesList";
import { Menu } from "../../modules/Menu/Menu";
import { SelectLanguage } from "../../components/SelectLanguage/SelectLanguage";
import { SiteModulesListWrapper } from "./components/SiteModulesListWrapper/SiteModulesListWrapper";
import { SiteFontSetter } from "./components/SiteFontSetter/SiteFontSetter";
import { ThemeStylesSetter } from "./components/ThemeStylesSetter/ThemeStylesSetter";

interface IProps {
    site: ISiteV2;
}

export const GeneratorSitePublic = (props: IProps) => {
    const setAndReturnTitle = () => {
        let title;
        if (!isDemoFromHost(props.site.host)) {
            title = getBrandedSingleCustomerPageName();
        } else {
            title = "Demosida - " + getBrand();
        }
        window.document.title = title;
        return title;
    };

    const getMetaRobots = () => {
        if (props.site.metaNoindex === true) {
            return "noindex, noimageindex, nofollow"
        } else {
            return "all";
        }
    } 

    return (
        <ThemeStylesSetter site={props.site}>
            <div id="DialogPublicPlacement" />
            <Helmet>
                <title>{setAndReturnTitle()}</title>
                <meta name="robots" content={getMetaRobots()} />
            </Helmet>
            <SiteFontSetter>
                {props.site.siteGraphic ? (
                    <SiteGraphic type="SITE" siteGraphic={props.site.siteGraphic} editAvailable={false} />
                ) : null}
                <Menu site={props.site} editMode={false} editAvailable={false} />
                <SelectLanguage site={props.site} />
                <SiteModulesListWrapper site={props.site}>
                    <SiteModulesList editAvailable={false} site={props.site} />
                </SiteModulesListWrapper>
            </SiteFontSetter>
        </ThemeStylesSetter>
    );
};
