import { useState } from "react";
import { ISiteV2 } from "../../interfaces/Site";
import styles from "./SiteModuleGiftRegistry.module.scss";
import { ISiteModuleGiftRegistry } from "../../interfaces/ISiteModule";
import { ContentTypeRichText } from "../../contentTypes/ContentTypeRichText/ContentTypeRichText";
import { ContentTypeModuleHeading } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeading";
import { GiftRegistryItem } from "./components/GiftRegistryItem/GiftRegistryItem";
import { useImmer } from "use-immer";
import { ContentTypeButton } from "../../contentTypes/ContentTypeButton/ContentTypeButton";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleGiftRegistry;
}

export const SiteModuleGiftRegistry = (props: IProps) => {
    const useExpansion = props.siteModule.giftRegistry?.length && props.siteModule.giftRegistry.length > 1;
    const [view, setView] = useState<"START" | "LIST">(useExpansion ? "START" : "LIST");
    const [giftRegistryReservations, setGiftRegistryReservations] = useImmer(props.site.giftRegistryReservations);

    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeading contentData={props.siteModule.title} site={props.site} />
            <div className={styles.contentWrapper}>
                <div className={styles.rteWrapper}>
                    <ContentTypeRichText contentData={props.siteModule.text} site={props.site} />
                </div>
                {view === "START" && (
                    <div className={styles.showGiftRegistryButtonAndEditButtonWrapper}>
                        <ContentTypeButton
                            site={props.site}
                            contentData={props.siteModule.showGiftRegistryButton}
                            small={false}
                            onClick={() => setView("LIST")}
                            data-test-id="gift-registry-open-button"
                            submit={false}
                        />
                    </div>
                )}
                {view === "LIST" ? (
                    <div className={styles.giftRegistryWrapper}>
                        {props.siteModule.giftRegistry.map((item) => (
                            <GiftRegistryItem
                                giftRegistryItem={item}
                                giftRegistryReservations={giftRegistryReservations}
                                setGiftRegistryReservations={setGiftRegistryReservations}
                                site={props.site}
                                key={item.id}
                                siteModule={props.siteModule}
                            />
                        ))}
                    </div>
                ) : null}
            </div>
        </section>
    );
};
