import styles from "./ContentTypeImage.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataImage } from "../../interfaces/IContentData";
import { ImgTagFromContentTypeImage } from "../components/ImgTagFromContentTypeImage/ImgTagFromContentTypeImage";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataImage;
    sizes?: string;
    onClick?: () => void;
    alt?: string;
}

export const ContentTypeImage = (props: IProps) => {
    if (!props.contentData.image) {
        return null;
    }

    return (
        <div className={`${styles[props.site.theme]}`} onClick={props.onClick}>
            <ImgTagFromContentTypeImage alt={props.alt} image={props.contentData.image} sizes={props.sizes} />
        </div>
    );
};
