import React from "react";
import { NavHashLink } from "react-router-hash-link";
import { ISiteV2 } from "../../../interfaces/Site";
import styles from "./MenuItem.module.scss";
import { TSiteModule } from "../../../interfaces/ISiteModule";
import { useTranslation } from "../../../../contexts/LanguageContext";
import { getSiteModuleSlug } from "../../../utils/getSiteModuleSlug";
import { getSiteModuleMenuText } from "../../../utils/getSiteModuleMenuText";

interface IProps {
    siteModule: TSiteModule;
    closeMenu: () => void;
    site: ISiteV2;
    measurementOnly?: boolean;
    navigationFits: boolean;
}

export const ContentMenuItem = (props: IProps) => {
    const { language } = useTranslation();
    const slug = getSiteModuleSlug(props.siteModule, props.site.siteModules, language);

    const menuItemText = getSiteModuleMenuText(props.siteModule, language);

    if (menuItemText === null) {
        return null;
    }

    if (props.measurementOnly) {
        return (
            <li>
                <span className={`${styles[props.site.theme]} ${styles.navigationFits}`}>{menuItemText}</span>
            </li>
        );
    }
    return (
        <li>
            <NavHashLink
                to={"#" + slug}
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                onClick={() => {
                    props.closeMenu();
                }}
                className={`${styles[props.site.theme]} ${props.navigationFits ? styles.navigationFits : ""}`}
            >
                {menuItemText}
            </NavHashLink>
        </li>
    );
};

export default ContentMenuItem;
