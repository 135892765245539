import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./ActionBarItem.module.scss";

interface IProps {
    icon?: JSX.Element;
    text: string;
    onClick?: () => void;
    highlight?: boolean;
    hide?: boolean;
    active?: boolean;
    testId?: string;
    to?: string;
    "data-umami-event"?: string;
    "data-umami-event-position"?: string;
}

export function ActionBarItem(props: IProps) {
    if (props.hide === true) {
        return null;
    }
    if (props.to) {
        return (
            <NavLink
                data-test-id={props.testId}
                className={`${styles.button} ${props.active === true ? styles.active : ""} ${
                    props.highlight === true ? styles.highlight : ""
                }`}
                activeClassName={styles.active}
                to={props.to}
                exact={true}
                onClick={props.onClick}
                data-umami-event={props["data-umami-event"]}
                data-umami-event-position={props["data-umami-event-position"]}
            >
                {props.icon ? <span className={styles.icon}>{props.icon}</span> : null}
                <span className={styles.text}>{props.text}</span>
            </NavLink>
        );
    } else {
        return (
            <button
                data-test-id={props.testId}
                className={`${styles.button} ${props.active === true ? styles.active : ""} ${
                    props.highlight === true ? styles.highlight : ""
                }`}
                onClick={props.onClick}
                data-umami-event={props["data-umami-event"]}
                data-umami-event-position={props["data-umami-event-position"]}
            >
                {props.icon ? <span className={styles.icon}>{props.icon}</span> : null}
                <span className={styles.text}>{props.text}</span>
            </button>
        );
    }
}
