import { LinkButton } from "../../../../../../components/button/LinkButton";
import { IconCheck } from "../../../../../../icons/check";
import { useEditSiteNavigationBasePath } from "../../../../../../utils/useEditSiteNavigationBasePath";
import styles from "./SelectProduct.module.scss";
import { ExamplePrintsList } from "./components/ExamplePrintsList/ExamplePrintsList";
import { SitePrintsList } from "./components/SitePrintsList/SitePrintsList";

type TProps =
    | {
          quickStart: true;
      }
    | {
          quickStart: false;
          siteId: string;
      };

export const SelectProduct = (props: TProps) => {
    const navigationBasePath = useEditSiteNavigationBasePath();
    return (
        <div className={styles.wrapper}>
            <div className={styles.grid}>
                <div className={styles.sectionFullWidth}>
                    <h1>Inbjudningar</h1>
                    <div className={styles.intro}>
                        Hur ni väljer att skicka ut er bröllopshemsida är helt upp till er! Antingen gör ni allt
                        digitalt, då skickar ni adressen via till exempel SMS eller mejl. Men vill ni åt en lite extra
                        lyxig känsla erbjuder vi även matchande inbjudningskort att beställa hem som ni sedan skickar
                        till era gäster via posten.
                    </div>
                </div>
                {props.quickStart ? null : <SitePrintsList siteId={props.siteId} />}
                <div className={styles.section}>
                    <div className={styles.push}>
                        <h3 className={styles.sectionTitle}>Matchande inbjudningskort</h3>
                        <div className={styles.price}>150:- / 10 st</div>
                        <ul>
                            <li>
                                <IconCheck /> Matchar designen på er bröllopssida
                            </li>
                            <li>
                                <IconCheck /> A6-format med tryck på båda sidor
                            </li>
                            <li>
                                <IconCheck /> Tjockt matt papper (300 gsm)
                            </li>
                            <li>
                                <IconCheck /> Fast frakt 95:-
                            </li>
                        </ul>
                    </div>
                    <div>
                        <LinkButton
                            fullWidth={true}
                            type="primary"
                            to={navigationBasePath + "inbjudningar/nytt"}
                            data-test-id="select-new-invite"
                        >
                            Prova att skapa inbjudan!
                        </LinkButton>
                    </div>
                </div>
                <ExamplePrintsList />
                {/*
                <div className={styles.section}>
                <div className={styles.push}>
                        <h3 className={styles.sectionTitle}>Save the date</h3>
                        <div className={styles.price}>150:- / 10 st</div>
                        <ul>
                            <li>
                                <IconCheck /> Matchar designen på er bröllopssida
                            </li>
                            <li>
                                <IconCheck /> A6-format med tryck på båda sidor
                            </li>
                            <li>
                                <IconCheck /> Tjockt matt papper (300 gsm)
                            </li>
                            <li>
                                <IconCheck /> Fast frakt 95:-
                            </li>
                        </ul>
                    </div>
                    <div>
                        {props.quickStart ? (
                            <LinkButton fullWidth={true} type="primary" to={"/snabbstart?registerAccount=true"}>
                                Registrera konto (krävs för att skapa inbjudan)
                            </LinkButton>
                        ) : (
                            <LinkButton
                                fullWidth={true}
                                type="primary"
                                to={"/redigera/" + props.siteId + "/inbjudningar/nytt"}
                                data-test-id="select-new-invite"
                            >
                                Skapa inbjudan
                            </LinkButton>
                        )}
                    </div>
                </div>
                        */}
            </div>
        </div>
    );
};
