import { Updater } from "use-immer";
import styles from "./MenuItemEdit.module.scss";
import { ISiteV2 } from "../../../../interfaces/Site";
import { TSiteModule } from "../../../../interfaces/ISiteModule";
import { getSiteModuleMenuText } from "../../../../utils/getSiteModuleMenuText";
import { useTranslation } from "../../../../../contexts/LanguageContext";
import { ContentBox } from "../../../../editComponents/ContentBox/ContentBox";
import { Input } from "../../../../editComponents/form/Input/Input";
import { TLanguageCode } from "../../../../interfaces/IContentData";
import { Switch } from "../../../../editComponents/form/Switch/Switch";
import { languageFromLanguageCode } from "../../../../../utils/LanguageHelpers";

interface IProps {
    site: ISiteV2;
    siteModule: TSiteModule;
    updateSite: Updater<ISiteV2>;
}

const getMenuItemInfo = (siteModule: TSiteModule, language: TLanguageCode) => {
    if (siteModule.hide) {
        return "modulen är dold och visas inte för besökarna";
    }
    if (siteModule.hideInMenu) {
        return "dold i menyn";
    }
    if (siteModule.menuTitle[language]) {
        return "menynamn";
    }
    if ("title" in siteModule) {
        if (siteModule.title.text[language]) {
            return "från modulens rubrik";
        } else {
            return "dold, text saknas";
        }
    } else {
        return "dold, text saknas";
    }
};

export const MenuItemEdit = (props: IProps) => {
    const { getInTranslation, setInTranslation } = useTranslation();

    const labelEditMenuName = (languageCode: TLanguageCode) => {
        return props.site.availableLanguages.length > 1
            ? "Menynamn på " + languageFromLanguageCode(languageCode)
            : "Menynamn";
    };

    const hasMultipleLanguages = props.site.availableLanguages.length > 1;

    return (
        <ContentBox padding={false} className={styles.wrapper}>
            <>
                <div className={styles.languagesWrapper}>
                    {props.site.availableLanguages.map((language) => (
                        <div className={styles.language} key={language.languageCode}>
                            <div className={styles.titleAndDescription}>
                                <div className={styles.title}>
                                    {getSiteModuleMenuText(props.siteModule, language.languageCode)}
                                </div>
                                <div className={styles.description}>
                                    {props.site.availableLanguages.length > 1
                                        ? languageFromLanguageCode(language.languageCode) + ", "
                                        : ""}
                                    {getMenuItemInfo(props.siteModule, language.languageCode)}
                                </div>
                            </div>
                            <div className={`${styles.formFields} ${!hasMultipleLanguages ? styles.hasOneLanguage : ""}`}>
                                <div className={styles.inputWrapper}>
                                    <Input
                                        label={labelEditMenuName(language.languageCode)}
                                        name={"editMenuItem" + props.siteModule.id}
                                        id={"editMenuItem" + props.siteModule.id}
                                        handleChange={(value: string) =>
                                            props.updateSite((site) => {
                                                const draftItem = site.siteModules.find(
                                                    (item) => item.id === props.siteModule.id
                                                );
                                                if (!draftItem) {
                                                    throw new Error("Updating menu name but did not find draft item");
                                                }
                                                draftItem.menuTitle = setInTranslation(
                                                    draftItem.menuTitle,
                                                    value,
                                                    language.languageCode
                                                );
                                            })
                                        }
                                        value={getInTranslation(props.siteModule.menuTitle, language.languageCode)}
                                    />
                                </div>
                                {!hasMultipleLanguages ? (<div>
                                    <Switch
                                        label="Dölj i menyn"
                                        id={"hideMenuItem" + props.siteModule.id}
                                        handleChange={(value: boolean) =>
                                            props.updateSite((site) => {
                                                const draftItem = site.siteModules.find(
                                                    (item) => item.id === props.siteModule.id
                                                );
                                                if (!draftItem) {
                                                    throw new Error(
                                                        "Updating hidden in menu but did not find draft item"
                                                    );
                                                }
                                                draftItem.hideInMenu = value;
                                            })
                                        }
                                        value={props.siteModule.hideInMenu}
                                        name={"hideMenuItem" + props.siteModule.id}
                                    />
                                </div>) : null}
                            </div>
                        </div>
                    ))}
                </div>
                {hasMultipleLanguages ? (
                    <div className={styles.switchMultipleLanguagesWrapper}>
                        <Switch
                            label="Dölj i menyn"
                            id={"hideMenuItem" + props.siteModule.id}
                            handleChange={(value: boolean) =>
                                props.updateSite((site) => {
                                    const draftItem = site.siteModules.find((item) => item.id === props.siteModule.id);
                                    if (!draftItem) {
                                        throw new Error("Updating hidden in menu but did not find draft item");
                                    }
                                    draftItem.hideInMenu = value;
                                })
                            }
                            value={props.siteModule.hideInMenu}
                            labelPosition="RIGHT"
                            name={"hideMenuItem" + props.siteModule.id}
                        />
                    </div>
                ) : null}
            </>
        </ContentBox>
    );
};
