import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./GuestHeading.module.scss";
import { ISiteModuleRsvp } from "../../../../interfaces/ISiteModule";
import { ContentTypeText } from "../../../../contentTypes/ContentTypeText/ContentTypeText";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
    guestNumber: number;
}

export function GuestHeading(props: IProps) {
    return (
        <h3 className={styles[props.site.theme]}>
            <ContentTypeText site={props.site} contentData={props.siteModule.guestIndexTitle} tag={"span"} />{" "}
            {props.guestNumber}
        </h3>
    );
}
