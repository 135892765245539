import { IRsvpV2 } from "../ViewRsvp";
import { getInRsvpTranslation } from "./getInRsvpTranslation";

interface IAnswer {
    id: string | null;
    labels: string[];
    count: number;
}

export const getChoiceQuestionAnswersWithCount = (rsvps: IRsvpV2[], questionId: string): IAnswer[] => {
    const answers: IAnswer[] = [];

    rsvps.forEach((rsvp) => {
        const element = rsvp.formElements.find((element) => element.formElement.id === questionId);
        const answer = element?.selectedOption;
        const answerId = answer?.id || null;

        const prevObject = answers.find((item) => item.id === answerId);
        const label = answerId === null ? "Inget svar" : getInRsvpTranslation(answer?.text, rsvp);
        if (prevObject) {
            prevObject.count = prevObject.count + 1;
            if (label && !prevObject.labels.find((alreadyAddedLabel) => alreadyAddedLabel === label)) {
                prevObject.labels.push(label);
            }
        } else {
            const labels = [];
            if (label) labels.push(label);
            answers.push({ id: answerId, labels: labels, count: 1 });
        }
    });
    return answers.sort((b, a) => a.count - b.count);
};
