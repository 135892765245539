import React, { useState } from "react";
import { convertLineBreaksToHtmlBreaks } from "../../utils/convertLineBreaksToHtmlBreaks";
import styles from "./ContentTypeTitle.module.scss";
import EditButton, { PointerDirection } from "../../components/EditButton/EditButton";
import useOnClickOutside from "../../utils/useOnClickOutside";
import { IconMoveDownContentModule } from "../../icons/moveDownContentModule";
import { IconMoveUpContentModule } from "../../icons/moveUpContentModule";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataTitle, TLanguageCode } from "../../interfaces/IContentData";
import { useTranslation } from "../../../contexts/LanguageContext";
import { TranslateModePreview } from "../../editComponents/TranslateModePreview/TranslateModePreview";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataTitle;
    updateContentData: (value: IContentDataTitle) => void;
    movableByUser?: boolean;
    placeholder?: string;
    className?: string;
    language?: TLanguageCode;
    tag: "h1" | "h2" | "h3" | "h4";
}

export const ContentTypeTitleEdit = (props: IProps) => {
    const [hasFocus, setHasFocus] = useState(false);
    const wrapper = useOnClickOutside(() => setHasFocus(false), hasFocus);
    const { getInTranslation, setInTranslation } = useTranslation();

    const updateContent = (content: IContentDataTitle) => {
        props.updateContentData(content);
    };

    const handleBlur = (e: React.FocusEvent<HTMLHeadingElement>) => {
        let targetElement = e.target;
        if (getInTranslation(props.contentData.text, props.language) !== targetElement.innerText) {
            updateContent({
                ...props.contentData,
                text: setInTranslation(props.contentData.text, targetElement.innerText, props.language),
            });
        }
    };

    const moveText = (value: number) => {
        const currentValue = props.contentData.verticalAlignment || 0;
        let newValue = currentValue + value;
        if (newValue > 1 || newValue < -1) {
            newValue = value;
        }
        if (!(newValue === 1 || newValue === 0 || newValue === -1)) {
            throw Error("VerticalAlignment value must be from -1 to +1");
        }
        updateContent({
            ...props.contentData,
            verticalAlignment: newValue,
        });
    };

    const alwaysHideTranslateModeBecauseNotOnPage = !!props.language;

    return (
        <div className={styles.editWrapper} ref={wrapper} onClick={() => setHasFocus(true)}>
            {hasFocus && props.movableByUser ? (
                <>
                    <div className={styles.moveButtonUp}>
                        <EditButton callback={() => moveText(-1)} pointer={PointerDirection.RIGHT}>
                            <>
                                <IconMoveUpContentModule />
                                <span className={styles.text}>Upp</span>
                            </>
                        </EditButton>
                    </div>
                    <div className={styles.moveButtonDown}>
                        <EditButton callback={() => moveText(1)} pointer={PointerDirection.RIGHT}>
                            <>
                                <IconMoveDownContentModule />
                                <span className={styles.text}>Ner</span>
                            </>
                        </EditButton>
                    </div>
                </>
            ) : null}
            <props.tag
                className={`${styles.editable} ${props.className}`}
                placeholder={props.placeholder || "Ingen rubrik"}
                contentEditable
                suppressContentEditableWarning={true}
                onBlur={handleBlur}
                dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(
                    getInTranslation(props.contentData.text, props.language)
                )}
            />
            {alwaysHideTranslateModeBecauseNotOnPage ? null : (
                <TranslateModePreview languages={props.site.availableLanguages} translation={props.contentData.text} />
            )}
        </div>
    );
};
