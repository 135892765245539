import { convertLineBreaksToHtmlBreaks } from "../../utils/convertLineBreaksToHtmlBreaks";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataTitle } from "../../interfaces/IContentData";
import { useTranslation } from "../../../contexts/LanguageContext";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataTitle;
    tag: "h1" | "h2" | "h3" | "h4";
    className?: string;
}

export const ContentTypeTitle = (props: IProps) => {
    const { getInTranslation } = useTranslation();
    return (
        <props.tag
            className={props.className}
            dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(getInTranslation(props.contentData.text))}
        />
    );
};
