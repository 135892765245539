import React from "react";
import GeneratorButton from "../../../components/GeneratorButton/GeneratorButton";
import GeneratorModal from "../../../components/GeneratorModal/GeneratorModal";
import { IconNewWindow } from "../../../icons/newWindow";
import { IGiftRegistryReservations, IImage, ISite } from "../../../interfaces/Site";
import { Image } from "../../components/Image/Image";
import { IGiftRegistryItem } from "../GiftRegistry";
import { EditGiftRegistryItem } from "./components/EditGiftRegistryItem/EditGiftRegistryItem";
import { GiftRegistryCancelReservation } from "./components/GiftRegistryCancelReservation/GiftRegistryCancelReservation";
import { GiftRegistryReserve } from "./components/GiftRegistryReserve/GiftRegistryReserve";
import { QuantityAndReservedQuantity } from "./components/QuantityAndReservedQuantity/QuantityAndReservedQuantity";
import styles from "./GiftRegistryItem.module.scss";
import { giftRegistryReservedQuantity } from "./utils/GiftRegistryReservedQuantity";

interface IProps {
    giftRegistryItem: IGiftRegistryItem;
    className: any;
    id: string;
    updateGiftRegistryItem: (item: IGiftRegistryItem, newItem: IGiftRegistryItem) => void;
    removeItem: (item: IGiftRegistryItem) => void;
    editMode: boolean;
    addOrUpdateImageToSite: (image: IImage) => void;
    site: ISite;
    moduleId: string;
    editAvailable: boolean;
    updateGiftRegistryReservations: (giftRegistryReservations: IGiftRegistryReservations[]) => void;
}

interface IState {
    editItem: boolean;
    showImageModal: boolean;
    reserveOpen: boolean;
    cancelReservationOpen: boolean;
}

export class GiftRegistryItem extends React.Component<IProps, IState> {
    state: IState = {
        editItem: false,
        showImageModal: false,
        reserveOpen: false,
        cancelReservationOpen: false,
    };

    removeItem = () => {
        this.props.removeItem(this.props.giftRegistryItem);
    };

    showEditItem = () => {
        this.setState({ editItem: true });
    };

    showImageModal = () => this.setState({ showImageModal: true });
    closeImageModal = () => this.setState({ showImageModal: false });

    showReserve = () => this.setState({ reserveOpen: true });
    closeReserve = () => this.setState({ reserveOpen: false });

    showCancelReservation = () => this.setState({ cancelReservationOpen: true });
    closeCancelReservation = () => this.setState({ cancelReservationOpen: false });

    showButtonCencelReservation = () =>
        giftRegistryReservedQuantity(this.props.giftRegistryItem, this.props.site.giftRegistryReservations) > 0;
    showButtonReserve = () =>
        giftRegistryReservedQuantity(this.props.giftRegistryItem, this.props.site.giftRegistryReservations) <
        this.props.giftRegistryItem.quantity!;

    render() {
        return (
            <>
                {this.props.editMode === true && this.state.editItem === true && (
                    <EditGiftRegistryItem
                        closeEdit={() => {
                            this.setState({ editItem: false });
                        }}
                        giftRegistryItem={this.props.giftRegistryItem}
                        updateGiftRegistryItem={this.props.updateGiftRegistryItem}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                        site={this.props.site}
                        deleteItem={this.removeItem.bind(this)}
                    />
                )}
                <div
                    className={`${styles[this.props.site.theme]} ${
                        this.props.editMode === true ? styles.markAsEditable : ""
                    }`}
                    onClick={this.props.editMode === true ? this.showEditItem : undefined}
                >
                    {this.props.giftRegistryItem.image && (
                        <div className={styles.imageWrapper}>
                            {this.props.editMode ? (
                                <Image image={this.props.giftRegistryItem.image} site={this.props.site} sizes={null} />
                            ) : (
                                <>
                                    <Image
                                        onClick={this.showImageModal}
                                        image={this.props.giftRegistryItem.image}
                                        site={this.props.site}
                                        sizes="(min-width: 500px) 220px, 100vw"
                                    />
                                    <GeneratorModal
                                        site={this.props.site}
                                        open={this.state.showImageModal}
                                        close={this.closeImageModal}
                                        large={true}
                                    >
                                        <Image
                                            image={this.props.giftRegistryItem.image}
                                            site={this.props.site}
                                            sizes="100vw"
                                        />
                                    </GeneratorModal>
                                </>
                            )}
                        </div>
                    )}
                    <div className={styles.contentWrapper}>
                        <div className={`${styles.title}`}>{this.props.giftRegistryItem.title || "Ingen rubrik"}</div>
                        <QuantityAndReservedQuantity
                            site={this.props.site}
                            giftRegistryReservations={this.props.site.giftRegistryReservations}
                            className={styles.quantity}
                            giftRegistryItem={this.props.giftRegistryItem}
                        />
                        {this.props.giftRegistryItem.link && (
                            <div className={styles.linkWrapper}>
                                <a
                                    onClick={(e) => this.props.editMode === true && e.preventDefault()}
                                    href={this.props.giftRegistryItem.link}
                                    className={styles.link}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <IconNewWindow />
                                    <span className={styles.linkText}>{this.props.giftRegistryItem.link}</span>
                                </a>
                            </div>
                        )}
                        {this.props.giftRegistryItem.description && (
                            <div className={`${styles.description}`}>{this.props.giftRegistryItem.description}</div>
                        )}
                        {!!this.props.giftRegistryItem.reservable && (
                            <div className={styles.reserveButtons}>
                                {this.props.editAvailable === true ? (
                                    <>
                                        {this.showButtonReserve() && (
                                            <div>
                                                <GeneratorButton
                                                    small={true}
                                                    buttonText={
                                                        this.props.site._id === "6758d027ea5815782f6fe27a"
                                                            ? "Reserve"
                                                            : "Reservera"
                                                    }
                                                    site={this.props.site}
                                                    type="primary"
                                                />
                                            </div>
                                        )}
                                        {this.showButtonCencelReservation() && (
                                            <div>
                                                <GeneratorButton
                                                    small={true}
                                                    buttonText={
                                                        this.props.site._id === "6758d027ea5815782f6fe27a"
                                                            ? "Cancel reservation"
                                                            : "Ångra reservation"
                                                    }
                                                    site={this.props.site}
                                                    type="secondary"
                                                />
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {this.showButtonReserve() && (
                                            <div>
                                                <GeneratorButton
                                                    callback={this.showReserve}
                                                    small={true}
                                                    buttonText={
                                                        this.props.site._id === "6758d027ea5815782f6fe27a"
                                                            ? "Reserve"
                                                            : "Reservera"
                                                    }
                                                    site={this.props.site}
                                                    type="primary"
                                                />
                                            </div>
                                        )}
                                        {this.showButtonCencelReservation() && (
                                            <div>
                                                <GeneratorButton
                                                    callback={this.showCancelReservation}
                                                    small={true}
                                                    buttonText={
                                                        this.props.site._id === "6758d027ea5815782f6fe27a"
                                                            ? "Cancel reservation"
                                                            : "Ångra reservation"
                                                    }
                                                    site={this.props.site}
                                                    type="secondary"
                                                />
                                            </div>
                                        )}
                                        {this.state.reserveOpen === true && (
                                            <GiftRegistryReserve
                                                moduleId={this.props.moduleId}
                                                close={this.closeReserve}
                                                giftRegistryItem={this.props.giftRegistryItem}
                                                site={this.props.site}
                                                updateGiftRegistryReservations={
                                                    this.props.updateGiftRegistryReservations!
                                                }
                                            />
                                        )}
                                        {this.state.cancelReservationOpen === true && (
                                            <GiftRegistryCancelReservation
                                                moduleId={this.props.moduleId}
                                                close={this.closeCancelReservation}
                                                giftRegistryItem={this.props.giftRegistryItem}
                                                site={this.props.site}
                                                updateGiftRegistryReservations={
                                                    this.props.updateGiftRegistryReservations!
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
