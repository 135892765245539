import { ISiteV2 } from "../../../interfaces/Site";
import styles from "./FormConfirmationBox.module.scss";

interface IProps {
    title: JSX.Element | string;
    text: JSX.Element | string;
    site: ISiteV2;
}

export const FormConfirmationBox = (props: IProps) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>{props.title}</div>
            {props.text}
        </div>
    );
};
