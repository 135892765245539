import { useState } from "react";
import styles from "./ImgTagFromContentTypeImage.module.scss";
import { getCDNUrl } from "../../../../utils/DomainHelpers";
import { IImageV2 } from "../../../interfaces/IContentData";

interface IProps {
    image: IImageV2;
    onClick?: () => void;
    sizes?: string;
    alt?: string;
    silentError?: boolean;
}

export function ImgTagFromContentTypeImage(props: IProps) {
    const [failedToLoadSrc, setFailedToLoadSrc] = useState<null | string>(null);

    const src = getCDNUrl() + props.image.path + props.image.fileName;

    const handleError = () => {
        setFailedToLoadSrc(src);
    };

    const getSrcSet = () => {
        if (props.image.sizes?.length) {
            const srcSet = props.image.sizes.map((sizeItem: { size: number; fileName: string }) => {
                return getCDNUrl() + props.image.path + sizeItem.fileName + " " + sizeItem.size + "w";
            });
            srcSet.push(src + " 4000w");
            return srcSet.join(",");
        } else {
            return undefined;
        }
    };

    const showError = src === failedToLoadSrc;

    if (showError && props.silentError) {
        return null;
    }

    return showError ? (
        <div className={styles.error} onClick={props.onClick}>
            Misslyckades att ladda bild
        </div>
    ) : (
        <img
            className={`${props.onClick ? styles.clickable : ""} ${styles.image}`}
            onClick={props.onClick}
            onError={handleError}
            src={src}
            srcSet={getSrcSet()}
            alt={props.alt || ""}
            sizes={props.sizes}
        />
    );
}
