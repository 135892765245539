import React from "react";
import { ILanguage, ISiteV2 } from "../../interfaces/Site";
import { useTranslation } from "../../../contexts/LanguageContext";
import styles from "./SelectLanguage.module.scss";
import { translatedLanguageFromLanguageCode } from "../../../utils/LanguageHelpers";
import { capitalize } from "../../../utils/capitalize";

interface IProps {
    site: ISiteV2;
}

export function SelectLanguageEdit(props: IProps) {
    const { setOverrideLanguage, language: currentLanguage } = useTranslation();
    const availableLanguages: ILanguage[] = props.site.availableLanguages;

    if (availableLanguages.length < 2) {
        return null;
    }

    return (
        <div className={`${styles[props.site.theme]}`} >
            <div className={styles.innerWrapper}>
            {availableLanguages.filter(language => currentLanguage !== language.languageCode).map((language) => (
                    <button
                        key={language.languageCode}
                        className={styles.button}
                        onClick={() => setOverrideLanguage(language.languageCode)}
                    >
                        {capitalize(translatedLanguageFromLanguageCode(language.languageCode))}
                    </button>
                ))}
            </div>
        </div>
    );
}
