import { ISiteV2 } from "../../interfaces/Site";
import styles from "./SiteModuleGiftRegistry.module.scss";
import { ISiteModuleGiftRegistry } from "../../interfaces/ISiteModule";
import { ContentTypeModuleHeadingEdit } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeadingEdit";
import { IContentDataRichText, IContentDataTitle } from "../../interfaces/IContentData";
import { ContentTypeRichTextEdit } from "../../contentTypes/ContentTypeRichText/ContentTypeRichTextEdit";
import EditButton from "../../components/EditButton/EditButton";
import { IconEdit } from "../../icons/edit";
import { useState } from "react";
import { GiftRegistryItemsEdit } from "./components/GiftRegistryItemsEdit/GiftRegistryItemsEdit";
import { Dialog } from "../../editComponents/Dialog/Dialog";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleGiftRegistry;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleGiftRegistry, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
    updateSiteModule: <SiteModule extends ISiteModuleGiftRegistry>(updater: (siteModule: SiteModule) => void) => void;
}

export const SiteModuleGiftRegistryEdit = (props: IProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {showModal ? (
                <Dialog open={showModal} handleClose={() => setShowModal(false)} closableByOutsideClickAndEsc={true}>
                    <GiftRegistryItemsEdit
                        site={props.site}
                        siteModule={props.siteModule}
                        updateSiteModuleProperty={props.updateSiteModuleProperty}
                        updateSiteModule={props.updateSiteModule}
                        close={() => setShowModal(false)}
                    />
                </Dialog>
            ) : null}
            <section className={styles[props.site.theme]}>
                <ContentTypeModuleHeadingEdit
                    contentData={props.siteModule.title}
                    site={props.site}
                    updateContentData={(value: IContentDataTitle) => props.updateSiteModuleProperty("title", value)}
                />
                <div className={styles.contentWrapper}>
                    <div className={styles.rteWrapper}>
                        <ContentTypeRichTextEdit
                            contentData={props.siteModule.text}
                            site={props.site}
                            updateContentData={(value: IContentDataRichText) =>
                                props.updateSiteModuleProperty("text", value)
                            }
                        />
                    </div>
                    <div className={styles.showGiftRegistryButtonAndEditButtonWrapper}>
                        <EditButton callback={() => setShowModal(true)}>
                            <>
                                <IconEdit></IconEdit>
                                Ändra önskelista
                            </>
                        </EditButton>
                    </div>
                </div>
            </section>
        </>
    );
};
