import styles from "./SiteModuleLocation.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { ISiteModuleLocation } from "../../interfaces/ISiteModule";
import { ContentTypeImage } from "../../contentTypes/ContentTypeImage/ContentTypeImage";
import { ContentTypeModuleHeading } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeading";
import { ContentTypeRichText } from "../../contentTypes/ContentTypeRichText/ContentTypeRichText";
import { ContentTypeTitle } from "../../contentTypes/ContentTypeTitle/ContentTypeTitle";
import { ContentTypeMap } from "../../contentTypes/ContentTypeMap/ContentTypeMap";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleLocation;
}

export const SiteModuleLocation = (props: IProps) => {
    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeading
                contentData={props.siteModule.title}
                site={props.site}
            />
            <div className={styles.imageMapTextWrapper}>
                <div className={styles.imageWrapper}>
                    <ContentTypeImage
                        contentData={props.siteModule.image}
                        site={props.site}
                        sizes="(min-width: 750px) 650px, 100vw"
                    />
                </div>
                <div className={styles.textContentWrapper}>
                    <div className={styles.innerTextContentWrapper}>
                        <ContentTypeTitle
                            tag="h3"
                            contentData={props.siteModule.secondTitle}
                            site={props.site}
                        />
                        <div className={styles.textWrapper}>
                            <ContentTypeRichText
                                contentData={props.siteModule.text}
                                site={props.site}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.mapWrapper}>
                    <ContentTypeMap site={props.site} contentData={props.siteModule.map} />
                </div>
            </div>
        </section>
    );
};
