import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IconHelp } from "../../../../icons/help";
import { IconHome } from "../../../../icons/home";
import { IconRvsp } from "../../../../icons/rvsp";
import IconUser from "../../../../icons/user";
import styles from "./GeneratorEditMenu.module.scss";
import { IconMail } from "../../../../icons/mail";
import { useEditSiteNavigationBasePath } from "../../../../utils/useEditSiteNavigationBasePath";
import { IconFeedback } from "../../../../icons/feedback";
import { FeedbackDialog } from "../../../../editComponents/FeedbackDialog/FeedbackDialog";
import { Help } from "../../../../modules/Help/Help";

interface IProps {
    siteId?: string;
    quickStart: boolean;
}

export function GeneratorEditMenu(props: IProps) {
    const [showFeedback, setShowFeedback] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const navigationBasePath = useEditSiteNavigationBasePath();

    return (
        <>
            {showHelp ? <Help close={() => setShowHelp(false)} /> : null}
            {showFeedback ? <FeedbackDialog handleClose={() => setShowFeedback(false)} /> : null}
            <div className={styles.wrapper}>
                <div className={styles.menuItems}>
                    <>
                        <NavLink exact activeClassName={styles.active} to={navigationBasePath}>
                            <span className={styles.icon}>{IconHome()}</span>
                            <span className={styles.text}>Min sida</span>
                        </NavLink>
                        <NavLink exact activeClassName={styles.active} to={navigationBasePath + "visa-osa/"}>
                            <span className={styles.icon}>{IconRvsp()}</span>
                            <span className={styles.text}>OSA</span>
                        </NavLink>
                        {process.env.REACT_APP_BRAND === "wedding" ? (
                            <NavLink
                                exact
                                activeClassName={styles.active}
                                to={navigationBasePath + "inbjudningar/"}
                                data-test-id="generator-edit-menu-settings"
                            >
                                <span className={styles.icon}>{IconMail()}</span>
                                <span className={styles.text}>Inbjudningar</span>
                            </NavLink>
                        ) : null}
                    </>
                </div>
                <div className={styles.additionalOptions}>
                    {props.quickStart ? null : (
                        <button
                            onClick={() => setShowFeedback(true)}
                            className={`${styles.feedback} ${showFeedback ? styles.active : ""}`}
                        >
                            <span className={styles.icon}>{IconFeedback()}</span>
                            <span className={styles.text}>Feedback</span>
                        </button>
                    )}
                    <button onClick={() => setShowHelp(true)} className={showHelp ? styles.active : ""}>
                        <span className={styles.icon}>{IconHelp()}</span>
                        <span className={styles.text}>Hjälp</span>
                    </button>
                    {!props.quickStart && (
                        <NavLink exact activeClassName={styles.active} to={"/redigera/" + props.siteId + "/konto/"}>
                            <span className={styles.icon}>{IconUser()}</span>
                            <span className={styles.text}>Konto</span>
                        </NavLink>
                    )}
                </div>
            </div>
        </>
    );
}
