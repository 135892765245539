import React, { useContext } from "react";
import styles from "./PreviewMap.module.scss";
import { ISiteV2 } from "../../../../interfaces/Site";
import { TMapTheme } from "../../../../interfaces/IContentData";
import { getMapImageUrl } from "../../utils/getMapImageUrl";
import { QuickStartContext } from "../../../../../contexts/QuickStartContext";

interface IProps {
    location: string;
    mapTheme: TMapTheme | null;
    markerColor: string | null;
    zoom: number | null;
    site: ISiteV2;
}

export const PreviewMap = (props: IProps) => {
    const isQuickStart = useContext(QuickStartContext);

    const imageSrc = getMapImageUrl(
        "PREVIEW",
        props.location,
        props.markerColor,
        isQuickStart,
        props.site._id,
        props.zoom,
        props.mapTheme
    );

    const mapAndLabel = () => (
        <>
            <div className={styles.locationLabel}>{props.location}</div>
            <img src={imageSrc} loading="lazy" alt="karta" />
        </>
    );

    if (!props.location) {
        return null;
    }
    
    return <div className={`${styles.mapWrapper}`}>{mapAndLabel()}</div>;
};
