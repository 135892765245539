import { SelectVerticalV2 } from "../../../../components/SelectVerticalV2/SelectVerticalV2";
import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./SelectNumberOfGuests.module.scss";
import { ISiteModuleRsvp } from "../../../../interfaces/ISiteModule";
import { ContentTypeText } from "../../../../contentTypes/ContentTypeText/ContentTypeText";
import { ContentTypeButton } from "../../../../contentTypes/ContentTypeButton/ContentTypeButton";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
    handleSelectedNumberOfGuests: (guests: number) => void;
    selectedNumberOfGuests: number;
}

export function SelectNumberOfGuests(props: IProps) {
    const guestsOptions = [...Array(props.siteModule.maxNumberOfGuests)].map((e, index) => ({
        title: index + 1,
        value: index + 1,
    }));

    const selectNumberOfGuests = (val: number | string) => {
        if (typeof val === "string") {
            throw new Error("String should be number in selectNumberOfGuests");
        }
        props.handleSelectedNumberOfGuests(val);
    };

    return (
        <div className={styles.wrapper}>
            {props.siteModule.maxNumberOfGuests !== 1 ? (
                <ContentTypeText
                    className={styles.selectNumberOfPersonsText}
                    site={props.site}
                    contentData={props.siteModule.selectNumberOfGuestsText}
                    tag={"div"}
                />
            ) : null}
            {props.siteModule.maxNumberOfGuests !== 1 ? (
                <SelectVerticalV2
                    value={props.selectedNumberOfGuests}
                    site={props.site}
                    options={guestsOptions}
                    handleChange={selectNumberOfGuests}
                    data-test-id="select-number-of-persons-rsvp"
                />
            ) : (
                <ContentTypeButton
                    site={props.site}
                    contentData={props.siteModule.oneGuestRsvpButton}
                    submit={false}
                    small={false}
                    onClick={() => selectNumberOfGuests(1)}
                />
            )}
        </div>
    );
}
