import { ISite, ISiteV2 } from "../interfaces/Site";
import { IPrintableCard } from "../views/GeneratorEdit/views/ViewCommunication/components/EditPrint/components/PrintEditor/interfaces/IPrintableCard";
import { colorToCssValue } from "./colorToCssValue";

interface IStyles {
    property: "backgroundColor" | "color" | "borderColor";
    color: "primary" | "background";
}

export const getColorStyle = (site: ISite, styles: IStyles[]) => {
    const returnStyle = {} as any;
    styles.forEach((style) => {
        returnStyle[style.property] = getColorFromSite(site, style.color);
    });
    return returnStyle;
};

export const getColorFromSite = (site: ISite | ISiteV2, colorType: IStyles["color"]): string => {
    const color = site.colorScheme[colorType];
    return colorToCssValue(color);
};

export const getColorFromPrint = (site: IPrintableCard, colorType: IStyles["color"]): string => {
    const color = site.colorScheme[colorType];

    if (typeof color === "string") {
        return color;
    } else {
        return colorToCssValue(color);
    }
};
