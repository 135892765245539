import { useTranslation } from "../../contexts/LanguageContext";
import { TSiteModule } from "../interfaces/ISiteModule";
import { ISiteV2 } from "../interfaces/Site";
import { getSiteModuleSlug } from "../utils/getSiteModuleSlug";
import styles from "./SiteModule.module.scss";
import { SiteModuleCountdown } from "./SiteModuleCountdown/SiteModuleCountdown";
import { SiteModuleForm } from "./SiteModuleForm/SiteModuleForm";
import { SiteModuleGiftRegistry } from "./SiteModuleGiftRegistry/SiteModuleGiftRegistry";
import { SiteModuleHeader } from "./SiteModuleHeader/SiteModuleHeader";
import { SiteModuleImageAndText } from "./SiteModuleImageAndText/SiteModuleImageAndText";
import { SiteModuleLocation } from "./SiteModuleLocation/SiteModuleLocation";
import { SiteModulePeopleList } from "./SiteModulePeopleList/SiteModulePeopleList";
import { SiteModuleRsvp } from "./SiteModuleRsvp/SiteModuleRsvp";
import { SiteModuleText } from "./SiteModuleText/SiteModuleText";
import { SiteModuleTextAndImageList } from "./SiteModuleTextAndImageList/SiteModuleTextAndImageList";
import { SiteModuleVideo } from "./SiteModuleVideo/SiteModuleVideo";

interface IProps {
    site: ISiteV2;
    siteModule: TSiteModule;
    editAvailable: boolean;
}

export const SiteModule = (props: IProps) => {
    const { language } = useTranslation();
    const slug = getSiteModuleSlug(props.siteModule, props.site.siteModules, language);
    
    let siteModuleComponent = <></>;

    if (props.siteModule.siteModuleType === "Header") {
        siteModuleComponent = <SiteModuleHeader site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "ImageAndText") {
        siteModuleComponent = <SiteModuleImageAndText site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "Text") {
        siteModuleComponent = <SiteModuleText site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "Video") {
        siteModuleComponent = <SiteModuleVideo site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "PeopleList") {
        siteModuleComponent = <SiteModulePeopleList site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "TextAndImageList") {
        siteModuleComponent = <SiteModuleTextAndImageList site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "GiftRegistry") {
        siteModuleComponent = <SiteModuleGiftRegistry site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "Location") {
        siteModuleComponent = <SiteModuleLocation site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "Rsvp") {
        siteModuleComponent = <SiteModuleRsvp site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "Countdown") {
        siteModuleComponent = <SiteModuleCountdown site={props.site} siteModule={props.siteModule} />;
    }

    if (props.siteModule.siteModuleType === "Form") {
        siteModuleComponent = <SiteModuleForm site={props.site} siteModule={props.siteModule} />;
    }

    return (
        <div className={`${styles.wrapper}`}>
            <div className={props.editAvailable === true ? styles.scrollToIdWithActionBar : styles.scrollToId} id={slug}></div>
            {siteModuleComponent}
        </div>
    );
};
