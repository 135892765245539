export const getMapImageUrl = (
    saveOrPreview: "PREVIEW" | "SAVE",
    location: string,
    markerColor: string | null,
    isQuickStart: boolean | null,
    siteId: string,
    zoom: number | null,
    mapTheme: string | null
) => {
    markerColor = markerColor || "#ea4335";
    markerColor = markerColor.replace("#", "0x");
    location = encodeURIComponent(location);
    mapTheme = mapTheme || "NORMAL";
    zoom = zoom || 12;


    let url = `${process.env.REACT_APP_API_URL}/api/sites/${isQuickStart ? "quick-start" : siteId}/maps${
        saveOrPreview === "PREVIEW" ? "/preview" : ""
    }/${location}/${zoom}/${mapTheme}/${markerColor}/`;

    return url;
};
