import React from "react";
import styles from "./SelectImageSize.module.scss";
import { IContentDataImage, UserSelectedImageSize } from "../../../../../../interfaces/IContentData";

interface IProps {
    contentData: IContentDataImage;
    updateContentData: (value: IContentDataImage) => void;
}

export function SelectImageSize(props: IProps) {
    const setSize = (size: UserSelectedImageSize) => {
        if (!props.contentData) {
            throw new Error("No image, cant set size");
        }
        props.updateContentData({
            ...props.contentData,
            userSelectedImageSize: size
        });
    };
    return (
        <>
            <div className={styles.sizeWrapper}>
                <div
                    className={
                        props.contentData.userSelectedImageSize === "LARGE" ? styles.sizeActive : styles.size
                    }
                    onClick={() => setSize("LARGE")}
                >
                    Stor bild
                </div>
                <div
                    className={
                        props.contentData.userSelectedImageSize === "MEDIUM" ? styles.sizeActive : styles.size
                    }
                    onClick={() => setSize("MEDIUM")}
                >
                    Mellanstor bild
                </div>
                <div
                    className={
                        props.contentData.userSelectedImageSize === "SMALL" ? styles.sizeActive : styles.size
                    }
                    onClick={() => setSize("SMALL")}
                >
                    Liten bild
                </div>
            </div>
            <div className={styles.smallScreenInfoImageSizesV2}>
                Beroende på tema syns bara skillnad på de olika storlekarna om du har en större skärm (tex. en dator
                eller padda).
            </div>
        </>
    );
}
