import styles from "./PrintEditor.module.scss";
import { ActionBarItem } from "../../../../../../../../modules/actionBar/components/ActionBarItem/ActionBarItem";
import { IconSave } from "../../../../../../../../icons/save";
import { IconPreview } from "../../../../../../../../icons/preview";
import { ActionBar } from "../../../../../../../../modules/actionBar/ActionBar";
import { IconEdit } from "../../../../../../../../icons/edit";
import { PrintableCard } from "./components/PrintableCard/PrintableCard";
import { IPrintableCard } from "./interfaces/IPrintableCard";
import { Updater } from "use-immer";
import { EditSelectVertical } from "../../../../../../../../components/EditSelectVertical/EditSelectVertical";
import { useState } from "react";
import IconEnvelope from "../../../../../../../../icons/envelope";
import ActionCurtain from "../../../../../../../../components/actionCurtain/ActionCurtain";
import Button from "../../../../../../../../components/button/Button";
import { EditPrintDesign } from "./components/EditPrintDesign/EditPrintDesign";
import { ISite, ISiteV2 } from "../../../../../../../../interfaces/Site";
import { IconDesign } from "../../../../../../../../icons/design";

interface IProps {
    editMode: boolean;
    toggleEditMode: () => void;
    card: IPrintableCard;
    updateCard: Updater<IPrintableCard>;
    handleSave: () => void;
    isDirty: boolean;
    isNew: boolean;
    drawerSlotPortalNode: JSX.Element;
    site: ISite | ISiteV2;
}

export const PrintEditor = (props: IProps) => {
    const [selectedPage, setSelectedPage] = useState<1 | 2>(1);
    const [showOrderWithDirtyContent, setShowOrderWithDirtyContent] = useState(false);
    const [openDrawer, setOpenDrawer] = useState<null | "design">(null);

    const orderPath = "/redigera/" + props.card.site + "/inbjudningar/" + props.card._id + "/bestall";

    const goToOrder = () => {
        window.location.href = orderPath;
    };
    return (
        <>
            {showOrderWithDirtyContent ? (
                <ActionCurtain title={"Osparade ändringar"} close={() => setShowOrderWithDirtyContent(false)}>
                    <div>
                        Du har osparade ändringar som kommer försvinna om du byter sida. Vill du fortsätta till
                        beställningssidan?
                        <div className={styles.buttonWrapper}>
                            <Button
                                type="secondary"
                                buttonText={"Avbryt"}
                                callback={() => setShowOrderWithDirtyContent(false)}
                            />
                            <Button type="secondary" buttonText={"Fortsätt"} callback={goToOrder} />
                        </div>
                    </div>
                </ActionCurtain>
            ) : null}
            <div className={styles.wrapper}>
                <ActionBar primary={true}>
                    {props.editMode !== true ? (
                        <ActionBarItem text="Redigera" icon={IconEdit()} onClick={props.toggleEditMode} />
                    ) : (
                        <>
                            <ActionBarItem
                                text="Spara"
                                icon={IconSave()}
                                highlight={props.isDirty}
                                onClick={props.handleSave}
                                testId="button-print-actionbar-save"
                                data-umami-event={props.isNew ? "save-button-create-print" : ""}
                            />
                            <ActionBarItem
                                text="Design"
                                active={openDrawer === "design"}
                                icon={IconDesign()}
                                onClick={() =>
                                    openDrawer === "design" ? setOpenDrawer(null) : setOpenDrawer("design")
                                }
                                testId={"button-print-actionbar-design"}
                            />
                            <ActionBarItem text="Granska" icon={IconPreview()} onClick={props.toggleEditMode} />
                            {props.card._id ? (
                                props.isDirty ? (
                                    <ActionBarItem
                                        text="Beställ"
                                        onClick={() => setShowOrderWithDirtyContent(true)}
                                        testId="button-print-actionbar-order"
                                        icon={IconEnvelope()}
                                        highlight={!props.isDirty}
                                        data-umami-event="order-print-button"
                                        data-umami-event-position="dirty-data"
                                    />
                                ) : (
                                    <ActionBarItem
                                        text="Beställ"
                                        to={orderPath}
                                        testId="button-print-actionbar-order"
                                        icon={IconEnvelope()}
                                        highlight={!props.isDirty}
                                        data-umami-event="order-print-button"
                                        data-umami-event-position="not-dirty-data"
                                    />
                                )
                            ) : null}
                        </>
                    )}
                </ActionBar>
                <div className={styles.editWrapper}>
                    <EditPrintDesign
                        updateCard={props.updateCard}
                        print={props.card}
                        site={props.site}
                        close={() => setOpenDrawer(null)}
                        open={openDrawer === "design" && props.editMode === true}
                        portalNode={props.drawerSlotPortalNode}
                    />
                    <div>
                        <EditSelectVertical
                            options={[
                                { value: 1, title: "Framsida" },
                                { value: 2, title: "Baksida" },
                            ]}
                            value={selectedPage}
                            onChangeCallback={setSelectedPage}
                        />
                    </div>
                    <PrintableCard
                        card={props.card}
                        page={selectedPage}
                        shadow={true}
                        updateCard={props.updateCard}
                        editMode={props.editMode}
                    />
                </div>
            </div>
        </>
    );
};
