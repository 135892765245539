import { SelectVerticalV2 } from "../../../../components/SelectVerticalV2/SelectVerticalV2";
import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./SelectNumberOfGuests.module.scss";
import { ISiteModuleRsvp } from "../../../../interfaces/ISiteModule";
import { ContentTypeTextEdit } from "../../../../contentTypes/ContentTypeText/ContentTypeTextEdit";
import { IContentDataText, TLanguageCode } from "../../../../interfaces/IContentData";
import { ContentTypeButtonEdit } from "../../../../contentTypes/ContentTypeButton/ContentTypeButtonEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
    selectedNumberOfGuests: number;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleRsvp, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
    language?: TLanguageCode;
}

export function SelectNumberOfGuestsEdit(props: IProps) {
    const guestsOptions = [...Array(props.siteModule.maxNumberOfGuests)].map((e, index) => ({
        title: index + 1,
        value: index + 1,
    }));

    return (
        <div className={styles.wrapper}>
            {props.siteModule.maxNumberOfGuests !== 1 ? (
                <ContentTypeTextEdit
                    className={styles.selectNumberOfPersonsText}
                    site={props.site}
                    contentData={props.siteModule.selectNumberOfGuestsText}
                    updateContentData={(value: IContentDataText) =>
                        props.updateSiteModuleProperty("selectNumberOfGuestsText", value)
                    }
                    tag={"div"}
                    placeholder={"Ingen text"}
                    language={props.language}
                />
            ) : null}
            {props.siteModule.maxNumberOfGuests !== 1 ? (
                <SelectVerticalV2
                    value={props.selectedNumberOfGuests}
                    site={props.site}
                    options={guestsOptions}
                    data-test-id="select-number-of-persons-rvsp"
                />
            ) : (
                <ContentTypeButtonEdit
                    site={props.site}
                    contentData={props.siteModule.oneGuestRsvpButton}
                    updateContentData={(value: IContentDataText) =>
                        props.updateSiteModuleProperty("oneGuestRsvpButton", value)
                    }
                    placeholder={"Ingen text"}
                    small={false}
                    language={props.language}
                />
            )}
        </div>
    );
}
