import { ContentTypeTextEdit } from "../../../../../../contentTypes/ContentTypeText/ContentTypeTextEdit";
import { ContentBox } from "../../../../../../editComponents/ContentBox/ContentBox";
import { IContentDataText } from "../../../../../../interfaces/IContentData";
import { ISiteModuleGiftRegistry } from "../../../../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { EmulateContentSurface } from "../../../../../components/EmulateContentSurface/EmulateContentSurface";
import { LanguageTitle } from "../../../../../components/LanguageTitle/LanguageTitle";
import { VerticalGapOnChildren } from "../../../../../components/VerticalGapOnChildren/VerticalGapOnChildren";
import styles from "./GiftRegistryStatusTextEdit.module.scss";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleGiftRegistry;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleGiftRegistry, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
}

export const GiftRegistryStatusTextEdit = (props: IProps) => {
    return (
        <>
            <ContentBox padding={true} className={styles.wrapper} title="Statustexter">
                <p>Om gåvan redan är reserverad visas följande text. Tryck på texten för att ändra den.</p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <EmulateContentSurface site={props.site}>
                                <div className={styles.labelsWrappers}>
                                    <div>
                                        <ContentTypeTextEdit
                                            site={props.site}
                                            contentData={props.siteModule.statusTextReservedSingular}
                                            updateContentData={(value: IContentDataText) =>
                                                props.updateSiteModuleProperty("statusTextReservedSingular", value)
                                            }
                                            placeholder={"Ingen text"}
                                            tag={"div"}
                                            language={language.languageCode}
                                        />
                                    </div>
                                </div>
                            </EmulateContentSurface>
                        </div>
                    ))}
                </VerticalGapOnChildren>
                <p>
                    Om ni önskat ett högre antal än ett, visas texten nedan på hur många som önskas och hur många som
                    redan är reserverade.
                </p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <EmulateContentSurface site={props.site}>
                                <div className={styles.labelsWrappers}>
                                    <div>
                                        <ContentTypeTextEdit
                                            site={props.site}
                                            contentData={props.siteModule.statusTextQuantity}
                                            updateContentData={(value: IContentDataText) =>
                                                props.updateSiteModuleProperty("statusTextQuantity", value)
                                            }
                                            placeholder={"Ingen text"}
                                            tag={"span"}
                                            language={language.languageCode}
                                        />
                                        : 10,{" "}
                                        <ContentTypeTextEdit
                                            site={props.site}
                                            contentData={props.siteModule.statusTextReservedPlural}
                                            updateContentData={(value: IContentDataText) =>
                                                props.updateSiteModuleProperty("statusTextReservedPlural", value)
                                            }
                                            placeholder={"Ingen text"}
                                            tag={"span"}
                                            language={language.languageCode}
                                        />
                                        : 5
                                    </div>
                                </div>
                            </EmulateContentSurface>
                        </div>
                    ))}
                </VerticalGapOnChildren>
            </ContentBox>
        </>
    );
};
