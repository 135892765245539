import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./GuestForm.module.scss";
import { ISiteModuleRsvp } from "../../../../interfaces/ISiteModule";
import { ContentTypeForm } from "../../../../contentTypes/ContentTypeForm/ContentTypeForm";
import { IFormElementOption, TFormElement } from "../../../../interfaces/FormElement";
import { GuestHeading } from "../GuestHeading/GuestHeading";
import { IFormData } from "../../../../interfaces/IFormData";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
    index: number;
    handleValueChange: (index: number, formElement: TFormElement, value: string | IFormElementOption) => void;
    formData: IFormData;
}

export const GuestForm = (props: IProps) => {
    return (
        <div className={styles[props.site.theme]}>
            {props.siteModule.maxNumberOfGuests > 1 ? (
                <GuestHeading
                    site={props.site}
                    siteModule={props.siteModule}
                    guestNumber={props.index + 1}
                />
            ) : null}

            <ContentTypeForm
                handleValueChange={(formElement, value) => props.handleValueChange(props.index, formElement, value)}
                site={props.site}
                contentData={props.siteModule.form}
                formPartIdentifier={"Guest" + props.index}
                formData={props.formData}
            />
        </div>
    );
};
