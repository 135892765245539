import React from "react";
import styles from "./SiteModuleHeader.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { ISiteModuleHeader } from "../../interfaces/ISiteModule";
import { ContentTypeImage } from "../../contentTypes/ContentTypeImage/ContentTypeImage";
import { ContentTypeText } from "../../contentTypes/ContentTypeText/ContentTypeText";
import { ContentTypeTitle } from "../../contentTypes/ContentTypeTitle/ContentTypeTitle";
import { useTranslation } from "../../../contexts/LanguageContext";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleHeader;
}

export const SiteModuleHeader = (props: IProps) => {
    const hideImageWrapper = () => {
        return !props.siteModule.image.image;
    };

    const titleVerticalAlignment = () => {
        if (props.site.theme === "theme9") {
            const verticalAlignment = props.siteModule.title.verticalAlignment || 0;
            if (verticalAlignment === -1) return styles["verticalAlignmentTop"];
            if (verticalAlignment === 1) return styles["verticalAlignmentBottom"];
        }
        return "";
    };

    const { getInTranslation } = useTranslation();

    const imageSize = props.siteModule.image?.userSelectedImageSize || null;

    const imageAltText = getInTranslation(props.siteModule.title.text) || "";

    return (
        <header className={`${styles[props.site.theme]} ${titleVerticalAlignment()}`}>
            {hideImageWrapper() ? null : (
                <div className={styles.imageWrapper}>
                    <div
                        className={`${imageSize ? styles.imageSizeConstrainer : null} ${
                            imageSize ? styles[imageSize] : null
                        }`}
                    >
                        <ContentTypeImage alt={imageAltText} contentData={props.siteModule.image} site={props.site} sizes="100vw" />
                    </div>
                </div>
            )}
            <div className={styles.textWrapper}>
                <div className={styles.textInnerWrapper}>
                    <div
                        className={styles.yetAnotherInnerWrapper}
                        style={
                            {
                                "--number-of-chars-in-secondline":
                                    getInTranslation(props.siteModule.secondLine.text)?.length || 0,
                            } as React.CSSProperties
                        }
                    >
                        <ContentTypeTitle tag="h1" contentData={props.siteModule.title} site={props.site} />
                        <ContentTypeText
                            className={`${styles.text} ${"font-" + props.site.fonts.text?.replace(" ", "_")}`}
                            tag="div"
                            contentData={props.siteModule.secondLine}
                            site={props.site}
                        />
                    </div>
                </div>
            </div>
        </header>
    );
};
