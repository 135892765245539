import { IModule } from "../interfaces/Module";

export const slugify = (string: string) => {
    const a = "àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
    const b = "aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-och-") // Replace & with 'och'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
};

export const findUniqueSlug = <Module extends IModule>(slug: string, allModules: Module[], module?: Module) => {
    const isSlugUnique = (slug: string, module?: Module) => {
        const count = allModules.filter((countModule: Module) => {
            return countModule.slug === slug && countModule !== module;
        }).length;

        return count === 0 ? true : false;
    };

    if (!isSlugUnique(slug, module)) {
        let index = 1;

        while (true) {
            index += 1;
            const slugWithIndex = slug + "-" + index;
            if (isSlugUnique(slugWithIndex, module)) {
                slug = slugWithIndex;
                break;
            }
        }
    }
    return slug;
};
