import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./RsvpFormEdit.module.scss";
import { ISiteModuleRsvp } from "../../../../interfaces/ISiteModule";
import {
    IContentDataForm,
    IContentDataRichText,
    IContentDataText,
    IContentDataTitle,
} from "../../../../interfaces/IContentData";
import { Tab } from "../../../../editComponents/Tab/Tab";
import { useState } from "react";
import { ContentTypeFormEdit } from "../../../../contentTypes/ContentTypeForm/ContentTypeFormEdit";
import { DialogTop } from "../../../../editComponents/DialogTop/DialogTop";
import { RsvpMaxNumberOfGuestsEdit } from "./components/RsvpMaxNumberOfGuestsEdit/RsvpMaxNumberOfGuestsEdit";
import { FormRecipientsEdit } from "../../../components/FormRecipientsEdit/FormRecipientsEdit";
import { DraftFunction, Updater } from "use-immer";
import { GuestTitleEdit } from "./components/GuestTitleEdit/GuestTitleEdit";
import { FormSendButtonEdit } from "../../../components/FormSendButtonEdit/FormSendButtonEdit";
import { FormConfirmationEdit } from "../../../components/FormConfirmationEdit/FormConfirmationEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
    updateSite: Updater<ISiteV2>;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleRsvp, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
    updateSiteModule: <SiteModule extends ISiteModuleRsvp>(updater: (siteModule: SiteModule) => void) => void;
    close: () => void;
}

export const RsvpFormEdit = (props: IProps) => {
    const [view, setView] = useState<"QUESTIONS" | "CONFIRMATION" | "OTHER">("QUESTIONS");

    const RsvpTabs = (
        <>
            <Tab active={view === "QUESTIONS"} onClick={() => setView("QUESTIONS")} text="Frågor" />
            <Tab active={view === "CONFIRMATION"} onClick={() => setView("CONFIRMATION")} text="Bekräftelse" />
            <Tab active={view === "OTHER"} onClick={() => setView("OTHER")} text="Övrigt" />
        </>
    );

    const updateForm = (updater: DraftFunction<IContentDataForm>) => {
        return props.updateSiteModule((draft) => {
            updater(draft.form);
        });
    };

    if (view === "QUESTIONS") {
        return (
            <>
                <div className={styles.wrapper}>
                    <ContentTypeFormEdit
                        tabs={RsvpTabs}
                        contentData={props.siteModule.form}
                        site={props.site}
                        updateForm={updateForm}
                        close={props.close}
                        editingFormFieldsWarning={
                            props.site.hasRsvps ? (
                                <div className={styles.editWarning}>
                                    <strong>Varning.</strong> Du har redan tagit emot OSA. Om den totala
                                    sammanställningen ska bli rätt över alla OSA kan du inte ändra frågan eller svarens
                                    innebörd. Du kan rätta stavfel eller göra frågan obligatorisk utan risk, men om du
                                    vill byta ut en hel fråga eller ett svarsalternativ, ta bort den befintliga och
                                    skapa en ny istället.
                                </div>
                            ) : undefined
                        }
                    />
                </div>
            </>
        );
    }

    if (view === "CONFIRMATION") {
        return (
            <>
                <DialogTop title="OSA-formulär" close={props.close} tabs={RsvpTabs} />
                <div className={styles.confirmationWrapper}>
                    <FormRecipientsEdit formId={props.siteModule.id} updateSite={props.updateSite} site={props.site} />
                    <FormConfirmationEdit
                        site={props.site}
                        siteModule={props.siteModule}
                        updateConfirmationText={(value: IContentDataRichText) =>
                            props.updateSiteModuleProperty("confirmationText", value)
                        }
                        updateConfirmationTitle={(value: IContentDataTitle) =>
                            props.updateSiteModuleProperty("confirmationTitle", value)
                        }
                    />
                </div>
            </>
        );
    }

    if (view === "OTHER") {
        return (
            <>
                <DialogTop title="OSA-formulär" close={props.close} tabs={RsvpTabs} />
                <div className={styles.othersWrapper}>
                    <div className={styles.sendButtonAndTitle}>
                        <FormSendButtonEdit
                            site={props.site}
                            siteModule={props.siteModule}
                            updateSendButton={(value: IContentDataText) =>
                                props.updateSiteModuleProperty("sendButton", value)
                            }
                        />
                        <GuestTitleEdit
                            site={props.site}
                            siteModule={props.siteModule}
                            updateSiteModuleProperty={props.updateSiteModuleProperty}
                        />
                    </div>
                    <div>
                        <RsvpMaxNumberOfGuestsEdit
                            site={props.site}
                            siteModule={props.siteModule}
                            updateSiteModuleProperty={props.updateSiteModuleProperty}
                        />
                    </div>
                </div>
            </>
        );
    }

    throw new Error("No view in RsvpFormEdit");
};
