import styles from "./SiteModuleText.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { ISiteModuleText } from "../../interfaces/ISiteModule";
import { ContentTypeModuleHeading } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeading";
import { ContentTypeRichText } from "../../contentTypes/ContentTypeRichText/ContentTypeRichText";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleText;
}

export const SiteModuleText = (props: IProps) => {
    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeading
                contentData={props.siteModule.title}
                site={props.site}
            />
            <div className={styles.textContentWrapper}>
                <ContentTypeRichText
                    contentData={props.siteModule.text}
                    site={props.site}
                />
            </div>
        </section>
    );
};
