import { useEffect, useState } from "react";
import styles from "./FeedbackForm.module.scss";
import Axios from "axios";
import { IError } from "../../../../../App";
import ShowError from "../../../../components/ShowError/ShowError";
import { DialogTop } from "../../../DialogTop/DialogTop";
import Loading from "../../../../components/Loading/Loading";
import FormWrapper from "../../../../components/form/FormWrapper/FormWrapper";
import { Textarea } from "../../../form/Textarea/Textarea";
import { Switch } from "../../../form/Switch/Switch";
import Button from "../../../../components/button/Button";

interface IProps {
    handleClose: () => void;
}

export const FeedbackForm = (props: IProps) => {
    const [loading, setLoading] = useState(false);
    const [userMail, setUserMail] = useState(true);
    const [feedbackText, setFeedbackText] = useState("");
    const [expectsReply, setExpectsReply] = useState(false);
    const [error, setError] = useState<IError | null>(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const getLoggedInUser = async () => {
            setLoading(false);
            try {
                const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/accounts/me/");
                setUserMail(response.data.mail);
            } catch (error) {
                setError({ text: "Misslyckades att hämta feedbackformuläret", response: (error as any).response });
            }
            setLoading(false);
        };
        getLoggedInUser();
    }, []);

    if (error) {
        return <ShowError error={error} />;
    }

    const handleDone = async () => {
        setLoading(true);
        try {
            await Axios.post(process.env.REACT_APP_API_URL + "/api/feedback/", {
                expectsReply: expectsReply,
                userMail: userMail,
                feedbackText: feedbackText,
            });
            setSuccess(true);
        } catch (error) {
            setError({ text: "Misslyckades att skicka feedbackformuläret", response: (error as any).response });
        }
        setLoading(false);
    };

    if (success) {
        return (
            <>
                <DialogTop title="Tack" close={props.handleClose} />
                <p>
                    Tack för din feedback!{" "}
                    {expectsReply
                        ? "Vi återkommer oftast inom en arbetsdag. Vi kommer att svara till e-postadressen " +
                          userMail +
                          "."
                        : null}
                </p>
            </>
        );
    }

    return (
        <>
            <DialogTop title="Feedback" close={props.handleClose} />
            <p>
                Har du synpunkter, förbättringsförslag eller önskemål? Hör gärna av dig! Vi löser ofta flera av våra
                kunders önskemål snabbt.
            </p>
            {loading ? <Loading /> : null}
            <FormWrapper submit={handleDone} className={styles.formWrapper}>
                <div>
                    <Textarea
                        placeholder="Skriv din feedback här"
                        value={feedbackText}
                        handleChange={setFeedbackText}
                        id={"feedbackTextarea"}
                        name={"feedbackTextarea"}
                        required={true}
                    />
                </div>
                <div>
                    <Switch
                        handleChange={setExpectsReply}
                        name={"expectsReply"}
                        id={"expectsReply"}
                        value={expectsReply}
                        label={"Svara gärna mig (skickas till " + userMail + ")"}
                        labelPosition="RIGHT"
                    />
                </div>
                <div className={styles.submitWrapper}>
                    <Button buttonText="Skicka" type="primary" submit={true} fullWidth={true} />
                </div>
            </FormWrapper>
        </>
    );
};
