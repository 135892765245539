import styles from "./ContentBox.module.scss";

interface IProps {
    children?: JSX.Element | JSX.Element[];
    selectable?: boolean;
    padding?: boolean;
    className?: string;
    title?: string;
}

export function ContentBox(props: IProps) {
    return (
        <div
            className={`${styles.wrapper} ${props.padding ? styles.padding : ""} ${
                props.selectable ? styles.selectable : ""
            } ${props.className ? props.className : ""}`}
        >
            {props.title ? <strong>{props.title}</strong> : null}
            {props.children}
        </div>
    );
}
