import Axios from "axios";
import React from "react";
import { EditSelectVertical } from "../../components/EditSelectVertical/EditSelectVertical";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import GeneratorModal from "../../components/GeneratorModal/GeneratorModal";
import { SelectVertical } from "../../components/SelectVertical/SelectVertical";
import ContentGeneratorButton from "../../content/GeneratorButton/GeneratorButton";
import ContentH3 from "../../content/h3/H3";
import ContentOneLine from "../../content/oneLine/OneLine";
import ContentRTE from "../../content/RTE/RTE";
import { IRvspPersonV2, IRvspV2 } from "../../interfaces/Rvsp";
import SetReceiverMail from "../../modules/SetReceiverMail/SetReceiverMail";
import ModuleHeading from "../components/ModuleHeading/ModuleHeading";
import GeneratorModule from "../GeneratorModule";
import Person from "./Person/Person";
import styles from "./RSVP2.module.scss";
import { IErrorEdit } from "../../interfaces/IErrorV2";
import { ErrorDialogEdit } from "../../components/ErrorDialogEdit/ErrorDialogEdit";

interface IState {
    personsQuantity: number;
    persons: IRvspPersonV2[];
    postKey?: string;
    editView: View;
    view: View;
    error: IErrorEdit | null;
}

enum View {
    form,
    confirmation,
    spam,
}

export class RSVP2 extends GeneratorModule {
    state: IState = {
        personsQuantity: 0,
        persons: [],
        editView: View.form,
        view: View.form,
        error: null,
    };

    personsOptions = [
        {
            title: "1",
            value: "1",
        },
        {
            title: "2",
            value: "2",
        },
        {
            title: "3",
            value: "3",
        },
        {
            title: "4",
            value: "4",
        },
        {
            title: "5",
            value: "5",
        },
    ];

    changeNumberOfPersons = (value: string) => {
        const personsQuantity = parseInt(value);
        let persons = [] as IRvspPersonV2[];

        // eslint-disable-next-line array-callback-return
        [...Array(personsQuantity)].map((e, index) => {
            persons[index] = this.state.persons[index] || {
                weddingYesOrNo: true,
            };
        });

        Axios.get(process.env.REACT_APP_API_URL + "/api/rsvps/post-key")
            .then((res) => {
                this.setState({
                    postKey: res.data.key,
                    personsQuantity,
                    persons,
                });
            })
            .catch((err) => {
                this.setState({
                    error: {
                        description: "Misslyckades att ladda OSA-formuläret",
                        response: (err as any).response,
                    },
                });
            });
    };

    updateValue = (index: number, name: string, value: string, customValueType: string, label: string) => {
        let persons = this.state.persons;
        let person = persons[index];

        if (customValueType) {
            if (!person.customValues) {
                person.customValues = [];
            }

            let index = person.customValues.findIndex((customValue) => {
                return customValue.id === name;
            });

            if (index < 0) {
                person.customValues.push({
                    id: name,
                    value,
                    type: customValueType,
                    label,
                });
            } else {
                person.customValues[index].value = value;
            }
        } else {
            (person as any)[name] = value;
        }

        this.setState({
            persons,
        });
    };

    submit = () => {
        const RSVP: IRvspV2 = {
            persons: this.state.persons,
            postKey: this.state.postKey,
        };
        Axios.post(process.env.REACT_APP_API_URL + "/api/rsvps/" + this.props.site._id + "/v2/", RSVP)
            .then((res) => {
                if (res.data.invertedSuccess && res.data.invertedSuccess === true) {
                    this.setState({
                        view: View.spam,
                    });
                } else {
                    this.setState({
                        view: View.confirmation,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    error: {
                        description: "Tyvärr misslyckades vi att spara din OSA. Något gick fel. Försök gärna igen.",
                        response: (err as any).response,
                    },
                });
            });
    };

    editViews = [
        {
            title: "Formulär",
            value: View.form,
        },
        {
            title: "Bekräftelse",
            value: View.confirmation,
        },
    ];

    changeEditView = (view: View) => {
        this.setState({
            editView: view,
        });
    };

    resetView = () => {
        this.setState({
            view: View.form,
            editView: View.form,
            personsQuantity: 0,
            persons: [],
        });
    };

    viewIs = (view: View) => {
        if (this.props.editMode === true) {
            return this.state.editView === view;
        } else {
            return this.state.view === view;
        }
    };

    render() {
        return (
            <section className={styles[this.props.site.theme]}>
                <ErrorDialogEdit error={this.state.error} />
                <div className={styles.innerWrapper}>
                    <ModuleHeading
                        site={this.props.site}
                        propertyParent={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                    ></ModuleHeading>
                    <div className={styles.textAndFormWrapper}>
                        <div className={styles.rteWrapper}>
                            <ContentRTE
                                site={this.props.site}
                                propertyParent={this.props.moduleData}
                                property="text"
                                updateProperty={this.props.updateProperty}
                                editMode={this.props.editMode}
                            ></ContentRTE>
                        </div>
                        <div className={styles.selectNumberOfPersonsWrapper}>
                            <ContentOneLine
                                className={styles.selectNumberOfPersonsText}
                                site={this.props.site}
                                propertyParent={this.props.moduleData}
                                property="chooseNumberOfPersonsText"
                                placeholder="Ingen text"
                                updateProperty={this.props.updateProperty}
                                editMode={this.props.editMode}
                            />
                            <SelectVertical
                                site={this.props.site}
                                disabled={this.props.editMode}
                                options={this.personsOptions}
                                onChangeCallback={this.changeNumberOfPersons}
                            />
                        </div>
                        {this.props.editMode || (this.state.personsQuantity && this.state.personsQuantity > 0) ? (
                            <div className={styles.selectViewWrapper}>
                                {this.props.editMode === true ? (
                                    <EditSelectVertical
                                        value={this.state.editView}
                                        onChangeCallback={this.changeEditView}
                                        options={this.editViews}
                                    />
                                ) : null}
                            </div>
                        ) : null}
                        {this.viewIs(View.form) ? (
                            <FormWrapper submit={this.submit.bind(this)} disabled={this.props.editMode === true}>
                                <>
                                    {[...Array(this.props.editMode ? 1 : this.state.personsQuantity)].map(
                                        (e, index) => (
                                            <Person
                                                data={this.props.editMode ? null : this.state.persons[index]}
                                                key={index}
                                                index={index}
                                                updateValue={this.updateValue}
                                                module={this.props.moduleData}
                                                editMode={this.props.editMode}
                                                updateProperty={this.props.updateProperty}
                                                site={this.props.site}
                                                propertyParent={this.props.moduleData}
                                            ></Person>
                                        )
                                    )}
                                    {this.props.editMode ||
                                    (this.state.personsQuantity && this.state.personsQuantity > 0) ? (
                                        <div className={styles.buttonAndReceiverWrapper}>
                                            <div>
                                                <div className={styles.buttonWrapper}>
                                                    <ContentGeneratorButton
                                                        buttonDisabled={this.props.editAvailable}
                                                        buttonText="Skicka"
                                                        buttonType="primary"
                                                        buttonSubmit={true}
                                                        site={this.props.site}
                                                        propertyParent={this.props.moduleData}
                                                        property="sendButton"
                                                        updateProperty={this.props.updateProperty}
                                                        editMode={this.props.editMode}
                                                    />
                                                </div>
                                            </div>
                                            {this.props.editMode === true && (
                                                <div className={styles.receiverWrapper}>
                                                    <SetReceiverMail
                                                        site={this.props.site}
                                                        updateReceiverMail={this.props.updateReceiverMail}
                                                        receiverCategory="rsvp"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </>
                            </FormWrapper>
                        ) : null}
                        {this.viewIs(View.confirmation) ? (
                            <GeneratorModal
                                site={this.props.site}
                                open={true}
                                close={() => {
                                    this.resetView();
                                }}
                            >
                                <div className={styles.confirmation}>
                                    <ContentH3
                                        updateProperty={this.props.updateProperty}
                                        site={this.props.site}
                                        editMode={this.props.editMode}
                                        property="confirmationTitle"
                                        propertyParent={this.props.moduleData}
                                        fallbackContent="Tack!"
                                    ></ContentH3>
                                    <div className={styles.confirmationText}>
                                        <ContentRTE
                                            updateProperty={this.props.updateProperty}
                                            site={this.props.site}
                                            editMode={this.props.editMode}
                                            property="confirmationText"
                                            propertyParent={this.props.moduleData}
                                            fallbackContent="<p>Tack för att du OSA, vi har tagit emot dina uppgifter.</p>"
                                        ></ContentRTE>
                                    </div>
                                </div>
                            </GeneratorModal>
                        ) : null}
                        {this.viewIs(View.spam) ? (
                            <GeneratorModal
                                site={this.props.site}
                                open={true}
                                close={() => {
                                    this.resetView();
                                }}
                            >
                                <div className={styles.confirmation}>
                                    <h3 className={styles.confirmationTitleError}>Tyvärr gick det fel!</h3>
                                    <div className={styles.confirmationText}>
                                        Tyvärr lyckades vi inte spara din OSA då vi identifierade dig som en robot.
                                        Vänligen fyll i en gång till och vänta några sekunder innan du trycker på
                                        skicka. Vi ber om ursäkt för besväret!
                                    </div>
                                </div>
                            </GeneratorModal>
                        ) : null}
                    </div>
                </div>
            </section>
        );
    }
}

export default RSVP2;
