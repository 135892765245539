import { useEffect, useState } from "react";
import styles from "./SelectSiteVersion.module.scss";
import dayjs from "dayjs";
import { IErrorEdit } from "../../../../interfaces/IErrorV2";
import { ISiteV2, ISiteVersion } from "../../../../interfaces/Site";
import { getEditSiteVersions } from "../../../../services/SiteService";
import { Drawer } from "../../../../components/Drawer/Drawer";
import Loading from "../../../../components/Loading/Loading";
import { ErrorDialogEdit } from "../../../../components/ErrorDialogEdit/ErrorDialogEdit";

interface IProps {
    site: ISiteV2;
    setVersion: (siteVersion: ISiteVersion) => void;
    close: () => void;
    open: boolean;
    portalNode?: JSX.Element;
    latestVersion: string;
}

export const SelectSiteVersion = (props: IProps) => {
    const [versions, setVersions] = useState<{ data: ISiteVersion; siteVersion: string }[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorEdit, setErrorEdit] = useState<IErrorEdit | null>(null);

    useEffect(() => {
        const getVersions = async () => {
            try {
                const versions = await getEditSiteVersions(props.site._id);
                setVersions(versions);
            } catch (error) {
                setErrorEdit({
                    description: "Misslyckades att hämta tidigare versioner",
                    response: (error as any).response,
                });
            }
            setIsLoading(false);
        };
        getVersions();
    }, [props.site._id]);

    return (
        <Drawer
            title="Versioner"
            drawerId="siteVersions"
            close={props.close}
            open={props.open}
            portalNode={props.portalNode}
        >
            <div className={styles.wrapper}>
                {isLoading && <Loading />}
                <ErrorDialogEdit error={errorEdit} />
                <p className={styles.text}>
                    Har du råkat spara över något du vill ha tillbaka? Här finns alla sparade versioner av din sida upp
                    till 30 dagar bakåt. Tryck på valfri version i listan nedan för att förhandsgranska den.
                </p>
                <ul className={styles.versionsList}>
                    {versions.map((version) => (
                        <li
                            key={version.siteVersion}
                            className={`${props.site.siteVersion === version.siteVersion ? styles.selected : ""}`}
                        >
                            <button onClick={() => props.setVersion(version.data)}>
                                {dayjs(version.siteVersion).toDate().toLocaleString()}
                                {props.latestVersion === version.siteVersion
                                    ? " (nuvarande)"
                                    : props.site.siteVersion === version.siteVersion
                                    ? " (vald)"
                                    : null}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </Drawer>
    );
};
