import Axios from "axios";
import React, { useState } from "react";
import { IError } from "../../../../../../../App";
import { trackEvent } from "../../../../../../../utils/UmamiHelpers";
import Button from "../../../../../../components/button/Button";
import { ButtonRow } from "../../../../../../components/ButtonRow/ButtonRow";
import FormWrapper from "../../../../../../components/form/FormWrapper/FormWrapper";
import Select from "../../../../../../components/form/Select/Select";
import TextField from "../../../../../../components/form/TextField/TextField";
import Loading from "../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../components/ShowError/ShowError";
import { ISite, ISiteV2 } from "../../../../../../interfaces/Site";
import { logError } from "../../../../../../utils/LogError";
import { slugify } from "../../../../../../utils/Slug";
import { DomainAvailable } from "./components/DomainAvailable/DomainAvailable";
import { DomainPayment } from "./components/DomainPayment/DomainPayment";
import styles from "./EditDomainName.module.scss";

interface IProps {
    site: ISite | ISiteV2;
    updateHost: (host: string) => void;
}

enum TopLevelDomain {
    "SE" = "se",
    "NU" = "nu",
    "COM" = "com",
    "EU" = "eu",
}

enum View {
    SearchDomain,
    DomainAvailable,
    DomainNotAvailable,
    DomainOwner,
}

const topLevelDomainOptions = [
    {
        title: "." + TopLevelDomain.SE,
        value: TopLevelDomain.SE,
    },
    {
        title: "." + TopLevelDomain.NU,
        value: TopLevelDomain.NU,
    },
    {
        title: "." + TopLevelDomain.COM,
        value: TopLevelDomain.COM,
    },
    {
        title: "." + TopLevelDomain.EU,
        value: TopLevelDomain.EU,
    },
];

export function EditDomainName(props: IProps) {
    const [domainName, setDomainName] = useState("");
    const [topLevelDomain, setTopLevelDomain] = useState(TopLevelDomain.SE);
    const [isLoading, setIsLoading] = useState(false);
    const [view, setView] = useState(View.SearchDomain);
    const [fullDomain, setFullDomain] = useState("");
    const [error, setError] = useState<IError | null>(null);
    const [showDomainPayment, setShowDomainPayment] = useState(false);
    const [showAlreadyOwnedDomainPayment, setShowAlreadyOwnedDomainPayment] = useState(false);
    const [loadingText, setLoadingText] = useState("");

    const handleSubmit = async () => {
        setIsLoading(true);
        const domain = domainName + "." + topLevelDomain;
        setFullDomain(domain);
        Axios.get(process.env.REACT_APP_API_URL + "/api/sites/" + props.site._id + "/host/domain-available/" + domain)
            .then((response) => {
                setIsLoading(false);
                if (response.data.available) {
                    setView(View.DomainAvailable);
                } else {
                    setView(View.DomainNotAvailable);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                setError({
                    text: "Misslyckades att kontrollera om domänen är ledig eller inte",
                    response: err.response,
                });
            });
    };

    const registerDomain = async (domain: string, tryCount: number = 0) => {
        try {
            // Wait until payment has been registered
            await new Promise((resolve) => setTimeout(resolve, 5000));
            await Axios.post(
                `${process.env.REACT_APP_API_URL}/api/sites/${props.site._id}/host/domain-registration/register/${domain}`
            );
        } catch (e) {
            if ((e as any).response.status === 402) {
                if (tryCount < 10) {
                    await registerDomain(domain, tryCount + 1);
                } else {
                    setIsLoading(false);
                    setLoadingText("");
                    setError({
                        text: "Misslyckades att registrera din betalning. Om vi har debiterat dig, vänligen hör av dig till oss för vidare hjälp.",
                        response: e,
                    });
                    logError(
                        "EditHost.EditDomainName.RegisterDomain.NoPayment",
                        e,
                        3,
                        `Couldnt register domain ${domain} due to no payment registrered`
                    );
                    throw e;
                }
            } else {
                setIsLoading(false);
                setLoadingText("");
                setError({
                    text: "Misslyckades att registrera domän, försök gärna igen eller kontakta oss om du får felet igen.",
                    response: e,
                });
                logError("EditHost.EditDomainName.RegisterDomain", e, 3, `Couldnt register domain ${domain}`);
                throw e;
            }
        }
    };

    const handlePaymentDone = async (paymentReference: string) => {
        trackEvent("site-payment-done", {product: "domain"});

        setShowDomainPayment(false);
        handleRegisterDomain(paymentReference);
    };

    const handleRegisterDomain = async (paymentReference?: string) => {
        setIsLoading(true);
        setLoadingText(
            "Stäng eller uppdatera inte sidan, väntar på registrering av domän hos GleSYS. Kan dröja upp till någon minut."
        );
        await registerDomain(fullDomain);
        setLoadingText("Stäng eller uppdatera inte sidan, vi sparar domänen");
        await props.updateHost(fullDomain);
        window.location.href = "/redigera/" + props.site._id + "/valj-adress/doman/bekraftelse/" + paymentReference;
    };

    const handleAlreadyOwnedDomainPaymentDone = async (paymentReference?: string) => {
        trackEvent("site-payment-done", {product: "domain-self-owned"});

        setShowAlreadyOwnedDomainPayment(false);
        setLoadingText("Uppdatera inte sidan, sparar domänen");
        await props.updateHost(fullDomain);
        window.location.href =
            "/redigera/" + props.site._id + "/valj-adress/doman-annan-registrar/bekraftelse/" + paymentReference;
    };

    return (
        <div className={styles.wrapper}>
            <ShowError error={error} />
            {isLoading === true && <Loading fixed={true} text={loadingText} />}
            {showDomainPayment === true && (
                <DomainPayment
                    alreadyOwned={false}
                    fullDomain={fullDomain}
                    close={() => setShowDomainPayment(false)}
                    site={props.site}
                    handlePaymentDone={handlePaymentDone}
                />
            )}
            {showAlreadyOwnedDomainPayment === true && (
                <DomainPayment
                    alreadyOwned={true}
                    fullDomain={fullDomain}
                    close={() => setShowAlreadyOwnedDomainPayment(false)}
                    site={props.site}
                    handlePaymentDone={handleAlreadyOwnedDomainPaymentDone}
                />
            )}
            {(view === View.SearchDomain || view === View.DomainNotAvailable) && (
                <>
                    <h1>Sök ledig domän</h1>
                    <div className={styles.contentWrapper}>
                        <FormWrapper className={styles.searchDomainForm} submit={handleSubmit}>
                            <div>
                                <TextField
                                    required={true}
                                    label={"Domän/Adress"}
                                    name="domainName"
                                    id={"domainName"}
                                    onChangeCallback={(val: string) => {
                                        let newValue = slugify(val);
                                        setDomainName(newValue);
                                    }}
                                    value={domainName}
                                    testId="domain-name-input"
                                />
                            </div>
                            <div>
                                <Select
                                    id="topLevelDomainSelect"
                                    label="Toppdomän"
                                    options={topLevelDomainOptions}
                                    defaultValue={topLevelDomain}
                                    onChangeCallback={(val: TopLevelDomain) => {
                                        setTopLevelDomain(val);
                                    }}
                                />
                            </div>
                            <div>
                                {domainName && (
                                    <Button
                                        data-test-id="searchDomainButton"
                                        fullWidth={true}
                                        submit={true}
                                        type="primary"
                                        buttonText="Sök tillgänglighet"
                                    />
                                )}
                            </div>
                        </FormWrapper>
                    </div>
                </>
            )}

            {view === View.DomainAvailable && (
                <div className={styles.contentWrapper}>
                    <DomainAvailable
                        site={props.site}
                        setShowDomainPayment={setShowDomainPayment}
                        fullDomain={fullDomain}
                        setViewSearchDomain={() => {
                            setView(View.SearchDomain);
                        }}
                        handleRegisterDomain={() => {
                            handleRegisterDomain();
                        }}
                    />
                </div>
            )}

            {view === View.DomainNotAvailable && (
                <div className={styles.contentWrapper}>
                    <h3 className={styles.notAvailableTitle}>{fullDomain} är upptagen</h3>
                    <p>
                        Domänen {fullDomain} är tyvärr redan upptagen. Välj en annan domän. Om du redan har köpt{" "}
                        {fullDomain} någon annanstans och är ägaren, tryck på knappen nedan.{" "}
                    </p>
                    <Button
                        type="secondary"
                        callback={() => {
                            props.site.customDomainActive
                                ? handleAlreadyOwnedDomainPaymentDone()
                                : setView(View.DomainOwner);
                        }}
                        buttonText={"Jag äger redan " + fullDomain}
                    />
                </div>
            )}

            {view === View.DomainOwner && (
                <div className={styles.contentWrapper}>
                    <h3>Om du redan äger {fullDomain}</h3>
                    <p>
                        Om du redan äger {fullDomain} har du köpt den av någon annan leverantör, då kan vi inte
                        automatiskt koppla ihop den med din sida men du ska få all information du behöver ange hos ditt
                        webbhotell för att det ska fungera. Först behöver du köpa vårt domänpaket så vi kan aktivera din
                        domän hos oss.
                    </p>
                    <ButtonRow
                        primary={
                            <Button
                                type="primary"
                                callback={() => {
                                    setShowAlreadyOwnedDomainPayment(true);
                                }}
                                buttonText="Köp domänpaket till befintligt ägd domän"
                            />
                        }
                        secondary={
                            <Button
                                type="secondary"
                                callback={() => {
                                    setView(View.SearchDomain);
                                }}
                                buttonText={"Välj en annan domän"}
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
}
