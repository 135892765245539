import React from "react";
import { IError } from "../../../App";
import { Product } from "../../../utils/products";
import Loading from "../../components/Loading/Loading";
import ShowError from "../../components/ShowError/ShowError";
import IconSwish from "../../icons/swish";
import { ISite, ISiteV2 } from "../../interfaces/Site";
import { Stripe } from "./components/Stripe/Stripe";
import { Swish } from "./components/Swish/Swish";
import styles from "./Payment.module.scss";

interface IProps {
    handlePaymentDone: (paymentReference: string) => void;
    setHideEverythingButPaymentMethod: (hide: boolean) => void;
    hideEverythingButPaymentMethod: boolean;
}

interface IPropsSite extends IProps {
    paymentProduct: Product;
    site: ISite | ISiteV2;
}

interface IPropsOrder extends IProps {
    orderId: string;
}

interface IState {
    loading: boolean;
    paymentProvider: paymentProvider | null;
    error: IError | null;
    loadingMessage?: string;
}

enum paymentProvider {
    SWISH,
    STRIPE,
}

export class Payment extends React.Component<IPropsSite | IPropsOrder> {
    state: IState = {
        loading: false,
        paymentProvider: null,
        error: null,
    };

    setLoading = (loading: boolean, loadingMessage?: string) => {
        this.setState({ loading, loadingMessage: loadingMessage || "" });
    };

    showError = (errorText: string, response: any) => {
        this.setState({
            error: {
                text: errorText,
                response: response,
            },
        });
    };

    render() {
        return (
            <>
                <ShowError error={this.state.error} />
                {this.state.loading === true && <Loading text={this.state.loadingMessage} />}
                {this.props.hideEverythingButPaymentMethod !== true && (
                    <div className={styles.choosePaymentProviderWrapper}>
                        <button
                            className={
                                this.state.paymentProvider === paymentProvider.SWISH
                                    ? styles.paymentProviderButtonSelected
                                    : styles.paymentProviderButton
                            }
                            onClick={() => this.setState({ paymentProvider: paymentProvider.SWISH })}
                            data-umami-event="select-swish-payment-provider-button"
                        >
                            <div className={styles.logoWrapper}>
                                <IconSwish />
                            </div>
                            <span>Betala med Swish</span>
                        </button>
                        <button
                            className={
                                this.state.paymentProvider === paymentProvider.STRIPE
                                    ? styles.paymentProviderButtonSelected
                                    : styles.paymentProviderButton
                            }
                            onClick={() => this.setState({ paymentProvider: paymentProvider.STRIPE })}
                            data-test-id="button-pay-with-card"
                            data-umami-event="select-card-payment-provider-button"
                        >
                            <div className={styles.logoWrapper}>
                                <img src="/images/mastercard.png" alt="Mastercard" />{" "}
                                <img src="/images/visa.png" alt="Visa" />
                            </div>
                            <span>Betala med kort</span>
                        </button>
                    </div>
                )}
                {this.state.paymentProvider === paymentProvider.STRIPE && (
                    <Stripe
                        paymentDone={this.props.handlePaymentDone}
                        showError={this.showError}
                        setLoading={this.setLoading}
                        {...("paymentProduct" in this.props) ? {paymentProduct: this.props.paymentProduct, siteId: this.props.site._id} : {orderId: this.props.orderId}}
                    />
                )}
                {this.state.paymentProvider === paymentProvider.SWISH && (
                    <Swish
                        setShowOnlySwish={this.props.setHideEverythingButPaymentMethod}
                        paymentDone={this.props.handlePaymentDone}
                        showError={this.showError}
                        setLoading={this.setLoading}
                        {...("paymentProduct" in this.props) ? {paymentProduct: this.props.paymentProduct, siteId: this.props.site._id} : {orderId: this.props.orderId}}
                    />
                )}
            </>
        );
    }
}
