import React from "react";
import { IModule } from "../interfaces/Module";
import { ISite } from "../interfaces/Site";

export interface IEditProps {
    module: IModule;
    property: string;
    updateProperty: any;
    site: ISite;
    updatePropertySuccess?: any;
}

export interface IEditState {}

export class Edit<T extends IEditProps, S extends IEditState> extends React.Component<T, S> {
    updateContent = (content: any) => {
        if (this.props.updatePropertySuccess) {
            this.props.updatePropertySuccess();
        }
        this.props.updateProperty(this.props.module, this.props.property, content);
    };

    propValue = (): any => {
        return this.props.module.properties[this.props.property];
    };
}

export default Edit;
