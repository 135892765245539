import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import Content, { IContentProps } from "../Content";
import ContentOneLine from "../oneLine/OneLine";
import CountDown from "./components/CountDown/CountDown";
import EditCountDown from "./Edit";

export class ContentCountDown extends Content<IContentProps> {
    contentType: string = "countdown";

    getCountDownToDate(): Date {
        if (this.propValue()) {
            return this.propValue();
        } else {
            let placeHolderDate = new Date();
            placeHolderDate.setMonth(placeHolderDate.getMonth() + 6);
            placeHolderDate.setHours(15);
            placeHolderDate.setMinutes(0);

            return placeHolderDate;
        }
    }

    render() {
        return (
            <>
                {this.state.showEditCurtain === true && (
                    <ActionCurtain close={this.closeEditCurtain.bind(this)}>
                        <EditCountDown
                            site={this.props.site}
                            module={this.props.propertyParent}
                            updateProperty={this.props.updateProperty}
                            updatePropertySuccess={this.closeEditCurtain.bind(this)}
                            property={this.props.property}
                        />
                    </ActionCurtain>
                )}
                <div>
                    <CountDown
                        editDateCallback={this.props.editMode ? this.openEditCurtain.bind(this) : null}
                        textDays={
                            <ContentOneLine
                                site={this.props.site}
                                propertyParent={this.props.propertyParent}
                                updateProperty={this.props.updateProperty}
                                property={"textDays"}
                                fallbackContent="dagar"
                                placeholder="Ingen text"
                                editMode={this.props.editMode}
                            />
                        }
                        textHours={
                            <ContentOneLine
                                site={this.props.site}
                                propertyParent={this.props.propertyParent}
                                updateProperty={this.props.updateProperty}
                                property={"textHours"}
                                fallbackContent="timmar"
                                placeholder="Ingen text"
                                editMode={this.props.editMode}
                            />
                        }
                        textMinutes={
                            <ContentOneLine
                                site={this.props.site}
                                propertyParent={this.props.propertyParent}
                                updateProperty={this.props.updateProperty}
                                property={"textMinutes"}
                                fallbackContent="minuter"
                                placeholder="Ingen text"
                                editMode={this.props.editMode}
                            />
                        }
                        textSeconds={
                            <ContentOneLine
                                site={this.props.site}
                                propertyParent={this.props.propertyParent}
                                updateProperty={this.props.updateProperty}
                                property={"textSeconds"}
                                fallbackContent="sekunder"
                                placeholder="Ingen text"
                                editMode={this.props.editMode}
                            />
                        }
                        date={this.getCountDownToDate()}
                    ></CountDown>
                </div>
            </>
        );
    }
}

export default ContentCountDown;
