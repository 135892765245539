import React from "react";
import styles from "./Textarea.module.scss";
import { Label } from "../Label/Label";

interface IProps {
    value: string;
    handleChange: (value: string) => void;
    label?: string | JSX.Element;
    placeholder?: string;
    "data-test-id"?: string;
    id: string;
    autoFocus?: boolean;
    name: string;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    required?: boolean;
}

export const Textarea = (props: IProps) => {
    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        props.handleChange(event.target.value);
    };
    return (
        <div className={`${styles.wrapper} ${props.className}`}>
            {props.label ? (
                <Label htmlFor={props.id} className={styles.label}>
                    {props.label}
                </Label>
            ) : null}
            <textarea
                onChange={onChange}
                value={props.value}
                name={props.name}
                id={props.id}
                autoFocus={props.autoFocus}
                className={`${styles.input}`}
                data-test-id={props["data-test-id"]}
                placeholder={props.placeholder}
                disabled={props.disabled}
                readOnly={props.readOnly}
                required={props.required}
            />
            <div className="invalidFeedback" data-invalid-feedback="true"></div>
        </div>
    );
};
