import Axios from "axios";
import { ISite, ISiteV2, ISiteVersion } from "../interfaces/Site";
import { transformSiteIn, transformSiteOut } from "../utils/TransformSite";
import { isSiteV2 } from "../utils/isSiteV2";
import { transformSiteV2In, transformSiteV2Out } from "../utils/TransformSiteV2";
import { IWizardData } from "../views/StartWizard/StartWizard";

export const getPublicSite = async (host: string): Promise<ISite | ISiteV2> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/sites/public/" + host);
    const site = isSiteV2(response.data) ? transformSiteV2In(response.data) : transformSiteIn(response.data);
    return site;
};

export const getEditSite = async (siteId: string): Promise<ISite | ISiteV2> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/sites/" + siteId);
    const site = isSiteV2(response.data) ? transformSiteV2In(response.data) : transformSiteIn(response.data);
    return site;
};

export const putEditSite = async (site: ISite): Promise<ISite> => {
    const data = transformSiteOut(site);
    const response = await Axios.put(process.env.REACT_APP_API_URL + "/api/sites/" + site._id, data);
    return transformSiteIn(response.data.site);
};

export const putEditSiteV2 = async (site: ISiteV2): Promise<ISiteV2> => {
    const data = transformSiteV2Out(site);
    const response = await Axios.put(process.env.REACT_APP_API_URL + "/api/sites/V2/" + site._id, data);
    return transformSiteV2In(response.data.site);
};

export const postCreateSite = async (site: ISite): Promise<string> => {
    const data = transformSiteOut(site, { stripId: true });
    const response = await Axios.post(process.env.REACT_APP_API_URL + "/api/sites/", data);
    return response.data.site._id;
};

export const postCreateSiteV2 = async (site: ISiteV2, wizardData?: IWizardData): Promise<string> => {
    const data = { site: transformSiteV2Out(site), wizardData: wizardData };
    const response = await Axios.post(process.env.REACT_APP_API_URL + "/api/sites/V2", data);
    return response.data.site._id;
};

export const getEditQuickStartSite = async (
    host: string
): Promise<{ site: ISiteV2; loggedInUsersSiteId: null | string }> => {
    interface IQuickStartGet {
        site: ISiteV2;
        loggedInUsersSiteId: null | string;
    }

    const response = await Axios.get<IQuickStartGet>(process.env.REACT_APP_API_URL + "/api/sites/quick-start/" + host);
    const site = transformSiteV2In(response.data.site);
    return { site, loggedInUsersSiteId: response.data.loggedInUsersSiteId };
};

export const postPublicSiteLogin = async (host: string, password: string): Promise<ISite | ISiteV2> => {
    const response = await Axios.post(process.env.REACT_APP_API_URL + "/api/sites/public/" + host + "/login", {
        password,
    });
    const site = isSiteV2(response.data) ? transformSiteV2In(response.data) : transformSiteIn(response.data);
    return site;
};

export const getEditSiteVersions = async (siteId: string): Promise<{ data: ISiteVersion; siteVersion: string }[]> => {
    const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/sites/" + siteId + "/versions");
    const versions = response.data.versions;
    return versions;
};
