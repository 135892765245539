import { useState } from "react";
import {
    IErrorEdit,
    responseDeprecatedServerErrorResponse,
    responseIsServerErrorResponse,
} from "../../interfaces/IErrorV2";
import { Dialog } from "../../editComponents/Dialog/Dialog";

interface IProps {
    error: IErrorEdit | null;
}

const errorMessageFromError = (error: IErrorEdit): string => {
    const response = error.response;

    if (responseIsServerErrorResponse(response)) {
        if (response.data.errorDescription.sv) {
            return response.data.errorDescription.sv;
        }
    }
    if (responseDeprecatedServerErrorResponse(response)) {
        return response.data.message;
    }
    if (error.description) {
        return error.description;
    }
    return "Ett okänt fel har uppstått.";
};

export const ErrorDialogEdit = (props: IProps) => {
    const [closedError, setClosedError] = useState<IErrorEdit>();

    if (!props.error) {
        return null;
    }

    if (closedError === props.error) {
        return null;
    }

    const errorMessage = errorMessageFromError(props.error);

    const handleClose = () => {
        if (!props.error) throw new Error("No error in error dialog edit");
        setClosedError(props.error);
    };

    return (
        <Dialog closeButton={true} small={true} open={true} handleClose={handleClose} closableByOutsideClickAndEsc={true}>
            <h2>Ett fel uppstod</h2>
            <p>{errorMessage}</p>
        </Dialog>
    );
};
