import React from "react";
import { ISite, ISiteV2 } from "../../../interfaces/Site";
import styles from "./Radio.module.scss";

interface IProps {
    onChangeCallback: any;
    value?: string;
    name: string;
    id: string;
    label: string;
    disabled?: boolean;
    site?: ISite | ISiteV2;
    children?: any;
    required: boolean;
}

export class Radio extends React.Component<IProps> {
    handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChangeCallback(event.target.checked ? this.props.label : null);
    };

    render() {
        return (
            <span className={`${this.props.site ? styles[this.props.site.theme] : styles.wrapper}`}>
                <div className={styles.radioAndTextWrapper}>
                    <div className={styles.radioWrapper}>
                        <input
                            type="radio"
                            className={styles.radio}
                            required={this.props.required}
                            id={this.props.id}
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.handleRadioChange.bind(this)}
                            disabled={this.props.disabled === true ? true : undefined}
                            data-invalid-feedback-id={this.props.name + "_invalid_feedback"}
                        />
                    </div>
                    <div className={styles.textWrapper}>
                        <label className={styles.label} htmlFor={this.props.id}>
                            {this.props.label}
                        </label>
                        {this.props.children ? <span>{this.props.children}</span> : ""}
                    </div>
                </div>
            </span>
        );
    }
}
