import React from "react";
import { IGiftRegistryReservations, ISite } from "../../../../../interfaces/Site";
import { IGiftRegistryItem } from "../../../GiftRegistry";
import { giftRegistryReservedQuantity } from "../../utils/GiftRegistryReservedQuantity";

interface IProps {
    className?: any;
    site: ISite;
    giftRegistryItem: IGiftRegistryItem;
    giftRegistryReservations: IGiftRegistryReservations[] | undefined;
}

export function QuantityAndReservedQuantity(props: IProps) {
    if (props.giftRegistryItem.reservable !== true) {
        return null;
    }

    const reservedQuantity = giftRegistryReservedQuantity(props.giftRegistryItem, props.giftRegistryReservations);

    return (
        <div className={props.className}>
            {props.giftRegistryItem.quantity === 1 ? (
                <>{reservedQuantity > 0 ? props.site._id === "6758d027ea5815782f6fe27a" ? "Reserved" : "Reserverad" : ""}</>
            ) : (
                <>
                    {props.site._id === "6758d027ea5815782f6fe27a" ? "Quantity" : "Antal" }: {props.giftRegistryItem.quantity}
                    {!!reservedQuantity && <span>, {props.site._id === "6758d027ea5815782f6fe27a" ? "reserved" : "reserverade"}: {reservedQuantity}</span>}
                </>
            )}
        </div>
    );
}
