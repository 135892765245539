import React from "react";
import styles from "./RsvpChoiceGraphs.module.scss";
import { IRsvpV2 } from "../../../../ViewRsvp";
import { IFormElementSummary } from "../../../../utils/uniqueFormElementsFromRsvps";
import { getInRsvpTranslationOneLanguage } from "../../../../utils/getInRsvpTranslation";
import { getChoiceQuestionAnswersWithCount } from "../../../../utils/getChoiceQuestionAnswersWithCount";

interface IProps {
    rsvps: IRsvpV2[];
    questions: IFormElementSummary[];
}

interface IAnswers {
    [questionId: string]: { id: string | null; labels: string[]; count: number }[];
}

export function RsvpChoiceGraphs(props: IProps) {
    const questions = props.questions.filter((question) => question.formElementType === "SingleSelect");

    if (!questions.length) return null;

    const answers: IAnswers = {};
    questions.forEach((question) => {
        answers[question.id] = getChoiceQuestionAnswersWithCount(props.rsvps, question.id);
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.graphsWrapper}>
                {questions.map((question) => (
                    <div className={styles.graph} key={question.id}>
                        <div className={styles.question}>{getInRsvpTranslationOneLanguage(question.label)}</div>
                        <div className={styles.answers}>
                            {answers[question.id]
                                .map((answer: { id: string | null; labels: string[]; count: number }) => (
                                    <React.Fragment key={answer.id}>
                                        <div className={styles.count}>{answer.count} st</div>
                                        <div className={styles.text}>{answer.labels.join("/")}</div>
                                    </React.Fragment>
                                ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
