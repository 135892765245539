import { Route, Switch } from "react-router-dom";
import Axios from "axios";
import { EditDomainName } from "./components/EditDomainName/EditDomainName";
import { EditSubdomain } from "./components/EditSubdomain/EditSubdomain";
import { SelectProduct } from "./components/SelectProduct/SelectProduct";
import { CheckoutConfirmation } from "./components/CheckoutConfirmation/CheckoutConfirmation";
import Loading from "../../../../components/Loading/Loading";
import ShowError from "../../../../components/ShowError/ShowError";
import { useEffect, useState } from "react";
import { ISite, ISiteV2 } from "../../../../interfaces/Site";
import { IError } from "../../../../../App";
import { getEditSite } from "../../../../services/SiteService";

interface IProps {
    siteId: string;
}

export const ViewCheckout = (props: IProps) => {
    const [loading, setLoading] = useState(true);
    const [site, setSite] = useState<ISite | ISiteV2 | null>(null);
    const [error, setError] = useState<IError | null>(null);

    useEffect(() => {
        const getSite = async () => {
            setLoading(true);
            try {
                setSite(await getEditSite(props.siteId));
            } catch (error) {
                setError({ text: "Misslyckades att hämta sidan", response: (error as any).response });
            }
            setLoading(false);
        };
        getSite();
    }, [props.siteId]);

    if (loading) {
        return <Loading fixed={true} />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    if (!site) {
        throw Error("Site not loaded but not error");
    }

    const updateHost = async (host: string): Promise<void> => {
        try {
            await Axios.put(process.env.REACT_APP_API_URL + "/api/sites/" + props.siteId + "/host", { host });
            const newSite = { ...site, host };
            setSite(newSite);
        } catch (err) {
            setError({ text: "Misslyckades att uppdatera adressen", response: (error as any).response });
        }
    };

    return (
        <Switch>
            <Route
                path="/redigera/:siteId/valj-adress/subdoman/bekraftelse/:paymentReference?"
                render={(props) => (
                    <CheckoutConfirmation
                        product="subdomain"
                        site={site}
                        paymentReference={props.match.params.paymentReference}
                    />
                )}
            />
            <Route
                path="/redigera/:siteId/valj-adress/doman/bekraftelse/:paymentReference?"
                render={(props) => (
                    <CheckoutConfirmation
                        product="domain-name"
                        site={site}
                        paymentReference={props.match.params.paymentReference}
                    />
                )}
            />
            <Route
                path="/redigera/:siteId/valj-adress/doman-annan-registrar/bekraftelse/:paymentReference?"
                render={(props) => (
                    <CheckoutConfirmation
                        product="domain-other-registrar"
                        site={site}
                        paymentReference={props.match.params.paymentReference}
                    />
                )}
            />
            <Route exact path="/redigera/:siteId/valj-adress">
                <SelectProduct site={site} />
            </Route>
            <Route path="/redigera/:siteId/valj-adress/subdoman">
                <EditSubdomain updateHost={updateHost} site={site} />
            </Route>
            <Route path="/redigera/:siteId/valj-adress/doman">
                <EditDomainName updateHost={updateHost} site={site} />
            </Route>
        </Switch>
    );
};
