import React from "react";
import styles from "./EditDesignTemplateItem.module.scss";
import { IDesignTemplate } from "../utils/designTemplates";
import { ISite, ISiteV2 } from "../../../interfaces/Site";
import { siteHasDesignTemplate } from "../utils/siteHasDesignTemplate";

interface IProps {
    designTemplate: IDesignTemplate;
    setDesignTemplate: (designTemplate: IDesignTemplate) => void;
    site: ISite | ISiteV2;
}

export const EditDesignTemplateItem = (props: IProps) => {
    let url =
        "/images/design-layout-thumbnails/" + process.env.REACT_APP_BRAND + "/" + props.designTemplate.name + ".webp";
    let urlPngFallback =
        "/images/design-layout-thumbnails/" + process.env.REACT_APP_BRAND + "/" + props.designTemplate.name + ".png";

    return (
        <div
            className={`${styles.wrapper} ${
                siteHasDesignTemplate(props.site, props.designTemplate) ? styles.active : ""
            }`}
            onClick={() => props.setDesignTemplate(props.designTemplate)}
            data-test-id={"design-template-selector-" + props.designTemplate?.name}
            data-test-design-template-name={props.designTemplate?.name}
        >
            {url ? (
                <picture>
                    <source srcSet={url} type="image/webp" />
                    <source srcSet={urlPngFallback} type="image/png" />
                    <img srcSet={urlPngFallback} alt="Designmall" />
                </picture>
            ) : (
                props.designTemplate?.name
            )}
        </div>
    );
};
