import React from "react";
import ContentH1 from "../../content/h1/H1";
import ContentImage from "../../content/image/Image";
import ContentOneLine from "../../content/oneLine/OneLine";
import GeneratorModule from "../GeneratorModule";
import styles from "./Header.module.scss";

export class Header extends GeneratorModule {
    hideImageWrapper = () => {
        return !this.props.moduleData.properties["image"] && !this.props.editMode;
    };

    imageSize = () => {
        if (typeof this.props.moduleData.properties["image"]?.size === "string") {
            return this.props.moduleData.properties["image"].size;
        } else {
            return null;
        }
    };

    getAltText = () => {
        return this.props.moduleData.properties["title"] || "";
    };

    render() {
        const titleVerticalAlignment = () => {
            if (this.props.site.theme === "theme9") {
                const verticalAlignment = this.props.moduleData.properties["titleVerticalAlignment"] || 0;
                if (verticalAlignment === -1) return styles["verticalAlignmentTop"];
                if (verticalAlignment === 1) return styles["verticalAlignmentBottom"];
            }
            return "";
        };
        return (
            <header className={`${styles[this.props.site.theme]} ${titleVerticalAlignment()}`}>
                {this.hideImageWrapper() ? null : (
                    <div className={styles.imageWrapper}>
                        <div
                            className={`${this.imageSize() ? styles.imageSizeConstrainer : ""} ${
                                this.imageSize() ? styles[this.imageSize()] : ""
                            }`}
                        >
                            <ContentImage
                                site={this.props.site}
                                propertyParent={this.props.moduleData}
                                property="image"
                                updateProperty={this.props.updateProperty}
                                editMode={this.props.editMode}
                                addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                                userCanChooseWidth={true}
                                sizes="100vw"
                                alt={this.getAltText()}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.textWrapper}>
                    <div className={styles.textInnerWrapper}>
                        <div
                            className={styles.yetAnotherInnerWrapper}
                            style={
                                {
                                    "--number-of-chars-in-secondline":
                                        this.props.moduleData.properties["secondline"]?.length || 0,
                                } as React.CSSProperties
                            }
                        >
                            <ContentH1
                                site={this.props.site}
                                propertyParent={this.props.moduleData}
                                property="title"
                                updateProperty={this.props.updateProperty}
                                editMode={this.props.editMode}
                                movableByUser={this.props.site.theme === "theme9"}
                            />
                            <ContentOneLine
                                className={`${styles.text} ${"font-" + this.props.site.fonts.text?.replace(" ", "_")}`}
                                site={this.props.site}
                                propertyParent={this.props.moduleData}
                                property="secondline"
                                placeholder="Inget datum"
                                updateProperty={this.props.updateProperty}
                                editMode={this.props.editMode}
                            />
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
