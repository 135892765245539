import { ISiteV2 } from "../../../interfaces/Site";
import styles from "./SiteModulePeopleListItem.module.scss";
import { rteContentIsEmpty } from "../../../utils/rteContentIsEmpty";
import { ISiteModulePeopleListItem } from "../../../interfaces/ISiteModule";
import { ContentTypeRichText } from "../../../contentTypes/ContentTypeRichText/ContentTypeRichText";
import { ContentTypeTitle } from "../../../contentTypes/ContentTypeTitle/ContentTypeTitle";
import { ContentTypeImage } from "../../../contentTypes/ContentTypeImage/ContentTypeImage";
import { useTranslation } from "../../../../contexts/LanguageContext";
import { ContentTypeText } from "../../../contentTypes/ContentTypeText/ContentTypeText";

interface IProps {
    site: ISiteV2;
    listItem: ISiteModulePeopleListItem;
}

export function SiteModulePeopleListItem(props: IProps) {
    const { getInTranslation } = useTranslation();

    const showTextBox =
        getInTranslation(props.listItem.title.text) || getInTranslation(props.listItem.name.text) ||
        !rteContentIsEmpty(getInTranslation(props.listItem.text.richText));

    return (
        <div className={`${styles[props.site.theme]}`}>
            <div className={styles.imageWrapper}>
                <ContentTypeImage
                    sizes="220px"
                    contentData={props.listItem.image}
                    site={props.site}
                />
            </div>
            {showTextBox ? (
                <div className={styles.textAndHeadingContentWrapper}>
                    <ContentTypeTitle tag="h3" contentData={props.listItem.name} site={props.site} />
                    <ContentTypeText
                        className={styles.title}
                        contentData={props.listItem.title}
                        site={props.site}
                        tag="div"
                    />
                    <div className={styles.textContentWrapper}>
                        <ContentTypeRichText contentData={props.listItem.text} site={props.site} />
                    </div>
                </div>
            ) : null}
        </div>
    );
}
