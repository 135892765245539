import { TLanguageCode, TTranslation } from "../../../../../interfaces/IContentData";
import { IRsvpV2 } from "../ViewRsvp";

export const getInRsvpTranslation = (translation: TTranslation | undefined, rsvp: IRsvpV2) => {
    if (!translation) {
        return "";
    }
    return translation[rsvp.language];
    /*
    We could do like the function below and show all in one prefered language, but since we cant really trust
    the IDs and the translations 100%, since the user might not understand, we always show what the 
    visitor has entered, nothing else.
    */
};

// This is for grouping, questions were we cant use the language from the guest
export const getInRsvpTranslationOneLanguage = (translation: TTranslation | undefined) => {
    if (!translation) return "";
    if (translation.sv) return translation.sv;
    if (translation.en) return translation.en;
    const allLanguages = Object.keys(translation) as TLanguageCode[];

    if (!allLanguages.length) return null;
    return translation[allLanguages[0]];
}