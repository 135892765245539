import React from "react";
import { Route, Switch } from "react-router-dom";
import { QuickStartContext } from "../../../contexts/QuickStartContext";
import { trackEvent } from "../../../utils/UmamiHelpers";
import { DrawerSlot } from "../../components/DrawerSlot/DrawerSlot";
import { logError } from "../../utils/LogError";
import { GeneratorEditMenu } from "./modules/GeneratorEditMenu/GeneratorEditMenu";
import { ViewAccount } from "./views/Account/ViewAccount";
import { EditHome } from "./views/EditHome/EditHome";
import { ViewCheckout } from "./views/ViewCheckout/ViewCheckout";
import { ViewCommunication } from "./views/ViewCommunication/ViewCommunication";
import { ViewPrintCheckout } from "./views/ViewPrintCheckout/ViewPrintCheckout";
import { ViewPrintCheckoutConfirmation } from "./views/ViewPrintCheckoutConfirmation/ViewPrintCheckoutConfirmation";
import { EditAvailableContext } from "../../../contexts/EditAvailableContext";
import { ViewRsvp } from "./views/ViewRsvp/ViewRsvp";
import { SiteVersionsPreview } from "../GeneratorSite/GeneratorSiteVersionsPreview";
import { ISiteV2 } from "../../interfaces/Site";
import { IWizardData } from "../StartWizard/StartWizard";

interface IState {
    drawerSlotPortalNode: any;
}

interface IProps {
    quickStart: boolean;
    siteId?: string;
    host?: string;
    wizardSite?: ISiteV2; // This component should do the actual fetch of site, and not the components below. But no time for refactor now.
    wizardData?: IWizardData;
}

export default class GeneratorEdit extends React.Component<IProps, IState> {
    state: IState = {
        drawerSlotPortalNode: null,
    };

    componentDidCatch(error: any, info: any) {
        logError("GeneratorEdit.componentDidCatch", error, 3, info);
    }

    componentDidMount() {
        if (this.props.quickStart === true) {
            const params = new URLSearchParams(window.location.search);
            if (params.get("register") === "true") {
                trackEvent("url-param-register");
            }
        }
    }

    setDrawerSlotPortalNode = (drawerSlotPortalNode: any) => {
        this.setState({
            drawerSlotPortalNode,
        });
    };

    render() {
        return (
            <QuickStartContext.Provider value={this.props.quickStart}>
                <EditAvailableContext.Provider value={true}>
                    <DrawerSlot setPortalNode={this.setDrawerSlotPortalNode.bind(this)}>
                        <GeneratorEditMenu quickStart={this.props.quickStart} siteId={this.props.siteId} />
                        <Switch>
                            <Route
                                path="/redigera/:siteId/visa-osa/"
                                render={(props) => (
                                    <ViewRsvp
                                        {...props}
                                        siteId={this.props.siteId}
                                        quickStart={this.props.quickStart}
                                    />
                                )}
                            />
                            <Route
                                path="/(sms|digital-studentinbjudan|jpkt)?/snabbstart/visa-osa/"
                                render={(props) => <ViewRsvp {...props} quickStart={this.props.quickStart} />}
                            />
                            <Route
                                path="/(sms|digital-studentinbjuda|jpkt)?/snabbstart/inbjudningar"
                                render={(props) => (
                                    <ViewCommunication
                                        quickStart={true}
                                        drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                    />
                                )}
                            />
                            <Route path="/redigera/:siteId/konto/">
                                {this.props.siteId ? <ViewAccount siteId={this.props.siteId} /> : null}
                            </Route>
                            <Route path="/redigera/:siteId/valj-adress">
                                {this.props.siteId ? <ViewCheckout siteId={this.props.siteId} /> : null}
                            </Route>
                            <Route
                                path="/redigera/:siteId/inbjudningar/bekraftelse/:orderId"
                                render={(props) => (
                                    <ViewPrintCheckoutConfirmation orderId={props.match.params.orderId} />
                                )}
                            />
                            <Route
                                path="/redigera/:siteId/inbjudningar/:printId/bestall"
                                render={(routeProps) => (
                                    <ViewPrintCheckout
                                        siteId={routeProps.match.params.siteId}
                                        printables={[routeProps.match.params.printId]}
                                        routerHistory={routeProps.history}
                                    />
                                )}
                            />
                            <Route
                                path="/redigera/:siteId/inbjudningar"
                                render={(props) => (
                                    <ViewCommunication
                                        siteId={this.props.siteId}
                                        quickStart={this.props.quickStart}
                                        drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                    />
                                )}
                            />
                            <Route
                                path="/redigera/:siteId/versioner"
                                exact
                                render={(props) => (
                                    <SiteVersionsPreview
                                        siteId={props.match.params.siteId}
                                        drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                    />
                                )}
                            />
                            <Route
                                path="/redigera/:siteId"
                                exact
                                render={(props) => (
                                    <EditHome
                                        siteId={this.props.siteId}
                                        routerHistory={props.history}
                                        quickStart={this.props.quickStart}
                                        editAvailable={true}
                                        drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                    />
                                )}
                            />
                            <Route
                                path="/(sms|digital-studentinbjudan|jpkt)?/snabbstart"
                                exact
                                render={(props) => (
                                    <EditHome
                                        host={this.props.host}
                                        routerHistory={props.history}
                                        quickStart={this.props.quickStart}
                                        editAvailable={true}
                                        drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                    />
                                )}
                            />
                            <Route
                                path="/kom-igang/redigera"
                                exact
                                render={(props) => (
                                    <EditHome
                                        host={this.props.host}
                                        routerHistory={props.history}
                                        quickStart={this.props.quickStart}
                                        editAvailable={true}
                                        drawerSlotPortalNode={this.state.drawerSlotPortalNode}
                                        wizardSite={this.props.wizardSite}
                                        wizardData={this.props.wizardData}
                                    />
                                )}
                            />
                        </Switch>
                    </DrawerSlot>
                </EditAvailableContext.Provider>
            </QuickStartContext.Provider>
        );
    }
}
