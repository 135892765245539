import { LANGUAGE_CODES } from "../../constants/languageCodes";
import { capitalize } from "../../utils/capitalize";
import { languageFromLanguageCode } from "../../utils/LanguageHelpers";
import { TLanguageCode } from "../interfaces/IContentData";
import { ILanguage } from "../interfaces/Site";

export const AVOID_DUPLICATE_KEY_LANGUAGE_OPTIONS = "____AVOID_DUPLICATE_KEY_QUICK_FIX"

export const selectLanguageOptions = (removeFromList: ILanguage[]) => {
    const selectableLanguageCodes = LANGUAGE_CODES.filter(
        (option) => !removeFromList.find((language) => language.languageCode === option)
    );

    const allLanguagesOptions = selectableLanguageCodes
        .map((languageCode) => ({
            title: languageFromLanguageCode(languageCode),
            value: languageCode,
        }))
        .filter((option) => option.title !== option.value && option.title)
        .sort((a, b) => a.title!.localeCompare(b.title!));

    const topLanguageCode: TLanguageCode[] = ["sv", "en", "no", "fi", "da"].filter(
        (option) => !removeFromList.find((language) => language.languageCode === option)
    ) as TLanguageCode[];

    const topLanguageOptions = topLanguageCode
        .map((languageCode) => ({
            title: languageFromLanguageCode(languageCode),
            value: languageCode + AVOID_DUPLICATE_KEY_LANGUAGE_OPTIONS,
        }))
        .filter((option) => option.title !== option.value);

    const languageOptions = [...topLanguageOptions, { title: "", value: "" }, ...allLanguagesOptions].map((option) => ({
        title: capitalize(option.title) as string,
        value: option.value,
    }));

    return languageOptions;
};
