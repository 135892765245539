import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import styles from "./RsvpQuestions.module.scss";
import { IRsvpV2 } from "../../ViewRsvp";
import { IFormElementSummary, uniqueFormElementsFromRsvps } from "../../utils/uniqueFormElementsFromRsvps";
import { getInRsvpTranslationOneLanguage } from "../../utils/getInRsvpTranslation";
import { IRsvpFilter, RsvpFilter } from "./components/RsvpFilter/RsvpFilter";
import { RsvpQuestionTextAnswersSummary } from "./components/RsvpQuestionTextAnswersSummary/RsvpQuestionTextAnswersSummary";
import { getChoiceQuestionAnswersWithCount } from "../../utils/getChoiceQuestionAnswersWithCount";

interface IProps {
    rsvps: IRsvpV2[];
    demo: boolean;
}

export function RsvpQuestions(props: IProps) {
    const [expanded, setExpanded] = useState<boolean | null | string>(null);
    const [filter, setFilter] = useState<IRsvpFilter>({});

    const rsvpsFiltered = props.rsvps.filter((rsvp) => {
        if (filter.answer && filter.question) {
            return (
                rsvp.formElements.find((formElement) => formElement.formElement.id === filter.question?.id)
                    ?.selectedOption?.id === filter.answer?.id
            );
        } else {
            return true;
        }
    });

    const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getAnswersSummary = (question: IFormElementSummary) => {
        const values = getChoiceQuestionAnswersWithCount(rsvpsFiltered, question.id);

        if (values && values.length) {
            return values.map((answer) => {
                return (
                    <div key={answer.id}>
                        <>
                            {answer.labels.join("/")}: {answer.count} st
                        </>
                    </div>
                );
            });
        } else {
            return null;
        }
    };

    const questions = uniqueFormElementsFromRsvps(props.rsvps);

    return (
        <div className={styles.wrapper}>
            <h1>
                OSA
                <div className={styles.totalCount}>
                    {filter.answer ? (
                        <>
                            Visar {rsvpsFiltered.length} av {props.rsvps.length} svar
                        </>
                    ) : (
                        <>{props.rsvps.length} svar</>
                    )}
                </div>
            </h1>
            {props.demo === true ? (
                <div className={styles.intro}>
                    Du har inte fått någon OSA än. Nedan visar vi några exempelanmälningar. Du väljer själv vilka frågor
                    dina gäster ska svara på när du skapar OSA-formuläret.
                </div>
            ) : null}
            <RsvpFilter questions={questions} rsvps={props.rsvps} filter={filter} setFilter={setFilter} />

            {questions.map((question) => (
                <ExpansionPanel
                    key={question.id}
                    expanded={expanded === question.id}
                    onChange={handleChange(question.id)}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>{getInRsvpTranslationOneLanguage(question.label)}</div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <div className={styles.questionValueWrapper}>
                            {question.formElementType === "SingleSelect" ? (
                                <>
                                    {filter.answer ? (
                                        <div className={styles.label}>
                                            Svar av personer som svarat "{getInRsvpTranslationOneLanguage(filter?.answer?.text)}"
                                            på frågan "{getInRsvpTranslationOneLanguage(filter.question?.label)}":
                                        </div>
                                    ) : null}
                                    <div>{getAnswersSummary(question)}</div>
                                </>
                            ) : null}
                            {question.formElementType === "ShortText" ? (
                                <RsvpQuestionTextAnswersSummary
                                    rsvps={rsvpsFiltered}
                                    filter={filter}
                                    question={question}
                                />
                            ) : null}
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))}
        </div>
    );
}
