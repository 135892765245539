import React from "react";
import styles from "./ContentTypeMap.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataMap } from "../../interfaces/IContentData";
import { Map } from "./components/Map/Map";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataMap;
}

export const ContentTypeMap = (props: IProps) => {
    if (props.contentData.map) {
        return (
            <div className={styles.mapWrapper}>
                <Map site={props.site} map={props.contentData} linkToExternalMap={true} />
            </div>
        );
    } else {
        return null;
    }
};
