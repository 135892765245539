import React from "react";
import styles from "./VerticalGapOnChildren.module.scss";

interface IProps {
    children: JSX.Element[];
}

export const VerticalGapOnChildren = (props: IProps) => {
    return (
        <div className={styles.wrapper}>
            {props.children}
        </div>
    );
};
