import React from "react";
import { View404 } from "../../../views/View404/View404";
import Loading from "../../components/Loading/Loading";
import { IGiftRegistryReservations, ISite, ISiteV2 } from "../../interfaces/Site";
import { getPublicSite } from "../../services/SiteService";
import { loadSiteFonts } from "../../utils/LoadSiteFonts";
import { logError } from "../../utils/LogError";
import {
    setGeneratorColorStylesVariables,
    setGeneratorFontsStylesVariables,
} from "../../utils/SetGeneratorStylesVariables";
import { Home } from "../GeneratorSite/Home";
import { ViewPublicLogin } from "./views/PublicLogin/PublicLogin";
import { isSiteV2 } from "../../utils/isSiteV2";
import { GeneratorSitePublic } from "../GeneratorSite/GeneratorSitePublic";
import { TLanguageCode } from "../../interfaces/IContentData";
import { LANGUAGE_CODES } from "../../../constants/languageCodes";
import { Redirect } from "react-router-dom";
import { LanguageProvider } from "../../../contexts/LanguageContext";

interface IState {
    site?: ISite | ISiteV2;
    loading: boolean;
    show404: boolean;
    showPublicLogin: boolean;
    language: TLanguageCode | null;
    languageRedirect: TLanguageCode | null;
}

interface IProps {
    quickStart: boolean;
    showError: any;
    host: string;
    routerHistory: any;
    unvalidatedLanguage: string | null;
}

function isLanguageCode(string: TLanguageCode | string): string is TLanguageCode {
    return LANGUAGE_CODES.includes(string as TLanguageCode);
}

const getBrowserPreferedLanguage = (): TLanguageCode => {
    if (navigator.language) {
        const language = navigator.language.slice(0, 2);
        if (isLanguageCode(language)) {
            return language;
        }
    }
    return "sv"; // Fallback;
};

export default class GeneratorPublic extends React.Component<IProps, IState> {
    state: IState = {
        site: undefined,
        loading: true,
        show404: false,
        showPublicLogin: false,
        language: null,
        languageRedirect: null,
    };

    componentDidUpdate(prevProps: IProps) {
        if (this.props.host !== prevProps.host) {
            this.getDataFromDb();
        }

        if (this.props.unvalidatedLanguage !== prevProps.unvalidatedLanguage) {
            if (this.state.site) {
                this.validateLanguage(this.state.site);
            }
        }
    }

    componentDidCatch(error: any, info: any) {
        logError("GeneratorPublic.componentDidCatch", error, 3, info, this.state.site);
    }

    componentDidMount() {
        this.getDataFromDb();
        /*
        if (isDemoFromHost(this.props.host)) {
            trackEvent("demo", UmamiEventType.funnel, UmamiTrackingId.standard);
        }
        */
    }

    getDataFromDb = async () => {
        this.setState({ loading: true });
        try {
            const site = await getPublicSite(this.props.host);
            this.setSiteInitial(site);
        } catch (error) {
            if ((error as any).response?.status === 404) {
                this.setState({ show404: true });
            } else if ((error as any).response?.status === 401) {
                this.setState({ showPublicLogin: true });
            } else {
                logError("generator.generatorPublic.getDataFromDb", error, 1, (error as any).response);
                this.props.showError("Misslyckades att hämta sidan", (error as any).response);
            }
        }
        this.setState({ loading: false });
    };

    validateLanguage = (site: ISite | ISiteV2): boolean => {
        const abortAndShow404 = () => {
            this.setState({
                showPublicLogin: false,
                show404: true,
                site,
            });
            return false;
        };

        if (isSiteV2(site)) {
            if (this.props.unvalidatedLanguage) {
                if (!isLanguageCode(this.props.unvalidatedLanguage)) {
                    return abortAndShow404();
                }
                const validatedLanguageCode: TLanguageCode = this.props.unvalidatedLanguage;
                if (site.availableLanguages.find((language) => language.languageCode === validatedLanguageCode)) {
                    this.setState({ language: validatedLanguageCode });
                    return true;
                } else {
                    return abortAndShow404();
                }
            } else {
                if (site.availableLanguages.length > 1) {
                    let autoSelectedLanguage = site.availableLanguages[0].languageCode;
                    if (
                        site.availableLanguages.find(
                            (language) => language.languageCode === getBrowserPreferedLanguage()
                        )
                    ) {
                        autoSelectedLanguage = getBrowserPreferedLanguage();
                    }
                    this.setState({ languageRedirect: autoSelectedLanguage });
                    return false;
                } else {
                    this.setState({ language: site.availableLanguages[0].languageCode });
                    return true;
                }
            }
        } else {
            if (this.props.unvalidatedLanguage) {
                return abortAndShow404();
            } else {
                return true;
            }
        }
    };

    setSiteInitial = (site: ISite | ISiteV2) => {
        try {
            if (this.validateLanguage(site)) {
                loadSiteFonts(site.fonts);
                setGeneratorFontsStylesVariables(site.fonts);
                setGeneratorColorStylesVariables(site.colorScheme);

                this.setState({
                    showPublicLogin: false,
                    show404: false,
                    site,
                });
            }
        } catch (error) {
            logError("generator.generatorPublic.setSiteInitial", error, 1);
        }
    };

    updateGiftRegistryReservations = (giftRegistryReservations: IGiftRegistryReservations[]) => {
        let site = this.state.site;
        site!.giftRegistryReservations = giftRegistryReservations;
        this.setState({ site });
    };

    render() {
        if (this.state.languageRedirect) {
            return <Redirect to={"/" + this.state.languageRedirect + window.location.search} />;
        }
        if (this.state.show404 === true) {
            return <View404 />;
        }
        if (this.state.showPublicLogin === true) {
            return <ViewPublicLogin host={this.props.host} setSiteInitial={this.setSiteInitial} />;
        }
        if (!this.state.site) {
            return <Loading fixed={true} inverted={true} />;
        }

        if (isSiteV2(this.state.site)) {
            if (!this.state.language) {
                throw Error("No language in generatorPublic but isSiteV2");
            }
            return (
                <>
                    {this.state.loading === true && <Loading fixed={true} />}
                    <LanguageProvider defaultLanguage={this.state.language}>
                        <GeneratorSitePublic site={this.state.site} />
                    </LanguageProvider>
                </>
            );
        }
        return (
            <>
                {this.state.loading === true && <Loading fixed={true} />}

                <Home
                    routerHistory={this.props.routerHistory}
                    quickStart={this.props.quickStart}
                    editAvailable={false}
                    editMode={false}
                    site={this.state.site!}
                    updateGiftRegistryReservations={this.updateGiftRegistryReservations.bind(this)}
                />
            </>
        );
    }
}
