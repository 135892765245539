import { useState } from "react";
import styles from "./ContentTypeImage.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataImage } from "../../interfaces/IContentData";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import { EditImage } from "./components/EditImage/EditImage";
import { ImgTagFromContentTypeImage } from "../components/ImgTagFromContentTypeImage/ImgTagFromContentTypeImage";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataImage;
    updateContentData: (value: IContentDataImage) => void;
    userCanChooseWidth?: boolean;
    sizes?: string;
}

export const ContentTypeImageEdit = (props: IProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {showModal === true && (
                <ActionCurtain close={() => setShowModal(false)}>
                    <EditImage
                        site={props.site}
                        contentData={props.contentData}
                        updateContentData={props.updateContentData}
                        userCanChooseWidth={props.userCanChooseWidth}
                        close={() => setShowModal(false)}
                    />
                </ActionCurtain>
            )}
            <div className={`${styles[props.site.theme]}`}>
                {props.contentData.image ? (
                    <ImgTagFromContentTypeImage image={props.contentData.image} sizes={props.sizes} onClick={() => setShowModal(true)} />
                ) : (
                    <div className={styles.placeholder + " image-placeholder"} onClick={() => setShowModal(true)}>
                        Ingen bild uppladdad
                    </div>
                )}
            </div>
        </>
    );
};
