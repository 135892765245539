import { IFonts } from "../interfaces/Site";
import { TFontHeading, TFontText } from "../enums/font";

export const WEB_FONT_ACTIVE_EVENT_NAME = "CustomEventWebFontsActive";

const event = new Event(WEB_FONT_ACTIVE_EVENT_NAME);

const dispatchFontsActiveEvent = () => {
    document.documentElement.dispatchEvent(event);
};

document.fonts.onloadingdone = () => {
    dispatchFontsActiveEvent();
};

export function loadSiteFonts(fonts: IFonts) {
    loadFonts([
        { font: fonts.heading, type: "HEADING" },
        { font: fonts.text, type: "TEXT" },
    ]);
}

const webSafeFonts = ["Arial", "Georgia"];

export const fontTypeMap: { HEADING: TFontType[]; TEXT: TFontType[]; PREVIEW: TFontType[] } = {
    HEADING: ["400", "700"],
    TEXT: ["400", "400i", "700", "700i"],
    PREVIEW: ["400"],
};

const parseFontName = (font: TFontText | TFontHeading) => {
    return font.toLowerCase().replaceAll(" ", "-");
};

type TFontTypeGroup = "HEADING" | "TEXT" | "PREVIEW";
type TFontType = "400" | "400i" | "700" | "700i";
export type TFontToLoad = { font: TFontHeading | TFontText; type: TFontTypeGroup };

let fontsLoadingOrLoaded: { font: TFontHeading | TFontText; type: TFontType }[] = [];
const typesNotLoaded = (fontToLoad: TFontToLoad): string[] => {
    const types = fontTypeMap[fontToLoad.type];
    const typesNotLoaded = types.filter(
        (type) => !fontsLoadingOrLoaded.find((font) => font.font === fontToLoad.font && type === font.type)
    );
    return typesNotLoaded;
};

export function loadFonts(fonts: TFontToLoad[]) {
    const fontsToLoad = fonts
        .map((font) => {
            if (!font?.font) return null;
            if (webSafeFonts.indexOf(font.font) !== -1) return null;
            const notLoaded = typesNotLoaded(font);
            if (notLoaded.length === 0) return null;

            const fontUrlPart = parseFontName(font.font) + ":" + notLoaded.join(",");
            return fontUrlPart;
        })
        .filter((font) => !!font);

    loadFont(fontsToLoad as string[]);
}

const loadFont = (fontUrlPart: string[]) => {
    const element = document.createElement("link");
    element.rel = "stylesheet";
    element.href = "https://fonts.bunny.net/css?family=" + fontUrlPart.join("|");
    document.head.append(element);
};
