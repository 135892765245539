import React, { useState } from "react";
import { Updater } from "use-immer";
import { GiftRegistryCancelReservation } from "./components/GiftRegistryCancelReservation/GiftRegistryCancelReservation";
import { GiftRegistryReserve } from "./components/GiftRegistryReserve/GiftRegistryReserve";
import { QuantityAndReservedQuantity } from "./components/QuantityAndReservedQuantity/QuantityAndReservedQuantity";
import styles from "./GiftRegistryItem.module.scss";
import { giftRegistryReservedQuantity } from "./utils/GiftRegistryReservedQuantity";
import { IContentDataGiftRegistryItem, ISiteModuleGiftRegistry } from "../../../../interfaces/ISiteModule";
import { IGiftRegistryReservations, ISiteV2 } from "../../../../interfaces/Site";
import { ContentTypeImage } from "../../../../contentTypes/ContentTypeImage/ContentTypeImage";
import { ContentTypeTitle } from "../../../../contentTypes/ContentTypeTitle/ContentTypeTitle";
import { IconNewWindow } from "../../../../icons/newWindow";
import { useTranslation } from "../../../../../contexts/LanguageContext";
import { ContentTypeButton } from "../../../../contentTypes/ContentTypeButton/ContentTypeButton";
import { DialogPublic } from "../../../../components/DialogPublic/DialogPublic";

interface IProps {
    giftRegistryItem: IContentDataGiftRegistryItem;
    giftRegistryReservations: IGiftRegistryReservations[];
    setGiftRegistryReservations: Updater<IGiftRegistryReservations[]>;
    siteModule: ISiteModuleGiftRegistry;
    site: ISiteV2;
}

export const GiftRegistryItem = (props: IProps) => {
    const [view, setView] = useState<"CANCEL_RESERVATION" | "IMAGE" | "RESERVE" | null>(null);
    const { getInTranslation } = useTranslation();

    const showButtonCancelReservation = () =>
        giftRegistryReservedQuantity(props.giftRegistryItem, props.giftRegistryReservations) > 0;
    const showButtonReserve = () =>
        giftRegistryReservedQuantity(props.giftRegistryItem, props.giftRegistryReservations) <
        props.giftRegistryItem.quantity!;

    return (
        <>
            <div className={`${styles[props.site.theme]}`}>
                {props.giftRegistryItem.image ? (
                    <div className={styles.imageWrapper}>
                        <>
                            <ContentTypeImage
                                onClick={() => setView("IMAGE")}
                                contentData={props.giftRegistryItem.image}
                                site={props.site}
                                sizes="(min-width: 500px) 220px, 100vw"
                            />
                            <DialogPublic
                                open={view === "IMAGE"}
                                handleClose={() => setView(null)}
                                closableByOutsideClickAndEsc={true}
                                large={true}
                            >
                                <ContentTypeImage
                                    onClick={() => setView("IMAGE")}
                                    contentData={props.giftRegistryItem.image}
                                    site={props.site}
                                    sizes="100vw"
                                />
                            </DialogPublic>
                        </>
                    </div>
                ) : null}
                <div className={styles.contentWrapper}>
                    <ContentTypeTitle
                        className={`${styles.title}`}
                        tag="h3"
                        contentData={props.giftRegistryItem.title}
                        site={props.site}
                    />
                    <QuantityAndReservedQuantity
                        giftRegistryReservations={props.giftRegistryReservations}
                        className={styles.quantity}
                        giftRegistryItem={props.giftRegistryItem}
                        siteModule={props.siteModule}
                        site={props.site}
                    />
                    {props.giftRegistryItem.link && (
                        <div className={styles.linkWrapper}>
                            <a
                                href={props.giftRegistryItem.link}
                                className={styles.link}
                                rel="noreferrer"
                                target="_blank"
                            >
                                <IconNewWindow />
                                <span className={styles.linkText}>{props.giftRegistryItem.link}</span>
                            </a>
                        </div>
                    )}
                    {getInTranslation(props.giftRegistryItem.description.text) ? (
                        <div className={`${styles.description}`}>
                            {getInTranslation(props.giftRegistryItem.description.text)}
                        </div>
                    ) : null}
                    {!!props.giftRegistryItem.reservable && (
                        <div className={styles.reserveButtons}>
                            <>
                                {showButtonReserve() && (
                                    <div>
                                        <ContentTypeButton
                                            site={props.site}
                                            contentData={props.siteModule.reserveButton}
                                            small={true}
                                            onClick={() => setView("RESERVE")}
                                            submit={false}
                                        />
                                    </div>
                                )}
                                {showButtonCancelReservation() && (
                                    <div>
                                        <ContentTypeButton
                                            site={props.site}
                                            contentData={props.siteModule.cancelReservationButton}
                                            small={true}
                                            onClick={() => setView("CANCEL_RESERVATION")}
                                            submit={false}
                                        />
                                    </div>
                                )}
                                {view === "RESERVE" && (
                                    <GiftRegistryReserve
                                        siteModule={props.siteModule}
                                        close={() => setView(null)}
                                        giftRegistryItem={props.giftRegistryItem}
                                        site={props.site}
                                        giftRegistryReservations={props.giftRegistryReservations}
                                        setGiftRegistryReservations={props.setGiftRegistryReservations}
                                    />
                                )}
                                {view === "CANCEL_RESERVATION" && (
                                    <GiftRegistryCancelReservation
                                        siteModule={props.siteModule}
                                        close={() => setView(null)}
                                        giftRegistryItem={props.giftRegistryItem}
                                        site={props.site}
                                        giftRegistryReservations={props.giftRegistryReservations}
                                        setGiftRegistryReservations={props.setGiftRegistryReservations}
                                    />
                                )}
                            </>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
