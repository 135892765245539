import styles from "./Tab.module.scss";

interface IProps {
    icon?: JSX.Element;
    text: string;
    onClick?: () => void;
    highlight?: boolean;
    hide?: boolean;
    active?: boolean;
    "data-test-id"?: string;
}

export function Tab(props: IProps) {
    return (
        <button
            data-test-id={props["data-test-id"]}
            className={`${styles.button} ${props.active === true ? styles.active : ""} ${
                props.highlight === true ? styles.highlight : ""
            }`}
            onClick={props.onClick}
        >
            {props.icon ? <span className={styles.icon}>{props.icon}</span> : null}
            <span className={styles.text}>{props.text}</span>
        </button>
    );
}
