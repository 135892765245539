import styles from "./EditGiftRegistryImage.module.scss";
import { UploadImage } from "../../../../../../../contentTypes/components/UploadImage/UploadImage";
import Button from "../../../../../../../components/button/Button";
import { IContentDataImage } from "../../../../../../../interfaces/IContentData";
import { ISiteV2 } from "../../../../../../../interfaces/Site";
import { ImgTagFromContentTypeImage } from "../../../../../../../contentTypes/components/ImgTagFromContentTypeImage/ImgTagFromContentTypeImage";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataImage;
    updateContentData: (value: IContentDataImage) => void;
}

export const EditGiftRegistryImage = (props: IProps) => {
    const deleteImage = () => {
        const newObj = { ...props.contentData, image: null };
        props.updateContentData(newObj);
    };

    return (
        <div className={styles.wrapper}>
            {props.contentData.image ? (
                <div className={styles.currentImageAndEditImage}>
                    <div className={styles.inner}>
                        <div className={styles.imageWrapper}>
                            <ImgTagFromContentTypeImage image={props.contentData.image} />
                        </div>
                        <div className={styles.buttonWrapper}>
                            <Button buttonText="Ta bort bild" type="warning" callback={deleteImage} />
                        </div>
                    </div>
                </div>
            ) : null}
            <div className={styles.uploadImage}>
                <UploadImage
                    updateContentData={props.updateContentData}
                    site={props.site}
                    contentData={props.contentData}
                />
            </div>
        </div>
    );
};
