import { ISiteV2 } from "../../interfaces/Site";
import styles from "./SiteModuleRsvp.module.scss";
import { ISiteModuleRsvp } from "../../interfaces/ISiteModule";
import { ContentTypeRichText } from "../../contentTypes/ContentTypeRichText/ContentTypeRichText";
import { ContentTypeModuleHeading } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeading";
import { SelectNumberOfGuests } from "./components/SelectNumberOfGuests/SelectNumberOfGuests";
import FormWrapper from "../../components/form/FormWrapper/FormWrapper";
import { GuestForm } from "./components/GuestForm/GuestForm";
import { useImmer } from "use-immer";
import { IFormElementOption, TFormElement } from "../../interfaces/FormElement";
import { showFormElementWithFormCondition } from "../../utils/showFormElementWithFormCondition";
import { ContentTypeButton } from "../../contentTypes/ContentTypeButton/ContentTypeButton";
import { randomId } from "../../utils/Random";
import { useContext, useState } from "react";
import Axios from "axios";
import Loading from "../../components/Loading/Loading";
import { FormConfirmationBox } from "../components/FormConfirmationBox/FormConfirmationBox";
import { ContentTypeTitle } from "../../contentTypes/ContentTypeTitle/ContentTypeTitle";
import { EditAvailableContext } from "../../../contexts/EditAvailableContext";
import { IFormData } from "../../interfaces/IFormData";
import { useTranslation } from "../../../contexts/LanguageContext";
import { TranslationWithFallback } from "../../components/TranslationWithFallback/TranslationWithFallback";
import { DialogPublic } from "../../components/DialogPublic/DialogPublic";
import { IErrorPublic } from "../../interfaces/IErrorV2";
import { ErrorDialogPublic } from "../../components/ErrorDialogPublic/ErrorDialogPublic";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
}

export const SiteModuleRsvp = (props: IProps) => {
    const [rsvps, setRsvps] = useImmer<IFormData[]>([]);
    const [postKey, setPostKey] = useState<string | null>(null);
    const [errorPublic, setErrorPublic] = useState<IErrorPublic | null>(null);
    const [confirmationView, setConfirmationView] = useState<"SPAM" | "SUCCESS" | "EDIT_MODE" | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const isEditAvailable = useContext(EditAvailableContext);
    const { language } = useTranslation();

    const setNumberOfGuests = async (numberOfGuests: number) => {
        try {
            setRsvps((draft) => {
                if (draft.length > numberOfGuests) {
                    draft.splice(0, draft.length - numberOfGuests);
                } else if (draft.length < numberOfGuests) {
                    [...Array(numberOfGuests - draft.length)].forEach((_, i) => {
                        draft.push({ formData: {} });
                    });
                }
            });
            const response = await Axios.get(process.env.REACT_APP_API_URL + "/api/rsvps/post-key");
            setPostKey(response.data.key);
        } catch (error) {
            setErrorPublic({
                description: { sv: "Misslyckades att ladda OSA-formuläret", en: "Failed to load RSVP form" },
                response: (error as any)?.response,
            });
        }
    };

    const handleValueChange = (index: number, formElement: TFormElement, value: string | IFormElementOption) => {
        setRsvps((draft) => {
            draft[index].formData[formElement.id] = {
                formElement,
                value: typeof value === "string" ? value : undefined,
                selectedOption: typeof value !== "string" ? value : undefined,
            };
        });
    };

    const handleSubmit = async () => {
        try {
            if (isEditAvailable) {
                setConfirmationView("EDIT_MODE");
                return;
            }
            setIsLoading(true);
            const guests = rsvps.map((rsvp) => {
                return {
                    formElements: props.siteModule.form.formElements.map((formElement) => {
                        if (showFormElementWithFormCondition(formElement, rsvp)) {
                            return {
                                ...rsvp.formData[formElement.id],
                                formElement: formElement,
                            };
                        } else {
                            return {
                                formElement,
                                selectedOption: undefined,
                                value: undefined,
                            };
                        }
                    }),
                };
            });
            const data = {
                guests: guests,
                language: language,
                groupIdentifier: randomId(),
                postKey: postKey,
                formId: props.siteModule.id,
                formTitle: props.siteModule.title?.text,
            };
            const response = await Axios.post(
                process.env.REACT_APP_API_URL + "/api/rsvps/" + props.site._id + "/v4/",
                data
            );
            if (response.data.invertedSuccess && response.data.invertedSuccess === true) {
                setConfirmationView("SPAM");
            } else {
                setConfirmationView("SUCCESS");
            }
            setRsvps([]);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setErrorPublic({
                description: { sv: "Misslyckades att skicka OSA", en: "Failed to send RSVP" },
                response: (error as any)?.response,
            });
        }
    };

    return (
        <section className={styles[props.site.theme]} data-test-id="rsvp-module-form">
            {isLoading ? <Loading fixed={true} /> : null}
            <ErrorDialogPublic error={errorPublic} />
            <div className={styles.innerWrapper}>
                <ContentTypeModuleHeading contentData={props.siteModule.title} site={props.site} />
                <div className={styles.textAndFormWrapper}>
                    <div className={styles.rteWrapper}>
                        <ContentTypeRichText contentData={props.siteModule.text} site={props.site} />
                    </div>
                    <div className={styles.editOrSelectNumberOfPersonsWrapper}>
                        <SelectNumberOfGuests
                            handleSelectedNumberOfGuests={setNumberOfGuests}
                            selectedNumberOfGuests={rsvps.length}
                            siteModule={props.siteModule}
                            site={props.site}
                        />
                    </div>
                    {rsvps.length === 0 ? null : (
                        <FormWrapper className={styles.form} submit={handleSubmit} data-test-id="rsvp-form">
                            <>
                                {rsvps.map((_, index) => (
                                    <GuestForm
                                        site={props.site}
                                        key={index}
                                        siteModule={props.siteModule}
                                        index={index}
                                        handleValueChange={handleValueChange}
                                        formData={rsvps[index]}
                                    />
                                ))}
                                <div className={styles.buttonWrapper}>
                                    <ContentTypeButton
                                        submit={true}
                                        contentData={props.siteModule.sendButton}
                                        site={props.site}
                                        small={false}
                                        data-test-id="rsvp-form-submit-button"
                                    />
                                </div>
                            </>
                        </FormWrapper>
                    )}
                    {confirmationView === "SUCCESS" ? (
                        <DialogPublic
                            open={true}
                            handleClose={() => {
                                setConfirmationView(null);
                            }}
                            data-test-id="rsvp-confirmation-modal"
                            closableByOutsideClickAndEsc={true}
                        >
                            <FormConfirmationBox
                                site={props.site}
                                title={
                                    <ContentTypeTitle
                                        tag="h1"
                                        contentData={props.siteModule.confirmationTitle}
                                        site={props.site}
                                    />
                                }
                                text={
                                    <ContentTypeRichText
                                        contentData={props.siteModule.confirmationText}
                                        site={props.site}
                                    />
                                }
                            />
                        </DialogPublic>
                    ) : null}
                    {confirmationView === "SPAM" ? (
                        <DialogPublic
                            open={true}
                            handleClose={() => {
                                setConfirmationView(null);
                            }}
                            closableByOutsideClickAndEsc={true}
                        >
                            <FormConfirmationBox
                                site={props.site}
                                title={
                                    <h1>
                                        <TranslationWithFallback
                                            translation={{ sv: "Tyvärr gick det fel", en: "Something went wrong" }}
                                        />
                                    </h1>
                                }
                                text={
                                    <TranslationWithFallback
                                        translation={{
                                            sv: "Tyvärr lyckades vi inte spara din OSA då vi identifierade dig som en robot. Vänligen fyll i en gång till och vänta några sekunder innan du trycker på skicka. Vi ber om ursäkt för besväret!",
                                            en: "Unfortunately we identified you as a bot. Please fill in the form again, wait a couple of seconds before you press submit. We apologize for the inconvenience.",
                                        }}
                                    />
                                }
                            />
                        </DialogPublic>
                    ) : null}
                    {confirmationView === "EDIT_MODE" ? (
                        <DialogPublic
                            open={true}
                            handleClose={() => {
                                setConfirmationView(null);
                            }}
                            closableByOutsideClickAndEsc={true}
                        >
                            <FormConfirmationBox
                                site={props.site}
                                title={<h1>Kan inte skicka OSA härifrån</h1>}
                                text="Du kan inte skicka in formuläret från granska-läget. För att testa formuläret gå till sidans publika adress (samma som gästerna besöker)."
                            />
                        </DialogPublic>
                    ) : null}
                </div>
            </div>
        </section>
    );
};
