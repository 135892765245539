import { ContentTypeButtonEdit } from "../../../contentTypes/ContentTypeButton/ContentTypeButtonEdit";
import { ContentBox } from "../../../editComponents/ContentBox/ContentBox";
import { IContentDataText } from "../../../interfaces/IContentData";
import { ISiteModuleForm, ISiteModuleRsvp } from "../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../interfaces/Site";
import { EmulateContentSurface } from "../EmulateContentSurface/EmulateContentSurface";
import { LanguageTitle } from "../LanguageTitle/LanguageTitle";
import { VerticalGapOnChildren } from "../VerticalGapOnChildren/VerticalGapOnChildren";
import styles from "./FormSendButtonEdit.module.scss";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleForm | ISiteModuleRsvp;
    updateSendButton: (value: IContentDataText) => void;
}

export const FormSendButtonEdit = (props: IProps) => {
    return (
        <>
            <ContentBox padding={true} className={styles.wrapper} title="Skicka-knapp">
                <p>Knappen som gästen skickar forumläret med. Tryck på texten för att ändra den.</p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <EmulateContentSurface site={props.site} className={styles.buttonWrapper}>
                                <ContentTypeButtonEdit
                                    site={props.site}
                                    contentData={props.siteModule.sendButton}
                                    updateContentData={props.updateSendButton}
                                    placeholder={"Ingen text"}
                                    small={false}
                                    language={language.languageCode}
                                />
                            </EmulateContentSurface>
                        </div>
                    ))}
                </VerticalGapOnChildren>
            </ContentBox>
        </>
    );
};
