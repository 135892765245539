import { IRsvpV2 } from "../ViewRsvp";
import { getInRsvpTranslation } from "./getInRsvpTranslation";

export const answerFromFormElementId = (rsvp: IRsvpV2, formElementId: string) => {
    const formElement = rsvp.formElements.find((formElement) => formElement.formElement.id === formElementId);
    if (!formElement) return null;
    if (formElement.selectedOption) {
        return getInRsvpTranslation(formElement.selectedOption.text, rsvp)
    }
    return formElement.value;
};