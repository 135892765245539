import styles from "./SiteModuleImageAndText.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { ISiteModuleImageAndText } from "../../interfaces/ISiteModule";
import { ContentTypeModuleHeading } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeading";
import { ContentTypeTitle } from "../../contentTypes/ContentTypeTitle/ContentTypeTitle";
import { ContentTypeRichText } from "../../contentTypes/ContentTypeRichText/ContentTypeRichText";
import { ContentTypeImage } from "../../contentTypes/ContentTypeImage/ContentTypeImage";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleImageAndText;
}

export const SiteModuleImageAndText = (props: IProps) => {
    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeading contentData={props.siteModule.title} site={props.site} />
            <div className={styles.imageAndTextWrapper}>
                <div className={styles.imageWrapper}>
                    <ContentTypeImage contentData={props.siteModule.image} site={props.site} sizes="(min-width: 968px) 968px, 100vw"/>
                </div>
                <div className={styles.textContentWrapper}>
                    <div className={styles.innerTextContentWrapper}>
                        <ContentTypeTitle tag="h3" contentData={props.siteModule.secondTitle} site={props.site} />
                        <div className={styles.textWrapper}>
                            <ContentTypeRichText contentData={props.siteModule.text} site={props.site} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
