import React, { useState } from "react";
import ContentH3 from "../../../content/h3/H3";
import ContentImage from "../../../content/image/Image";
import ContentOneLine from "../../../content/oneLine/OneLine";
import ContentRTE from "../../../content/RTE/RTE";
import { IModule } from "../../../interfaces/Module";
import { IImage, ISite } from "../../../interfaces/Site";
import { ItemActionBar } from "../../../modules/ItemActionBar/ItemActionBar";
import { getEmptyNewPeopleListItem, IPeopleListItem } from "../PeopleList";
import styles from "./PeopleListItem.module.scss";

interface IProps {
    listItem: IPeopleListItem;
    module: IModule;
    editMode: boolean;
    site: ISite;
    updatePropertyOnListItem: any;
    addOrUpdateImageToSite: (image: IImage) => void;
    allListItems: IPeopleListItem[];
    updateList: (listItems: IPeopleListItem[]) => void;
}

export function PeopleListItem(props: IProps) {
    const [isHighlited, setIsHighlited] = useState(false);

    const updateProperty = (module: IModule, name: string, value: string) => {
        props.updatePropertyOnListItem(props.listItem, name, value);
    };

    return (
        <div className={`${styles[props.site.theme]} ${isHighlited ? styles.highlighted : ""}`}>
            <div className={styles.imageWrapper}>
                <ContentImage
                    site={props.site}
                    property="image"
                    updateProperty={updateProperty}
                    propertyParent={props.listItem}
                    editMode={props.editMode}
                    addOrUpdateImageToSite={props.addOrUpdateImageToSite}
                    sizes="220px"
                ></ContentImage>
            </div>
            <div className={styles.textAndHeadingContentWrapper}>
                <ContentH3
                    site={props.site}
                    property="name"
                    propertyParent={props.listItem}
                    updateProperty={updateProperty}
                    editMode={props.editMode}
                />
                <div className={styles.title}>
                    <ContentOneLine
                        site={props.site}
                        property="title"
                        propertyParent={props.listItem}
                        updateProperty={updateProperty}
                        editMode={props.editMode}
                        placeholder={"Titel"}
                    />
                </div>
                <div className={styles.textContentWrapper}>
                    <ContentRTE
                        site={props.site}
                        property="text"
                        propertyParent={props.listItem}
                        updateProperty={updateProperty}
                        editMode={props.editMode}
                    />
                </div>
            </div>
            {props.editMode === true ? (
                <ItemActionBar<IPeopleListItem>
                    getNewItem={getEmptyNewPeopleListItem}
                    updateList={props.updateList}
                    itemName="Person"
                    items={props.allListItems}
                    item={props.listItem}
                    setHighligtItem={setIsHighlited}
                />
            ) : null}
        </div>
    );
}
