import React from "react";
import styles from "./Map.module.scss";
import { ISiteV2 } from "../../../../interfaces/Site";
import { IContentDataMap } from "../../../../interfaces/IContentData";
import { getCDNUrl } from "../../../../../utils/DomainHelpers";

interface IProps {
    map: IContentDataMap;
    site: ISiteV2;
    linkToExternalMap: boolean;
}

export const Map = (props: IProps) => {
    if (!props.map.map) {
        return null;
    }

    const mapAndLabel = () => {
        if (!props.map.map) {
            return null;
        }
        return (
            <>
                <div className={styles.locationLabel}>{props.map.map.location}</div>
                <img src={getCDNUrl() + props.map.map.path} loading="lazy" alt="karta" />
            </>
        );
    };

    if (props.linkToExternalMap) {
        return (
            <a
                className={styles.mapWrapper}
                target="_blank"
                rel="noreferrer"
                href={"https://www.google.se/maps/search/?api=1&query=" + encodeURIComponent(props.map.map.location)}
            >
                {mapAndLabel()}
            </a>
        );
    } else {
        return <div className={`${styles.mapWrapper}`}>{mapAndLabel()}</div>;
    }
};
