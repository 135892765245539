import React from "react";
import { YesOrNo } from "../../../components/form/YesOrNo/YesOrNo";
import { YesOrNoEdit } from "../../../components/form/YesOrNo/YesOrNoEdit";
import ContentMultipleTextFields from "../../../content/MultipleTextFields/MultipleTextFields";
import ContentMultipleYesOrNo from "../../../content/MultipleYesOrNo/MultipleYesOrNo";
import ContentOneLine from "../../../content/oneLine/OneLine";
import { IModule } from "../../../interfaces/Module";
import { IRvspPersonV2 } from "../../../interfaces/Rvsp";
import { ISite } from "../../../interfaces/Site";
import styles from "./Person.module.scss";

interface IProps {
    index: number;
    updateValue: any;
    data: IRvspPersonV2 | null;
    module: IModule;
    editMode: boolean;
    site: ISite;
    updateProperty: any;
    propertyParent: any;
}

interface IState {
    personsQuantity: number;
}

export class Person extends React.Component<IProps, IState> {
    updateCustomField = (value: string, name: string, customValueType: string, label: string) => {
        this.props.updateValue(this.props.index, name, value, customValueType, label);
    };

    updateField = (value: string, name: string) => {
        this.props.updateValue(this.props.index, name, value);
    };

    labelOnChange = (newLabel: string) => {
        this.props.updateProperty(this.props.propertyParent, "weddingYesOrNoLabel", newLabel);
    };

    labelYesOnChange = (newLabel: string) => {
        this.props.updateProperty(this.props.propertyParent, "weddingYesOrNoLabelYes", newLabel);
    };

    labelNoOnChange = (newLabel: string) => {
        this.props.updateProperty(this.props.propertyParent, "weddingYesOrNoLabelNo", newLabel);
    };

    uglyFixForSpecificCustomer = () => {
        return this.props.site.host === "ingermagnus.brollopssida.se";
    };

    render() {
        return (
            <div className={styles[this.props.site.theme]}>
                <h3 className={styles.personHeading}>
                    <ContentOneLine
                        className={styles.personHeadingEditable}
                        site={this.props.site}
                        propertyParent={this.props.module}
                        property="personTitle"
                        placeholder="Ingen text"
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                    />{" "}
                    {this.props.index + 1}
                </h3>
                <ContentMultipleTextFields
                    propertyParent={this.props.module}
                    editMode={this.props.editMode}
                    property="customTextFieldsAbove"
                    site={this.props.site}
                    updateProperty={this.props.updateProperty}
                    updateCustomField={this.updateCustomField}
                    id={"RVSP-MultipleTextFieldsAbove-" + this.props.index}
                />
                <div className={styles.yesOrNoWrapper}>
                    {this.props.editMode === true ? (
                        <YesOrNoEdit
                            label={this.props.module.properties.weddingYesOrNoLabel}
                            id={"RVSP-WeddingYesOrNo-" + this.props.index}
                            labelOnChange={this.labelOnChange.bind(this)}
                            labelNoOnChange={this.labelNoOnChange.bind(this)}
                            labelYesOnChange={this.labelYesOnChange.bind(this)}
                            labelNo={this.props.module.properties.weddingYesOrNoLabelNo}
                            labelYes={this.props.module.properties.weddingYesOrNoLabelYes}
                            site={this.props.site}
                        ></YesOrNoEdit>
                    ) : (
                        <YesOrNo
                            label={this.props.module.properties.weddingYesOrNoLabel}
                            disabled={false}
                            labelNo={this.props.module.properties.weddingYesOrNoLabelNo}
                            labelYes={this.props.module.properties.weddingYesOrNoLabelYes}
                            name="weddingYesOrNo"
                            id={"RVSP-WeddingYesOrNo-" + this.props.index}
                            onChangeCallback={this.updateField}
                            value={this.props.editMode ? undefined : this.props.data!.weddingYesOrNo}
                            site={this.props.site}
                        />
                    )}

                    <ContentMultipleYesOrNo
                        propertyParent={this.props.module}
                        editMode={this.props.editMode}
                        property="customYesOrNoItems"
                        site={this.props.site}
                        updateProperty={this.props.updateProperty}
                        updateCustomField={this.updateCustomField}
                        id={"RVSP-MultipleYesOrNo-" + this.props.index}
                    />
                </div>
                {(this.props.editMode === true ||
                    this.props.data!.weddingYesOrNo === true ||
                    this.uglyFixForSpecificCustomer()) && (
                    <ContentMultipleTextFields
                        propertyParent={this.props.module}
                        editMode={this.props.editMode}
                        property="customTextFieldsBelow"
                        site={this.props.site}
                        updateProperty={this.props.updateProperty}
                        updateCustomField={this.updateCustomField}
                        id={"RVSP-MultipleTextFieldsBelow-" + this.props.index}
                    />
                )}
            </div>
        );
    }
}

export default Person;
