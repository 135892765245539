import { TTranslation } from "./IContentData";

export interface IErrorPublic {
    description: TTranslation;
    response?: unknown | IServerErrorResponse | IDeprecatedServerErrorResponse;
}

export interface IErrorEdit {
    description: string;
    response?: unknown | IServerErrorResponse | IDeprecatedServerErrorResponse;
}

export const responseIsServerErrorResponse = (response: unknown): response is IServerErrorResponse => {
    if (
        typeof response === "object" &&
        response &&
        "data" in response &&
        typeof response.data === "object" &&
        response.data !== null &&
        "isErrorResponse" in response.data &&
        response.data.isErrorResponse === true
    ) {
        return true;
    }
    return false;
};

export const responseDeprecatedServerErrorResponse = (
    response: unknown
): response is IDeprecatedServerErrorResponse => {
    if (
        typeof response === "object" &&
        response &&
        "data" in response &&
        typeof response.data === "object" &&
        response.data !== null &&
        "message" in response.data &&
        typeof response.data.message === "string"
    ) {
        return true;
    }
    return false;
};

export interface IServerErrorResponse {
    data: { isErrorResponse: true; errorDescription: TTranslation };
}

export interface IDeprecatedServerErrorResponse {
    data: { message: string };
}
