import * as React from "react";

export const IconVersions = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
        >
            <path d="M21 7h-3a2 2 0 0 1-2-2V2"></path>
            <path d="M21 6v6.5c0 .8-.7 1.5-1.5 1.5h-7c-.8 0-1.5-.7-1.5-1.5v-9c0-.8.7-1.5 1.5-1.5H17Z"></path>
            <path d="M7 8v8.8c0 .3.2.6.4.8s.5.4.8.4H15"></path>
            <path d="M3 12v8.8c0 .3.2.6.4.8s.5.4.8.4H11"></path>
        </svg>
    );
};
