import { ContentTypeModuleHeading } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeading";
import { ISiteModuleTextAndImageList } from "../../interfaces/ISiteModule";
import { ISiteV2 } from "../../interfaces/Site";
import { SiteModuleTextAndImageListItem } from "./SiteModuleTextAndImageListItem/SiteModuleTextAndImageListItem";
import styles from "./SiteModuleTextAndImageList.module.scss";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleTextAndImageList;
}

export const SiteModuleTextAndImageList = (props: IProps) => {

    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeading
                contentData={props.siteModule.title}
                site={props.site}
            />
            {props.siteModule.list.length ? (
                <div className={styles.itemsWrapper}>
                    {props.siteModule.list.map((listItem) => (
                        <SiteModuleTextAndImageListItem
                            site={props.site}
                            listItem={listItem}
                            key={listItem.id}
                        />
                    ))}
                </div>
            ) : null}
        </section>
    );
};
