import { ISiteModuleForm } from "../../interfaces/ISiteModule";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModuleForm = (): ISiteModuleForm => {
    return {
        siteModuleType: "Form",
        ...getNewSiteModuleBasic(false),
        title: {
            contentDataType: "Title",
            text: {},
        },
        sendButton: {
            contentDataType: "Text",
            text: { sv: "Skicka", en: "Send" },
        },
        showFormButton: {
            contentDataType: "Text",
            text: { sv: "Visa formulär", en: "Show form" },
        },
        text: {
            contentDataType: "RichText",
            richText: {},
        },
        form: {
            contentDataType: "Form",
            formElements: [],
        },
        confirmationTitle: {
            contentDataType: "Title",
            text: { sv: "Tack!", en: "Thank you!" },
        },
        confirmationText: {
            contentDataType: "RichText",
            richText: { sv: "<p>Nu är formuläret skickat.</p>" },
        },
    };
};
