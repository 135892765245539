import { rteContentIsEmpty } from "../../../../utils/rteContentIsEmpty";
import styles from "./TranslateModePreviewTypeRTE.module.scss";

interface IProps {
    value: string;
}

export function TranslateModePreviewTypeRte(props: IProps) {

    const createMarkup = (data: string) => {
        let markup = "<span></span>";
        if (!rteContentIsEmpty(data)) {
            markup = data;
        }

        return { __html: markup };
    };

    return <div className={styles.wrapper} dangerouslySetInnerHTML={createMarkup(props.value)}></div>;
}
