import React from "react";
import { ISite, ISiteV2 } from "../../interfaces/Site";
import styles from "./GeneratorButton.module.scss";

interface IProps {
    buttonText: string;
    callback?: any;
    type: "primary" | "secondary";
    disabled?: boolean;
    submit?: boolean;
    site: ISite | ISiteV2;
    small?: boolean;
    "data-test-id"?: string;
}

export class GeneratorButton extends React.Component<IProps> {
    render() {
        return this.props.submit ? (
            <input
                type="submit"
                value={this.props.buttonText}
                disabled={this.props.disabled ? true : false}
                className={`${styles[this.props.site.theme]} ${this.props.small ? styles.small : ""} ${
                    this.props.type === "secondary" ? styles.secondary : ""
                }`}
                data-test-id={this.props["data-test-id"]}
            />
        ) : (
            <button
                type="button"
                disabled={this.props.disabled ? true : false}
                className={`${styles[this.props.site.theme]} ${this.props.small ? styles.small : ""} ${
                    this.props.type === "secondary" ? styles.secondary : ""
                }`}
                onClick={this.props.callback}
                data-test-id={this.props["data-test-id"]}
            >
                {this.props.buttonText}
            </button>
        );
    }
}

export default GeneratorButton;
