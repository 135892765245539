import { ISiteModuleCountdown } from "../../interfaces/ISiteModule";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModuleCountdown = (): ISiteModuleCountdown => {
    return {
        siteModuleType: "Countdown",
        ...getNewSiteModuleBasic(true),
        countdownDate: null,
    };
};
