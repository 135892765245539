import { useEffect, useState } from "react";
import ActionCurtain from "../../../../../../components/actionCurtain/ActionCurtain";
import { getOrder } from "../../../../../../services/OrderService";
import { IOrder } from "../../../../../../interfaces/Order";
import { IError } from "../../../../../../../App";
import Loading from "../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../components/ShowError/ShowError";
import { Payment } from "../../../../../../modules/Payment/Payment";

interface IProps {
    handlePaymentDone: (paymentReference: string) => void;
    close: () => void;
    orderId: string;
}

export function PrintPayment(props: IProps) {
    const [hideEverythingButPaymentMethod, setHideEverythingButPaymentMethod] = useState(false);
    const [order, setOrder] = useState<IOrder | null>(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<IError | null>(null);

    useEffect(() => {
        const getShipmentMethodsForSelect = async () => {
            setLoading(true);
            try {
                const data = await getOrder(props.orderId);
                setOrder(data);
            } catch (error) {
                setError({ text: "Misslyckades att hämta order", response: (error as any).response });
            }
            setLoading(false);
        };
        getShipmentMethodsForSelect();
    }, [props.orderId]);
    
    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ShowError error={error} />;
    }
    if (!order) {
        throw new Error("Order not available but not loading state");
    }

    return (
        <ActionCurtain title="Köp kort" close={props.close} preventClosingOnClickOutside={true}>
            <>
                {!hideEverythingButPaymentMethod && (
                    <>
                        <p>
                            Efter att du har betalat <strong>{order.totalInclVat / 100} kr</strong> läggs beställningen på dina kort direkt och produktionen tar vid. Eftersom korten trycks specifikt för dig går det inte att ångra köpet. Dubbelkolla därför att du har stavat rätt och att allt är enligt dina önskemål.
                        </p>
                    </>
                )}
                {<Payment
                    orderId={order._id}
                    setHideEverythingButPaymentMethod={setHideEverythingButPaymentMethod}
                    hideEverythingButPaymentMethod={hideEverythingButPaymentMethod}
                    handlePaymentDone={props.handlePaymentDone}
                />
                }
            </>
        </ActionCurtain>
    );
}
