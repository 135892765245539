import React, { useState } from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import { EditVideo } from "./components/EditVideo/EditVideo";
import styles from "./ContentTypeVideo.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataVideo } from "../../interfaces/IContentData";
import { getYoutubeIdFromUrl } from "../../utils/getYouTubeIdFromUrl";
import { VideoThumbnail } from "./components/VideoThumbnail/VideoThumbnail";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataVideo;
    updateContentData: (value: IContentDataVideo) => void;
}

export const ContentTypeVideoEdit = (props: IProps) => {
    const [showModal, setShowModal] = useState(false);
    const videoIdentifier = getYoutubeIdFromUrl(props.contentData.videoUrl);

    return (
        <>
            {showModal === true && (
                <ActionCurtain close={() => setShowModal(false)}>
                    <EditVideo
                        site={props.site}
                        contentData={props.contentData}
                        updateContentData={props.updateContentData}
                        close={() => setShowModal(false)}
                    />
                </ActionCurtain>
            )}
                <div className={styles.wrapper}>
                    {videoIdentifier ? (
                        <div
                            className={`${styles.clickable} ${styles.thumbnailWrapper}`}
                            onClick={() => setShowModal(true)}
                        >
                            <VideoThumbnail videoIdentifier={videoIdentifier} />
                        </div>
                    ) : (
                        <div
                            className={styles.placeholder + " image-placeholder"}
                            onClick={() => setShowModal(true)}
                        >
                            Ingen video tillagd
                        </div>
                    )}
                </div>
        </>
    );
};
