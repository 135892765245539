import Button from "../../../../components/button/Button";
import FormWrapper from "../../../../components/form/FormWrapper/FormWrapper";
import TextField from "../../../../components/form/TextField/TextField";
import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./EditVideo.module.scss";
import { IContentDataVideo } from "../../../../interfaces/IContentData";
import { useImmer } from "use-immer";
import { getYoutubeIdFromUrl } from "../../../../utils/getYouTubeIdFromUrl";
import { Switch } from "../../../../components/form/Switch/Switch";
import { Video } from "../Video/Video";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataVideo;
    updateContentData: (value: IContentDataVideo) => void;
    close: () => void;
}

export function EditVideo(props: IProps) {
    const [newVideoValues, setNewVideoValues] = useImmer({
        ...props.contentData,
    });
    const handleDone = async () => {
        props.updateContentData({
            contentDataType: "Video",
            autoStart: newVideoValues.autoStart,
            type: "YouTube",
            videoUrl: newVideoValues.videoUrl,
        });
        props.close();
    };

    const videoIdentifier = getYoutubeIdFromUrl(newVideoValues.videoUrl);

    return (
        <FormWrapper submit={handleDone}>
            <div className={styles.wrapper}>
                <div className={styles.formWrapper}>
                    <div className={styles.formFieldWrapper}>
                        <TextField
                            label="Url till Youtube-klipp"
                            name="videoUrl"
                            id="videoUrl"
                            value={newVideoValues.videoUrl || ""}
                            onChangeCallback={(value: string) =>
                                setNewVideoValues((values) => {values.videoUrl = value})
                            }
                            placeholder="https://www.youtube.com/watch?v=OOgjmSqd2KE"
                        />
                    </div>
                    <div className={styles.formFieldWrapper}>
                        <Switch
                            label="Autostart av klipp"
                            name="autoStart"
                            handleChange={(value: boolean) => setNewVideoValues((values) => {values.autoStart = value})}
                            value={newVideoValues.autoStart}
                            id="autoStartSwitch"
                            dark={true}
                        />
                    </div>
                </div>
                {videoIdentifier ? (
                    <div className={styles.videoWrapper}>
                        <Video videoIdentifier={videoIdentifier} autoStart={false} />
                    </div>
                ) : null}
            </div>
            <div className={styles.bottomButtons}>
                <Button buttonText="Klar" type="primary" submit={true} fullWidth={true} />
            </div>
        </FormWrapper>
    );
}
