export const isLocalDevFromPort = (port: string): boolean => {
    if (!port) {
        return false;
    }
    return port.toString().substring(0, 2) === "30";
};

export const isLocalDev = (): boolean => {
    return isLocalDevFromPort(window.location.port);
};

export const getCDNUrl = (): string =>
    isLocalDev()
        ? "https://brollopssida-cdn-local.fra1.cdn.digitaloceanspaces.com/"
        : "https://brollopssida-cdn.fra1.cdn.digitaloceanspaces.com/";

export const getDomain = (): string => {
    return getDomainNameFromHostname(window.location.hostname);
};

export const getDomainNameFromHostname = (url: string): string => {
    url = url.toLowerCase();
    if (url.startsWith("www.")) {
        url = url.slice(4);
    }
    return url;
};

export const getSubDomainFromHostName = (url: string): string | null => {
    const urlSplited = url.split(".");
    if (urlSplited && urlSplited.length > 2) {
        url = urlSplited.slice(0, -2).join(".");
        url = url.toLowerCase();
        return url === "www" ? null : url;
    } else {
        return null;
    }
};

export const getHostFromDomainFromLocationHost = (url: string, port: string, search: string): string | null => {
    const urlParams = new URLSearchParams(search);
    const paramOverrideDomain = urlParams.get("domainHost");

    if (paramOverrideDomain) {
        return paramOverrideDomain;
    }

    const currentDomain = getDomainNameFromHostname(url);
    if (isLocalDevFromPort(port)) {
        return null;
    }
    return currentDomain;
};

export const getHostFromDomain = (): string | null => {
    return getHostFromDomainFromLocationHost(window.location.hostname, window.location.port, window.location.search);
};

export const isAppTypeEdit = (host: string | null) => {
    if (["festhemsida.se", "brollopssida.se", "eventsida.se", null].includes(host)) {
        return true;
    } else {
        return false;
    }
}

export const getAppTypeFromDomain = (): "EDIT" | "PUBLIC" => {
    return isAppTypeEdit(getHostFromDomain()) ? "EDIT" : "PUBLIC";
};

export const isDemoFromHost = (host: string | null): boolean => {
    return host?.substring(0, 5) === "demo.";
};
