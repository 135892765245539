import React from "react";
import { ISite, ISiteV2 } from "../../../interfaces/Site";
import { Radio } from "../Radio/Radio";
import styles from "./RadioGroup.module.scss";
import { IRadioGroupOption } from "./RadioGroupEdit";

interface IProps {
    onChangeCallback: any;
    value?: string;
    name: string;
    id: string;
    label: string;
    disabled: boolean;
    site: ISite | ISiteV2;
    options: IRadioGroupOption[];
    required: boolean;
}

interface IState {
    value: string | undefined;
}

export class RadioGroup extends React.Component<IProps, IState> {
    state: IState = {
        value: this.props.value,
    };

    setValue(value: string) {
        if (this.props.disabled) {
            return;
        }

        this.props.onChangeCallback(value, this.props.name);
    }

    componentWillUnmount(): void {
        // If this is removed because it is in a ShowFormContentCondition, set the value to nothing
        this.props.onChangeCallback("", this.props.name);
    }

    render() {
        return (
            <div className={`${styles[this.props.site.theme]}`}>
                <div className={styles.label}>
                    {this.props.label}
                    <div
                        className="invalidFeedback"
                        id={this.props.id + "_invalid_feedback"}
                        data-invalid-feedback="true"
                    ></div>
                </div>
                <div className={styles.options}>
                    {this.props.options?.map((option) => (
                        <div className={styles.option} key={option.id}>
                            <Radio
                                required={this.props.required}
                                value={this.state.value}
                                onChangeCallback={this.setValue.bind(this)}
                                label={option.label}
                                id={this.props.id + option.id}
                                name={this.props.id}
                                site={this.props.site}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
