import { IContentDataTitle } from "../../interfaces/IContentData";
import { ISiteV2 } from "../../interfaces/Site";
import { ContentTypeTitleEdit } from "../ContentTypeTitle/ContentTypeTitleEdit";
import styles from "./ContentTypeModuleHeading.module.scss";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataTitle;
    updateContentData: (value: IContentDataTitle) => void;
}

export const ContentTypeModuleHeadingEdit = (props: IProps) => {
    return (
        <div className={styles[props.site.theme]}>
            <hr className={styles.hr} />
            <ContentTypeTitleEdit
                tag="h2"
                contentData={props.contentData}
                site={props.site}
                updateContentData={props.updateContentData}
            />
        </div>
    );
};
