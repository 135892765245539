import { TFormElement } from "../interfaces/FormElement";
import { IFormData } from "../interfaces/IFormData";

export const showFormElementWithFormCondition = (formElement: TFormElement, formData: IFormData) => {
    const conditionQuestion = formElement.showConditions[0]?.formElementId;
    const conditionOption = formElement.showConditions[0]?.formElementOptionIds[0];

    // There are no (valid) formConditions
    if (!conditionQuestion || !conditionOption) {
        return true;
    }

    // There is a form condition and its true
    if (formData.formData[conditionQuestion]?.selectedOption?.id === conditionOption) {
        return true;
    }

    // THre is a form condition and it's not met
    return false;
};
