import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";
import { ISiteV2 } from "../interfaces/Site";
import { getBrandedContactMail } from "../utils/getBrand";
import { SiteModule } from "./SiteModule";

interface IProps {
    site: ISiteV2;
    editAvailable: boolean;
}

export const SiteModulesList = (props: IProps) => {
    const siteModules = props.site.siteModules.filter((module) => module.hide !== true);
    return (
        <>
            {siteModules && siteModules.length ? (
                siteModules.map((siteModule) => (
                    <ErrorBoundary
                        key={siteModule.id}
                        logErrorSource={"SiteModule" + siteModule?.siteModuleType}
                        visitorsErrorMessage={
                            "Ett oväntat fel uppstod i den här modulen. Felet har loggats. Ladda om sidan. Om inte det hjälper, kontakta " +
                            getBrandedContactMail() +
                            " för hjälp."
                        }
                    >
                        <SiteModule editAvailable={props.editAvailable} key={siteModule.id} site={props.site} siteModule={siteModule} />
                    </ErrorBoundary>
                ))
            ) : (
                <></>
            )}
        </>
    );
};
