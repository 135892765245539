import styles from "./ViewPrintCheckout.module.scss";
import Button from "../../../../components/button/Button";
import { PrintProductRow } from "./components/PrintProductRow/PrintProductRow";
import { useState } from "react";
import { PrintPayment } from "./components/PrintPayment/PrintPayment";
import { SelectShipmentMethod, getBetterShipmentMethodName } from "./components/SelectShipmentMethod/SelectShipmentMethod";
import { IShipmentMethod, postCreateOrder } from "../../../../services/OrderService";
import { IShippingAddress } from "../../../../interfaces/Order";
import { ShippingAddress } from "./components/ShippingAddress/ShippingAddress";
import {
    getFreightCostFormatted,
    getPrintProductCostFormatted,
    getTotalCostFormatted,
    getTotalVatFormatted,
} from "./utils/getPrintProductCost";
import { trackEvent } from "../../../../../utils/UmamiHelpers";

interface IProps {
    printables: string[];
    routerHistory: any;
    siteId: string;
}

export const ViewPrintCheckout = (props: IProps) => {
    const [paymentOrderId, setPaymentOrderId] = useState<string | null>(null);
    const [shipmentMethod, setShipmentMethod] = useState<IShipmentMethod | null>(null);
    const [shippingAddress, setShippingAddress] = useState<IShippingAddress | null>();
    const [numberOfPrints, setNumberOfPrints] = useState(10);
    const [envelopes, setEnvelopes] = useState<"none" | "standard" | "premium">("none");
    const [trim, setTrim] = useState<"none" | "rounded">("none");

    const handlePaymentDone = async (): Promise<void> => {
        trackEvent("print-payment-done");
        window.location.href =
            "/redigera/" + props.siteId + "/inbjudningar/bekraftelse/" + paymentOrderId;
    };

    const handleOrderConfirmation = async () => {
        if (!shipmentMethod) {
            throw new Error("No shipmentmethod data available when creating order");
        }

        if (!shippingAddress) {
            throw new Error("No shippingAddress data available when creating order");
        }
        const orderId = await postCreateOrder({
            print: props.printables[0],
            site: props.siteId,
            quantity: numberOfPrints,
            envelopes: envelopes,
            shipmentMethodUid: shipmentMethod.shipmentMethodUid,
            shipmentMethodName: getBetterShipmentMethodName(shipmentMethod.name),
            shippingAddress: shippingAddress,
        });

        setPaymentOrderId(orderId)
    };

    return (
        <div className={styles.wrapper}>
            {paymentOrderId ? (
                <PrintPayment
                    handlePaymentDone={handlePaymentDone}
                    close={() => setPaymentOrderId(null)}
                    orderId={paymentOrderId}
                />
            ) : null}
            <h1>Kassa</h1>

            <div className={styles.infoBox}>
                <h3 className={styles.title}>Produkt</h3>
                <PrintProductRow
                    numberOfPrints={numberOfPrints}
                    envelopes={envelopes}
                    setEnvelopes={setEnvelopes}
                    setNumberOfPrints={setNumberOfPrints}
                    printId={props.printables[0]}
                    setTrim={setTrim}
                />
            </div>
            <div className={styles.infoBox}>
                <h3 className={styles.title}>Totalkostnad</h3>
                <div>
                    <div>
                        {numberOfPrints} inbjudningar{trim !== "none" ? " med rundade hörn" : ""}{envelopes !== "none" ? " och kuvert" : ""}:{" "}
                        {getPrintProductCostFormatted(envelopes, numberOfPrints, trim)}
                    </div>
                    <div>Frakt: {getFreightCostFormatted()}</div>
                    <br />
                    <div>Varav moms: {getTotalVatFormatted(envelopes, numberOfPrints, trim)}</div>
                    <div>
                        <strong>Totalt: {getTotalCostFormatted(envelopes, numberOfPrints, trim)}</strong>
                    </div>
                </div>
                <h3 className={styles.title}>Mottagare</h3>
                <ShippingAddress setShippingAddress={setShippingAddress} shippingAddress={shippingAddress} />
                <h3 className={styles.title}>Leverans</h3>
                {shippingAddress ? (
                    <SelectShipmentMethod
                        quantity={10}
                        envelopes={"standard"}
                        printId={props.printables[0]}
                        shipmentMethod={shipmentMethod}
                        setShipmentMethod={setShipmentMethod}
                        shippingAddress={shippingAddress}
                    />
                ) : (
                    <div>Beräknat leveransdatum visas efter att du fyllt i din adress.</div>
                )}

                {shippingAddress ? (
                    <div className={styles.buttonWrapper}>
                        <Button
                            type="primary"
                            data-test-id="buy-printables-button"
                            callback={handleOrderConfirmation}
                            buttonText={"Beställ"}
                            data-umami-event="buy-printables-button"
                        />
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};
