import { IContentDataGiftRegistryItem } from "../../../../../interfaces/ISiteModule";
import { randomId } from "../../../../../utils/Random";

export const getNewGiftRegistryItem = (): IContentDataGiftRegistryItem => {
    return {
        id: "CLIENTID-GIFT-REGISTRY-ITEM" + randomId(),
        title: {
            contentDataType: "Title",
            text: {},
        },
        description: {
            contentDataType: "Text",
            text: {},
        },
        reservable: true,
        quantity: 1,
        link: null,
        image: {
            contentDataType: "Image",
            image: null,
        },
    };
};