import React, { useState, useRef } from "react";
import Axios from "axios";

import ReactCrop, { centerCrop, makeAspectCrop, Crop } from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import styles from "./CropImage.module.scss";
import { SelectAspectRatio } from "./components/SelectImageSize/SelectAspectRatio";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { IImageV2 } from "../../../../../../interfaces/IContentData";
import { QuickStartContext } from "../../../../../../../contexts/QuickStartContext";
import { IError } from "../../../../../../../App";
import { getCDNUrl } from "../../../../../../../utils/DomainHelpers";
import Loading from "../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../components/ShowError/ShowError";
import Button from "../../../../../../components/button/Button";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: "%",
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    );
}

interface IProps {
    image: IImageV2;
    updateImage: (value: IImageV2) => void;
    site: ISiteV2;
    goToImageView: () => void;
}

export function CropImage(props: IProps) {
    const imgRef = useRef<HTMLImageElement>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<Crop>();
    const [aspect, setAspect] = useState<number | undefined>();
    const isQuickStart = React.useContext(QuickStartContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<IError | null>(null);

    const imageUrl = getCDNUrl() + props.image.path + props.image.fileName;

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    function handleAspectRatioChange(newAspectRatio: number | undefined) {
        if (!newAspectRatio) {
            setAspect(undefined);
        } else if (imgRef.current) {
            const { width, height } = imgRef.current;
            setAspect(newAspectRatio);
            setCrop(centerAspectCrop(width, height, newAspectRatio));
        }
    }

    const convertPercentToPixelsOnImage = (percentCrop: Crop) => {
        if (!imgRef.current) {
            throw Error("Could not extract width and height from image");
        }
        const imageWidth = imgRef.current.naturalWidth;
        const imageHeight = imgRef.current.naturalHeight;
        const pixels = {
            height: Math.round((percentCrop.height / 100) * imageHeight),
            width: Math.round((percentCrop.width / 100) * imageWidth),
            x: Math.round((percentCrop.x / 100) * imageWidth),
            y: Math.round((percentCrop.y / 100) * imageHeight),
        };

        if (pixels.width + pixels.x > imageWidth) {
            pixels.width = imageWidth - pixels.x;
        }

        if (pixels.height + pixels.y > imageHeight) {
            pixels.height = imageHeight - pixels.y;
        }

        return pixels;
    };

    const handleSave = async () => {
        if (!completedCrop) {
            throw new Error("No crop data available when saving");
        }
        setIsLoading(true);
        try {
            const percentCrop = convertPercentToPixelsOnImage(completedCrop);
            const response = await Axios.put(
                process.env.REACT_APP_API_URL +
                    "/api/sites/" +
                    (isQuickStart ? "quick-start" : props.site._id) +
                    "/crop-image/v2",
                {
                    originalName: props.image.originalName,
                    sourceImageUrl: imageUrl,
                    crop: { ...percentCrop },
                }
            );

            const imageBeforeEdit = props.image.imageBeforeEdit ? props.image.imageBeforeEdit : props.image;

            const newImageObject = { imageBeforeEdit, ...response.data };
            props.updateImage(newImageObject);
            props.goToImageView();
        } catch (err) {
            setError({ text: "Misslyckades att beskära bilden" });
            setIsLoading(false);
        }
    };

    const showSaveButton = completedCrop && completedCrop.height && completedCrop.width;

    return (
        <div className={styles.wrapper}>
            {isLoading ? <Loading /> : null}
            <ShowError error={error} />
            <div className={styles.ratioWrapper}>
                <SelectAspectRatio selectedAspectRatio={aspect} handleAspectRatioChange={handleAspectRatioChange} />
            </div>
            <div className={styles.cropWrapper}>
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(_, percentCrop) => setCompletedCrop(percentCrop)}
                    aspect={aspect}
                >
                    <img ref={imgRef} alt="" className={styles.image} src={imageUrl} onLoad={onImageLoad} />
                </ReactCrop>
            </div>
            <div className={styles.buttons}>
                <Button buttonText="Avbryt" type="secondary" callback={props.goToImageView} />
                {showSaveButton ? <Button buttonText="Spara" type="primary" callback={handleSave} /> : null}
            </div>
        </div>
    );
}
