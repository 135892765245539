import styles from "./Countdown.module.scss";
import { useEffect, useState } from "react";
import { ISiteV2 } from "../../../../interfaces/Site";
import { ISiteModuleCountdown } from "../../../../interfaces/ISiteModule";
import { calculateCountdown } from "./utils/calculateCountdown";
import { useTranslation } from "../../../../../contexts/LanguageContext";
import { TLanguageCode } from "../../../../interfaces/IContentData";
import { COUNTDOWN_TRANSLATIONS, IDateUnitsTranslation } from "./utils/countdownTranslations";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleCountdown;
    onClick?: () => void;
}

interface ICurrentCountdown {
    days: number;
    hours: number;
    min: number;
    sec: number;
}

export const Countdown = (props: IProps) => {
    const [currentCountdown, setCurrentCountdown] = useState<ICurrentCountdown>(
        calculateCountdown(props.siteModule.countdownDate)
    );
    const { language } = useTranslation();

    const translateFromNativeJs = (translate: keyof IDateUnitsTranslation, locale: TLanguageCode) => {
        if (COUNTDOWN_TRANSLATIONS[locale]) {
            // Switch to this when later version of TS
            // return COUNTDOWN_TRANSLATIONS[locale][translate];
            return (COUNTDOWN_TRANSLATIONS[locale] as IDateUnitsTranslation)[translate];
        } else {
            return ""
        }
    };

    useEffect(() => {
        const updateCountDown = () => {
            setCurrentCountdown(calculateCountdown(props.siteModule.countdownDate));
        };
        const interval = setInterval(updateCountDown, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [props.siteModule.countdownDate]);

    const format = (value: number) => {
        let newValue = String(value);
        return newValue;
    };

    return (
        <div
            className={`${styles[props.site.theme]} ${props.onClick ? styles.editable : ""}`}
            onClick={props.onClick}
        >
            <span className={styles.col}>
                <strong>{format(currentCountdown.days)}</strong>
                <span>{translateFromNativeJs(currentCountdown.days === 1 ? "day" : "days", language)}</span>
            </span>

            <span className={styles.col}>
                <strong>{format(currentCountdown.hours)}</strong>
                <span>
                    <span>{translateFromNativeJs(currentCountdown.hours === 1 ? "hour" : "hours", language)}</span>
                </span>
            </span>

            <span className={styles.col}>
                <strong>{format(currentCountdown.min)}</strong>
                <span>
                    <span>{translateFromNativeJs(currentCountdown.min === 1 ? "minute" : "minutes", language)}</span>
                </span>
            </span>

            <span className={styles.col}>
                <strong>{format(currentCountdown.sec)}</strong>
                <span>
                    <span>{translateFromNativeJs(currentCountdown.sec === 1 ? "second" : "seconds", language)}</span>
                </span>
            </span>
        </div>
    );
};
