import React from "react";
import ActionCurtain from "../../components/actionCurtain/ActionCurtain";
import Button from "../../components/button/Button";
import { LinkButton } from "../../components/button/LinkButton";
import { ISite, ISiteV2 } from "../../interfaces/Site";
import styles from "./QuickStartSaveConfirmation.module.scss";

interface IProps {
    close: () => void;
    site: ISite | ISiteV2;
}

export class QuickStartSaveConfirmation extends React.Component<IProps> {
    render() {
        return (
            <ActionCurtain title={"Välkommen"} close={this.props.close}>
                <div className={styles.wrapper}>
                    <>
                        <p>
                            Välkommen! Nu är din sida sparad. För att visa sidan för dina gäster behöver du välja en
                            adress. Det gör du genom att trycka på välj adress i menyn eller på knappen här nedan. Efter
                            att du valt adress kan du självklart fortsätta att redigera sidan.
                        </p>
                        <div className={styles.buttonPublishWrapper}>
                            <LinkButton
                                type="primary"
                                to={"/redigera/" + this.props.site._id + "/valj-adress"}
                                data-umami-event="select-host-button"
                                data-umami-event-position="welcome-after-registration"
                            >
                                Välj adress
                            </LinkButton>
                        </div>
                        <Button
                            type="secondary"
                            buttonText="Fortsätt redigera"
                            callback={this.props.close}
                            data-test-id="button-in-quick-start-confirmation-continue"
                        />
                    </>
                </div>
            </ActionCurtain>
        );
    }
}
