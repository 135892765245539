import { ISiteModuleGiftRegistry } from "../../interfaces/ISiteModule";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModuleGiftRegistry = (): ISiteModuleGiftRegistry => {
    return {
        siteModuleType: "GiftRegistry",
        ...getNewSiteModuleBasic(false),
        title: {
            contentDataType: "Title",
            text: {},
        },
        text: {
            contentDataType: "RichText",
            richText: {},
        },
        giftRegistry: [],
        showGiftRegistryButton: {
            contentDataType: "Text",
            text: { sv: "Visa önskelista", en: "Show gift registry" },
        },
        reserveButton: {
            contentDataType: "Text",
            text: { sv: "Reservera", en: "Reserve" },
        },
        cancelReservationButton: {
            contentDataType: "Text",
            text: { sv: "Ångra reservation", en: "Cancel reservation" },
        },
        labelReserveQuantity: {
            contentDataType: "Text",
            text: { sv: "Antal", en: "Quantity" },
        },
        labelReservePassword: {
            contentDataType: "Text",
            text: { sv: "Lösenord (för att kunna ångra senare)", en: "Password (if you want to cancel the reservation later)" },
        },
        labelCancelReservationQuantity: {
            contentDataType: "Text",
            text: { sv: "Antal du hade reserverat", en: "Quantity reserved" },
        },
        labelCancelReservationPassword: {
            contentDataType: "Text",
            text: { sv: "Lösenord", en: "Password" },
        },
        statusTextReservedSingular: {
            contentDataType: "Text",
            text: { sv: "Reserverad", en: "Reserved" },
        },
        statusTextQuantity: {
            contentDataType: "Text",
            text: { sv: "Antal", en: "Quantity" },
        },
        statusTextReservedPlural: {
            contentDataType: "Text",
            text: { sv: "reserverade", en: "reserved" },
        },
    };
};

/*
    - "Antal: 8, reserverade: 1", samt "Reserverad"
    - Fyra labels i popup
    */
