import dayjs from "dayjs";
import { IRsvpV2 } from "../ViewRsvp";
import { uniqueFormElementsFromRsvps } from "./uniqueFormElementsFromRsvps";
import { getInRsvpTranslationOneLanguage } from "./getInRsvpTranslation";
import { answerFromFormElementId } from "./textAnswerFromFormElementId";

export const formatRsvpsForExport = (rsvps: IRsvpV2[]) => {
    const questions = uniqueFormElementsFromRsvps(rsvps);
    let headerRow = questions.map((column) => ({
        value: getInRsvpTranslationOneLanguage(column.label) || "",
    }));
    headerRow = [...headerRow, { value: "Datum" }];

    const dataRows = rsvps.map((rsvp) => {

        const date = { type: String, value: dayjs(rsvp.createdAt).format("YYYY-MM-DD") };

        let columns = questions.map((column) => ({
            type: String,
            value: answerFromFormElementId(rsvp, column.id) || "",
        }));

        columns = [...columns, date];

        return columns;
    });

    return [headerRow, ...dataRows];
};