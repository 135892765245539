import React from "react";
import styles from "./DialogPublic.module.scss";
import * as RadixDialog from "@radix-ui/react-dialog";
import { IconClose } from "../../icons/close";

interface IProps {
    children: JSX.Element | JSX.Element[];
    open: boolean;
    handleClose: () => void;
    closableByOutsideClickAndEsc: boolean;
    large?: boolean;
    "data-test-id"?: string;
    padding?: boolean;
}

export const DialogPublic = (props: IProps) => {
    const onOpenChange = (open: boolean) => {
        if (!open) {
            props.handleClose();
        }
    };

    return (
        <RadixDialog.Root open={props.open} onOpenChange={onOpenChange}>
            <RadixDialog.Portal container={document.getElementById("DialogPublicPlacement")}>
                <RadixDialog.Overlay className={styles.Overlay}>
                    <RadixDialog.Content
                        className={`${styles.content} ${props.large ? styles.large : ""} ${props.padding ? styles.padding : ""}`}
                        onPointerDownOutside={(e) => {
                            if (!props.closableByOutsideClickAndEsc) e.preventDefault();
                        }}
                        onEscapeKeyDown={(e) => {
                            if (!props.closableByOutsideClickAndEsc) e.preventDefault();
                        }}
                        onInteractOutside={(e) => {
                            if (!props.closableByOutsideClickAndEsc) e.preventDefault();
                        }}
                        data-test-id={props["data-test-id"]}
                    >
                        <button className={styles.close} onClick={props.handleClose}>
                            <IconClose />
                        </button>
                        {props.children}
                    </RadixDialog.Content>
                </RadixDialog.Overlay>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    );
};
