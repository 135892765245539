import React, { useState } from "react";
import styles from "./EditImage.module.scss";
import { ISiteV2 } from "../../../../interfaces/Site";
import { IContentDataImage, IImageV2 } from "../../../../interfaces/IContentData";
import Button from "../../../../components/button/Button";
import { SelectImageSize } from "./components/SelectImageSize/SelectImageSize";
import { UploadImage } from "../../../components/UploadImage/UploadImage";
import { CropImage } from "./components/CropImage/CropImage";
import { ImgTagFromContentTypeImage } from "../../../components/ImgTagFromContentTypeImage/ImgTagFromContentTypeImage";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataImage;
    updateContentData: (value: IContentDataImage) => void;
    userCanChooseWidth?: boolean;
    close: () => void;
}

enum View {
    UPLOAD_AND_OVERVIEW,
    CROP,
}

export const EditImage = (props: IProps) => {
    const [view, setView] = useState<View>(View.UPLOAD_AND_OVERVIEW);

    const deleteImage = () => {
        const newObj = { ...props.contentData, image: null };
        props.updateContentData(newObj);
        props.close();
    };

    const revertToImageBeforeEdit = () => {
        const imageBeforeEdit = props.contentData.image?.imageBeforeEdit;
        if (imageBeforeEdit) {
            props.updateContentData({ ...props.contentData, image: imageBeforeEdit });
        }
    };

    if (view === View.CROP && props.contentData.image) {
        return (
            <CropImage
                image={props.contentData.image}
                updateImage={(image: IImageV2) => props.updateContentData({ ...props.contentData, image })}
                site={props.site}
                goToImageView={() => setView(View.UPLOAD_AND_OVERVIEW)}
            />
        );
    } else if (view === View.UPLOAD_AND_OVERVIEW) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.uploadImageAndSelectImageSize}>
                    <UploadImage
                        updateContentData={props.updateContentData}
                        site={props.site}
                        contentData={props.contentData}
                        onSuccess={props.close}
                    />
                    {props.userCanChooseWidth ? (
                        <SelectImageSize updateContentData={props.updateContentData} contentData={props.contentData} />
                    ) : null}
                </div>
                {props.contentData.image ? (
                    <div className={styles.currentImageAndEditImage}>
                        <div className={styles.inner}>
                            <div className={styles.imageWrapper}>
                                <ImgTagFromContentTypeImage image={props.contentData.image} />
                            </div>
                            <div className={styles.buttonWrapper}>
                                <Button buttonText="Ta bort bild" type="warning" callback={deleteImage} />
                                <Button buttonText="Beskär bild" type="secondary" callback={() => setView(View.CROP)} />
                            </div>
                        </div>
                        {props.contentData.image.imageBeforeEdit ? (
                            <div className={styles.revertCropButton}>
                                <Button
                                    buttonText="Ångra beskärning"
                                    type="secondary"
                                    callback={revertToImageBeforeEdit}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        );
    } else {
        throw new Error("No view in EditImage");
    }
};
