import { ISiteV2 } from "../../interfaces/Site";
import styles from "./ContentTypeButton.module.scss";
import { useTranslation } from "../../../contexts/LanguageContext";
import { IContentDataText } from "../../interfaces/IContentData";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataText;
    submit: boolean;
    small: boolean;
    onClick?: () => void;
    "data-test-id"?: string;
}

export const ContentTypeButton = (props: IProps) => {
    const { getInTranslation } = useTranslation();
    if (props.submit) {
        return (
            <input
                type="submit"
                className={`${styles[props.site.theme]} ${props.small ? styles.small : ""}`}
                value={getInTranslation(props.contentData.text)}
                data-test-id={props["data-test-id"]}
            />
        );
    } else {
        return (
            <button onClick={props.onClick} type="button" className={`${styles[props.site.theme]} ${props.small ? styles.small : ""}`} data-test-id={props["data-test-id"]}>
                {getInTranslation(props.contentData.text)}
            </button>
        );
    }
};
