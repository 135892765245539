import { ISiteModuleBasic } from "../../interfaces/ISiteModule";
import { randomId } from "../../utils/Random";

export const getNewSiteModuleBasic = (hideInMenu: boolean): ISiteModuleBasic => {
    return {
        id: "CLIENTID" + randomId(),
        hide: false,
        hideInMenu: hideInMenu,
        menuTitle: {},
    };
};
