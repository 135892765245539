import { ContentBox } from "../../../../../../editComponents/ContentBox/ContentBox";
import { Select } from "../../../../../../editComponents/form/Select/Select";
import { ISiteModuleRsvp } from "../../../../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { EmulateContentSurface } from "../../../../../components/EmulateContentSurface/EmulateContentSurface";
import { LanguageTitle } from "../../../../../components/LanguageTitle/LanguageTitle";
import { VerticalGapOnChildren } from "../../../../../components/VerticalGapOnChildren/VerticalGapOnChildren";
import { SelectNumberOfGuestsEdit } from "../../../SelectNumberOfGuests/SelectNumberOfGuestsEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleRsvp, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
}

export const RsvpMaxNumberOfGuestsEdit = (props: IProps) => {
    const maxNumberOfGuestsOptions = [...Array(5)].map((_, index) => ({
        title: index === 0 ? "1 gäst" : index + 1 + " gäster",
        value: index + 1 + "",
    }));

    const handleChange = (value: string) => {
        const valueAsNumber = parseInt(value);
        props.updateSiteModuleProperty("maxNumberOfGuests", valueAsNumber);
    };

    const value = props.siteModule.maxNumberOfGuests + "";

    return (
        <>
            <ContentBox padding={true} title="Välja antal gäster">
                <p>
                    Gästen som skickar formuläret väljer först antalet gäster i sällskapet att OSA för. Här kan du
                    redigera max antal gäster personen kan OSA för samtidigt. Det går alltid att skicka OSA-formuläret
                    flera gånger.
                </p>
                <div>
                    <Select
                        id="maxNumberOfGuests"
                        label="Max antal gäster"
                        options={maxNumberOfGuestsOptions}
                        value={value}
                        handleChange={handleChange}
                    />
                </div>
                <p>Gästen väljer antalet gäster i gränssnittet nedan. Tryck på texten för att ändra den.</p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <EmulateContentSurface site={props.site}>
                                <SelectNumberOfGuestsEdit
                                    updateSiteModuleProperty={props.updateSiteModuleProperty}
                                    site={props.site}
                                    siteModule={props.siteModule}
                                    selectedNumberOfGuests={0}
                                    language={language.languageCode}
                                />
                            </EmulateContentSurface>
                        </div>
                    ))}
                </VerticalGapOnChildren>
            </ContentBox>
        </>
    );
};
