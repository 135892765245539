import { useState } from "react";
import { ISiteModuleTextAndImageList, ISiteModuleTextAndImageListItem } from "../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../interfaces/Site";
import styles from "./SiteModuleTextAndImageListItem.module.scss";
import { ContentTypeImageEdit } from "../../../contentTypes/ContentTypeImage/ContentTypeImageEdit";
import { IContentDataImage, IContentDataRichText, IContentDataTitle } from "../../../interfaces/IContentData";
import { ContentTypeRichTextEdit } from "../../../contentTypes/ContentTypeRichText/ContentTypeRichTextEdit";
import { ContentTypeTitleEdit } from "../../../contentTypes/ContentTypeTitle/ContentTypeTitleEdit";
import { ItemActionBar } from "../../../modules/ItemActionBar/ItemActionBar";
import { getNewSiteModuleTextAndImageListItem } from "../../utils/getNewSiteModuleTextAndImageList";

interface IProps {
    site: ISiteV2;
    listItem: ISiteModuleTextAndImageListItem;
    list: ISiteModuleTextAndImageListItem[];
    updateSiteModule: <SiteModule extends ISiteModuleTextAndImageList>(
        updater: (siteModule: SiteModule) => void
    ) => void;
}

export function SiteModuleTextAndImageListItemEdit(props: IProps) {
    const [isHighlited, setIsHighlited] = useState(false);

    const updateListItemProperty = <List extends ISiteModuleTextAndImageListItem, Property extends keyof List>(
        property: keyof List,
        value: List[Property]
    ) => {
        props.updateSiteModule((siteModule) => {
            const thisItem = siteModule.list.find((loopItem) => loopItem.id === props.listItem.id) as List;
            thisItem[property] = value;
        });
    };

    const updateList = (newList: ISiteModuleTextAndImageListItem[]) => {
        props.updateSiteModule((siteModule) => {
            siteModule.list = newList;
        });
    };

    return (
        <div className={`${styles[props.site.theme]} ${isHighlited ? styles.highlighted : ""}`}>
            <div className={styles.imageWrapper}>
                <ContentTypeImageEdit
                    contentData={props.listItem.image}
                    site={props.site}
                    updateContentData={(value: IContentDataImage) => updateListItemProperty("image", value)}
                    sizes="(min-width: 750px) 600px, 100vw"
                />
            </div>
            <div className={styles.textAndHeadingContentWrapper}>
                <ContentTypeTitleEdit
                    tag="h3"
                    contentData={props.listItem.title}
                    site={props.site}
                    updateContentData={(value: IContentDataTitle) => updateListItemProperty("title", value)}
                />
                <div className={styles.textContentWrapper}>
                    <ContentTypeRichTextEdit
                        contentData={props.listItem.text}
                        site={props.site}
                        updateContentData={(value: IContentDataRichText) => updateListItemProperty("text", value)}
                    />
                </div>
            </div>
            <ItemActionBar<ISiteModuleTextAndImageListItem>
                getNewItem={getNewSiteModuleTextAndImageListItem}
                updateList={updateList}
                itemName="Ruta"
                items={props.list}
                item={props.listItem}
                setHighligtItem={setIsHighlited}
            />
        </div>
    );
}
