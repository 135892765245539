import { ISiteV2, ISiteV2Rest } from "../interfaces/Site";

export const transformSiteV2In = (data: ISiteV2Rest) => {
    let site: ISiteV2 = {
        ...data
    };
    return site;
};

export const transformSiteV2Out = (site: ISiteV2) => {
    let data: Partial<ISiteV2> = {
        siteModules: site.siteModules,
        fonts: site.fonts,
        theme: site.theme,
        colorScheme: site.colorScheme,
        siteGraphic: site.siteGraphic,
        mostRecentSelectedDesignTemplate: site.mostRecentSelectedDesignTemplate,
        formRecipients: site.formRecipients,
        availableLanguages: site.availableLanguages
    };
    return data;
};
