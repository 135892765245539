import { ISiteModuleTextAndImageList, ISiteModuleTextAndImageListItem } from "../../interfaces/ISiteModule";
import { randomId } from "../../utils/Random";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModuleTextAndImageList = (): ISiteModuleTextAndImageList => {
    return {
        siteModuleType: "TextAndImageList",
        ...getNewSiteModuleBasic(false),
        title: {
            contentDataType: "Title",
            text: {},
        },
        list: [getNewSiteModuleTextAndImageListItem()],
    };
};

export const getNewSiteModuleTextAndImageListItem = (): ISiteModuleTextAndImageListItem => {
    return {
        id: "CLIENTID-LIST-ITEM" + randomId(),
        title: {
            contentDataType: "Title",
            text: {},
        },
        text: {
            contentDataType: "RichText",
            richText: {},
        },
        image: {
            contentDataType: "Image",
            image: null,
        },
    };
};
