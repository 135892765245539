import * as RadixSwitch from "@radix-ui/react-switch";
import styles from "./Switch.module.scss";
import { Label } from "../Label/Label";

interface IProps {
    handleChange: (value: boolean) => void;
    name: string;
    id: string;
    value: boolean;
    label: string;
    labelPosition?: "RIGHT" | "LEFT";
}
export const Switch = (props: IProps) => {
    return (
        <div className={`${props.labelPosition === "RIGHT" ? styles.labelRight : ""} ${styles.wrapper}`}>
            <Label htmlFor={props.id} className={styles.label}>
                {props.label}
            </Label>
            <RadixSwitch.Root
                id={props.id}
                name={props.name}
                onCheckedChange={props.handleChange}
                checked={props.value}
                className={styles.Root}
            >
                <RadixSwitch.Thumb className={styles.Thumb} />
            </RadixSwitch.Root>
        </div>
    );
};
