import React from "react";
import styles from "./DialogTop.module.scss";
import { IconClose } from "../../icons/close";
import { IconBack } from "../../icons/back";
import { Tabs } from "../Tabs/Tabs";

interface IProps {
    close?: () => void;
    title: string;
    back?: () => void;
    backText?: string;
    tabs?: JSX.Element;
}

export const DialogTop = (props: IProps) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.topRow}>
                <div className={styles.left}>
                    {props.back ? (
                        <button className={styles.back} onClick={props.back}>
                            <span>
                                <IconBack />
                            </span>
                            <span>{props.backText}</span>
                        </button>
                    ) : null}
                    <div className={styles.title}>{props.title}</div>
                </div>
                {props.close ? (
                    <button className={styles.close} onClick={props.close}>
                        <span>Stäng</span>
                        <span>
                            <IconClose />
                        </span>
                    </button>
                ) : null}
            </div>
            {props.tabs ? <Tabs className={styles.tabsWrapper}>{props.tabs}</Tabs> : null}
        </div>
    );
};
