import Axios from "axios";
import React, { useContext, useState } from "react";
import { Updater } from "use-immer";
import { giftRegistryReservedQuantity } from "../../utils/GiftRegistryReservedQuantity";
import { QuantityAndReservedQuantity } from "../QuantityAndReservedQuantity/QuantityAndReservedQuantity";
import styles from "./GiftRegistryCancelReservation.module.scss";
import { IContentDataGiftRegistryItem, ISiteModuleGiftRegistry } from "../../../../../../interfaces/ISiteModule";
import { IGiftRegistryReservations, ISiteV2 } from "../../../../../../interfaces/Site";
import Loading from "../../../../../../components/Loading/Loading";
import FormWrapper from "../../../../../../components/form/FormWrapper/FormWrapper";
import TextField from "../../../../../../components/form/TextField/TextField";
import { useTranslation } from "../../../../../../../contexts/LanguageContext";
import { ContentTypeButton } from "../../../../../../contentTypes/ContentTypeButton/ContentTypeButton";
import { ContentTypeText } from "../../../../../../contentTypes/ContentTypeText/ContentTypeText";
import { EditAvailableContext } from "../../../../../../../contexts/EditAvailableContext";
import { DialogPublic } from "../../../../../../components/DialogPublic/DialogPublic";
import { IErrorEdit, IErrorPublic } from "../../../../../../interfaces/IErrorV2";
import { ErrorDialogPublic } from "../../../../../../components/ErrorDialogPublic/ErrorDialogPublic";
import { ErrorDialogEdit } from "../../../../../../components/ErrorDialogEdit/ErrorDialogEdit";

interface IProps {
    close: () => void;
    giftRegistryItem: IContentDataGiftRegistryItem;
    site: ISiteV2;
    giftRegistryReservations: IGiftRegistryReservations[];
    setGiftRegistryReservations: Updater<IGiftRegistryReservations[]>;
    siteModule: ISiteModuleGiftRegistry;
}

export function GiftRegistryCancelReservation(props: IProps) {
    const [quantity, setQuantity] = useState<number | "">(1);
    const [password, setPassword] = useState<string | "">();
    const [isLoading, setIsLoading] = useState(false);
    const [errorPublic, setErrorPublic] = useState<IErrorPublic | null>(null);
    const [errorEdit, setErrorEdit] = useState<IErrorEdit | null>(null);
    const { getInTranslation } = useTranslation();
    const isEditAvailable = useContext(EditAvailableContext);

    const handleSubmit = () => {
        if (isEditAvailable) {
            setErrorEdit({
                description:
                    "Du kan inte reservera eller ångra reservationer från granska-läget. Du måste gå till den publika adressen, den som dina gäster besöker.",
            });
            return;
        }
        setIsLoading(true);

        if (hideQuantityTextField()) {
            setQuantity(1);
        }

        Axios.delete(
            `${process.env.REACT_APP_API_URL}/api/gift-registry/${props.site._id}/${props.siteModule.id}/${props.giftRegistryItem.id}/v2`,
            {
                data: {
                    quantity,
                    password,
                },
            }
        )
            .then((response) => {
                props.setGiftRegistryReservations((items) => (items = response.data.giftRegistryReservations));
                setIsLoading(false);
                props.close();
            })
            .catch((err) => {
                setErrorPublic({
                    description: { sv: "Misslyckades att ta bort reservation", en: "Failed to remove reservation" },
                    response: err.response,
                });
                setIsLoading(false);
            });
    };

    const handleQuantityChange = (value: string): void => {
        value ? setQuantity(parseInt(value)) : setQuantity("");
    };

    const handlePasswordChange = (value: string): void => setPassword(value);

    const maxQuantityCancelable = () =>
        giftRegistryReservedQuantity(props.giftRegistryItem, props.giftRegistryReservations);

    const hideQuantityTextField = () => maxQuantityCancelable() === 1 && props.giftRegistryItem.quantity === 1;
    return (
        <DialogPublic padding={true} closableByOutsideClickAndEsc={true} open={true} handleClose={props.close}>
            <>
                {isLoading && <Loading />}
                <ErrorDialogPublic error={errorPublic} />
                <ErrorDialogEdit error={errorEdit} />
                <div className={styles.wrapper}>
                    <h3>{getInTranslation(props.giftRegistryItem.title.text)}</h3>
                    <QuantityAndReservedQuantity
                        site={props.site}
                        siteModule={props.siteModule}
                        giftRegistryReservations={props.giftRegistryReservations}
                        className={styles.quantity}
                        giftRegistryItem={props.giftRegistryItem}
                    />
                    <FormWrapper submit={handleSubmit} disabled={false}>
                        <div className={styles.textFieldsWrapper}>
                            <div className={styles.textFieldPassword}>
                                <TextField
                                    label={
                                        <ContentTypeText
                                            site={props.site}
                                            contentData={props.siteModule.labelCancelReservationPassword}
                                            tag={"span"}
                                        />
                                    }
                                    name="password"
                                    placeholder=""
                                    id="reserveGiftPassword"
                                    onChangeCallback={handlePasswordChange}
                                    value={password}
                                    required={true}
                                />
                            </div>
                            {!hideQuantityTextField() && (
                                <div className={styles.textFieldQuantity}>
                                    <TextField
                                        label={
                                            <ContentTypeText
                                                site={props.site}
                                                contentData={props.siteModule.labelCancelReservationQuantity}
                                                tag={"span"}
                                            />
                                        }
                                        number={true}
                                        name="quantity"
                                        id="reserveGiftQuantity"
                                        onChangeCallback={handleQuantityChange}
                                        value={quantity + ""} // + "" converts to string
                                        min={1}
                                        required={true}
                                        max={maxQuantityCancelable()}
                                    />
                                </div>
                            )}
                        </div>
                        <ContentTypeButton
                            submit={true}
                            site={props.site}
                            contentData={props.siteModule.cancelReservationButton}
                            small={false}
                        />
                    </FormWrapper>
                </div>
            </>
        </DialogPublic>
    );
}
