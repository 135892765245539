import Button from "../../../../../../components/button/Button";
import styles from "./EditFormElementOptions.module.scss";
import { IFormElementOption, IFormElementSingleSelect, TFormElement } from "../../../../../../interfaces/FormElement";
import { useTranslation } from "../../../../../../../contexts/LanguageContext";
import { randomId } from "../../../../../../utils/Random";
import { IconDelete } from "../../../../../../icons/delete";
import { Input } from "../../../../../../editComponents/form/Input/Input";
import { Label } from "../../../../../../editComponents/form/Label/Label";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { TLanguageCode } from "../../../../../../interfaces/IContentData";
import { languageFromLanguageCode } from "../../../../../../../utils/LanguageHelpers";

interface IProps {
    formElement: IFormElementSingleSelect;
    site: ISiteV2;
    updateFormElement: (updater: (draft: TFormElement) => void) => void;
}

export function EditFormElementOptions(props: IProps) {
    const { getInTranslation, setInTranslation } = useTranslation();

    const updateEditOptionText = (text: string, option: IFormElementOption, language: TLanguageCode) => {
        props.updateFormElement((draft) => {
            if (draft.formElementType !== "SingleSelect") {
                throw new Error("Can only edit options on single select");
            }
            const draftOption = draft.options.find((item) => item.id === option.id);
            if (!draftOption) {
                throw new Error("No option with id " + option.id);
            }
            draftOption.text = setInTranslation(draftOption.text, text, language);
        });
    };

    const addOption = () => {
        props.updateFormElement((draft) => {
            if (draft.formElementType !== "SingleSelect") {
                throw new Error("Can only edit options on single select");
            }
            draft.options.push({
                id: "FORM-OPTION-" + randomId(),
                text: {},
            });
        });
    };

    const deleteOption = (option: IFormElementOption) => {
        props.updateFormElement((draft) => {
            if (draft.formElementType !== "SingleSelect") {
                throw new Error("Can only edit options on single select");
            }
            const index = draft.options.findIndex((item) => item.id === option.id);
            draft.options.splice(index, 1);
        });
    };

    const hasMultipleLanguages = props.site.availableLanguages.length !== 1;

    const label = (languageCode: TLanguageCode, index: number) => {
        if (!hasMultipleLanguages) return undefined;

        return "Svarsalternativ " + (index + 1) + " på " + languageFromLanguageCode(languageCode);
    };

    return (
        <div className={styles.wrapper}>
            {props.formElement.options.length ? (
                <>
                    {hasMultipleLanguages ? null : (
                        <Label htmlFor={null} className={styles.label}>
                            Svarsalternativ
                        </Label>
                    )}
                    <div className={`${styles.list} ${hasMultipleLanguages ? styles.hasMultipleLanguages : ""}`}>
                        {props.formElement.options.map((option, index) => (
                            <div
                                key={option.id}
                                className={`${styles.listItem} ${
                                    hasMultipleLanguages ? styles.hasMultipleLanguages : ""
                                }`}
                            >
                                <div className={styles.inputWrapper}>
                                    {props.site.availableLanguages.map((language) => (
                                        <Input
                                            key={language.languageCode}
                                            name={"editOption" + option.id + language.languageCode}
                                            required={true}
                                            label={label(language.languageCode, index)}
                                            id={"editOption" + option.id + language.languageCode}
                                            handleChange={(value: string) =>
                                                updateEditOptionText(value, option, language.languageCode)
                                            }
                                            value={getInTranslation(option.text, language.languageCode)}
                                        />
                                    ))}
                                </div>
                                <div className={styles.delete}>
                                    <button onClick={() => deleteOption(option)} className={styles.delete}>
                                        {IconDelete()}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className={styles.noAlternativesPadding}>Inga svarsalternativ</div>
            )}
            <div className={styles.addButton}>
                <Button type="secondary" buttonText="Lägg till svarsalternativ" callback={addOption} submit={false} />
            </div>
        </div>
    );
}
