import { Dialog } from "../../editComponents/Dialog/Dialog";
import { DialogTop } from "../../editComponents/DialogTop/DialogTop";
import Button from "../button/Button";
import { LinkButton } from "../button/LinkButton";
import styles from "./QuickStartAlreadyLoggedInDialog.module.scss";

interface IProps {
    siteId: string;
    handleClose: () => void;
}

export const QuickStartAlreadyLoggedInDialog = (props: IProps) => {
    return (
        <Dialog small={true} open={true} handleClose={props.handleClose} closableByOutsideClickAndEsc={true}>
            <DialogTop title="Har du kommit rätt?" close={props.handleClose} />
            Du är redan inloggad hos oss. Här skapar du en ny sida och ett nytt konto. Har du kommit rätt?
            <div className={styles.buttons}>
                <Button buttonText="Fortsätt" type="secondary" callback={props.handleClose} />
                <LinkButton type="primary" to={"/redigera/" + props.siteId}>
                    Gå till min sida
                </LinkButton>
            </div>
        </Dialog>
    );
};
