import { Updater } from "use-immer";
import { ContentBox } from "../../../editComponents/ContentBox/ContentBox";
import { IFormRecipients, ISiteV2 } from "../../../interfaces/Site";
import styles from "./FormRecipientsEdit.module.scss";
import { FormRecipientsListEdit } from "./components/FormRecipientsListEdit/FormRecipientsListEdit";

interface IProps {
    site: ISiteV2;
    formId: string;
    updateSite: Updater<ISiteV2>;
}

export const FormRecipientsEdit = (props: IProps) => {

    let currentFormRecipients: IFormRecipients | undefined = props.site.formRecipients.find(
        (item) => item.formId === props.formId
    );

    if (!currentFormRecipients) {
        currentFormRecipients = {
            formId: props.formId,
            recipients: [],
        };
    }

    const updateformRecipients = (updater: (draft: IFormRecipients) => void) => {
        return props.updateSite((draft) => {
            const currentFormRecipients = draft.formRecipients.find((form) => form.formId === props.formId);
            if (currentFormRecipients) {
                updater(currentFormRecipients);
            } else {
                const newFormRecipients = {
                    formId: props.formId,
                    recipients: [],
                };
                updater(newFormRecipients);
                draft.formRecipients.push(newFormRecipients);
            }
        });
    };

    return (
        <>
            <ContentBox padding={true} className={styles.wrapper} title="Mottagare">
                <p>
                    Välj vilken e-postadress du vill skicka formuläret till. <strong>Testa alltid formuläret när du har ändrat
                    e-postadress så du vet att dina gästers information kommer fram!</strong>
                </p>
                <FormRecipientsListEdit
                    formRecipients={currentFormRecipients}
                    updateformRecipients={updateformRecipients}
                />
            </ContentBox>
        </>
    );
};
