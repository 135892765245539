import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataForm } from "../../interfaces/IContentData";
import { FormElementShortText } from "./components/FormElementShortText/FormElementShortText";
import { IFormElementOption, TFormElement } from "../../interfaces/FormElement";
import { FormElementSingleSelect } from "./components/FormElementSingleSelect/FormElementSingleSelect";
import styles from "./ContentTypeForm.module.scss";
import { VisibilityWithFormCondition } from "./components/VisibilityWithFormCondition/VisibilityWithFormCondition";
import { IFormData } from "../../interfaces/IFormData";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataForm;
    formPartIdentifier: string;
    handleValueChange: (formElement: TFormElement, value: string | IFormElementOption) => void;
    formData: IFormData;
}

export const ContentTypeForm = (props: IProps) => {
    return (
        <div className={styles[props.site.theme]}>
            {props.contentData.formElements.map((formElement) => (
                <VisibilityWithFormCondition key={formElement.id} formElement={formElement} formData={props.formData}>
                    <>
                        {formElement.formElementType === "ShortText" ? (
                            <div className={styles.halfWidth}>
                                <FormElementShortText
                                    handleValueChange={props.handleValueChange}
                                    site={props.site}
                                    formElement={formElement}
                                    formPartIdentifier={props.formPartIdentifier}
                                    value={props.formData.formData[formElement.id]?.value || ""}
                                />
                            </div>
                        ) : null}
                        {formElement.formElementType === "SingleSelect" ? (
                            <div className={styles.fullWidth}>
                                <FormElementSingleSelect
                                    handleValueChange={props.handleValueChange}
                                    site={props.site}
                                    formElement={formElement}
                                    formPartIdentifier={props.formPartIdentifier}
                                    formElementData={props.formData.formData[formElement.id]}
                                />
                            </div>
                        ) : null}
                    </>
                </VisibilityWithFormCondition>
            ))}
        </div>
    );
};
