import { Component, ErrorInfo, ReactNode } from "react";
import { logError } from "../../utils/LogError";
import { ISite, ISiteV2 } from "../../interfaces/Site";
import styles from "./ErrorBoundary.module.scss";

interface Props {
    children: ReactNode;
    site?: ISiteV2 | ISite;
    logErrorSource: string;
    visitorsErrorMessage: string;
}

interface State {
    hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logError("clientErrorBoundary." + this.props.logErrorSource, error, 3, errorInfo, this.props.site);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div className={styles.wrapper}>
                    <h1>Ett fel uppstod</h1>
                    <p>{this.props.visitorsErrorMessage}</p>
                </div>
            );
        }

        return this.props.children;
    }
}
