import { IContentDataGiftRegistryItem } from "../../../../../interfaces/ISiteModule";
import { IGiftRegistryReservations } from "../../../../../interfaces/Site";


export const giftRegistryReservedQuantity = (
    giftRegistryItem: IContentDataGiftRegistryItem,
    giftRegistryReservations: IGiftRegistryReservations[] | null
): number => {
    const id = giftRegistryItem.id;
    if (giftRegistryReservations && giftRegistryReservations.length) {
        const giftRegistryReservation = giftRegistryReservations.find(
            (giftRegistryReservation) => giftRegistryReservation.giftRegistryId === id
        );
        if (giftRegistryReservation && giftRegistryReservation.quantityReserved) {
            return giftRegistryReservation.quantityReserved;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
};
