import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataRichText, TLanguageCode } from "../../interfaces/IContentData";
import { RichTextEditor } from "../../components/RichTextEditor/RichTextEditor";
import styles from "./ContentTypeRichText.module.scss";
import { useTranslation } from "../../../contexts/LanguageContext";
import { TranslateModePreview } from "../../editComponents/TranslateModePreview/TranslateModePreview";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataRichText;
    updateContentData: (value: IContentDataRichText) => void;
    language?: TLanguageCode;
}

export const ContentTypeRichTextEdit = (props: IProps) => {
    const { getInTranslation, setInTranslation, language } = useTranslation();

    const handleChange = (value: string) => {
        props.updateContentData({
            ...props.contentData,
            richText: setInTranslation(props.contentData.richText, value, props.language),
        });
    };
    
    const alwaysHideTranslateModeBecauseNotOnPage = !!props.language;

    return (
        <>
            <div className={styles.wrapper}>
                <RichTextEditor
                    initialValue={getInTranslation(props.contentData.richText, props.language)}
                    onContentChange={handleChange}
                    key={props.language || language} // Force rerender when changing language
                />
            </div>
            {alwaysHideTranslateModeBecauseNotOnPage ? null : (
                <TranslateModePreview
                    languages={props.site.availableLanguages}
                    type="RTE"
                    translation={props.contentData.richText}
                />
            )}
        </>
    );
};
