import { createContext, useContext, useState } from "react";
import { TLanguageCode, TTranslation } from "../generator/interfaces/IContentData";

interface ILanguageContext {
    setOverrideLanguage: (language: TLanguageCode) => void;
    overrideLanguage: null | TLanguageCode;
    language: TLanguageCode;
    translateMode: boolean;
    setTranslateMode: (translateMode: boolean) => void;
}

const LanguageContext = createContext<ILanguageContext | undefined>(undefined);

function LanguageProvider(props: {
    children: JSX.Element | JSX.Element[];
    defaultLanguage: TLanguageCode;
}): JSX.Element {
    const [overrideLanguage, setOverrideLanguage] = useState<TLanguageCode | null>(null);
    const [translateMode, setTranslateMode] = useState<boolean>(false);
    const language = overrideLanguage || props.defaultLanguage;

    return (
        <LanguageContext.Provider
            value={{ setOverrideLanguage, overrideLanguage, language, translateMode, setTranslateMode }}
            {...props}
        />
    );
}

interface IUseTranslation {
    setOverrideLanguage: (language: TLanguageCode) => void;
    language: TLanguageCode;
    getInTranslation: (translation: TTranslation, language?: TLanguageCode) => string;
    getInTranslationWithFallback: (translation: TTranslation, language?: TLanguageCode) => string;
    setInTranslation: (translation: TTranslation, text: string, language?: TLanguageCode) => TTranslation;
    setTranslateMode: (translateMode: boolean) => void;
    translateMode: boolean;
}

function useTranslation(): IUseTranslation {
    const context = useContext(LanguageContext);
    if (context === undefined) {
        throw new Error("useTranslation must be used within a LanguageProvider");
    }
    const language = context.language;
    const setOverrideLanguage = context.setOverrideLanguage;
    const translateMode = context.translateMode;
    const setTranslateMode = context.setTranslateMode;

    const setInTranslation = (translation: TTranslation, text: string, specificLanguage?: TLanguageCode) => {
        return { ...translation, [specificLanguage || language]: text };
    };

    const getInTranslation = (translation: TTranslation, specificLanguage?: TLanguageCode): string => {
        return translation[specificLanguage || language] || "";
    };

    const getInTranslationWithFallback = (translation: TTranslation): string => {
        let text: string | undefined = getInTranslation(translation);

        const getFallbackText = (translation: TTranslation | undefined) => {
            if (!translation) return "";
            if (translation.sv) return translation.sv;
            if (translation.en) return translation.en;
            const allLanguages = Object.keys(translation) as TLanguageCode[];

            if (!allLanguages.length) return "";
            return translation[allLanguages[0]];
        };

        if (!text) {
            text = getFallbackText(translation);
        }
        return text || "";
    };

    return {
        setOverrideLanguage,
        language,
        getInTranslation,
        getInTranslationWithFallback,
        setInTranslation,
        translateMode,
        setTranslateMode,
    };
}

export { LanguageProvider, useTranslation };
