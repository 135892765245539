import React from "react";
import { IModule } from "../interfaces/Module";
import { IImage, ISite } from "../interfaces/Site";

interface IProps {
    site: ISite;
    moduleData: IModule;
    updateProperty: any;
    editMode: boolean;
    editAvailable?: boolean;
    addOrUpdateImageToSite: (image: IImage) => void;
    updateReceiverMail?: any;
}

export class GeneratorModule extends React.Component<IProps> {}

export default GeneratorModule;
