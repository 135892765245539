import styles from "./GiftRegistryItemsEditListItem.module.scss";
import { IContentDataGiftRegistryItem } from "../../../../../../interfaces/ISiteModule";
import { ImgTagFromContentTypeImage } from "../../../../../../contentTypes/components/ImgTagFromContentTypeImage/ImgTagFromContentTypeImage";
import { IconMoveUpContentModule } from "../../../../../../icons/moveUpContentModule";
import { IconMoveDownContentModule } from "../../../../../../icons/moveDownContentModule";
import { IconDelete } from "../../../../../../icons/delete";
import { useState } from "react";
import { IconClose } from "../../../../../../icons/close";
import { useTranslation } from "../../../../../../../contexts/LanguageContext";
import { ContentBox } from "../../../../../../editComponents/ContentBox/ContentBox";

interface IProps {
    giftRegistryItem: IContentDataGiftRegistryItem;
    giftRegistryItems: IContentDataGiftRegistryItem[];
    handleEditItem: () => void;
    updateList: (list: IContentDataGiftRegistryItem[]) => void;
}

export function GiftRegistryItemsEditListItem(props: IProps) {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const { getInTranslation } = useTranslation();
    
    const itemIsLast = (items: IContentDataGiftRegistryItem[], item: IContentDataGiftRegistryItem) => {
        const index = items.indexOf(item);
        return index === items.length - 1;
    };

    const itemIsFirst = (items: IContentDataGiftRegistryItem[], item: IContentDataGiftRegistryItem) => {
        const index = items.indexOf(item);
        return index === 0;
    };

    const moveItem = (indexAdjustment: number) => {
        const collection = [...props.giftRegistryItems];
        const old_index = collection.indexOf(props.giftRegistryItem);
        let new_index = old_index + indexAdjustment;

        if (new_index < 0) new_index = 0;
        if (new_index >= collection.length) new_index = collection.length;

        collection.splice(new_index, 0, collection.splice(old_index, 1)[0]);
        props.updateList(collection);
    };

    const deleteItem = () => {
        const index = props.giftRegistryItems.indexOf(props.giftRegistryItem);
        const collection = [...props.giftRegistryItems];
        collection.splice(index, 1);
        props.updateList(collection);
    };

    return (
        <ContentBox className={styles.wrapper} selectable={false} padding={false}>
            <div className={styles.left} onClick={props.handleEditItem}>
                {props.giftRegistryItem.image.image ? (
                    <div className={styles.thumbnail}>
                        <ImgTagFromContentTypeImage silentError={true} image={props.giftRegistryItem.image.image} sizes="60px" />
                    </div>
                ) : null}

                <div className={styles.titleAndDescription}>
                    <div className={styles.title}>{getInTranslation(props.giftRegistryItem.title.text)}</div>
                    <div className={styles.description}>
                        {getInTranslation(props.giftRegistryItem.description.text)}
                    </div>
                </div>
            </div>
            {showDeleteConfirmation ? (
                <div className={styles.buttons}>
                    <div className={styles.questionText}>Ta bort?</div>
                    <button onClick={() => deleteItem()}>
                        {IconDelete()}
                        <span>Ja, ta bort</span>
                    </button>
                    <button onClick={() => setShowDeleteConfirmation(false)}>
                        {IconClose()}
                        <span>Nej, avbryt</span>
                    </button>
                </div>
            ) : (
                <div className={styles.buttons}>
                    <button onClick={() => setShowDeleteConfirmation(true)} className={styles.delete}>
                        {IconDelete()}
                        <span>Ta bort</span>
                    </button>
                    {!itemIsFirst(props.giftRegistryItems, props.giftRegistryItem) ? (
                        <button onClick={() => moveItem(-1)}>{IconMoveUpContentModule()}</button>
                    ) : null}
                    {!itemIsLast(props.giftRegistryItems, props.giftRegistryItem) ? (
                        <button onClick={() => moveItem(1)}>{IconMoveDownContentModule()}</button>
                    ) : null}
                </div>
            )}
        </ContentBox>
    );
}
