import TextField from "../../../../../../components/form/TextField/TextField";
import { ContentTypeTextEdit } from "../../../../../../contentTypes/ContentTypeText/ContentTypeTextEdit";
import { ContentBox } from "../../../../../../editComponents/ContentBox/ContentBox";
import { IContentDataText } from "../../../../../../interfaces/IContentData";
import { ISiteModuleGiftRegistry } from "../../../../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { EmulateContentSurface } from "../../../../../components/EmulateContentSurface/EmulateContentSurface";
import { LanguageTitle } from "../../../../../components/LanguageTitle/LanguageTitle";
import { VerticalGapOnChildren } from "../../../../../components/VerticalGapOnChildren/VerticalGapOnChildren";
import styles from "./GiftRegistryLabelsEdit.module.scss";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleGiftRegistry;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleGiftRegistry, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
}

export const GiftRegistryLabelsEdit = (props: IProps) => {
    return (
        <>
            <ContentBox padding={true} className={styles.wrapper} title="Formulärfält">
                <p>Formulärfälten där gästen reserverar, tryck på texten för att ändra den.</p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <EmulateContentSurface site={props.site}>
                                <div className={styles.labelsWrappers}>
                                    <TextField
                                        label={
                                            <ContentTypeTextEdit
                                                site={props.site}
                                                contentData={props.siteModule.labelReserveQuantity}
                                                updateContentData={(value: IContentDataText) =>
                                                    props.updateSiteModuleProperty("labelReserveQuantity", value)
                                                }
                                                placeholder={"Ingen text"}
                                                tag={"div"}
                                                language={language.languageCode}
                                            />
                                        }
                                        disabled={true}
                                        number={true}
                                        name="labelReserveQuantityDummy"
                                        id="labelReserveQuantityDummy"
                                        onChangeCallback={() => {}}
                                    />
                                    <TextField
                                        label={
                                            <ContentTypeTextEdit
                                                site={props.site}
                                                contentData={props.siteModule.labelReservePassword}
                                                updateContentData={(value: IContentDataText) =>
                                                    props.updateSiteModuleProperty("labelReservePassword", value)
                                                }
                                                placeholder={"Ingen text"}
                                                tag={"div"}
                                                language={language.languageCode}
                                            />
                                        }
                                        disabled={true}
                                        number={true}
                                        name="labelReservePasswordDummy"
                                        id="labelReservePasswordDummy"
                                        onChangeCallback={() => {}}
                                    />
                                </div>
                            </EmulateContentSurface>
                        </div>
                    ))}
                </VerticalGapOnChildren>

                <p>Formulärfälten där gästen ångrar sin reservation, tryck på texten för att ändra den.</p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <EmulateContentSurface site={props.site}>
                                <div className={styles.labelsWrappers}>
                                    <TextField
                                        label={
                                            <ContentTypeTextEdit
                                                site={props.site}
                                                contentData={props.siteModule.labelCancelReservationQuantity}
                                                updateContentData={(value: IContentDataText) =>
                                                    props.updateSiteModuleProperty(
                                                        "labelCancelReservationQuantity",
                                                        value
                                                    )
                                                }
                                                placeholder={"Ingen text"}
                                                tag={"div"}
                                                language={language.languageCode}
                                            />
                                        }
                                        disabled={true}
                                        number={true}
                                        name="labelCancelReservationQuantityDummy"
                                        id="labelCancelReservationQuantityDummy"
                                        onChangeCallback={() => {}}
                                    />
                                    <TextField
                                        label={
                                            <ContentTypeTextEdit
                                                site={props.site}
                                                contentData={props.siteModule.labelCancelReservationPassword}
                                                updateContentData={(value: IContentDataText) =>
                                                    props.updateSiteModuleProperty(
                                                        "labelCancelReservationPassword",
                                                        value
                                                    )
                                                }
                                                placeholder={"Ingen text"}
                                                tag={"div"}
                                                language={language.languageCode}
                                            />
                                        }
                                        disabled={true}
                                        number={true}
                                        name="labelCancelReservationPasswordDummy"
                                        id="labelCancelReservationPasswordDummy"
                                        onChangeCallback={() => {}}
                                    />
                                </div>
                            </EmulateContentSurface>
                        </div>
                    ))}
                </VerticalGapOnChildren>
            </ContentBox>
        </>
    );
};
