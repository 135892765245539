// This has a sibling in back end (products.ts)

import { Origin } from "../types/origin";

export const productsWedding = {
    subdomain: {
        name: "Publicering med subdomän",
        price: 59900,
        months: 18
    },
    domain_name: {
        name: "Publicering och domännamn",
        price: 119900,
        months: 12
    },
    domain_name_other_registrar: {
        name: "Publicering med eget domännamn",
        price: 119900,
        months: 12
    },
    domain_name_upgrade_from_subdomain: {
        name: "Nytt domännamn",
        price: 60000,
        months: 12
    },
    domain_name_other_registrar_upgrade_from_subdomain: {
        name: "Eget domännamn",
        price: 60000,
        months: 12
    },
};

export const productsParty = {
    subdomain: {
        name: "Publicering med subdomän",
        price: 49900,
        months: 12
    },
    domain_name: {
        name: "Publicering och domännamn",
        price: 119900,
        months: 12
    },
    domain_name_other_registrar: {
        name: "Publicering med eget domännamn",
        price: 119900,
        months: 12
    },
    domain_name_upgrade_from_subdomain: {
        name: "Nytt domännamn",
        price: 70000,
        months: 12
    },
    domain_name_other_registrar_upgrade_from_subdomain: {
        name: "Eget domännamn",
        price: 70000,
        months: 12
    },
};

export const productsEvent = {
    subdomain: {
        name: "Publicering med subdomän",
        price: 49900,
        months: 12
    },
    domain_name: {
        name: "Publicering och domännamn",
        price: 119900,
        months: 12
    },
    domain_name_other_registrar: {
        name: "Publicering med eget domännamn",
        price: 119900,
        months: 12
    },
    domain_name_upgrade_from_subdomain: {
        name: "Nytt domännamn",
        price: 70000,
        months: 12
    },
    domain_name_other_registrar_upgrade_from_subdomain: {
        name: "Eget domännamn",
        price: 70000,
        months: 12
    },
};

const productBrandIndex = { wedding: productsWedding, party: productsParty, event: productsEvent };

const products = productBrandIndex[process.env.REACT_APP_BRAND as Origin];

export type Product = keyof typeof productsWedding;

export const stringIsProduct = (product: Product) => {
    return !!products[product];
};

export const getProductName = (product: Product): string => {
    return products[product].name;
};

export const getProductPriceAsKronor = (product: Product): number => {
    return products[product].price / 100;
};

export const getProductMonths = (product: Product): number => {
    return products[product].months;
};
