import React from "react";
import styles from "./Select.module.scss";
import { Label } from "../Label/Label";

export interface ISelectOption<T> {
    title: string;
    value: T;
}

interface IProps<T = string> {
    handleChange: (value: T) => void;
    value: T | undefined;
    options: ISelectOption<T>[];
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    defaultValue?: T;
    id: string;
    theme?: "LIGHT" | "DARK";
}

export const Select = <T extends string>(props: IProps<T>) => {
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.handleChange(event.target.value as T);
    };
    return (
        <div className={`${styles.wrapper} ${props.theme === "LIGHT" ? styles.light : styles.dark}`}>
            {props.label ? (
                <Label htmlFor={props.id} className={styles.label}>
                    {props.label}
                </Label>
            ) : null}
            <select className={styles.select} id={props.id} value={props.value} onChange={onChange}>
                {!props.value && props.placeholder ? <option value="">{props.placeholder}</option> : ""}
                {props.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.title}
                    </option>
                ))}
            </select>
        </div>
    );
};
