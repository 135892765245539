import { useTranslation } from "../../../contexts/LanguageContext";
import { capitalize } from "../../../utils/capitalize";
import { translatedLanguageFromLanguageCode } from "../../../utils/LanguageHelpers";
import { TLanguageCode, TTranslation } from "../../interfaces/IContentData";
import { ILanguage } from "../../interfaces/Site";
import { TranslateModePreviewTypeRte } from "./components/TranslateModePreviewTypeRTE/TranslateModePreviewTypeRTE";
import styles from "./TranslateModePreview.module.scss";

interface IProps {
    translation: TTranslation;
    languages: ILanguage[];
    type?: "RTE";
}

const pickLanguageIfEqual = (languages: TLanguageCode[]) => {
    if (languages.length === 1) return languages[0];
    if (languages.includes("sv")) return "sv";
    if (languages.includes("en")) return "en";
    return languages[0];
};

const pickTranslateModeLanguage = (
    translation: TTranslation,
    languages: ILanguage[],
    currentLanguage: TLanguageCode
): TLanguageCode | null => {
    const availableLanguagesOnSite = languages.map((language) => language.languageCode);
    const languagesWithContent = (Object.keys(translation) as TLanguageCode[])
        .filter((language) => !!translation[language])
        .filter((language) => language !== currentLanguage)
        .filter((language) => availableLanguagesOnSite.includes(language));

    if (!languagesWithContent.length) {
        return null;
    }

    return pickLanguageIfEqual(languagesWithContent);
};

export function TranslateModePreview(props: IProps) {
    const { getInTranslation, translateMode, language } = useTranslation();

    if (!translateMode) {
        return null;
    }

    const languageCode = pickTranslateModeLanguage(props.translation, props.languages, language);

    if (languageCode === null) {
        return (
            <div className={styles.outerWrapper}>
                <div className={`${styles.wrapper} ${styles.noTranslation}`}>Ingen översättning</div>
            </div>
        );
    }

    return (
        <div className={styles.outerWrapper}>
            <div className={`${styles.wrapper}`}>
                <div className={styles.languageTitle}>
                    {capitalize(translatedLanguageFromLanguageCode(languageCode))}:
                </div>
                {props.type === "RTE" ? (
                    <TranslateModePreviewTypeRte value={getInTranslation(props.translation, languageCode)} />
                ) : (
                    getInTranslation(props.translation, languageCode)
                )}
            </div>
        </div>
    );
}
