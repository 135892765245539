import { IFormElementOption } from "../../../../../interfaces/FormElement";
import { IRsvpV2 } from "../ViewRsvp";

export const uniqueOptionAnswersFromRsvps = (questionId: string, rsvps: IRsvpV2[]): IFormElementOption[] => {
    const options = rsvps.map((rsvp) => {
        const question = rsvp.formElements.find((formElement) => formElement.formElement.id === questionId);
        return question?.selectedOption;
    }).filter(item => item !== undefined) as IFormElementOption[]; // remove when newer typescript
    const uniqueAnswers = options.filter((a, i) => options.findIndex((s) => a.id === s.id) === i);
    return uniqueAnswers;
};
