import { LinkButton } from "../../components/button/LinkButton";
import { ISiteV2 } from "../../interfaces/Site";
import styles from "./EditVersions.module.scss";

interface IProps {
    site: ISiteV2;
    quickStart: boolean;
}

export const EditVersions = (props: IProps) => {
    if (props.quickStart) {
        return (
            <p className={styles.text}>Det finns inga tidigare versioner av din sida eftersom du inte har sparat än.</p>
        );
    }
    return (
        <>
            <p className={styles.text}>
                Har du råkat spara över något du vill ha tillbaka? Här finns alla sparade versioner av din sida upp till
                30 dagar bakåt.
            </p>
            <div className={styles.buttonWrapper}>
                <LinkButton
                    fullWidth={true}
                    type="secondary"
                    to={"/redigera/" + props.site._id + "/versioner"}
                    data-test-id="select-new-invite"
                >
                    Visa tidigare versioner
                </LinkButton>
            </div>
        </>
    );
};
