import { useState } from "react";
import styles from "./EditFormCondition.module.scss";
import { IFormElementSingleSelect, TFormElement } from "../../../../../../interfaces/FormElement";
import { useTranslation } from "../../../../../../../contexts/LanguageContext";
import { Select } from "../../../../../../editComponents/form/Select/Select";
import { Switch } from "../../../../../../editComponents/form/Switch/Switch";

interface IProps {
    formElements: TFormElement[];
    formElement: TFormElement;
    updateFormElement: (updater: (draft: TFormElement) => void) => void;
}

export function EditFormCondition(props: IProps) {
    const condition = props.formElement.showConditions[0];
    const conditionQuestionId = condition?.formElementId;
    const conditionAnswerId = condition?.formElementOptionIds[0];
    const { getInTranslationWithFallback } = useTranslation();

    const [selectedQuestion, setSelectedQuestion] = useState(conditionQuestionId || "");
    const [selectedAnswer, setSelectedAnswer] = useState(conditionAnswerId || "");
    const [conditionActive, setConditionActive] = useState(!!conditionAnswerId);

    const selectElements =
        props.formElements
            ?.filter((item) => item.formElementType === "SingleSelect")
            .filter((item) => item.id !== props.formElement.id) || [];

    const options = selectElements.map((item) => ({
        title: getInTranslationWithFallback(item.label),
        value: item.id,
    }));

    const conditionQuestion = selectElements.find((item) => item.id === selectedQuestion) as IFormElementSingleSelect; // Remove as when typescript 5 >
    const conditionAnswer = conditionQuestion?.options.find((option) => option.id === selectedAnswer);

    const getCurrentStatus = () => {
        if (!conditionAnswerId) {
            return "Frågan visas alltid";
        }
        if (!conditionQuestion || !conditionAnswer) {
            return "Frågan eller svaret som tidigare var ett villkor för att visa frågan har tagits bort. Frågan visas därför alltid.";
        }
        return (
            'Frågan visas om gästen svarat "' +
            getInTranslationWithFallback(conditionAnswer.text) +
            '" på frågan "' +
            getInTranslationWithFallback(conditionQuestion.label) +
            '"'
        );
    };

    const currentStatusIsWarning = () => {
        return conditionAnswerId && (!conditionQuestion || !conditionAnswer);
    }

    const answerOptions = conditionQuestion?.options?.map((option) => ({
        title: getInTranslationWithFallback(option.text),
        value: option.id,
    }));

    const notSelected = { title: "Ingen fråga vald", value: "" };

    options.unshift(notSelected);
    const notSelectedAnswer = { title: "Ingen fråga vald", value: "" };

    answerOptions?.unshift(notSelectedAnswer);

    const update = (active: boolean, question: string, answer: string) => {
        if (active && question && answer) {
            props.updateFormElement((draft) => {
                draft.showConditions[0] = {
                    type: "SHOW",
                    formElementId: question,
                    formElementOptionIds: [answer],
                };
            });
        } else {
            props.updateFormElement((draft) => {
                draft.showConditions = [];
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            <div>
                <Switch
                    label="Visa frågan beroende på annat svar"
                    name="conditionActive"
                    handleChange={(value: boolean) => {
                        setConditionActive(value);
                        update(value, selectedQuestion, selectedAnswer);
                    }}
                    value={conditionActive}
                    id="conditionActiveToggleSwitch"
                />
            </div>
            {conditionActive ? (
                <>
                    <div className={styles.selectWrapper}>
                        <div>
                            <Select
                                id="selectConditionQuestion"
                                label="Välj fråga"
                                options={options}
                                value={selectedQuestion}
                                handleChange={(value: string) => {
                                    setSelectedQuestion(value);
                                    setSelectedAnswer("");
                                    update(conditionActive, value, selectedAnswer);
                                }}
                            />
                        </div>
                        {selectedQuestion && answerOptions?.length ? (
                            <div>
                                <Select
                                    id="selectConditionAnswer"
                                    label="Välj svar"
                                    options={answerOptions}
                                    value={selectedAnswer}
                                    handleChange={(value: string) => {
                                        setSelectedAnswer(value);
                                        update(conditionActive, selectedQuestion, value);
                                    }}
                                />
                            </div>
                        ) : null} 
                    </div>
                    <p className={`${styles.currentStatus} ${currentStatusIsWarning() ? styles.warning : ""}`}>{getCurrentStatus()}</p>
                </>
            ) : null}
        </div>
    );
}
