import React from "react";
import styles from "./SelectPrintLayoutItem.module.scss";
import { IPrintableCard, TPrintableCardLayout } from "../../../../../interfaces/IPrintableCard";
import { PrintableCard } from "../../../../PrintableCard/PrintableCard";
import { createDemoCard } from "../../../../../../../utils/createDemoCard";
import { ISite, ISiteV2 } from "../../../../../../../../../../../../../interfaces/Site";

interface IProps {
    print: IPrintableCard;
    printLayout: TPrintableCardLayout;
    setPrintLayout: (layout: TPrintableCardLayout) => void;
    site: ISite | ISiteV2;
}
// When creating demo card with currentPrint, dont add photo. Wrong ratio... Or ad image object cover!
export const SelectPrintLayoutItem = (props: IProps) => {
    const previewCard = createDemoCard(props.printLayout, props.site, props.print);

    return (
        <div
            className={props.printLayout === props.print.layout ? styles.wrapperActive : styles.wrapper}
            onClick={() => props.setPrintLayout(props.printLayout)}
            data-test-id={"print-layout-select-" + props.printLayout}
        >
            <PrintableCard thumbnail={true} forPrint={false} page={1} shadow={true} card={previewCard} editMode={false} />
        </div>
    );
};
