import React, { useState } from "react";
import ContentH3 from "../../../content/h3/H3";
import ContentImage from "../../../content/image/Image";
import ContentRTE from "../../../content/RTE/RTE";
import { IModule } from "../../../interfaces/Module";
import { IImage, ISite } from "../../../interfaces/Site";
import { ItemActionBar } from "../../../modules/ItemActionBar/ItemActionBar";
import { getEmptyNewListItem, IListItem } from "../TextAndImageList";
import styles from "./TextAndImageListItem.module.scss";
import { rteContentIsEmpty } from "../../../utils/rteContentIsEmpty";

interface IProps {
    listItem: IListItem;
    editMode: boolean;
    site: ISite;
    updatePropertyOnListItem: any;
    updateList: (listItems: IListItem[]) => void;
    addOrUpdateImageToSite: (image: IImage) => void;
    allListItems: IListItem[];
}

export function TextAndImageListItem(props: IProps) {
    const [isHighlited, setIsHighlited] = useState(false);
    const updateProperty = (module: IModule, name: string, value: string) => {
        props.updatePropertyOnListItem(props.listItem, name, value);
    };
    const showTextBox =
        props.editMode || props.listItem.properties.title || !rteContentIsEmpty(props.listItem.properties.text);
    return (
        <div className={`${styles[props.site.theme]} ${isHighlited ? styles.highlighted : ""}`}>
            <div className={styles.imageWrapper}>
                <ContentImage
                    site={props.site}
                    property="image"
                    updateProperty={updateProperty}
                    propertyParent={props.listItem}
                    editMode={props.editMode}
                    addOrUpdateImageToSite={props.addOrUpdateImageToSite}
                    sizes="(min-width: 750px) 600px, 100vw"
                />
            </div>
            {showTextBox ? (
                <div className={styles.textAndHeadingContentWrapper}>
                    <ContentH3
                        site={props.site}
                        property="title"
                        propertyParent={props.listItem}
                        updateProperty={updateProperty}
                        editMode={props.editMode}
                    />
                    <div className={styles.textContentWrapper}>
                        <ContentRTE
                            site={props.site}
                            property="text"
                            propertyParent={props.listItem}
                            updateProperty={updateProperty}
                            editMode={props.editMode}
                        />
                    </div>
                </div>
            ) : null}
            {props.editMode === true ? (
                <ItemActionBar<IListItem>
                    getNewItem={getEmptyNewListItem}
                    updateList={props.updateList}
                    itemName="Ruta"
                    items={props.allListItems}
                    item={props.listItem}
                    setHighligtItem={setIsHighlited}
                />
            ) : null}
        </div>
    );
}
