import styles from "./EditGiftRegistryItem.module.scss";
import { IContentDataGiftRegistryItem, ISiteModuleGiftRegistry } from "../../../../../../interfaces/ISiteModule";
import { IContentDataImage, TLanguageCode } from "../../../../../../interfaces/IContentData";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { EditGiftRegistryImage } from "./components/EditGiftRegistryImage";
import { useTranslation } from "../../../../../../../contexts/LanguageContext";
import { Input } from "../../../../../../editComponents/form/Input/Input";
import { Switch } from "../../../../../../editComponents/form/Switch/Switch";
import { Textarea } from "../../../../../../editComponents/form/Textarea/Textarea";
import { ContentBox } from "../../../../../../editComponents/ContentBox/ContentBox";
import { languageFromLanguageCode } from "../../../../../../../utils/LanguageHelpers";

interface IProps {
    giftRegistryItem: IContentDataGiftRegistryItem;
    site: ISiteV2;
    updateSiteModule: <SiteModule extends ISiteModuleGiftRegistry>(updater: (siteModule: SiteModule) => void) => void;
}

export function EditGiftRegistryItem(props: IProps) {
    const { getInTranslation, setInTranslation } = useTranslation();

    const updateGiftRegistryItem = (updater: (draft: IContentDataGiftRegistryItem) => void) => {
        return props.updateSiteModule((draft) => {
            const thisItem = draft.giftRegistry.find((item) => item.id === props.giftRegistryItem.id);
            if (!thisItem) {
                throw new Error("No gift registry item with id " + props.giftRegistryItem.id);
            }
            updater(thisItem);
        });
    };

    const label = (languageCode: TLanguageCode, text: string) => {
        if (props.site.availableLanguages.length < 2) return text;

        return text + " på " + languageFromLanguageCode(languageCode);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.formWrapper}>
                <ContentBox padding={true} className={styles.formFieldWrapper}>
                    <>
                        {props.site.availableLanguages.map((language) => (
                            <div className={styles.formFieldWrapper} key={language.languageCode}>
                                <Input
                                    label={label(language.languageCode, "Rubrik")}
                                    name={"title" + language.languageCode}
                                    id={"editGiftRegistryItemTitle" + language.languageCode}
                                    handleChange={(value: string) =>
                                        updateGiftRegistryItem((item) => {
                                            item.title.text = setInTranslation(
                                                item.title.text,
                                                value,
                                                language.languageCode
                                            );
                                        })
                                    }
                                    value={getInTranslation(props.giftRegistryItem.title.text, language.languageCode)}
                                />
                            </div>
                        ))}
                        <div className={styles.formFieldWrapper}>
                            <Input
                                label="Länk"
                                name="link"
                                id="editGiftRegistryItemLink"
                                handleChange={(value: string) =>
                                    updateGiftRegistryItem((item) => {
                                        item.link = value;
                                    })
                                }
                                value={props.giftRegistryItem.link || ""}
                                placeholder="https://www.google.se/"
                            />
                        </div>
                        {props.site.availableLanguages.map((language) => (
                            <div className={styles.formFieldWrapper} key={language.languageCode}>
                                <Textarea
                                    label={label(language.languageCode, "Beskrivning")}
                                    name={"description" + language.languageCode}
                                    id={"editGiftRegistryItemDescription" + language.languageCode}
                                    handleChange={(value: string) =>
                                        updateGiftRegistryItem((item) => {
                                            item.description.text = setInTranslation(item.description.text, value, language.languageCode);
                                        })
                                    }
                                    value={getInTranslation(props.giftRegistryItem.description.text, language.languageCode)}
                                />
                            </div>
                        ))}
                    </>
                </ContentBox>
                <ContentBox padding={true} className={styles.formFieldWrapperReservableAndQuantity}>
                    <div className={styles.reservableWrapper}>
                        <Switch
                            label="Kan reserveras"
                            name="reservable"
                            handleChange={(value: boolean) =>
                                updateGiftRegistryItem((item) => {
                                    item.reservable = value;
                                })
                            }
                            value={props.giftRegistryItem.reservable}
                            id="editGiftRegistryItemReservable"
                        />
                    </div>

                    <div className={styles.quantityWrapper}>
                        {props.giftRegistryItem.reservable === true && (
                            <Input
                                label="Önskat antal"
                                type="number"
                                name="quantity"
                                id="editGiftRegistryItemQuantity"
                                handleChange={(value: string) =>
                                    updateGiftRegistryItem((item) => {
                                        if (value.length > 0) {
                                            item.quantity = parseInt(value);
                                        } else {
                                            item.quantity = null;
                                        }
                                    })
                                }
                                required={true}
                                value={props.giftRegistryItem.quantity + ""} // + "" converts to string
                            />
                        )}
                    </div>
                </ContentBox>
            </div>
            <ContentBox padding={true} className={styles.imageEditWrapper}>
                <EditGiftRegistryImage
                    site={props.site}
                    contentData={props.giftRegistryItem.image}
                    updateContentData={(image: IContentDataImage) => {
                        updateGiftRegistryItem((item) => {
                            item.image = image;
                        });
                    }}
                />
            </ContentBox>
        </div>
    );
}
