import { ISiteModulePeopleList, ISiteModulePeopleListItem } from "../../interfaces/ISiteModule";
import { randomId } from "../../utils/Random";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModulePeopleList = (): ISiteModulePeopleList => {
    return {
        siteModuleType: "PeopleList",
        ...getNewSiteModuleBasic(false),
        title: {
            contentDataType: "Title",
            text: {},
        },
        list: [getNewSiteModulePeopleListItem()],
    };
};

export const getNewSiteModulePeopleListItem = (): ISiteModulePeopleListItem => {
    return {
        id: "CLIENTID-LIST-ITEM" + randomId(),
        name: {
            contentDataType: "Title",
            text: {},
        },
        title: {
            contentDataType: "Text",
            text: {},
        },
        text: {
            contentDataType: "RichText",
            richText: {},
        },
        image: {
            contentDataType: "Image",
            image: null,
        },
    };
};
