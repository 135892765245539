import { ISite, ISiteV2 } from "../../../interfaces/Site";
import { IDesignTemplate } from "./designTemplates";
import isEqual from "lodash.isequal";

export const siteHasDesignTemplate = (site: ISite | ISiteV2, designTemplate: IDesignTemplate) => {
    if (site.theme !== designTemplate.theme) return false;
    if (site.siteGraphic !== designTemplate.siteGraphic) return false;
    if (site.fonts.heading !== designTemplate.fonts.heading) return false;
    if (site.fonts.text !== designTemplate.fonts.text) return false;
    if (!isEqual(site.colorScheme, designTemplate.colorScheme)) return false;
    return true;
};
