import { useState } from "react";
import styles from "./ContentTypeFormEdit.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataForm } from "../../interfaces/IContentData";
import { IFormElementBasic, TFormElement, TFormElementType } from "../../interfaces/FormElement";
import Button from "../../components/button/Button";
import { FormElementListItem } from "./components/FormElementListItem/FormElementListItem";
import { randomId } from "../../utils/Random";
import { DialogTop } from "../../editComponents/DialogTop/DialogTop";
import { EditFormElementListItem } from "./components/EditFormElementListItem/EditFormElementListItem";
import { DraftFunction } from "use-immer";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataForm;
    updateForm: (updater: DraftFunction<IContentDataForm>) => void;
    close: () => void;
    tabs: JSX.Element;
    editingFormFieldsWarning?: JSX.Element;
}

export const ContentTypeFormEdit = (props: IProps) => {
    const [editItemId, setEditItemId] = useState<string | null>(null);
    const formElements = props.contentData.formElements;

    const addNew = (formElementType: TFormElementType) => {
        const id = "form-element" + randomId();
        let newFormElement: TFormElement;
        const basicFormElement: IFormElementBasic = {
            id,
            label: {},
            showConditions: [],
        };
        if (formElementType === "ShortText") {
            newFormElement = {
                ...basicFormElement,
                required: false,
                formElementType: "ShortText",
            };
        } else if (formElementType === "SingleSelect") {
            newFormElement = {
                ...basicFormElement,
                formElementType: "SingleSelect",
                required: true,
                options: [],
            };
        }
        props.updateForm((form) => {
            form.formElements.push(newFormElement);
        });
        setEditItemId(id);
    };


    if (editItemId) {
        const editItem = formElements.find((item) => item.id === editItemId);
        if (!editItem) {
            throw new Error("No formElement with editItemId" + editItemId);
        }
        return (
            <>
                <DialogTop back={() => setEditItemId(null)} title="Ändra fråga" backText="Frågor" />
                <EditFormElementListItem
                    site={props.site}
                    formElement={editItem}
                    formElements={formElements}
                    updateForm={props.updateForm}
                    closeEdit={() => setEditItemId(null)}
                    editingFormFieldsWarning={props.editingFormFieldsWarning}
                />
            </>
        );
    } else {
        return (
            <>
                <DialogTop title="Formulär" close={props.close} tabs={props.tabs} />
                <div className={styles.wrapper}>
                    <>
                        <div className={styles.listWrapper}>
                            {formElements.map((item) => (
                                <FormElementListItem
                                    formElement={item}
                                    formElements={formElements}
                                    updateForm={props.updateForm}
                                    key={item.id}
                                    setEdit={() => setEditItemId(item.id)}
                                />
                            ))}
                        </div>
                        <div className={styles.newQuestionsWrapper}>
                            <Button
                                buttonText="Ny fråga - textsvar"
                                type="secondary"
                                callback={() => addNew("ShortText")}
                            />
                            <Button
                                buttonText="Ny fråga - svarsalternativ"
                                type="secondary"
                                callback={() => addNew("SingleSelect")}
                            />
                        </div>
                    </>
                </div>
            </>
        );
    }
};
