import dayjs from "dayjs";

export const calculateCountdown = (endDate: string | null) => {
    const timeLeft = {
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
    };
    if (!endDate) {
        return timeLeft;
    }

    const endDateTransformed = dayjs(endDate);
    const now = dayjs();
    let diff = dayjs(endDateTransformed).diff(now);

    if (!Number.isInteger(diff)) {
        return timeLeft;
    }

    // clear countdown when date is reached
    if (diff <= 0) return timeLeft;

    diff = Math.abs(diff / 1000);

    // calculate time difference between now and expected date

    if (diff >= 86400) {
        // 24 * 60 * 60
        timeLeft.days = Math.floor(diff / 86400);
        diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
        // 60 * 60
        timeLeft.hours = Math.floor(diff / 3600);
        diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
        timeLeft.min = Math.floor(diff / 60);
        diff -= timeLeft.min * 60;
    }
    timeLeft.sec = Math.floor(diff);

    return timeLeft;
};
