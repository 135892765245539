import React from "react";
import { ISite, ISiteV2 } from "../../interfaces/Site";
import styles from "./SelectVerticalV2.module.scss";

interface IProps {
    handleChange?: (value: string | number) => void;
    options: {
        title: string | number;
        value: string | number;
    }[];
    site: ISite | ISiteV2;
    "data-test-id"?: string;
    onClick?: () => void;
    value: string | number;
}

export class SelectVerticalV2 extends React.Component<IProps> {
    handleChange(value: string | number) {
        if (this.props.handleChange) {
            this.props.handleChange(value);
        }
    }

    render() {
        return (
            <div className={styles[this.props.site.theme]}>
                <div
                    className={this.props.onClick ? styles.innerWrapperWithClick : styles.innerWrapper}
                    onClick={this.props.onClick}
                >
                    {this.props.options.map((option, index) => (
                        <button
                            type="button"
                            key={option.value}
                            onClick={() => {
                                this.handleChange(option.value);
                            }}
                            className={`${option.value === this.props.value ? styles.selected : ""} 
                            ${!this.props.handleChange ? styles.nonHover : ""}
                             ${this.props.value ? styles.selectedExists : ""}`}
                            data-test-id={this.props["data-test-id"] ? this.props["data-test-id"] + "-" + index : ""}
                        >
                            <span>{option.title}</span>
                        </button>
                    ))}
                </div>
            </div>
        );
    }
}
