import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./GiftRegistryItemsEdit.module.scss";
import { IContentDataGiftRegistryItem, ISiteModuleGiftRegistry } from "../../../../interfaces/ISiteModule";
import { Button } from "../../../../components/button/Button";
import { GiftRegistryItemsEditListItem } from "./components/GiftRegistryItemsEditListItem/GiftRegistryItemsEditListItem";
import { useState } from "react";
import { EditGiftRegistryItem } from "./components/EditGiftRegistryItem/EditGiftRegistryItem";
import { DialogTop } from "../../../../editComponents/DialogTop/DialogTop";
import { Tab } from "../../../../editComponents/Tab/Tab";
import { ShowGiftRegistryButton } from "./components/ShowGiftRegistryButton/ShowGiftRegistryButton";
import { IContentDataText } from "../../../../interfaces/IContentData";
import { ReserveAndCancelReservationButtons } from "./components/ReserveAndCancelReservationButtons/ReserveAndCancelReservationButtons";
import { GiftRegistryLabelsEdit } from "./components/GiftRegistryLabelsEdit/GiftRegistryLabelsEdit";
import { GiftRegistryStatusTextEdit } from "./components/GiftRegistryStatusTextEdit/GiftRegistryStatusTextEdit";
import { getNewGiftRegistryItem } from "./utils/getNewGiftRegistryItem";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleGiftRegistry;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleGiftRegistry, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
    updateSiteModule: <SiteModule extends ISiteModuleGiftRegistry>(updater: (siteModule: SiteModule) => void) => void;
    close: () => void;
}

export const GiftRegistryItemsEdit = (props: IProps) => {
    const [view, setView] = useState<"LIST" | "EDIT" | "BUTTONS" | "TEXTS">("LIST");
    const [editItemId, setEditItemId] = useState<string | null>(null);

    const Tabs = (
        <>
            <Tab active={view === "LIST"} onClick={() => setView("LIST")} text="Önskelistan" />
            <Tab active={view === "BUTTONS"} onClick={() => setView("BUTTONS")} text="Knappar" />
            <Tab active={view === "TEXTS"} onClick={() => setView("TEXTS")} text="Texter" />
        </>
    );

    const edit = (item: IContentDataGiftRegistryItem) => {
        setEditItemId(item.id);
        setView("EDIT");
    };

    const addNewItem = () => {
        const newItem = getNewGiftRegistryItem();
        props.updateSiteModule((siteModule) => {
            siteModule.giftRegistry.push(newItem);
        });
        edit(newItem);
    };

    const updateList = (items: IContentDataGiftRegistryItem[]) => {
        props.updateSiteModule((siteModule) => {
            siteModule.giftRegistry = items;
        });
    };
    
    if (view === "LIST") {
        return (
            <>
                <DialogTop title="Önskelista" close={props.close} tabs={Tabs} />
                <div className={styles.listWrapper}>
                    {props.siteModule.giftRegistry.map((item) => (
                        <GiftRegistryItemsEditListItem
                            giftRegistryItem={item}
                            giftRegistryItems={props.siteModule.giftRegistry}
                            handleEditItem={() => edit(item)}
                            updateList={updateList}
                            key={item.id}
                        />
                    ))}
                </div>
                <Button buttonText="Lägg till ny" type="primary" callback={addNewItem} />
            </>
        );
    } else if (view === "EDIT") {
        const editItem = props.siteModule.giftRegistry.find(item => item.id === editItemId);
        if (!editItem) {
            throw new Error("Edit view in gift registry edit, but no item to edit");
        }
        return (
            <>
                <DialogTop back={() => setView("LIST")} title="Ändra" backText="Önskelista" />
                <EditGiftRegistryItem
                    giftRegistryItem={editItem}
                    site={props.site}
                    updateSiteModule={props.updateSiteModule}
                />
            </>
        );
    } else if (view === "BUTTONS") {
        return (
            <>
                <DialogTop title="Önskelista" close={props.close} tabs={Tabs} />
                <div className={styles.buttonsWrapper}>
                    <ShowGiftRegistryButton
                        site={props.site}
                        siteModule={props.siteModule}
                        updateButton={(value: IContentDataText) =>
                            props.updateSiteModuleProperty("showGiftRegistryButton", value)
                        }
                    />
                    <ReserveAndCancelReservationButtons
                        site={props.site}
                        siteModule={props.siteModule}
                        updateSiteModuleProperty={props.updateSiteModuleProperty}
                    />
                </div>
            </>
        );
    } else if (view === "TEXTS") {
        return (
            <>
                <DialogTop title="Önskelista" close={props.close} tabs={Tabs} />
                <div className={styles.buttonsWrapper}>
                    <GiftRegistryLabelsEdit
                        site={props.site}
                        siteModule={props.siteModule}
                        updateSiteModuleProperty={props.updateSiteModuleProperty}
                    />
                    <GiftRegistryStatusTextEdit
                        site={props.site}
                        siteModule={props.siteModule}
                        updateSiteModuleProperty={props.updateSiteModuleProperty}
                    />
                </div>
            </>
        );
    } 

    throw Error("No view in gift registry items edit");
};
