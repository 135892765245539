import { ISiteModuleVideo } from "../../interfaces/ISiteModule";
import { getNewSiteModuleBasic } from "./getNewSiteModuleBasic";

export const getNewSiteModuleVideo = (): ISiteModuleVideo => {
    return {
        siteModuleType: "Video",
        ...getNewSiteModuleBasic(false),
        title: {
            contentDataType: "Title",
            text: {},
        },
        video: {
            contentDataType: "Video",
            type: "YouTube",
            videoUrl: null,
            autoStart: false,
        },
    };
};
