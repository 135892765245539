import { ContentTypeRichTextEdit } from "../../../contentTypes/ContentTypeRichText/ContentTypeRichTextEdit";
import { ContentTypeTitleEdit } from "../../../contentTypes/ContentTypeTitle/ContentTypeTitleEdit";
import { ContentBox } from "../../../editComponents/ContentBox/ContentBox";
import { IContentDataRichText, IContentDataTitle } from "../../../interfaces/IContentData";
import { ISiteModuleForm, ISiteModuleRsvp } from "../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../interfaces/Site";
import { FormConfirmationBox } from "../FormConfirmationBox/FormConfirmationBox";
import { LanguageTitle } from "../LanguageTitle/LanguageTitle";
import { VerticalGapOnChildren } from "../VerticalGapOnChildren/VerticalGapOnChildren";
import styles from "./FormConfirmationEdit.module.scss";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp | ISiteModuleForm;
    updateConfirmationTitle: (value: IContentDataTitle) => void;
    updateConfirmationText: (value: IContentDataRichText) => void;
}

export const FormConfirmationEdit = (props: IProps) => {
    return (
        <>
            <ContentBox className={styles.wrapper} padding={true} title="Bekräftelse för gästen">
                <p>När din gäst skickat formuläret visas rutan nedan. Tryck på texten om du vill ändra den.</p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <FormConfirmationBox
                                site={props.site}
                                title={
                                    <ContentTypeTitleEdit
                                        tag="h1"
                                        contentData={props.siteModule.confirmationTitle}
                                        site={props.site}
                                        updateContentData={props.updateConfirmationTitle}
                                        language={language.languageCode}
                                    />
                                }
                                text={
                                    <ContentTypeRichTextEdit
                                        contentData={props.siteModule.confirmationText}
                                        site={props.site}
                                        updateContentData={props.updateConfirmationText}
                                        language={language.languageCode}
                                    />
                                }
                            />
                        </div>
                    ))}
                </VerticalGapOnChildren>
            </ContentBox>
        </>
    );
};
