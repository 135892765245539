import React from "react";
import styles from "./AddSiteModule.module.scss";
import { TSiteModule, TSiteModuleType } from "../../../interfaces/ISiteModule";
import { isBrand } from "../../../utils/getBrand";
import { Dialog } from "../../../editComponents/Dialog/Dialog";
import { DialogTop } from "../../../editComponents/DialogTop/DialogTop";

interface IProps {
    close: () => void;
    addNewModule: (type: TSiteModuleType, addBeforeThisModule?: TSiteModule) => void;
    addBeforeThisModule?: TSiteModule;
}

export const AddSiteModule = (props: IProps) => {
    const selectModule = (type: TSiteModuleType) => {
        props.addNewModule(type, props.addBeforeThisModule);
    };

    return (
        <Dialog open={true} handleClose={props.close} closableByOutsideClickAndEsc={true}>
            <DialogTop title="Lägg till ny modul" close={props.close} />
            <ul className={styles.list}>
                <li
                    onClick={() => {
                        selectModule("ImageAndText");
                    }}
                >
                    <div className={styles.title}>Bild och text</div>
                    <div className={styles.description}>Rubrik, bild och text</div>
                </li>
                <li
                    onClick={() => {
                        selectModule("TextAndImageList");
                    }}
                >
                    <div className={styles.title}>Text och bild i lista</div>
                    <div className={styles.description}>
                        Presentera flera saker i en lista med text och bild till varje punkt. Används ofta som "Bra att
                        veta"-modul.
                    </div>
                </li>
                <li
                    onClick={() => {
                        selectModule("Location");
                    }}
                >
                    <div className={styles.title}>Plats</div>
                    <div className={styles.description}>
                        Visar tex. karta, vägbeskrivning och tid till en plats där gästerna ska vara.
                    </div>
                </li>
                <li
                    onClick={() => {
                        selectModule("Text");
                    }}
                >
                    <div className={styles.title}>Text</div>
                    <div className={styles.description}>Text och rubrik</div>
                </li>
                <li
                    onClick={() => {
                        selectModule("PeopleList");
                    }}
                >
                    <div className={styles.title}>Personer</div>
                    <div className={styles.description}>Lista med personer</div>
                </li>
                <li
                    onClick={() => {
                        selectModule("Countdown");
                    }}
                >
                    <div className={styles.title}>Nedräkning</div>
                    <div className={styles.description}>Nedräkning i dagar, timmar, minuter och sekunder.</div>
                </li>
                <li
                    onClick={() => {
                        selectModule("Video");
                    }}
                >
                    <div className={styles.title}>YouTube-klipp</div>
                    <div className={styles.description}>YouTube-klipp med rubrik</div>
                </li>
                {!isBrand("event") ? (
                    <li
                        onClick={() => {
                            selectModule("GiftRegistry");
                        }}
                    >
                        <div className={styles.title}>Önskelista</div>
                        <div className={styles.description}>
                            Önskelista där dina gäster kan boka upp sina inköp så ni inte får dubbletter.
                        </div>
                    </li>
                ) : null}
                <li
                    onClick={() => {
                        selectModule("Rsvp");
                    }}
                >
                    <div className={styles.title}>OSA</div>
                    <div className={styles.description}>Formulär för OSA</div>
                </li>
                <li
                    onClick={() => {
                        selectModule("Form");
                    }}
                >
                    <div className={styles.title}>Formulär</div>
                    <div className={styles.description}>
                        Forumlär som skickas till valfri e-postadress.{" "}
                        {!isBrand("event") ? "Används ofta för anmälan av tal/spex till toastmasters" : ""}
                    </div>
                </li>
                <li
                    onClick={() => {
                        selectModule("Header");
                    }}
                >
                    <div className={styles.title}>Sidhuvud</div>
                    <div className={styles.description}>Sidhuvud med bild, namn och datum</div>
                </li>
            </ul>
        </Dialog>
    );
};
