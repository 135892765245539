import { TSiteGraphic } from "../components/SiteGraphic/SiteGraphic";
import { TFontHeading, TFontText } from "../enums/font";
import { TLanguageCode } from "./IContentData";
import { TSiteModule } from "./ISiteModule";
import { IModule, IModuleRest } from "./Module";

export enum enumTheme {
    theme4 = "theme4",
    theme5 = "theme5",
    theme7 = "theme7",
    theme8 = "theme8",
    theme9 = "theme9",
    theme10 = "theme10",
    theme11 = "theme11",
}

export interface ISiteRest {
    metaNoindex?: boolean;
    term?: string;
    host?: string;
    _id?: string;
    modules: IModuleRest[];
    images: IImageRest[];
    user?: string;
    colorScheme: IColorScheme;
    mailReceivers?: IMailReceivers;
    active?: boolean;
    password?: string;
    fonts: IFonts;
    theme: enumTheme;
    giftRegistryReservations?: IGiftRegistryReservations[];
    activeDate?: string;
    customDomainActive?: boolean;
    customDomainActiveDate?: string;
    customDomainRegistrationsAvailable?: number;
    siteGraphic?: TSiteGraphic | null;
    mostRecentSelectedDesignTemplate?: string;
    siteModules?: TSiteModule[];
    usesSiteModules?: boolean;
}

export interface IGiftRegistryReservations {
    giftRegistryId: string;
    quantityReserved: number;
}

export interface IMailReceivers {
    rsvp?: string[] | string;
    speech?: string[] | string;
}

export interface ISite {
    metaNoindex: boolean;
    host: string;
    _id: string;
    modules: IModule[];
    siteModules?: TSiteModule[];
    usesSiteModules?: boolean;
    images: IImage[];
    colorScheme: IColorScheme;
    mailReceivers?: IMailReceivers;
    active?: boolean;
    password?: string;
    fonts: IFonts;
    theme: enumTheme;
    giftRegistryReservations?: IGiftRegistryReservations[];
    activeDate?: string;
    customDomainActive?: boolean;
    customDomainActiveDate?: string;
    customDomainRegistrationsAvailable?: number;
    siteGraphic?: TSiteGraphic | null;
    mostRecentSelectedDesignTemplate?: string;
}

export interface ISiteV2Rest extends ISiteV2 {}

export interface ISiteV2 {
    host: string;
    _id: string;
    usesSiteModules: true;
    active: boolean;
    password: string | null;
    activeDate: string | null;
    customDomainActive: boolean;
    customDomainActiveDate: string | null;
    customDomainRegistrationsAvailable: number;
    metaNoindex: boolean;
    giftRegistryReservations: IGiftRegistryReservations[];
    hasRsvps: boolean;
    quickStart: boolean;
    siteVersion: string;

    availableLanguages: ILanguage[];
    siteModules: TSiteModule[];
    colorScheme: IColorScheme;
    fonts: IFonts;
    theme: enumTheme;
    siteGraphic?: TSiteGraphic | null;
    mostRecentSelectedDesignTemplate?: string;
    formRecipients: IFormRecipients[];
}

export interface ISiteVersion {
    availableLanguages: ILanguage[];
    siteModules: TSiteModule[];
    colorScheme: IColorScheme;
    fonts: IFonts;
    theme: enumTheme;
    siteGraphic?: TSiteGraphic | null;
    mostRecentSelectedDesignTemplate?: string;
    formRecipients: IFormRecipients[];
    siteVersion: string;
}

export interface IFormRecipients {
    formId: string;
    recipients: string[];
}

export interface ILanguage {
    languageCode: TLanguageCode;
}

export interface IFonts {
    heading: TFontHeading;
    text: TFontText;
}

export interface IColor {
    h: number;
    s: number;
    l: number;
    a: number;
}

export interface IColorScheme {
    primary: IColor;
    background: IColor;
    surface: IColor;
    onPrimary: IColor;
    onBackground: IColor;
    onSurface: IColor;
}

export interface IImageRest {
    _id?: string;
    id: string;
    path: string;
    fileName: string;
    date?: Date;
    temporary?: boolean; // Deprecated
    cdn?: boolean;
    sizes?: IImageSizeRest[];
    originalName?: string;
    imageBeforeEdit?: {
        path: string;
        fileName: string;
        sizes?: IImageSizeRest[];
    };
}

export interface IImage {
    _id?: string;
    id: string;
    path: string;
    fileName: string;
    date?: Date;
    temporary?: boolean; // Deprecated
    cdn?: boolean;
    sizes?: IImageSize[];
    originalName?: string;
    imageBeforeEdit?: {
        path: string;
        fileName: string;
        sizes?: IImageSize[];
    };
}

export interface IImageSize {
    size: number;
    fileName: string;
    _id?: string;
}

export interface IImageSizeRest {
    size: number;
    fileName: string;
    _id?: string;
}
