import { useLocation, useParams } from "react-router-dom";

export function useEditSiteNavigationBasePath() {
    const location = useLocation();

    const { siteId } = useParams<{ siteId?: string }>();

    if (location.pathname.includes("snabbstart")) {
        const urlParts = location.pathname.split("/");
        if (urlParts[1] === "snabbstart") {
            return "/snabbstart/";
        }
        if (urlParts[2] === "snabbstart") {
            return "/" + urlParts[1] + "/snabbstart/";
        }
        throw new Error("Location pathname includes snabbstart but does not match correct pattern");
    }

    if (location.pathname.includes("kom-igang")) {
        const urlParts = location.pathname.split("/");
        if (urlParts[1] === "kom-igang") {
            return "/kom-igang/";
        }
        if (urlParts[2] === "kom-igang") {
            return "/" + urlParts[1] + "/kom-igang/";
        }
        throw new Error("Location pathname includes kom-igang but does not match correct pattern");
    }

    if (!siteId) {
        throw new Error("Not snabbstart but not an siteId either");
    }

    return "/redigera/" + siteId + "/";
}
