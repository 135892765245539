import { useState } from "react";
import {
    IErrorPublic,
    responseDeprecatedServerErrorResponse,
    responseIsServerErrorResponse,
} from "../../interfaces/IErrorV2";
import { DialogPublic } from "../DialogPublic/DialogPublic";
import { useTranslation } from "../../../contexts/LanguageContext";
import { TTranslation } from "../../interfaces/IContentData";

interface IProps {
    error: IErrorPublic | null;
}

const errorMessageFromError = (error: IErrorPublic): TTranslation => {
    const response = error.response;
    if (responseIsServerErrorResponse(response)) {
        return response.data.errorDescription;
    }
    if (responseDeprecatedServerErrorResponse(response)) {
        return { sv: response.data.message };
    }
    if (error.description) {
        return error.description;
    }
    return { sv: "Ett okänt fel har uppstått.", en: "A unknown error occured." };
};

export const ErrorDialogPublic = (props: IProps) => {
    const [closedError, setClosedError] = useState<IErrorPublic>();
    const { getInTranslation } = useTranslation();

    if (!props.error) {
        return null;
    }

    if (closedError === props.error) {
        return null;
    }

    const errorMessage = getInTranslation(errorMessageFromError(props.error));

    const handleClose = () => {
        if (!props.error) throw new Error("No error in error dialog edit");
        setClosedError(props.error);
    };

    return (
        <DialogPublic open={true} padding={true} handleClose={handleClose} closableByOutsideClickAndEsc={true}>
            <>
                <h2>Ett fel uppstod</h2>
                <p>{errorMessage}</p>
            </>
        </DialogPublic>
    );
};
