import { useEffect, useState } from "react";
import styles from "./PrintProductRow.module.scss";
import { IError } from "../../../../../../../App";
import { getPrint } from "../../../../../../services/PrintService";
import Loading from "../../../../../../components/Loading/Loading";
import ShowError from "../../../../../../components/ShowError/ShowError";
import { IPrintableCard } from "../../../ViewCommunication/components/EditPrint/components/PrintEditor/interfaces/IPrintableCard";
import { PrintableCard } from "../../../ViewCommunication/components/EditPrint/components/PrintEditor/components/PrintableCard/PrintableCard";
import Select from "../../../../../../components/form/Select/Select";
import { getPrintProductCostFormatted } from "../../utils/getPrintProductCost";

interface IProps {
    printId: string;
    envelopes: "standard" | "premium" | "none";
    setEnvelopes: (envelopes: "standard" | "premium" | "none") => void;
    numberOfPrints: number;
    setNumberOfPrints: (envelopes: number) => void;
    setTrim: (trim: "none" | "rounded") => void;
}

const numberOfPrintsOptions = [...Array(30)].map((_, index) => ({
    title: (index + 1) * 10 + " stycken",
    value: (index + 1) * 10 + "",
}));

const envelopOptions = [
    { title: "Inga kuvert", value: "none" },
    { title: "Standardkuvert (3 kr/st)", value: "standard" },
    { title: "Premiumkuvert (5 kr/st)", value: "premium" },
];

export function PrintProductRow(props: IProps) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<IError | null>(null);
    const [print, setPrint] = useState<IPrintableCard | null>(null);

    useEffect(() => {
        const getPrintFromId = async () => {
            setLoading(true);
            try {
                const print = await getPrint(props.printId);
                setPrint(print);
                props.setTrim(print.trim)
            } catch (error) {
                setError({ text: "Misslyckades att hämta tryck", response: (error as any).response });
            }
            setLoading(false);
        };
        getPrintFromId();
    }, [props, props.printId, setPrint]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <ShowError error={error} />;
    }
    if (!print) {
        throw new Error("Print not available but not loading state");
    }

    return (
        <>
            <div className={`${styles.wrapper}`}>
                <div className={styles.thumbnail}>
                    <PrintableCard
                        thumbnail={true}
                        forPrint={false}
                        page={1}
                        shadow={true}
                        card={print}
                        editMode={false}
                    />
                    <PrintableCard
                        thumbnail={true}
                        forPrint={false}
                        page={2}
                        shadow={true}
                        card={print}
                        editMode={false}
                    />
                </div>
                <div className={styles.options}>
                    <div>
                        <Select
                            id="numberOfCards"
                            label="Antal kort (15 kr/st)"
                            options={numberOfPrintsOptions}
                            defaultValue={props.numberOfPrints}
                            onChangeCallback={(val: string) => {
                                props.setNumberOfPrints(parseInt(val));
                            }}
                        />
                    </div>
                    <div>
                        <Select
                            id="envelopes"
                            label="Kuvert"
                            options={envelopOptions}
                            defaultValue={props.envelopes}
                            onChangeCallback={props.setEnvelopes}
                        />
                    </div>
                    {print.trim === "rounded" ? <div>Rundade hörn 2 kr/st</div> : null}
                    <div>
                        Kostnad: {getPrintProductCostFormatted(props.envelopes, props.numberOfPrints, print.trim)}
                    </div>
                </div>
            </div>
        </>
    );
}
