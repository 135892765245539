import { ContentBox } from "../../../../../../editComponents/ContentBox/ContentBox";
import { ISiteModuleRsvp } from "../../../../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { EmulateContentSurface } from "../../../../../components/EmulateContentSurface/EmulateContentSurface";
import { LanguageTitle } from "../../../../../components/LanguageTitle/LanguageTitle";
import { VerticalGapOnChildren } from "../../../../../components/VerticalGapOnChildren/VerticalGapOnChildren";
import { GuestHeadingEdit } from "../../../GuestHeading/GuestHeadingEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleRsvp;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleRsvp, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
}

export const GuestTitleEdit = (props: IProps) => {
    return (
        <>
            <ContentBox padding={true} title="Gästens rubrik">
                <p>Varje gäst har en rubrik. Tex. Person 1, Person 2 etc. Tryck på texten för att ändra den.</p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <EmulateContentSurface site={props.site}>
                                <GuestHeadingEdit
                                    site={props.site}
                                    siteModule={props.siteModule}
                                    updateSiteModuleProperty={props.updateSiteModuleProperty}
                                    guestNumber={1}
                                    language={language.languageCode}
                                />
                            </EmulateContentSurface>
                        </div>
                    ))}
                </VerticalGapOnChildren>
            </ContentBox>
        </>
    );
};
