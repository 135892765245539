import { TFormElement } from "../../../../interfaces/FormElement";
import { IFormData } from "../../../../interfaces/IFormData";
import { showFormElementWithFormCondition } from "../../../../utils/showFormElementWithFormCondition";

interface IProps {
    formElement: TFormElement;
    children: JSX.Element;
    formData: IFormData;
}

export const VisibilityWithFormCondition = (props: IProps) => {
    if (showFormElementWithFormCondition(props.formElement, props.formData)) {
        return props.children;
    } else {
        return null;
    }
};
