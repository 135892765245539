import { ContentTypeModuleHeading } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeading";
import { ISiteModulePeopleList } from "../../interfaces/ISiteModule";
import { ISiteV2 } from "../../interfaces/Site";
import { SiteModulePeopleListItem } from "./SiteModulePeopleListItem/SiteModulePeopleListItem";
import styles from "./SiteModulePeopleList.module.scss";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModulePeopleList;
}

export const SiteModulePeopleList = (props: IProps) => {

    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeading
                contentData={props.siteModule.title}
                site={props.site}
            />
            {props.siteModule.list.length ? (
                <div className={styles.itemsWrapper}>
                    {props.siteModule.list.map((listItem) => (
                        <SiteModulePeopleListItem
                            site={props.site}
                            listItem={listItem}
                            key={listItem.id}
                        />
                    ))}
                </div>
            ) : null}
        </section>
    );
};
