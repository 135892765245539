import { useTranslation } from "../../../../../contexts/LanguageContext";
import { IFormElementShortText } from "../../../../interfaces/FormElement";
import { ISiteV2 } from "../../../../interfaces/Site";
import { Input } from "./components/Input/Input";
import styles from "./FormElementShortText.module.scss";

interface IProps {
    site: ISiteV2;
    formElement: IFormElementShortText;
    handleValueChange: (formElement: IFormElementShortText, value: string) => void;
    formPartIdentifier: string;
    value: string;
}

export const FormElementShortText = (props: IProps) => {
    const { getInTranslation } = useTranslation();
    const textfieldIdentifier = props.formPartIdentifier + "-" + props.formElement.id;
    return (
        <div className={styles.wrapper}>
            <Input
                label={getInTranslation(props.formElement.label)}
                value={props.value}
                site={props.site}
                name={textfieldIdentifier}
                id={textfieldIdentifier}
                handleChange={(value: string) => {
                    props.handleValueChange(props.formElement, value);
                }}
                required={props.formElement.required}
            />
        </div>
    );
};
