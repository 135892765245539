import Button from "../../../components/button/Button";
import styles from "./SiteModulesEditEmpty.module.scss";

interface IProps {
    addModule: () => void;
}

export const SiteModulesEditEmpty = (props: IProps) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.text}>Alla moduler är borttagna</div>
            <Button buttonText="Lägg till ny modul" type="primary" callback={props.addModule} />
        </div>
    );
};
