import React from "react";
import styles from "./Label.module.scss";

interface IProps {
    htmlFor: null | string;
    children: JSX.Element | string;
    className?: string;
}

export const Label = (props: IProps) => {
    return (
        <label htmlFor={props.htmlFor || undefined} className={`${styles.wrapper} ${props.className}`}>
            {props.children}
        </label>
    );
};
