import { randomId } from "../../../../../utils/Random";
import { IRsvpV2 } from "../ViewRsvp";

export const generateDemoRsvps = (): IRsvpV2[] => {
    const rsvps: IRsvpV2[] = [
        createDemoRsvp(1, "Elisabeth", "Johansson", true, "Ja t/r", "Vegetarian", "I natt är jag din"),
        createDemoRsvp(2, "Mohammed", "Cazhes", true, "Ja t/r", "", "Disco metal"),
        createDemoRsvp(3, "Lisa", "Cazhes", true, "Ja t/r", "Vegan", "Making Your Mind Up - Bucks Fizz"),
        createDemoRsvp(4, "Christina", "Kande", false, "Nej tack", "", ""),
        createDemoRsvp(5, "Stefan", "Kande", false, "Nej tack", "", ""),
        createDemoRsvp(6, "Elin", "Johanesson", true, "Ja t/r", "", "Simply the best"),
        createDemoRsvp(7, "Kalle", "Johanesson", true, "Ja hem", "", "Det är ju dig jag går och väntar på"),
        createDemoRsvp(8, "Johan", "Davidsson", true, "Ja hem", "", "Abba - Does your mother know"),
        createDemoRsvp(9, "Robert", "Stella", true, "Ja t/r", "Vegetarian", "Linda Bengtzing - hur svårt kan det va"),
        createDemoRsvp(10, "Gustaf", "Stella", true, "Ja t/r", "", "Gasolina med Daddy Yankee!"),
        createDemoRsvp(11, "Lina", "Hedlund", true, "Ja t/r", "Nötter", "Dancing Queen"),
        createDemoRsvp(12, "Cajsa", "Modig", true, "Ja, dit", "", "It's gonna be Me - Nsync"),
        createDemoRsvp(13, "Kent", "Vikander", true, "Ja t/r", "Färsk ananas", "One more time - Daft punk"),
        createDemoRsvp(14, "Lisa", "Vikander", true, "Ja t/r", "", "Stayin Alive"),
        createDemoRsvp(15, "Alice", "Eng", true, "Nej tack", "", "Knaan - Wavin flag"),
        createDemoRsvp(16, "Jesper", "Mejvel", true, "Ja, dit", "", "Papaoutai - med Stromae"),
        createDemoRsvp(17, "Julia", "Mejvel", true, "Nej tack", "", "Eminem - Houdini"),
        createDemoRsvp(18, "Gunde", "Andersson", true, "Nej tack", "", "Euphoria - Loreen"),
        createDemoRsvp(19, "Per-Olof", "Ängbotten", true, "Ja t/r", "Nötter", "I feel love, donna summer"),
        createDemoRsvp(20, "Monica", "Ängbotten", true, "Ja t/r", "", "Levels"),
        createDemoRsvp(21, "Bo", "Davidsson", false, "Nej tack", "", ""),
    ];
    return rsvps;
};

const createDemoRsvp = (
    number: number,
    firstName: string,
    lastname: string,
    attending: boolean,
    transport: "Ja t/r" | "Ja, dit" | "Ja hem" | "Nej tack",
    food: "" | "Vegetarian" | "Vegan" | "Nötter" | "Färsk ananas",
    song: string
): IRsvpV2 => {
    const rsvp: IRsvpV2 = {
        _id: "demo-id-db" + randomId(),
        formTitle: { sv: "OSA" },
        createdAt: "2024-06-" + (29 - number).toString().padStart(2, "0") + "T12:27:46.277Z",
        recipients: [],
        language: "sv",
        groupIdentifier: randomId(),
        formElements: [
            {
                formElement: {
                    id: "demo-formElement-firstname",
                    formElementType: "ShortText",
                    required: true,
                    showConditions: [],
                    label: { sv: "Förnamn" },
                },
                value: firstName,
            },
            {
                formElement: {
                    id: "demo-formElement-lastname",
                    formElementType: "ShortText",
                    required: true,
                    showConditions: [],
                    label: { sv: "Efternamn" },
                },
                value: lastname,
            },
            {
                formElement: {
                    id: "demo-formElement-email",
                    formElementType: "ShortText",
                    required: true,
                    showConditions: [],
                    label: { sv: "E-post" },
                },
                value: firstName + "@exempelmejl.com",
            },
            {
                formElement: {
                    id: "demo-formElement-phone",
                    formElementType: "ShortText",
                    required: true,
                    showConditions: [],
                    label: { sv: "Telefon" },
                },
                value: "070212345" + number.toString().padStart(2, "0"),
            },
            {
                formElement: {
                    id: "demo-formElement-attending",
                    formElementType: "SingleSelect",
                    showConditions: [],
                    required: true,
                    label: { sv: "Kommer du?" },
                    options: [],
                },
                selectedOption: { id: attending + "demoid", text: { sv: attending ? "Ja!" : "Nej" } },
            },
            {
                formElement: {
                    id: "demo-formElement-transport",
                    formElementType: "SingleSelect",
                    showConditions: [],
                    required: true,
                    options: [],
                    label: { sv: "Vill du åka buss?" },
                },
                selectedOption: { id: transport + "demoid", text: { sv: transport } },
            },
            {
                formElement: {
                    id: "demo-formElement-food",
                    formElementType: "ShortText",
                    required: true,
                    showConditions: [],
                    label: { sv: "Specialkost" },
                },
                value: food,
            },
            {
                formElement: {
                    id: "demo-formElement-song",
                    formElementType: "ShortText",
                    required: true,
                    showConditions: [],
                    label: { sv: "Om denna låten kommer på festen lovar jag att dansa" },
                },
                value: song,
            },
        ],
        formId: "demo-rvsps",
    };
    return rsvp;
};
