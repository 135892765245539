/*
The initial idea was to let the browser translate this with above function. It did the work mostly, but not all languages.
When it was discovered that bosniska did not work on chrome. I used this code to generate the const below instead using safari localy on my computer.
Not all languages are available, but probably the most?

const cachedTranslations: Partial<{ [key in TLanguageCode]: IDateUnitsTranslation }> = {};

const addNewLanguage = (locale: TLanguageCode): IDateUnitsTranslation => {
    const translatePlural = (type: "days" | "seconds" | "minutes" | "hours") => {
        try {
            const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });
            // This is made up by yourself. So this might not work in some languages. If customers reports back. Do it manually or fix it.
            return (
                rtf
                    .formatToParts(100, type)
                    .reverse()
                    .find((item) => item.type === "literal")
                    ?.value.trim() || ""
            );
        } catch (e) {
            return "";
        }
    };

    const translateSingular = (type: "day" | "second" | "minute" | "hour") => {
        try {
            return new Intl.DisplayNames(locale, { type: "dateTimeField" }).of(type) || "";
        } catch (e) {
            return "";
        }
    };

    const translations = {
        days: translatePlural("days"),
        day: translateSingular("day"),
        seconds: translatePlural("seconds"),
        second: translateSingular("second"),
        minute: translateSingular("minute"),
        minutes: translatePlural("minutes"),
        hours: translatePlural("hours"),
        hour: translateSingular("hour"),
    };

    cachedTranslations[locale] = translations;
    return translations;
};
*/

import { TLanguageCode } from "../../../../../interfaces/IContentData";

export interface IDateUnitsTranslation {
    hour: string;
    hours: string;
    minute: string;
    minutes: string;
    second: string;
    seconds: string;
    days: string;
    day: string;
}

export const COUNTDOWN_TRANSLATIONS: Partial<{ [key in TLanguageCode]: IDateUnitsTranslation }> = {
    sv: {
        days: "dagar",
        day: "dag",
        seconds: "sekunder",
        second: "sekund",
        minute: "minut",
        minutes: "minuter",
        hours: "timmar",
        hour: "timme",
    },
    af: {
        days: "dae",
        day: "dag",
        seconds: "sekondes",
        second: "sekonde",
        minute: "minuut",
        minutes: "minute",
        hours: "uur",
        hour: "uur",
    },
    sq: {
        days: "ditësh",
        day: "ditë",
        seconds: "sekondash",
        second: "sekondë",
        minute: "minutë",
        minutes: "minutash",
        hours: "orësh",
        hour: "orë",
    },
    am: {
        days: "ቀናት ውስጥ",
        day: "ቀን",
        seconds: "ሰከንዶች ውስጥ",
        second: "ሰከንድ",
        minute: "ደቂቃ",
        minutes: "ደቂቃዎች ውስጥ",
        hours: "ሰዓቶች ውስጥ",
        hour: "ሰዓት",
    },
    ar: {
        days: "يوم",
        day: "يوم",
        seconds: "ثانية",
        second: "الثواني",
        minute: "الدقائق",
        minutes: "دقيقة",
        hours: "ساعة",
        hour: "الساعات",
    },
    hy: {
        days: "օրից",
        day: "օր",
        seconds: "վայրկյանից",
        second: "վայրկյան",
        minute: "րոպե",
        minutes: "րոպեից",
        hours: "ժամից",
        hour: "ժամ",
    },
    as: {
        days: "দিনত",
        day: "দিন",
        seconds: "ছেকেণ্ডত",
        second: "ছেকেণ্ড",
        minute: "মিনিট",
        minutes: "মিনিটত",
        hours: "ঘণ্টাত",
        hour: "ঘণ্টা",
    },
    az: {
        days: "gün ərzində",
        day: "Gün",
        seconds: "saniyə ərzində",
        second: "Saniyə",
        minute: "Dəqiqə",
        minutes: "dəqiqə ərzində",
        hours: "saat ərzində",
        hour: "Saat",
    },
    eu: {
        days: "egun barru",
        day: "eguna",
        seconds: "segundo barru",
        second: "segundoa",
        minute: "minutua",
        minutes: "minutu barru",
        hours: "ordu barru",
        hour: "ordua",
    },
    be: {
        days: "дзён",
        day: "дзень",
        seconds: "секунд",
        second: "секунда",
        minute: "хвіліна",
        minutes: "хвілін",
        hours: "гадзін",
        hour: "гадзіна",
    },
    bn: {
        days: "দিনের মধ্যে",
        day: "দিন",
        seconds: "সেকেন্ডে",
        second: "সেকেন্ড",
        minute: "মিনিট",
        minutes: "মিনিটে",
        hours: "ঘন্টায়",
        hour: "ঘণ্টা",
    },
    bs: {
        days: "dana",
        day: "dan",
        seconds: "sekundi",
        second: "sekunda",
        minute: "minuta",
        minutes: "minuta",
        hours: "sati",
        hour: "sat",
    },
    br: {
        days: "deiz",
        day: "deiz",
        seconds: "eilenn",
        second: "eilenn",
        minute: "munut",
        minutes: "munut",
        hours: "eur",
        hour: "eur",
    },
    bg: {
        days: "дни",
        day: "ден",
        seconds: "секунди",
        second: "секунда",
        minute: "минута",
        minutes: "минути",
        hours: "часа",
        hour: "час",
    },
    my: {
        days: "ရက်အတွင်း",
        day: "ရက်",
        seconds: "စက္ကန့်အတွင်း",
        second: "စက္ကန့်",
        minute: "မိနစ်",
        minutes: "မိနစ်အတွင်း",
        hours: "နာရီအတွင်း",
        hour: "နာရီ",
    },
    ca: {
        days: "dies",
        day: "dia",
        seconds: "segons",
        second: "segon",
        minute: "minut",
        minutes: "minuts",
        hours: "hores",
        hour: "hora",
    },
    km: {
        days: "ថ្ងៃទៀត",
        day: "ថ្ងៃ",
        seconds: "វិនាទីទៀត",
        second: "វិនាទី",
        minute: "នាទី",
        minutes: "នាទីទៀត",
        hours: "ម៉ោង",
        hour: "ម៉ោង",
    },
    ce: {
        days: "де даьлча",
        day: "де",
        seconds: "секунд яьлча",
        second: "секунд",
        minute: "минот",
        minutes: "минот яьлча",
        hours: "сахьт даьлча",
        hour: "сахьт",
    },
    zh: {
        days: "天后",
        day: "日",
        seconds: "秒钟后",
        second: "秒",
        minute: "分钟",
        minutes: "分钟后",
        hours: "小时后",
        hour: "小时",
    },
    hr: {
        days: "dana",
        day: "dan",
        seconds: "sekundi",
        second: "sekunda",
        minute: "minuta",
        minutes: "minuta",
        hours: "sati",
        hour: "sat",
    },
    cs: {
        days: "dní",
        day: "den",
        seconds: "sekund",
        second: "sekunda",
        minute: "minuta",
        minutes: "minut",
        hours: "hodin",
        hour: "hodina",
    },
    da: {
        days: "dage",
        day: "dag",
        seconds: "sekunder",
        second: "sekund",
        minute: "minut",
        minutes: "minutter",
        hours: "timer",
        hour: "time",
    },
    nl: {
        days: "dagen",
        day: "dag",
        seconds: "seconden",
        second: "seconde",
        minute: "minuut",
        minutes: "minuten",
        hours: "uur",
        hour: "uur",
    },
    dz: {
        days: "ནང་",
        day: "ཚེས་",
        seconds: "ནང་",
        second: "སྐར་ཆཱ་",
        minute: "སྐར་མ",
        minutes: "ནང་",
        hours: "ནང་",
        hour: "ཆུ་ཚོད",
    },
    en: {
        days: "days",
        day: "day",
        seconds: "seconds",
        second: "second",
        minute: "minute",
        minutes: "minutes",
        hours: "hours",
        hour: "hour",
    },
    et: {
        days: "päeva pärast",
        day: "päev",
        seconds: "sekundi pärast",
        second: "sekund",
        minute: "minut",
        minutes: "minuti pärast",
        hours: "tunni pärast",
        hour: "tund",
    },
    ee: {
        days: "wo me",
        day: "ŋkeke",
        seconds: "wo me",
        second: "sekend",
        minute: "aɖabaƒoƒo",
        minutes: "wo me",
        hours: "wo me",
        hour: "gaƒoƒo",
    },
    fo: {
        days: "dagar",
        day: "dagur",
        seconds: "sekund",
        second: "sekund",
        minute: "minuttur",
        minutes: "minuttir",
        hours: "tímar",
        hour: "tími",
    },
    fi: {
        days: "päivän päästä",
        day: "päivä",
        seconds: "sekunnin päästä",
        second: "sekunti",
        minute: "minuutti",
        minutes: "minuutin päästä",
        hours: "tunnin päästä",
        hour: "tunti",
    },
    fr: {
        days: "jours",
        day: "jour",
        seconds: "secondes",
        second: "seconde",
        minute: "minute",
        minutes: "minutes",
        hours: "heures",
        hour: "heure",
    },
    gd: {
        days: "latha",
        day: "latha",
        seconds: "diog",
        second: "diog",
        minute: "mionaid",
        minutes: "mionaid",
        hours: "uair a thìde",
        hour: "uair a thìde",
    },
    gl: {
        days: "días",
        day: "día",
        seconds: "segundos",
        second: "segundo",
        minute: "minuto",
        minutes: "minutos",
        hours: "horas",
        hour: "hora",
    },
    ka: {
        days: "დღეში",
        day: "დღე",
        seconds: "წამში",
        second: "წამი",
        minute: "წუთი",
        minutes: "წუთში",
        hours: "საათში",
        hour: "საათი",
    },
    de: {
        days: "Tagen",
        day: "Tag",
        seconds: "Sekunden",
        second: "Sekunde",
        minute: "Minute",
        minutes: "Minuten",
        hours: "Stunden",
        hour: "Stunde",
    },
    el: {
        days: "ημέρες",
        day: "ημέρα",
        seconds: "δευτερόλεπτα",
        second: "δευτερόλεπτο",
        minute: "λεπτό",
        minutes: "λεπτά",
        hours: "ώρες",
        hour: "ώρα",
    },
    kl: {
        days: "ulloq unnuarlu",
        day: "Day",
        seconds: "sekundi",
        second: "Second",
        minute: "Minute",
        minutes: "minutsi",
        hours: "nalunaaquttap-akunnera",
        hour: "Hour",
    },
    gu: {
        days: "દિવસમાં",
        day: "દિવસ",
        seconds: "સેકંડમાં",
        second: "સેકન્ડ",
        minute: "મિનિટ",
        minutes: "મિનિટમાં",
        hours: "કલાકમાં",
        hour: "કલાક",
    },
    ha: {
        days: "a cikin kwanaki",
        day: "kwana",
        seconds: "dakika",
        second: "daƙiƙa",
        minute: "minti",
        minutes: "minti",
        hours: "awa",
        hour: "awa",
    },
    he: {
        days: "ימים",
        day: "יום",
        seconds: "שניות",
        second: "שנייה",
        minute: "דקה",
        minutes: "דקות",
        hours: "שעות",
        hour: "שעה",
    },
    hi: {
        days: "दिन में",
        day: "दिन",
        seconds: "सेकंड में",
        second: "सेकंड",
        minute: "मिनट",
        minutes: "मिनट में",
        hours: "घंटे में",
        hour: "घंटा",
    },
    hu: {
        days: "nap múlva",
        day: "nap",
        seconds: "másodperc múlva",
        second: "másodperc",
        minute: "perc",
        minutes: "perc múlva",
        hours: "óra múlva",
        hour: "óra",
    },
    is: {
        days: "daga",
        day: "dagur",
        seconds: "sekúndur",
        second: "sekúnda",
        minute: "mínúta",
        minutes: "mínútur",
        hours: "klukkustundir",
        hour: "klukkustund",
    },
    id: {
        days: "hari",
        day: "hari",
        seconds: "detik",
        second: "detik",
        minute: "menit",
        minutes: "menit",
        hours: "jam",
        hour: "jam",
    },
    ia: {
        days: "dies",
        day: "die",
        seconds: "secundas",
        second: "secunda",
        minute: "minuta",
        minutes: "minutas",
        hours: "horas",
        hour: "hora",
    },
    ga: {
        days: "lá",
        day: "lá",
        seconds: "soicind",
        second: "soicind",
        minute: "nóiméad",
        minutes: "nóiméad",
        hours: "uair an chloig",
        hour: "uair",
    },
    it: {
        days: "giorni",
        day: "giorno",
        seconds: "secondi",
        second: "secondo",
        minute: "minuto",
        minutes: "minuti",
        hours: "ore",
        hour: "ora",
    },
    ja: {
        days: "日後",
        day: "日",
        seconds: "秒後",
        second: "秒",
        minute: "分",
        minutes: "分後",
        hours: "時間後",
        hour: "時",
    },
    jv: {
        days: "dina",
        day: "dino",
        seconds: "detik",
        second: "detik",
        minute: "menit",
        minutes: "menit",
        hours: "jam",
        hour: "jam",
    },
    kn: {
        days: "ದಿನಗಳಲ್ಲಿ",
        day: "ದಿನ",
        seconds: "ಸೆಕೆಂಡ್‌ಗಳಲ್ಲಿ",
        second: "ಸೆಕೆಂಡ್",
        minute: "ನಿಮಿಷ",
        minutes: "ನಿಮಿಷಗಳಲ್ಲಿ",
        hours: "ಗಂಟೆಗಳಲ್ಲಿ",
        hour: "ಗಂಟೆ",
    },
    kk: {
        days: "күннен кейін",
        day: "күн",
        seconds: "секундтан кейін",
        second: "секунд",
        minute: "минут",
        minutes: "минуттан кейін",
        hours: "сағаттан кейін",
        hour: "сағат",
    },
    ko: {
        days: "일 후",
        day: "일",
        seconds: "초 후",
        second: "초",
        minute: "분",
        minutes: "분 후",
        hours: "시간 후",
        hour: "시",
    },
    ky: {
        days: "күндөн кийин",
        day: "күн",
        seconds: "секунддан кийин",
        second: "секунд",
        minute: "мүнөт",
        minutes: "мүнөттөн кийин",
        hours: "сааттан кийин",
        hour: "саат",
    },
    lo: {
        days: "ມື້",
        day: "ມື້",
        seconds: "ວິນາທີ",
        second: "ວິນາທີ",
        minute: "ນາທີ",
        minutes: "ນາທີ",
        hours: "ຊົ່ວໂມງ",
        hour: "ຊົ່ວໂມງ",
    },
    lv: {
        days: "dienām",
        day: "diena",
        seconds: "sekundēm",
        second: "sekundes",
        minute: "minūtes",
        minutes: "minūtēm",
        hours: "stundām",
        hour: "stundas",
    },
    lb: {
        days: "Deeg",
        day: "Dag",
        seconds: "Sekonnen",
        second: "Sekonn",
        minute: "Minutt",
        minutes: "Minutten",
        hours: "Stonnen",
        hour: "Stonn",
    },
    lt: {
        days: "dienų",
        day: "diena",
        seconds: "sekundžių",
        second: "sekundė",
        minute: "minutė",
        minutes: "minučių",
        hours: "valandų",
        hour: "valanda",
    },
    mk: {
        days: "дена",
        day: "ден",
        seconds: "секунди",
        second: "секунда",
        minute: "минута",
        minutes: "минути",
        hours: "часа",
        hour: "час",
    },
    ms: {
        days: "hari",
        day: "hari",
        seconds: "saat",
        second: "saat",
        minute: "minit",
        minutes: "minit",
        hours: "jam",
        hour: "jam",
    },
    ml: {
        days: "ദിവസത്തിൽ",
        day: "ദിവസം",
        seconds: "സെക്കൻഡിൽ",
        second: "സെക്കൻഡ്",
        minute: "മിനിറ്റ്",
        minutes: "മിനിറ്റിൽ",
        hours: "മണിക്കൂറിൽ",
        hour: "മണിക്കൂർ",
    },
    mt: {
        days: "ġurnata oħra",
        day: "jum",
        seconds: "sekonda oħra",
        second: "sekonda",
        minute: "minuta",
        minutes: "minuta oħra",
        hours: "siegħa",
        hour: "siegħa",
    },
    mi: {
        days: "rā",
        day: "rā",
        seconds: "hēkona",
        second: "hēkona",
        minute: "meneti",
        minutes: "meneti",
        hours: "haora",
        hour: "hāora",
    },
    mr: {
        days: "दिवसांमध्ये",
        day: "दिवस",
        seconds: "सेकंदांमध्ये",
        second: "सेकंद",
        minute: "मिनिट",
        minutes: "मिनिटांमध्ये",
        hours: "तासांमध्ये",
        hour: "तास",
    },
    ro: {
        days: "de zile",
        day: "zi",
        seconds: "de secunde",
        second: "secundă",
        minute: "minut",
        minutes: "de minute",
        hours: "de ore",
        hour: "oră",
    },
    mn: {
        days: "өдрийн дараа",
        day: "өдөр",
        seconds: "секундын дараа",
        second: "секунд",
        minute: "минут",
        minutes: "минутын дараа",
        hours: "цагийн дараа",
        hour: "цаг",
    },
    ne: {
        days: "दिनमा",
        day: "बार",
        seconds: "सेकेन्डमा",
        second: "सेकेन्ड",
        minute: "मिनेट",
        minutes: "मिनेटमा",
        hours: "घण्टामा",
        hour: "घण्टा",
    },
    se: {
        days: "jándora maŋŋilit",
        day: "beaivi",
        seconds: "sekundda maŋŋilit",
        second: "sekunda",
        minute: "minuhtta",
        minutes: "minuhtta maŋŋilit",
        hours: "diibmur maŋŋilit",
        hour: "diibmu",
    },
    no: {
        days: "dager",
        day: "dag",
        seconds: "sekunder",
        second: "sekund",
        minute: "minutt",
        minutes: "minutter",
        hours: "timer",
        hour: "time",
    },
    nb: {
        days: "dager",
        day: "dag",
        seconds: "sekunder",
        second: "sekund",
        minute: "minutt",
        minutes: "minutter",
        hours: "timer",
        hour: "time",
    },
    nn: {
        days: "dager",
        day: "dag",
        seconds: "sekund",
        second: "sekund",
        minute: "minutt",
        minutes: "minutt",
        hours: "timar",
        hour: "time",
    },
    or: {
        days: "ଦିନରେ",
        day: "ଦିନ",
        seconds: "ସେକେଣ୍ଡରେ",
        second: "ସେକେଣ୍ଡ୍",
        minute: "ମିନିଟ୍",
        minutes: "ମିନିଟ୍‌‌ରେ",
        hours: "ଘଣ୍ଟାରେ",
        hour: "ଘଣ୍ଟା",
    },
    os: {
        days: "боны фӕстӕ",
        day: "Бон",
        seconds: "s",
        second: "Секунд",
        minute: "Минут",
        minutes: "min",
        hours: "сахаты фӕстӕ",
        hour: "Сахат",
    },
    pa: {
        days: "ਦਿਨਾਂ ਵਿੱਚ",
        day: "ਦਿਨ",
        seconds: "ਸਕਿੰਟਾਂ ਵਿੱਚ",
        second: "ਸਕਿੰਟ",
        minute: "ਮਿੰਟ",
        minutes: "ਮਿੰਟਾਂ ਵਿੱਚ",
        hours: "ਘੰਟਿਆਂ ਵਿੱਚ",
        hour: "ਘੰਟਾ",
    },
    ps: {
        days: "ورځو کې",
        day: "ورځ",
        seconds: "ثانيو کې",
        second: "ثانيه",
        minute: "دقيقه",
        minutes: "دقيقو کې",
        hours: "ساعتو کې",
        hour: "ساعت",
    },
    fa: {
        days: "روز دیگر",
        day: "روز",
        seconds: "ثانیه بعد",
        second: "ثانیه",
        minute: "دقیقه",
        minutes: "دقیقه بعد",
        hours: "ساعت بعد",
        hour: "ساعت",
    },
    pl: {
        days: "dni",
        day: "dzień",
        seconds: "sekund",
        second: "sekunda",
        minute: "minuta",
        minutes: "minut",
        hours: "godzin",
        hour: "godzina",
    },
    pt: {
        days: "dias",
        day: "dia",
        seconds: "segundos",
        second: "segundo",
        minute: "minuto",
        minutes: "minutos",
        hours: "horas",
        hour: "hora",
    },
    ru: {
        days: "дней",
        day: "день",
        seconds: "секунд",
        second: "секунда",
        minute: "минута",
        minutes: "минут",
        hours: "часов",
        hour: "час",
    },
    sc: {
        days: "dies",
        day: "die",
        seconds: "segundos",
        second: "segundu",
        minute: "minutu",
        minutes: "minutos",
        hours: "oras",
        hour: "ora",
    },
    sr: {
        days: "дана",
        day: "дан",
        seconds: "секунди",
        second: "секунд",
        minute: "минут",
        minutes: "минута",
        hours: "сати",
        hour: "сат",
    },
    sd: {
        days: "ڏينهن ۾",
        day: "ڏينهن",
        seconds: "سيڪنڊن ۾",
        second: "سيڪنڊ",
        minute: "منٽ",
        minutes: "min",
        hours: "ڪلاڪ ۾",
        hour: "ڪلاڪ",
    },
    si: {
        days: "න්",
        day: "දිනය",
        seconds: "කින්",
        second: "තත්පරය",
        minute: "මිනිත්තුව",
        minutes: "කින්",
        hours: "කින්",
        hour: "පැය",
    },
    sk: {
        days: "dní",
        day: "deň",
        seconds: "sekúnd",
        second: "sekunda",
        minute: "minúta",
        minutes: "minút",
        hours: "hodín",
        hour: "hodina",
    },
    sl: {
        days: "dni",
        day: "dan",
        seconds: "sekund",
        second: "sekunda",
        minute: "minuta",
        minutes: "minut",
        hours: "ur",
        hour: "ura",
    },
    so: {
        days: "maalmood",
        day: "maalin",
        seconds: "ilbiriqsi",
        second: "ilbiriqsi",
        minute: "daqiiqad",
        minutes: "daqiidadood",
        hours: "saacadood",
        hour: "Saacad",
    },
    es: {
        days: "días",
        day: "día",
        seconds: "segundos",
        second: "segundo",
        minute: "minuto",
        minutes: "minutos",
        hours: "horas",
        hour: "hora",
    },
    sw: {
        days: "baada ya siku",
        day: "siku",
        seconds: "baada ya sekunde",
        second: "sekunde",
        minute: "dakika",
        minutes: "baada ya dakika",
        hours: "baada ya saa",
        hour: "saa",
    },
    tl: {
        days: "araw",
        day: "araw",
        seconds: "segundo",
        second: "segundo",
        minute: "minuto",
        minutes: "minuto",
        hours: "oras",
        hour: "oras",
    },
    tg: {
        days: "рӯз",
        day: "рӯз",
        seconds: "сония",
        second: "сония",
        minute: "дақиқа",
        minutes: "дақиқа",
        hours: "соат",
        hour: "соат",
    },
    ta: {
        days: "நாட்களில்",
        day: "நாள்",
        seconds: "விநாடிகளில்",
        second: "விநாடி",
        minute: "நிமிடம்",
        minutes: "நிமிடங்களில்",
        hours: "மணிநேரத்தில்",
        hour: "மணி",
    },
    tt: {
        days: "көннән",
        day: "көн",
        seconds: "секундтан",
        second: "секунд",
        minute: "минут",
        minutes: "минуттан",
        hours: "сәгатьтән",
        hour: "сәгать",
    },
    te: {
        days: "రోజుల్లో",
        day: "దినం",
        seconds: "సెకన్లలో",
        second: "సెకను",
        minute: "నిమిషము",
        minutes: "నిమిషాల్లో",
        hours: "గంటల్లో",
        hour: "గంట",
    },
    th: {
        days: "วัน",
        day: "วัน",
        seconds: "วินาที",
        second: "วินาที",
        minute: "นาที",
        minutes: "นาที",
        hours: "ชั่วโมง",
        hour: "ชั่วโมง",
    },
    ti: {
        days: "መዓልቲ",
        day: "መዓልቲ",
        seconds: "ካልኢት",
        second: "ካልኢት",
        minute: "ደቒቕ",
        minutes: "ደቒቕ",
        hours: "ሰዓት",
        hour: "ሰዓት",
    },
    to: {
        days: "ʻi he ʻaho ʻe",
        day: "ʻaho",
        seconds: "ʻi he sekoni ʻe",
        second: "sekoni",
        minute: "miniti",
        minutes: "ʻi he miniti ʻe",
        hours: "ʻi he houa ʻe",
        hour: "houa",
    },
    tr: {
        days: "gün sonra",
        day: "gün",
        seconds: "saniye sonra",
        second: "saniye",
        minute: "dakika",
        minutes: "dakika sonra",
        hours: "saat sonra",
        hour: "saat",
    },
    tk: {
        days: "günden",
        day: "gün",
        seconds: "sekuntdan",
        second: "sekunt",
        minute: "minut",
        minutes: "minutdan",
        hours: "sagatdan",
        hour: "sagat",
    },
    ug: {
        days: "كۈندىن كېيىن",
        day: "كۈن",
        seconds: "سېكۇنتتىن كېيىن",
        second: "سېكۇنت",
        minute: "مىنۇت",
        minutes: "مىنۇتتىن كېيىن",
        hours: "سائەتتىن كېيىن",
        hour: "سائەت",
    },
    uk: {
        days: "днів",
        day: "день",
        seconds: "секунд",
        second: "секунда",
        minute: "хвилина",
        minutes: "хвилин",
        hours: "годин",
        hour: "година",
    },
    ur: {
        days: "دنوں میں",
        day: "دن",
        seconds: "سیکنڈ میں",
        second: "سیکنڈ",
        minute: "منٹ",
        minutes: "منٹ میں",
        hours: "گھنٹے میں",
        hour: "گھنٹہ",
    },
    uz: {
        days: "kundan keyin",
        day: "kun",
        seconds: "soniyadan keyin",
        second: "soniya",
        minute: "daqiqa",
        minutes: "daqiqadan keyin",
        hours: "soatdan keyin",
        hour: "soat",
    },
    vi: {
        days: "ngày nữa",
        day: "Ngày",
        seconds: "giây nữa",
        second: "Giây",
        minute: "Phút",
        minutes: "phút nữa",
        hours: "giờ nữa",
        hour: "Giờ",
    },
    cy: {
        days: "diwrnod",
        day: "diwrnod",
        seconds: "eiliad",
        second: "eiliad",
        minute: "munud",
        minutes: "munud",
        hours: "awr",
        hour: "awr",
    },
    fy: {
        days: "deien",
        day: "dei",
        seconds: "sekonden",
        second: "Sekonde",
        minute: "Minút",
        minutes: "minuten",
        hours: "oere",
        hour: "oere",
    },
    wo: {
        days: "fan",
        day: "fan",
        seconds: "saa",
        second: "saa",
        minute: "simili",
        minutes: "simili",
        hours: "waxtu",
        hour: "waxt",
    },
    yi: {
        days: "טעג אַרום",
        day: "טאָג",
        seconds: "s",
        second: "סעקונדע",
        minute: "מינוט",
        minutes: "min",
        hours: "h",
        hour: "שעה",
    },
    zu: {
        days: "ezizayo",
        day: "Usuku",
        seconds: "ezayo",
        second: "Isekhondi",
        minute: "Iminithi",
        minutes: "ezayo",
        hours: "ezayo",
        hour: "Ihora",
    },
};
