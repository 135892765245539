import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./CountdownEdit.module.scss";
import { ISiteModuleCountdown } from "../../../../interfaces/ISiteModule";
import { DialogTop } from "../../../../editComponents/DialogTop/DialogTop";
import { Input } from "../../../../editComponents/form/Input/Input";
import { ContentBox } from "../../../../editComponents/ContentBox/ContentBox";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleCountdown;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleCountdown, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
    close: () => void;
}

export const CountdownEdit = (props: IProps) => {
    const handleChange = (value: string) => {
        props.updateSiteModuleProperty("countdownDate", value);
    };
    return (
        <>
            <DialogTop title="Nedräkning" close={props.close} />
            <div className={styles.wrapper}>
                <ContentBox padding={true}>
                <Input max="2099-01-01" min="1900-01-01" label="Datum att räkna ner till" type="datetime-local" value={props.siteModule.countdownDate || ""} handleChange={handleChange} id={"countdown-date-edit"} name={"countdown-date-edit"} />
                </ContentBox>
            </div>
        </>
    );
};
