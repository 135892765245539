import { Helmet } from "react-helmet";
import { ISiteV2 } from "../../../../interfaces/Site";
import styles from "./ThemeStylesSetter.module.scss";
import { getColorFromSite } from "../../../../utils/Style";

interface IProps {
    site: ISiteV2;
    children: React.ReactNode;
}

export function ThemeStylesSetter(props: IProps) {
    return (
        <>
            <Helmet>
                <style type="text/css">{`
                        body {
                            background-color: ${getColorFromSite(props.site, "background")};
                        }
                    `}</style>
                <meta name="theme-color" content={getColorFromSite(props.site, "background")} />
            </Helmet>
            <div className={`${styles[props.site.theme]}`}>{props.children}</div>
        </>
    );
}
