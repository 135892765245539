import { ISiteV2 } from "../../interfaces/Site";
import styles from "./SiteModuleCountdown.module.scss";
import { ISiteModuleCountdown } from "../../interfaces/ISiteModule";
import { useState } from "react";
import { Countdown } from "./components/Countdown/Countdown";
import { Dialog } from "../../editComponents/Dialog/Dialog";
import { CountdownEdit } from "./components/CountdownEdit/CountdownEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleCountdown;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleCountdown, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
}

export const SiteModuleCountdownEdit = (props: IProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <section className={styles[props.site.theme]}>
            <Dialog small={true} open={showModal} handleClose={() => setShowModal(false)} closableByOutsideClickAndEsc={true}>
                <CountdownEdit
                    site={props.site}
                    siteModule={props.siteModule}
                    updateSiteModuleProperty={props.updateSiteModuleProperty}
                    close={() => setShowModal(false)}
                />
            </Dialog>
            <Countdown onClick={() => setShowModal(true)} siteModule={props.siteModule} site={props.site} />
        </section>
    );
};
