import styles from "./EditMoreV2.module.scss";
import { Drawer } from "../../../../components/Drawer/Drawer";
import { ILanguage, ISiteV2 } from "../../../../interfaces/Site";
import { EditHost } from "../../../../modules/EditHost/EditHost";
import { EditPassword } from "../../../../modules/EditPassword/EditPassword";
import { SiteQrCode } from "../../../../modules/SiteQrCode/SiteQrCode";
import { EditMetaNoindex } from "../../../../modules/EditMetaNoindex/EditMetaNoindex";
import { EditLanguages } from "../../../../modules/EditLanguages/EditLanguages";
import { EditVersions } from "../../../../modules/EditVersions/EditVersions";

interface IProps {
    site: ISiteV2;
    close: () => void;
    open: boolean;
    portalNode?: JSX.Element;
    quickStart: boolean;
    setAvailableLanguages: (languages: ILanguage[]) => void;
}

export function EditMoreV2(props: IProps) {
    return (
        <Drawer title="Mer" drawerId="editMore" close={props.close} open={props.open} portalNode={props.portalNode}>
            <>
                <div className={styles.block}>
                    <h3 className={styles.title}>Adress</h3>
                    <EditHost site={props.quickStart ? undefined : props.site} />
                </div>
                <div className={styles.blockNoPadding}>
                    <h3 className={styles.title}>Språk</h3>
                    <EditLanguages site={props.site} setAvailableLanguages={props.setAvailableLanguages} />
                </div>
                <div className={styles.block}>
                    <h3 className={styles.title}>Lösenord för gästerna</h3>
                    <EditPassword quickStart={props.quickStart} site={props.site} />
                </div>
                <div className={styles.block}>
                    <h3 className={styles.title}>QR-kod</h3>
                    <SiteQrCode site={props.quickStart ? undefined : props.site} />
                </div>
                <div className={styles.block}>
                    <h3 className={styles.title}>Synlighet i sökmotorer</h3>
                    <EditMetaNoindex quickStart={props.quickStart} site={props.site} />
                </div>
                <div className={`${styles.blockNoPadding} ${styles.positionRelativeDueToLoading}`}>
                    <h3 className={styles.title}>Tidigare versioner</h3>
                    <EditVersions site={props.site} quickStart={props.quickStart}  />
                </div>
            </>
        </Drawer>
    );
}
