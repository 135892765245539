const envelopCosts = { none: 0, standard: 300, premium: 500 };
const printCost = 1500;
const trimCosts = { none: 0, rounded: 200 };

const currencyFormatter = (number: number) => {
    return new Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: "SEK",
        maximumSignificantDigits: 10,
    }).format(number);
};

const getPrintProductCost = (
    envelopes: "standard" | "premium" | "none",
    numberOfPrints: number,
    trim: "none" | "rounded"
) => {
    return (envelopCosts[envelopes] + printCost + trimCosts[trim]) * numberOfPrints;
};

export const getPrintProductCostFormatted = (
    envelopes: "standard" | "premium" | "none",
    numberOfPrints: number,
    trim: "none" | "rounded"
) => {
    return currencyFormatter(getPrintProductCost(envelopes, numberOfPrints, trim) / 100);
};

export const getFreightCost = () => {
    return 9500;
};

export const getFreightCostFormatted = () => {
    return currencyFormatter(getFreightCost() / 100);
};

export const getTotalCost = (
    envelopes: "standard" | "premium" | "none",
    numberOfPrints: number,
    trim: "none" | "rounded"
) => {
    return getFreightCost() + getPrintProductCost(envelopes, numberOfPrints, trim);
};

export const getTotalVat = (
    envelopes: "standard" | "premium" | "none",
    numberOfPrints: number,
    trim: "none" | "rounded"
) => {
    return (getFreightCost() + getPrintProductCost(envelopes, numberOfPrints, trim)) * 0.2;
};

export const getTotalCostFormatted = (
    envelopes: "standard" | "premium" | "none",
    numberOfPrints: number,
    trim: "none" | "rounded"
) => {
    return currencyFormatter(getTotalCost(envelopes, numberOfPrints, trim) / 100);
};

export const getTotalVatFormatted = (
    envelopes: "standard" | "premium" | "none",
    numberOfPrints: number,
    trim: "none" | "rounded"
) => {
    return currencyFormatter(getTotalVat(envelopes, numberOfPrints, trim) / 100);
};
