import Axios from "axios";
import { useState } from "react";
import Button from "../../components/button/Button";
import { Switch } from "../../components/form/Switch/Switch";
import Loading from "../../components/Loading/Loading";
import { ISite, ISiteV2 } from "../../interfaces/Site";
import styles from "./EditMetaNoindex.module.scss";
import ShowError from "../../components/ShowError/ShowError";
import { IError } from "../../../App";

interface IProps {
    site?: ISite | ISiteV2;
    quickStart: boolean;
}

export const EditMetaNoindex = (props: IProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [savedMetaNoindex, setSavedMetaNoindex] = useState(props.site?.metaNoindex || false);
    const [metaNoindexToggle, setMetaNoindexToggle] = useState(props.site?.metaNoindex || false);
    const [error, setError] = useState<IError | null>(null);

    const savedDiffs = () => {
        return savedMetaNoindex !== metaNoindexToggle;
    };

    const saveMetaNoindex = () => {
        if (!props.site) {
            throw new Error("No site when saving metaNoindex");
        }
        setIsLoading(true);
        Axios.put(process.env.REACT_APP_API_URL + "/api/sites/" + props.site._id + "/metaNoindex", {
            metaNoindex: metaNoindexToggle,
        })
            .then((response) => {
                setIsLoading(false);
                setSavedMetaNoindex(metaNoindexToggle);
            })
            .catch((err) => {
                setIsLoading(false);
                setError({ text: "Misslyckades att uppdatera synlighet i sökmotorer", response: err.response });
            });
    };

    const saveButtonText = metaNoindexToggle ? "Spara - dölj på Google" : "Spara - Visa på Google";

    return (
        <>
            <ShowError error={error} />
            {isLoading === true && <Loading></Loading>}
            <div className={styles.text}>
                Om du inte vill att din sida indexeras av sökmotorer (Google med flera) ber vi dem att inte visa ditt
                innehåll.
            </div>
            <div className={styles.checkboxWrapper}>
                <Switch
                    label={"Be sökmotorer att dölja sidan"}
                    name="metaNoindexToggle"
                    handleChange={setMetaNoindexToggle}
                    value={metaNoindexToggle}
                    id="metaNoindexToggle"
                ></Switch>
            </div>
            <div className={styles.fieldAndButtonWrapper}>
                {savedDiffs() && props.quickStart !== true ? (
                    <div>
                        <Button buttonText={saveButtonText} type="primary" callback={saveMetaNoindex} />
                    </div>
                ) : (
                    ""
                )}
            </div>
            {savedDiffs() ? (
                props.quickStart !== true ? (
                    ""
                ) : (
                    <div className={styles.publishFirst}>
                        Du måste spara sidan innan du kan ändra synlighet på sökmotorer
                    </div>
                )
            ) : (
                ""
            )}
        </>
    );
};
