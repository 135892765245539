import styles from "./SiteModuleImageAndText.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { ISiteModuleImageAndText } from "../../interfaces/ISiteModule";
import { ContentTypeTitleEdit } from "../../contentTypes/ContentTypeTitle/ContentTypeTitleEdit";
import { IContentDataImage, IContentDataRichText, IContentDataTitle } from "../../interfaces/IContentData";
import { ContentTypeModuleHeadingEdit } from "../../contentTypes/ContentTypeModuleHeading/ContentTypeModuleHeadingEdit";
import { ContentTypeRichTextEdit } from "../../contentTypes/ContentTypeRichText/ContentTypeRichTextEdit";
import { ContentTypeImageEdit } from "../../contentTypes/ContentTypeImage/ContentTypeImageEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleImageAndText;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleImageAndText, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
}

export const SiteModuleImageAndTextEdit = (props: IProps) => {

    return (
        <section className={styles[props.site.theme]}>
            <ContentTypeModuleHeadingEdit
                contentData={props.siteModule.title}
                site={props.site}
                updateContentData={(value: IContentDataTitle) => props.updateSiteModuleProperty("title", value)}
            />
            <div className={styles.imageAndTextWrapper}>
                <div className={styles.imageWrapper}>
                    <ContentTypeImageEdit
                        contentData={props.siteModule.image}
                        site={props.site}
                        sizes="(min-width: 968px) 968px, 100vw"
                        updateContentData={(value: IContentDataImage) => props.updateSiteModuleProperty("image", value)}
                    />
                </div>
                <div className={styles.textContentWrapper}>
                    <div className={styles.innerTextContentWrapper}>
                        <ContentTypeTitleEdit
                            tag="h3"
                            contentData={props.siteModule.secondTitle}
                            site={props.site}
                            updateContentData={(value: IContentDataTitle) =>
                                props.updateSiteModuleProperty("secondTitle", value)
                            }
                        />
                        <div className={styles.textWrapper}>
                            <ContentTypeRichTextEdit
                                contentData={props.siteModule.text}
                                site={props.site}
                                updateContentData={(value: IContentDataRichText) =>
                                    props.updateSiteModuleProperty("text", value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
