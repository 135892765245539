import { useState } from "react";
import styles from "./RsvpQuestionTextAnswersSummary.module.scss";
import { IRsvpFilter } from "../RsvpFilter/RsvpFilter";
import { IRsvpV2 } from "../../../../ViewRsvp";
import { getInRsvpTranslationOneLanguage } from "../../../../utils/getInRsvpTranslation";
import { Switch } from "../../../../../../../../editComponents/form/Switch/Switch";
import { IFormElementSummary } from "../../../../utils/uniqueFormElementsFromRsvps";

interface IProps {
    rsvps: IRsvpV2[];
    filter: IRsvpFilter;
    question: IFormElementSummary;
}

export function RsvpQuestionTextAnswersSummary(props: IProps) {
    const [uniqueValuesFilter, setUniqueValuesFilter] = useState<boolean>(false);

    const handleUniqueChange = (value: boolean) => {
        setUniqueValuesFilter(value);
    };

    let values = props.rsvps.map((rsvp) => {
        const formElement = rsvp.formElements
            .find((formElement) => formElement.formElement.id === props.question.id);
        return formElement?.value;
    }).filter(value => !!value);

    if (uniqueValuesFilter) {
        values = Array.from(new Set(values));
    }

    const summary = values?.length ? values.join(", ") : null;

    return (
        <>
            {props.filter.answer ? (
                <div className={styles.label}>
                    {uniqueValuesFilter ? "Unika svar" : "Svar"} av personer som svarat "
                    {getInRsvpTranslationOneLanguage(props.filter?.answer?.text)}" på frågan "
                    {getInRsvpTranslationOneLanguage(props.filter.question?.label)}":
                </div>
            ) : uniqueValuesFilter ? (
                <div className={styles.label}>Endast unika svar:</div>
            ) : null}
            <div>{summary}</div>
            <div className={styles.switchWrapper}>
                <Switch
                    handleChange={handleUniqueChange}
                    value={uniqueValuesFilter}
                    name="uniqueValuesFilter"
                    id="uniqueValuesFilter"
                    label="Visa endast unika svar (slå ihop dubbletter)"
                    labelPosition="RIGHT"
                />
            </div>
        </>
    );
}
