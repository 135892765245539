import { ContentTypeButtonEdit } from "../../../../../../contentTypes/ContentTypeButton/ContentTypeButtonEdit";
import { ContentBox } from "../../../../../../editComponents/ContentBox/ContentBox";
import { IContentDataText } from "../../../../../../interfaces/IContentData";
import { ISiteModuleGiftRegistry } from "../../../../../../interfaces/ISiteModule";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { EmulateContentSurface } from "../../../../../components/EmulateContentSurface/EmulateContentSurface";
import { LanguageTitle } from "../../../../../components/LanguageTitle/LanguageTitle";
import { VerticalGapOnChildren } from "../../../../../components/VerticalGapOnChildren/VerticalGapOnChildren";
import styles from "./ReserveAndCancelReservationButtons.module.scss";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleGiftRegistry;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleGiftRegistry, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
}

export const ReserveAndCancelReservationButtons = (props: IProps) => {
    return (
        <>
            <ContentBox padding={true} className={styles.wrapper} title="Reservera-knappar">
                <p>Knapparna för att reservera eller ångra en reservation. Tryck på texten för att ändra den.</p>
                <VerticalGapOnChildren>
                    {props.site.availableLanguages.map((language) => (
                        <div key={language.languageCode}>
                            <LanguageTitle availableLanguages={props.site.availableLanguages} language={language} />
                            <EmulateContentSurface site={props.site}>
                                <div className={styles.buttonsWrapper}>
                                    <ContentTypeButtonEdit
                                        site={props.site}
                                        contentData={props.siteModule.reserveButton}
                                        updateContentData={(value: IContentDataText) =>
                                            props.updateSiteModuleProperty("reserveButton", value)
                                        }
                                        placeholder={"Ingen text"}
                                        small={true}
                                        language={language.languageCode}
                                    />
                                    <ContentTypeButtonEdit
                                        site={props.site}
                                        contentData={props.siteModule.cancelReservationButton}
                                        updateContentData={(value: IContentDataText) =>
                                            props.updateSiteModuleProperty("cancelReservationButton", value)
                                        }
                                        placeholder={"Ingen text"}
                                        small={true}
                                        language={language.languageCode}
                                    />
                                </div>
                            </EmulateContentSurface>
                        </div>
                    ))}
                </VerticalGapOnChildren>
            </ContentBox>
        </>
    );
};
