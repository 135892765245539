import React from "react";
import styles from "./LanguageTitle.module.scss";
import { languageFromLanguageCode } from "../../../../utils/LanguageHelpers";
import { ILanguage } from "../../../interfaces/Site";

interface IProps {
    availableLanguages: ILanguage[];
    language: ILanguage;
}

export const LanguageTitle = (props: IProps) => {
    if (props.availableLanguages.length < 2) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            {languageFromLanguageCode(props.language.languageCode)}
        </div>
    );
};
