import { TSiteModule, TSiteModuleType } from "../../interfaces/ISiteModule";
import { getNewSiteModuleCountdown } from "./getNewSiteModuleCountdown";
import { getNewSiteModuleForm } from "./getNewSiteModuleForm";
import { getNewSiteModuleGiftRegistry } from "./getNewSiteModuleGiftRegistry";
import { getNewSiteModuleHeader } from "./getNewSiteModuleHeader";
import { getNewSiteModuleImageAndText } from "./getNewSiteModuleImageAndText";
import { getNewSiteModuleLocation } from "./getNewSiteModuleLocation";
import { getNewSiteModulePeopleList } from "./getNewSiteModulePeopleList";
import { getNewSiteModuleRsvp } from "./getNewSiteModuleRsvp";
import { getNewSiteModuleText } from "./getNewSiteModuleText";
import { getNewSiteModuleTextAndImageList } from "./getNewSiteModuleTextAndImageList";
import { getNewSiteModuleVideo } from "./getNewSiteModuleVideo";

export const scrollToSiteModuleAfterUpdate = (newModuleSlug: string) => {
    setTimeout(() => {
        const newModuleElement = document.querySelector("#" + newModuleSlug);
        if (newModuleElement) {
            newModuleElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    });
};

export const addSiteModule = (
    moduleCollection: TSiteModule[],
    moduleType: TSiteModuleType,
    moduleToPutNewModelBefore?: TSiteModule
) => {
    let newModule: TSiteModule;

    if (moduleType === "Header") {
        newModule = getNewSiteModuleHeader();
    } else if (moduleType === "Location") {
        newModule = getNewSiteModuleLocation();
    } else if (moduleType === "ImageAndText") {
        newModule = getNewSiteModuleImageAndText();
    } else if (moduleType === "Video") {
        newModule = getNewSiteModuleVideo();
    } else if (moduleType === "Text") {
        newModule = getNewSiteModuleText();
    } else if (moduleType === "TextAndImageList") {
        newModule = getNewSiteModuleTextAndImageList();
    } else if (moduleType === "PeopleList") {
        newModule = getNewSiteModulePeopleList();
    } else if (moduleType === "GiftRegistry") {
        newModule = getNewSiteModuleGiftRegistry();
    } else if (moduleType === "Rsvp") {
        newModule = getNewSiteModuleRsvp();
    } else if (moduleType === "Form") {
        newModule = getNewSiteModuleForm();
    } else if (moduleType === "Countdown") {
        newModule = getNewSiteModuleCountdown();
    } else {
        throw Error("Module type " + moduleType + " not found when creating new module");
    }
    if (moduleToPutNewModelBefore) {
        const index = moduleCollection.findIndex((moduleItem) => moduleItem.id === moduleToPutNewModelBefore.id);
        moduleCollection.splice(index, 0, newModule);
    } else {
        moduleCollection.push(newModule);
    }
    return { modules: moduleCollection, newModule };
};

export const deleteSiteModule = (moduleCollection: TSiteModule[], module: TSiteModule) => {
    const index = moduleCollection.findIndex((moduleItem) => moduleItem.id === module.id);
    moduleCollection.splice(index, 1);
    return moduleCollection;
};

export const siteModuleIsLast = (moduleCollection: TSiteModule[], module: TSiteModule) => {
    const index = moduleCollection.indexOf(module);
    return index === moduleCollection.length - 1;
};

export const siteModuleIsFirst = (moduleCollection: TSiteModule[], module: TSiteModule) => {
    const index = moduleCollection.indexOf(module);
    return index === 0;
};

export const siteModuleMove = (moduleCollection: TSiteModule[], id: string, indexAdjustment: number) => {
    const old_index = moduleCollection.findIndex((module) => module.id === id);
    if (!module) {
        throw new Error("Module not found in siteModuleMove");
    }
    let new_index = old_index + indexAdjustment;

    if (new_index < 0) {
        new_index = 0;
    }

    if (new_index >= moduleCollection.length) {
        new_index = moduleCollection.length;
    }

    moduleCollection.splice(new_index, 0, { ...moduleCollection.splice(old_index, 1)[0] });
    return moduleCollection;
};
