import React from "react";
import { convertLineBreaksToHtmlBreaks } from "../../utils/convertLineBreaksToHtmlBreaks";
import styles from "./ContentTypeText.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataText, TLanguageCode } from "../../interfaces/IContentData";
import { useTranslation } from "../../../contexts/LanguageContext";
import { TranslateModePreview } from "../../editComponents/TranslateModePreview/TranslateModePreview";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataText;
    updateContentData: (value: IContentDataText) => void;
    className?: string;
    tag: "div" | "span";
    placeholder: string;
    language?: TLanguageCode;
}

export const ContentTypeTextEdit = (props: IProps) => {
    const { getInTranslation, setInTranslation } = useTranslation();

    const updateContent = (content: IContentDataText) => {
        props.updateContentData(content);
    };

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        let targetElement = e.target;
        if (getInTranslation(props.contentData.text, props.language) !== targetElement.innerText) {
            updateContent({
                ...props.contentData,
                text: setInTranslation(props.contentData.text, targetElement.innerText, props.language),
            });
        }
    };

    const alwaysHideTranslateModeBecauseNotOnPage = !!props.language;

    return (
        <>
            <props.tag
                className={`${styles.editable} ${props.className}`}
                placeholder={props.placeholder}
                contentEditable
                suppressContentEditableWarning={true}
                onBlur={handleBlur}
                dangerouslySetInnerHTML={convertLineBreaksToHtmlBreaks(
                    getInTranslation(props.contentData.text, props.language)
                )}
            />
            {alwaysHideTranslateModeBecauseNotOnPage ? null : (
                <TranslateModePreview languages={props.site.availableLanguages} translation={props.contentData.text} />
            )}
        </>
    );
};
