import { Updater } from "use-immer";
import { ISiteV2 } from "../../interfaces/Site";
import styles from "./MenuEdit.module.scss";
import { DialogTop } from "../../editComponents/DialogTop/DialogTop";
import { MenuItemEdit } from "./components/MenuItemEdit/MenuItemEdit";

interface IProps {
    site: ISiteV2;
    updateSite: Updater<ISiteV2>;
    close: () => void;
}

export const MenuEdit = (props: IProps) => {
    return (
        <>
            <DialogTop title="Ändra meny" close={props.close} />
            <div className={styles.wrapper}>
                {props.site.siteModules.map((siteModule) => (
                    <MenuItemEdit key={siteModule.id} site={props.site} siteModule={siteModule} updateSite={props.updateSite} />
                ))}
            </div>
        </>
    );
};
