import { IFormElementSingleSelect, TFormElement } from "../../../../../../interfaces/FormElement";
import styles from "./FormConditionIndicationText.module.scss";

interface IProps {
    formElements: TFormElement[];
    formElement: TFormElement;
}

export function FormConditionIndicationText(props: IProps) {
    const condition = props.formElement.showConditions[0];
    const conditionQuestionId = condition?.formElementId;
    const conditionAnswerId = condition?.formElementOptionIds[0];

    const selectElements =
        props.formElements
            ?.filter((item) => item.formElementType === "SingleSelect")
            .filter((item) => item.id !== props.formElement.id) || [];

    const conditionQuestion = selectElements.find(
        (item) => item.id === conditionQuestionId
    ) as IFormElementSingleSelect; // Remove as when typescript 5 >
    const conditionAnswer = conditionQuestion?.options.find((option) => option.id === conditionAnswerId);

    if (!conditionAnswerId) {
        return null;
    }

    if (!conditionQuestion || !conditionAnswer) {
        return (
            <span className={styles.warning}>
                Felaktigt beroende - visas alltid
            </span>
        );
    }

    return <span>Visas beroende på annan fråga</span>;
}
