import styles from "./ContentTypeRichText.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { IContentDataRichText } from "../../interfaces/IContentData";
import { rteContentIsEmpty } from "../../utils/rteContentIsEmpty";
import { useTranslation } from "../../../contexts/LanguageContext";

interface IProps {
    site: ISiteV2;
    contentData: IContentDataRichText;
}

export const ContentTypeRichText = (props: IProps) => {

    const { getInTranslation } = useTranslation();

    const createMarkup = (data: string) => {
        let markup = "<span></span>";
        if (!rteContentIsEmpty(data)) {
            markup = data;
        }

        return { __html: markup };
    };

    return (
        <div className={styles.wrapper}>
            <div dangerouslySetInnerHTML={createMarkup(getInTranslation(props.contentData.richText))}></div>
        </div>
    );
};
