import styles from "./SiteModuleHeader.module.scss";
import { ISiteV2 } from "../../interfaces/Site";
import { ISiteModuleHeader } from "../../interfaces/ISiteModule";
import { ContentTypeImageEdit } from "../../contentTypes/ContentTypeImage/ContentTypeImageEdit";
import { IContentDataImage, IContentDataText, IContentDataTitle } from "../../interfaces/IContentData";
import { ContentTypeTitleEdit } from "../../contentTypes/ContentTypeTitle/ContentTypeTitleEdit";
import { ContentTypeTextEdit } from "../../contentTypes/ContentTypeText/ContentTypeTextEdit";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleHeader;
    updateSiteModuleProperty: <SiteModule extends ISiteModuleHeader, Property extends keyof SiteModule>(
        property: keyof SiteModule,
        value: SiteModule[Property]
    ) => void;
}

export const SiteModuleHeaderEdit = (props: IProps) => {
    const titleVerticalAlignment = () => {
        if (props.site.theme === "theme9") {
            const verticalAlignment = props.siteModule.title.verticalAlignment || 0;
            if (verticalAlignment === -1) return styles["verticalAlignmentTop"];
            if (verticalAlignment === 1) return styles["verticalAlignmentBottom"];
        }
        return "";
    };
    const imageSize = props.siteModule.image?.userSelectedImageSize || null;

    return (
        <header className={`${styles[props.site.theme]} ${titleVerticalAlignment()}`}>
            <div className={styles.imageWrapper}>
                <div
                    className={`${imageSize ? styles.imageSizeConstrainer : null} ${
                        imageSize ? styles[imageSize] : null
                    }`}
                >
                    <ContentTypeImageEdit
                        contentData={props.siteModule.image}
                        userCanChooseWidth={true}
                        site={props.site}
                        sizes="100vw"
                        updateContentData={(value: IContentDataImage) => props.updateSiteModuleProperty("image", value)}
                    />
                </div>
            </div>
            <div className={styles.textWrapper}>
                <div className={styles.textInnerWrapper}>
                    <div
                        className={styles.yetAnotherInnerWrapper}
                    >
                        <ContentTypeTitleEdit
                            tag="h1"
                            contentData={props.siteModule.title}
                            site={props.site}
                            updateContentData={(value: IContentDataTitle) =>
                                props.updateSiteModuleProperty("title", value)
                            }
                            movableByUser={props.site.theme === "theme9"}
                        />
                        <ContentTypeTextEdit
                            className={`${styles.text} ${"font-" + props.site.fonts.text?.replace(" ", "_")}`}
                            tag="div"
                            contentData={props.siteModule.secondLine}
                            site={props.site}
                            updateContentData={(value: IContentDataText) =>
                                props.updateSiteModuleProperty("secondLine", value)
                            }
                            placeholder="Inget datum"
                        />
                    </div>
                </div>
            </div>
        </header>
    );
};
